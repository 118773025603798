import { newS3FileToCreateFileInput } from "@app/usecases/FileUseCases";
import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { Upload } from "iconoir-react";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    CreateWithUploadDocument,
    GetDocumentByUrlQuery,
} from "../../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import { useS3FilesManagement } from "../../../../../hooks/createFileHook";
import OptionLayout from "./OptionLayout";
import "./i18n";

const OptionUploadFile = ({
    getDocumentQuery,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
}) => {
    const { t } = useTranslation("CreateDocumentOption");

    const { isLoading, uploadFiletoS3 } = useS3FilesManagement();

    const createNewVersionMutation = useGraphQLMutation(
        CreateWithUploadDocument,
    );

    const onFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const newS3File = await uploadFiletoS3(e.target.files[0]);
        const createFileInput = newS3FileToCreateFileInput(newS3File);
        if (!createFileInput) {
            return;
        }
        createNewVersionMutation.mutate(
            {
                id: getDocumentQuery.data.documentByUrl?.id!,
                file: createFileInput,
            },
            { onSuccess: () => getDocumentQuery.refetch() },
        );
    };

    const uploadFileButton = (
        <ButtonRegular
            loading={
                isLoading ||
                createNewVersionMutation.isPending ||
                getDocumentQuery.isRefetching
            }
            className="min-w-[110px]"
        >
            <label className="cursor-pointer">
                <input
                    type="file"
                    hidden
                    onChange={onFileUpload}
                    accept={
                        getDocumentQuery?.data?.documentByUrl?.extType ===
                        "OTHER"
                            ? "application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            : "application/pdf"
                    }
                />
                {t("upload_button")}
            </label>
        </ButtonRegular>
    );

    return (
        <OptionLayout
            Icon={Upload}
            button={uploadFileButton}
            title={t("upload_title")}
            subtitle={t("upload_desc")}
        />
    );
};

export default OptionUploadFile;
