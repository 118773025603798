import { useQueryClient } from "@tanstack/react-query";
import copy from "copy-to-clipboard";
import { CopyIcon, EyeIcon, EyeOff, MailIcon, Pencil } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    CreateSustainabilityCenterDocument,
    GetSustainabilityCenterDocument,
    UpdateSustainabilityCenterConfigDocument,
} from "../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../QueryClientWithHeaders";
import ButtonLarge from "../../../components/Button/ButtonLarge";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../components/Button/ButtonShared";
import Container from "../../../components/Container";
import LocaleSelect from "../../../components/Dropdown/dropdowns/Locale";
import { useSetToast } from "../../../components/Toast";
import BodyHeader from "../../../shared/components/BodyHeader";
import { useSustainabilityCenter } from "../SustainabilityCenter/hooks/useSustainabilityCenter";
import "./i18n";

export default function SustainabilityCenterDashboard() {
    const { t } = useTranslation("SustainabilityCenterDashboard");

    const { sustainabilityCenter } = useSustainabilityCenter(false, undefined);
    const navigate = useNavigate();

    const client = useQueryClient();
    const { mutate: updateSustainabilityCenterConfig } = useGraphQLMutation(
        UpdateSustainabilityCenterConfigDocument,
    );

    const { mutate: createSustainabilityCenter } = useGraphQLMutation(
        CreateSustainabilityCenterDocument,
    );

    const { setToastSuccess, setToastError } = useSetToast();

    const setPublished = (publish: boolean) => {
        if (!sustainabilityCenter) return;
        updateSustainabilityCenterConfig(
            {
                id: sustainabilityCenter?.id,
                published: publish,
            },
            {
                onSuccess: () => {
                    publish
                        ? setToastSuccess(t("publish_success"))
                        : setToastSuccess(t("unpublish_success"));
                    client.invalidateQueries({
                        queryKey: useGraphQL.getKey(
                            GetSustainabilityCenterDocument,
                        ),
                    });
                },
                onError: () => {
                    publish
                        ? setToastError(t("publish_error"))
                        : setToastError(t("unpublish_error"));
                },
            },
        );
    };

    const createPage = () => {
        createSustainabilityCenter(
            {},
            {
                onSuccess: () => {
                    setToastSuccess(t("create_success"));
                    client.invalidateQueries({
                        queryKey: useGraphQL.getKey(
                            GetSustainabilityCenterDocument,
                        ),
                    });
                },
                onError: () => {
                    setToastError(t("create_error"));
                },
            },
        );
    };

    const copyToClipboard = (text: string) => {
        copy(text);
        setToastSuccess(t("copied_to_clipboard"));
    };

    const setLocale = (locale: string | undefined) => {
        if (!sustainabilityCenter) return;
        updateSustainabilityCenterConfig(
            {
                id: sustainabilityCenter?.id,
                locale: locale,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                    client.invalidateQueries({
                        queryKey: useGraphQL.getKey(
                            GetSustainabilityCenterDocument,
                        ),
                    });
                },
                onError: () => {
                    setToastError(t("update_error"));
                },
            },
        );
    };

    return (
        <>
            <Container>
                <BodyHeader
                    dataChameleon={{
                        key: "sustainability-center",
                        type: "help",
                    }}
                    title={t("title")}
                    subTitle={t("subtitle")}
                    action={
                        sustainabilityCenter ? (
                            <div className="flex space-x-2">
                                {sustainabilityCenter?.published && (
                                    <ButtonLarge
                                        text={t("open_page")}
                                        variant={ButtonType.FLAT_PRIMARY}
                                        onClick={() => {
                                            navigate(
                                                `/${sustainabilityCenter?.tenant?.uname}`,
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        ) : (
                            <ButtonLarge
                                text={t("create_page")}
                                variant={ButtonType.FLAT_PRIMARY}
                                onClick={createPage}
                            />
                        )
                    }
                />
                <div className="flex flex-col items-start h-[800px] p-4 gap-4 self-stretch">
                    {sustainabilityCenter && (
                        <div className="flex p-4 items-center gap-8 sefl-stretch border border-tertiary rounded-xxl w-full">
                            <div className="flex items-center gap-5 flex-1">
                                {sustainabilityCenter?.logo?.signedUrl && (
                                    <img
                                        className="w-16 h-16"
                                        src={
                                            sustainabilityCenter?.logo
                                                ?.signedUrl
                                        }
                                    ></img>
                                )}
                                <div className="flex flex-col items-start gap-2 flex-1">
                                    <div className="text-primary font-bold text-xl">
                                        {sustainabilityCenter?.name}
                                    </div>
                                    {sustainabilityCenter?.contact && (
                                        <div className="flex gap-2 items-center">
                                            <MailIcon className="w-4 h-4" />
                                            <div
                                                className="text-primary text-sm"
                                                style={{
                                                    color: sustainabilityCenter?.hexColor
                                                        ? sustainabilityCenter?.hexColor
                                                        : "var(--color-green-DEFAULT)",
                                                }}
                                            >
                                                {sustainabilityCenter?.contact}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-5">
                                <div className="flex items-end gap-4">
                                    <ButtonRegular
                                        text={t("copy_link")}
                                        variant={ButtonType.OUTLINED}
                                        IconRight={CopyIcon}
                                        onClick={() =>
                                            copyToClipboard(
                                                `https://certif.beavr.tech/${sustainabilityCenter?.tenant?.uname}`,
                                            )
                                        }
                                    />
                                    <LocaleSelect
                                        locale={sustainabilityCenter?.locale}
                                        setLocale={setLocale}
                                    />
                                    <ButtonRegular
                                        text={t("edit_page")}
                                        IconRight={Pencil}
                                        variant={ButtonType.OUTLINED}
                                        onClick={() => {
                                            navigate(
                                                `/o/${sustainabilityCenter?.tenant?.uname}/sustainability/edit`,
                                            );
                                        }}
                                    />
                                    {sustainabilityCenter?.published ? (
                                        <ButtonRegular
                                            text={t("unpublish")}
                                            IconRight={EyeOff}
                                            variant={ButtonType.OUTLINED}
                                            onClick={() => setPublished(false)}
                                        />
                                    ) : (
                                        <ButtonRegular
                                            text={t("publish")}
                                            IconRight={EyeIcon}
                                            variant={ButtonType.OUTLINED}
                                            onClick={() => setPublished(true)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </>
    );
}
