import { isAiChatModalOpenAtom } from "@app/screens/Global/AskAIModal/ctx";
import { currentUserRoleAtom } from "@app/store/userStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue, useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import "../i18n";

export default function TopBarAskAI() {
    const { isBeavrAdmin } = useAtomValue(currentUserRoleAtom);
    const { org_uname } = useParams();

    const show = isBeavrAdmin && org_uname === "dev";
    const setIsAiChatModalOpen = useSetAtom(isAiChatModalOpenAtom);

    const handleButtonClick = () => {
        setIsAiChatModalOpen(true);
    };

    return show ? (
        <Button size="sm" onClick={handleButtonClick}>
            <FlexRow p="1.5" gap="2" alignItems="center" className="h-[26px]">
                <Text variant="body-bold">Ask AI</Text>
                <Icon name="magicWand" size="sm"></Icon>
            </FlexRow>
        </Button>
    ) : null;
}
