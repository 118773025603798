import {
    saveVersionDataAtom,
    updateLastEditedTimeAtom,
} from "@app/store/versionStore";
import { useAtomValue } from "jotai";
import {
    DocumentVersion,
    GeneratePdfDocDocument,
} from "../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../QueryClientWithHeaders";
import { browserDownload } from "../../../lib/utils-browser";

export const usePDFGenerator = (version?: DocumentVersion) => {
    const { mutateAsync, isPending } = useGraphQLMutation(
        GeneratePdfDocDocument,
    );
    const generatePdf = async () => {
        if (!version) {
            return;
        }
        const data = await mutateAsync({ versionId: version.id });
        data.exportDocBeta.signedUrl &&
            browserDownload(data.exportDocBeta.signedUrl, true);
    };

    return { generatePdf, isPending };
};

export const useSaveDocumentData = () => {
    const { mutate } = useAtomValue(saveVersionDataAtom);

    const { mutateAsync: updateLastEditedTimeMutate } = useAtomValue(
        updateLastEditedTimeAtom,
    );

    const saveDocumentData = async () => {
        mutate();
        await updateLastEditedTimeMutate();
    };
    return { saveDocumentData };
};
