import Checkbox from "@app/components/Checkbox";
import { reportingFrameworks } from "@app/usecases/ReportingUseCases";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Flex, FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function FrameworkMultiSelect({
    frameworkIds,
    setFrameworkIds,
}: {
    frameworkIds: (string | null)[] | undefined;
    setFrameworkIds: (frameworks: (string | null)[]) => void;
}) {
    const { t } = useTranslation("ReportingPage");

    const active = !!frameworkIds?.length;

    return (
        <MultiSelect
            values={frameworkIds || []}
            onValuesChange={setFrameworkIds}
        >
            <MultiSelect.Trigger>
                <HeaderFilter active={active}>
                    {t("indicator_table_frameworks_column_title", {
                        context: active && "active",
                        count: frameworkIds?.length,
                    })}
                </HeaderFilter>
            </MultiSelect.Trigger>
            <MultiSelect.Content>
                {reportingFrameworks.map(({ name, id }, idx) => (
                    <MultiSelect.Item key={idx} value={id}>
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                px="1.5"
                                py="2"
                                gap="3"
                                br="lg"
                                className="flex-1 hover:bg-secondary"
                            >
                                <Flex alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </Flex>
                                <Text variant="body-sm" className="capitalize">
                                    {name}
                                </Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
}
