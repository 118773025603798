import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import Checkbox from "@app/components/Checkbox";
import Loading from "@app/components/Loading";
import { orderSort } from "@app/lib/utils";
import { themesAtom } from "@app/store/themeStore";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";

import { Flex, FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtom } from "jotai";
import { ChevronDown } from "lucide-react";
import { FC, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

const SubthemeMultiSelect: FC<
    PropsWithChildren<{
        isHeader?: boolean;
        values: (string | null)[] | undefined;
        onValuesChange: (val: (string | null)[]) => void;
        searchFilter?: string | undefined;
        onSearchFilterChange?: (val: string | undefined) => void;
        hasPortal?: boolean;
        isModal?: boolean;
    }>
> = ({
    isHeader,
    hasPortal = false,
    values,
    onValuesChange,
    searchFilter,
    onSearchFilterChange,
    isModal,
}) => {
    const [{ data, isLoading }] = useAtom(themesAtom);
    const { t } = useTranslation("SubthemeMultiSelect");

    const filteredthemes = useMemo(() => {
        return orderSort(data?.themes || [])
            .map((theme) => {
                const filteredSubThemesIds = theme.subthemes
                    .filter(
                        ({ name }) =>
                            !searchFilter ||
                            name
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase()),
                    )
                    .map((elt) => elt.id);

                return { ...theme, filteredSubThemesIds: filteredSubThemesIds };
            })
            .filter((theme) => {
                return (
                    theme.subthemes.filter((elt) =>
                        theme.filteredSubThemesIds?.includes(elt.id),
                    ).length > 0
                );
            })
            .map((theme) => {
                const subthemes = orderSort(theme.subthemes).filter((elt) => {
                    return theme.filteredSubThemesIds?.includes(elt.id);
                });
                return {
                    ...theme,
                    subthemes: subthemes,
                    subThemeIds: subthemes.map((elt) => elt.id),
                };
            });
    }, [data, searchFilter]);

    const active = !!values?.length;

    return (
        <MultiSelect
            values={values || []}
            onValuesChange={onValuesChange}
            modal={isModal}
        >
            <MultiSelect.Trigger>
                {isHeader ? (
                    <HeaderFilter active={active}>
                        {t("placeholder", {
                            context: active && "active",
                            count: values?.length,
                        })}
                    </HeaderFilter>
                ) : (
                    <ButtonSmall
                        active={active}
                        variant={ButtonType.OUTLINED}
                        className="shadow-sm cursor-pointer focus:outline-none"
                        IconRight={ChevronDown}
                    >
                        {t("placeholder")}
                    </ButtonSmall>
                )}
            </MultiSelect.Trigger>
            <MultiSelect.Content hasPortal={hasPortal}>
                {isLoading && <Loading size="small" />}
                {!isLoading && onSearchFilterChange && (
                    <MultiSelect.Search
                        searchString={searchFilter || ""}
                        setSearchString={onSearchFilterChange}
                        placeholder={t("searchbar_placeholder")}
                    ></MultiSelect.Search>
                )}
                {!isLoading &&
                    filteredthemes.map((theme, idx) => (
                        <MultiSelect.Group key={idx}>
                            <>
                                <MultiSelect.Label
                                    value={theme.id || null}
                                    subItemsValues={theme.subThemeIds}
                                >
                                    {({ isGroupSelected }) => (
                                        <FlexRow
                                            alignItems="center"
                                            alignSelf="stretch"
                                            px="1.5"
                                            py="2"
                                            gap="3"
                                            br="lg"
                                            className={cn(
                                                "flex-1 hover:bg-secondary hover:text-green",
                                            )}
                                        >
                                            <Flex alignItems="center">
                                                <Checkbox
                                                    checked={isGroupSelected}
                                                    color={theme.color}
                                                ></Checkbox>
                                            </Flex>
                                            <Text variant="body-sm">
                                                {theme.name}
                                            </Text>
                                        </FlexRow>
                                    )}
                                </MultiSelect.Label>

                                {theme.subthemes.map((subtheme, idx2) => (
                                    <MultiSelect.Item
                                        key={idx2}
                                        value={subtheme.id}
                                    >
                                        {({ isSelected }) => (
                                            <FlexRow
                                                alignItems="center"
                                                alignSelf="stretch"
                                                p="1.5"
                                                gap="3"
                                                br="lg"
                                                className={cn(
                                                    " ml-[20px] flex-1 hover:bg-secondary hover:text-green",
                                                )}
                                            >
                                                <Flex alignItems="center">
                                                    <Checkbox
                                                        checked={isSelected}
                                                        color={theme.color}
                                                    />
                                                </Flex>
                                                <Ellipsis
                                                    asChild
                                                    className="whitespace-nowrap text-sm"
                                                >
                                                    <span>{subtheme.name}</span>
                                                </Ellipsis>
                                            </FlexRow>
                                        )}
                                    </MultiSelect.Item>
                                ))}
                            </>
                        </MultiSelect.Group>
                    ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
};

export default SubthemeMultiSelect;
