import { useQueryClient } from "@tanstack/react-query";

import {
    GetSupplierIndicatorsQuery,
    Indicator,
    IndicatorPatch,
    UpdateIndicatorDocument,
    UpdateIndicatorsDocument,
} from "../../../generated/client/graphql";
import { useGraphQLMutation } from "../../QueryClientWithHeaders";

const updateIndicatorsInplace = (
    indicators: Indicator[],
    indicatorId: string,
    patch: IndicatorPatch,
) => {
    const idx = indicators.findIndex((r) => r.id === indicatorId);
    if (idx === -1) {
        return { indicators };
    }
    indicators[idx] = {
        ...indicators[idx],
        ...(patch.documentAcceptance
            ? { documentAcceptance: patch.documentAcceptance }
            : undefined),
        ...(patch.tagIds
            ? {
                  tags: patch.tagIds.map((id) => ({
                      ...(indicators[idx].tags.find((t) => t.id === id) || {
                          name: "...",
                          color: "",
                      }),
                      id,
                  })),
              }
            : undefined),
        ...(patch.questionnaireIds
            ? {
                  supplierQuestionnaires: patch.questionnaireIds.map((id) => ({
                      ...(indicators[idx].supplierQuestionnaireTemplates?.find(
                          (q) => q.id === id,
                      ) || {
                          name: "...",
                          color: "",
                      }),
                      id,
                  })),
              }
            : undefined),
    } as Indicator;
};

export const useUpdateIndicator = (
    indicatorId: string,
    getIndicatorsKey: any[],
) => {
    const { mutate } = useGraphQLMutation(UpdateIndicatorDocument);

    const client = useQueryClient();
    const updateCache = (patch: IndicatorPatch) => {
        client.setQueryData<GetSupplierIndicatorsQuery>(
            getIndicatorsKey,
            (oldData) => {
                if (!oldData) {
                    return undefined;
                }
                const indicators = oldData.indicators;
                const updatedIndicators = [...(indicators || [])];

                updateIndicatorsInplace(
                    updatedIndicators as Indicator[],
                    indicatorId,
                    patch,
                );

                return {
                    indicators: updatedIndicators,
                };
            },
        );
    };
    const updateIndicator = (patch: IndicatorPatch) => {
        updateCache(patch);
        mutate(
            { id: indicatorId, patch },
            {
                onSuccess(data) {
                    client.setQueryData<GetSupplierIndicatorsQuery>(
                        getIndicatorsKey,
                        (oldData) => {
                            if (!oldData) {
                                return undefined;
                            }
                            const indicators = oldData.indicators;
                            const idx = indicators?.findIndex(
                                (r) => r.id === indicatorId,
                            );
                            if (idx === -1) {
                                return { indicators };
                            }
                            const updatedIndicators = [...(indicators || [])];
                            if (idx)
                                updatedIndicators[idx] = data.updateIndicator;

                            return { indicators: updatedIndicators };
                        },
                    );
                },
            },
        );
    };

    return { updateIndicator };
};

export const useUpdateIndicators = (getIndicatorsKey: any[]) => {
    const updateIndicatorMutation = useGraphQLMutation(
        UpdateIndicatorsDocument,
    );

    const client = useQueryClient();
    const updateCache = (indicatorIds: string[], patch: IndicatorPatch) => {
        client.setQueryData<GetSupplierIndicatorsQuery>(
            getIndicatorsKey,
            (oldData) => {
                if (!oldData) {
                    return undefined;
                }
                const indicators = oldData.indicators;
                const updatedIndicators = [...(indicators || [])];

                indicatorIds.forEach((indicatorId) =>
                    updateIndicatorsInplace(
                        updatedIndicators as Indicator[],
                        indicatorId,
                        patch,
                    ),
                );

                return {
                    indicators: updatedIndicators,
                };
            },
        );
    };
    const updateIndicators = (
        indicatorIds: string[],
        patch: IndicatorPatch,
        callback?: () => void,
    ) => {
        updateCache(indicatorIds, patch);
        updateIndicatorMutation.mutate(
            { inputs: indicatorIds.map((id) => ({ id, set: patch })) },
            {
                onSuccess: () => {
                    client.invalidateQueries({ queryKey: getIndicatorsKey });
                    callback?.();
                },
            },
        );
    };

    return { updateIndicators, isPending: updateIndicatorMutation.isPending };
};
