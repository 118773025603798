import ButtonLarge from "@app/components/Button/ButtonLarge";
import { ButtonType } from "@app/components/Button/ButtonShared";
import { DropdownTrigger } from "@app/components/Dropdown/components";
import BaseItem from "@app/components/Dropdown/components/dropdowns/items/BaseItem";
import { Flex, FlexRow } from "@design-system/Layout/Flex";

import DropdownContentLayout from "@app/components/Dropdown/components/dropdowns/layout";
import { currentUserRoleAtom } from "@app/store/userStore";
import { UserRole } from "@generated/client/graphql";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { AddHexagon } from "iconoir-react";
import { useAtomValue, useSetAtom } from "jotai";
import { Download, Eye, MoreHorizontal } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
    createCustomIndicatorsModalIsOpenAtom,
    exportModalIsOpenAtom,
    inactiveIndicatorsModalIsOpenAtom,
} from "./context";
import { useExportReportingFiles, useInactiveIndicators } from "./data";
import "./i18n";

const ReportingHeaderButton = () => {
    const { t } = useTranslation("ReportingPage");
    const setInactiveIndicatorsModalIsOpen = useSetAtom(
        inactiveIndicatorsModalIsOpenAtom,
    );
    const setCreateCustomIndicatorsModalIsOpen = useSetAtom(
        createCustomIndicatorsModalIsOpenAtom,
    );
    const setExportModalIsOpen = useSetAtom(exportModalIsOpenAtom);

    const { mutate: exportAndDownloadFiles, isPending: isExportingFiles } =
        useExportReportingFiles();
    const handleOpenInactiveIndicatorsModal = () => {
        setInactiveIndicatorsModalIsOpen(true);
    };
    const handleOpenCreateCustomIndicatorsModal = () => {
        setCreateCustomIndicatorsModalIsOpen(true);
    };
    const handleOpenExportModal = () => {
        setExportModalIsOpen(true);
    };
    const data = useInactiveIndicators();

    const { role } = useAtomValue(currentUserRoleAtom);

    const handleExportFilesClick = () => {
        exportAndDownloadFiles();
    };

    const DownloadIcon = () => <Download className="text-green h-4 w-4" />;
    const AddHexagonIcon = () => <AddHexagon className="text-green h-4 w-4" />;
    if (role === UserRole.Contributor) {
        return <></>;
    } else {
        return (
            <FlexRow gap="2">
                <Flex alignItems="center" justifyContent="end" gap="2">
                    <DropdownMenu modal={false}>
                        <DropdownTrigger asChild>
                            <ButtonLarge
                                variant={ButtonType.OUTLINED}
                                IconLeft={MoreHorizontal}
                                loading={isExportingFiles}
                            />
                        </DropdownTrigger>
                        <DropdownContentLayout
                            align="end"
                            side="bottom"
                            sideOffset={0}
                        >
                            <BaseItem
                                onClick={handleOpenInactiveIndicatorsModal}
                                IconLeft={() => (
                                    <Eye className="text-green h-4 w-4" />
                                )}
                            >
                                {t("see_not_relevant", {
                                    count: data?.length,
                                })}
                            </BaseItem>
                            <BaseItem
                                onClick={handleOpenExportModal}
                                IconLeft={DownloadIcon}
                            >
                                {t("export_reporting")}
                            </BaseItem>
                            <BaseItem
                                onClick={handleExportFilesClick}
                                IconLeft={DownloadIcon}
                            >
                                {t("export_reporting_files")}
                            </BaseItem>

                            {role === UserRole.BeavrAdmin && (
                                <BaseItem
                                    onClick={
                                        handleOpenCreateCustomIndicatorsModal
                                    }
                                    IconLeft={AddHexagonIcon}
                                >
                                    {t("add_custom_indicators")}
                                </BaseItem>
                            )}
                        </DropdownContentLayout>
                    </DropdownMenu>
                </Flex>
            </FlexRow>
        );
    }
};

export default ReportingHeaderButton;
