import { activeNavigationButtonKey } from "@app/store/routingStore";
import { currentUserRoleAtom } from "@app/store/userStore";
import { UserRole } from "@generated/client/graphql";
import { useAtomValue, useSetAtom } from "jotai";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
    getAdminNavigation,
    getContributorNavigation,
    navigationButtons,
} from "./utils";

export const useNavigation = () => {
    const supplierFlag = useFeatureFlagEnabled("supplier");
    const sustainabilityCenterFlag = useFeatureFlagEnabled(
        "sustainabilityCenter",
    );
    const legacyReporting = useFeatureFlagEnabled("legacyReporting");
    const { role, isPending } = useAtomValue(currentUserRoleAtom);

    const items = useMemo(() => {
        if (isPending) {
            return [];
        } else if (role === UserRole.Admin || role === UserRole.BeavrAdmin) {
            return getAdminNavigation(
                sustainabilityCenterFlag,
                supplierFlag,
                legacyReporting,
            );
        } else if (role === UserRole.Contributor) {
            return getContributorNavigation();
        }
    }, [supplierFlag, sustainabilityCenterFlag, legacyReporting]);

    const { pathname } = useLocation();
    const setActiveNavigationButtonKey = useSetAtom(activeNavigationButtonKey);

    const setKey = useCallback(
        (path: string): void => {
            const matches = items
                ?.flatMap((i) => i.items)
                .filter((item) => path.includes(navigationButtons[item].path));

            if (!!matches?.length) {
                setActiveNavigationButtonKey(matches[matches.length - 1]); // The last match is the good one as it is the deepest one in the hierarchy
            }
        },
        [items],
    );

    useEffect(() => {
        setKey(pathname);
    }, [pathname]);

    return { items, isPending };
};
