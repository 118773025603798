import { useAssociatedReportingDatapoints } from "@app/screens/Frameworks/framework/Csrd/pillar/data";
import { PrefillButton } from "@app/shared/components/PrefillButton";
import { CsrdDatapointPatch } from "@generated/client/graphql";
import "./i18n";
import { keys, RESOURCE_NAME } from "./i18n";

import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { reportingDatapointValueToCsrdDatapointValue } from "../../services";
import { PrefillModal } from "./PrefillModal";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Box } from "@design-system/Layout/Box";

type PrefillProps = {
    datapointId: string;
    hasIndicator: boolean;
    onConfirm: (patch: CsrdDatapointPatch) => void;
};

export const Prefill: FC<PrefillProps> = ({
    datapointId,
    hasIndicator,
    onConfirm,
}) => {
    const { associatedReportingDatapoints } = useAssociatedReportingDatapoints(
        hasIndicator ? datapointId : undefined,
    );
    const { t } = useTranslation(RESOURCE_NAME);

    const prefill = (associatedDatapointId: string) => {
        if (!associatedReportingDatapoints.length) {
            return;
        }

        const associatedDatapoint = associatedReportingDatapoints.find(
            ({ id }) => id === associatedDatapointId,
        );

        if (!associatedDatapoint) {
            throw new Error("Associated datapoint not found");
        }

        const patch: CsrdDatapointPatch = {
            comment: associatedDatapoint.comment,
            value: reportingDatapointValueToCsrdDatapointValue(
                associatedDatapoint.value,
            ),
        };

        onConfirm(patch);
    };

    const handleClick = () => {
        prefill(associatedReportingDatapoints[0].id);
    };

    if (!hasIndicator || !associatedReportingDatapoints.length) {
        return (
            <Tooltip>
                <Tooltip.Trigger asChild>
                    {/**
                     * It's necessary to wrap the PrefillButton in a Box component
                     * because the Tooltip isn't triggered on a disabled button.
                     */}
                    <Box>
                        <PrefillButton disabled />
                    </Box>
                </Tooltip.Trigger>
                <Tooltip.Content>
                    {hasIndicator
                        ? t(keys.tooltip_no_data)
                        : t(keys.tooltip_no_indicator)}
                </Tooltip.Content>
            </Tooltip>
        );
    }

    if (associatedReportingDatapoints.length === 1) {
        return (
            <PrefillButton
                onClick={handleClick}
                tooltipContent={t(keys.tooltip)}
            />
        );
    }

    return <PrefillModal datapointId={datapointId} onConfirm={prefill} />;
};
