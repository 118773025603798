import { memo } from "react";

type PDFViewerProps = {
    url?: string;
    name: string;
};

function comparisonFunction(
    { url: prevUrl }: PDFViewerProps,
    { url: nextUrl }: PDFViewerProps,
) {
    /**
     * TanstackQuery reloads on page focus, which causes the url to be resigned,
     * hence the url changes on every focus. This causes the PDFViewer to re-render.
     * To prevent this, we compare the urls without the query params.
     */
    const unsignedPrevUrl = prevUrl?.split("?")[0];
    const unsignedNextUrl = nextUrl?.split("?")[0];
    if (unsignedPrevUrl !== unsignedNextUrl) return false;
    return true;
}

export const PDFViewer = memo<PDFViewerProps>(({ url, name = "" }) => {
    return <iframe src={url} className="w-full flex-grow " title={name} />;
}, comparisonFunction);
