import { RestAiRepository } from "@app/repositories/RestRepositories/aiRepository";
import { AssistantStream } from "openai/lib/AssistantStream";

const aiRepository = new RestAiRepository();

export const useAskAi = () => {
    const sendMessage = async (
        prompt: string,
        threadId: string,
    ): Promise<AssistantStream | undefined> => {
        return await aiRepository.sendMessage(prompt, threadId);
    };

    const createThread = async (): Promise<string> => {
        return await aiRepository.createThread();
    };
    return { sendMessage, createThread };
};
