import { atom } from "jotai";

export const ValidationModal = {
    sendForReview: "sendForReview",
    unsendReviewRequest: "unsendReviewRequest",
} as const;

type ValidationModalType = keyof typeof ValidationModal;

export const validationModalAtom = atom<ValidationModalType | null>(null);
