import i18next from "@app/i18n";

const enResources = {
    new_tag: "New tag",
};

type ResourcesKeys = keyof typeof enResources;


const frResources: Record<ResourcesKeys, string> = {
    new_tag: "Nouveau tag",
};

export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "NewTagDropdownItem";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);