import i18next from "../../../../i18n";

i18next.addResources("en", "DocumentInfoBlock", {
    description: "Description",
    recurrence_title: "Review frequency",
    recurrence: "Renew annually",
    comment_placeholder: "Add additional context",
    expiration_date: "Document will expire on:",
});
i18next.addResources("fr", "DocumentInfoBlock", {
    description: "Description",
    recurrence_title: "Fréquence de revue",
    recurrence: "A renouveler annuellement",
    comment_placeholder: "Ajouter un commentaire",
    expiration_date: "Le document expirera le:",
});
