import i18next from "@app/i18n";

const enResources = {
    title: "Attach Documents",
    suggested_files: "Suggested Files",
    all_files: "All Files",
    search_placeholder: "Search files",
    dropzone_button: "Browse",
    dropzone_title: "Drag & drop your documents.",
    success: "Sucessfully Attached Documents",
    error: "Error attaching documents",
    save: "Save",
    cancel: "Cancel",
    tab_library: "File library",
    tab_upload: "Upload documents",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    title: "Attacher des documents",
    suggested_files: "Fichiers suggérés",
    all_files: "Tous les fichiers",
    search_placeholder: "Rechercher des fichiers",
    dropzone_button: "Parcourir",
    dropzone_title: "Glissez-déposez vos documents.",
    success: "Documents attachés avec succès",
    error: "Erreur lors de l'attachage des documents",
    save: "Enregistrer",
    cancel: "Annuler",
    tab_library: "Bibliothèque de fichiers",
    tab_upload: "Télécharger les documents",
} as const;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AttachDocumentsModal";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
