import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import {
    AnswerDatapointFieldsFragment,
    ContributorUpdateRequestDocument,
    DataRequestPatch,
    GetSupplierQuestionnaireDataRequestsForContributionQuery,
    RequestBlockFieldsFragment,
} from "../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../QueryClientWithHeaders";
import { saveBannerStateAtom } from "../components/SaveBanner";

export const useUpdateRequest = (key: QueryKey) => {
    const client = useQueryClient();
    const setSaveBannerState = useSetAtom(saveBannerStateAtom);

    const { mutate } = useGraphQLMutation(ContributorUpdateRequestDocument);

    const updateRequestBackend = (
        requestId: string,
        patch: DataRequestPatch,
    ) => {
        mutate(
            {
                requestId,
                patch,
            },
            {
                onSuccess: (data) => {
                    client.setQueryData<GetSupplierQuestionnaireDataRequestsForContributionQuery>(
                        key,
                        (oldData) => {
                            if (!oldData) {
                                return undefined;
                            }
                            const requests = (
                                "personalRequests" in oldData
                                    ? oldData.personalRequests
                                    : "supplierQuestionnaireRequests" in oldData
                                      ? oldData.supplierQuestionnaireRequests
                                      : undefined
                            ) as RequestBlockFieldsFragment[];
                            const idx = requests.findIndex(
                                (r) => r.id === requestId,
                            );
                            const updatedRequests = [...requests];
                            if (idx > -1) {
                                updatedRequests[idx] =
                                    data.updateDataRequest as RequestBlockFieldsFragment;
                            }
                            const newData = {
                                ...("personalRequests" in oldData
                                    ? { personalRequests: updatedRequests }
                                    : undefined),
                                ...("supplierQuestionnaireRequests" in oldData
                                    ? {
                                          supplierQuestionnaireRequests:
                                              updatedRequests,
                                      }
                                    : undefined),
                            };
                            return newData as GetSupplierQuestionnaireDataRequestsForContributionQuery;
                        },
                    );
                    const now = new Date();
                    setSaveBannerState({ lastSaveTime: now, error: false });
                },
                onError: () => {
                    setSaveBannerState({ error: true });
                },
            },
        );
    };

    const updateRequestCache = (requestId: string, patch: DataRequestPatch) => {
        client.setQueryData<GetSupplierQuestionnaireDataRequestsForContributionQuery>(
            key,
            (oldData) => {
                if (!oldData) {
                    return undefined;
                }
                const requests = (
                    "personalRequests" in oldData
                        ? oldData.personalRequests
                        : "supplierQuestionnaireRequests" in oldData
                          ? oldData.supplierQuestionnaireRequests
                          : undefined
                ) as RequestBlockFieldsFragment[];

                const idx = requests.findIndex((r) => r.id === requestId);
                const updatedRequests = [...requests];
                if (idx > -1) {
                    updatedRequests[idx] = {
                        ...updatedRequests[idx],
                        ...patch,
                        answer: {
                            ...updatedRequests[idx].answer,
                            ...patch.datapoint,
                            options: patch.datapoint?.optionIds?.map((id) => ({
                                id,
                            })),
                        } as AnswerDatapointFieldsFragment,
                    } as RequestBlockFieldsFragment;
                }

                const newData = {
                    ...("personalRequests" in oldData
                        ? { personalRequests: updatedRequests }
                        : undefined),
                    ...("supplierQuestionnaireRequests" in oldData
                        ? { supplierQuestionnaireRequests: updatedRequests }
                        : undefined),
                };
                return newData as GetSupplierQuestionnaireDataRequestsForContributionQuery;
            },
        );
    };

    const updateRequest = (requestId: string, patch: DataRequestPatch) => {
        updateRequestCache(requestId, patch);
        updateRequestBackend(requestId, patch);
    };

    return { updateRequest };
};
