import { useS3FilesManagement } from "@app/hooks/createFileHook";
import { NewS3File } from "@app/usecases/FileUseCases";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { File } from "@generated/client/graphql";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";
export default function ImageUpload({
    file,
    newS3File,
    setNewS3File,
}: {
    file: File | undefined;
    newS3File: NewS3File | undefined;
    setNewS3File: (f: NewS3File | undefined) => void;
}) {
    const { t } = useTranslation("Upload");
    const ref = useRef<HTMLInputElement | null>(null);
    const handleUploadClick = () => {
        ref && ref.current && ref.current.click();
    };
    const { uploadFiletoS3 } = useS3FilesManagement();

    const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const newS3File = await uploadFiletoS3(e.target.files[0]);
        setNewS3File(newS3File);
    };

    return (
        <div className="flex">
            <div>
                <div className="flex ">
                    <img
                        className="w-[88px] h-[88px] mr-4"
                        src={
                            newS3File?.signedUrl ??
                            (file?.signedUrl || undefined)
                        }
                    />
                    <div className="flex flex-col justify-center">
                        <Button
                            size="sm"
                            variant="outlined"
                            onClick={handleUploadClick}
                        >
                            <FlexRow
                                gap="1"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Text variant="body-sm">
                                    {t("change_file")}
                                </Text>
                                <Icon name="upload" size="sm" />
                            </FlexRow>
                        </Button>
                        <input
                            ref={ref}
                            type="file"
                            hidden
                            onChange={onFileUpload}
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
