import { NULL_TOKEN } from "@app/shared/utils/NULL_TOKEN";
import { usersAtom } from "@app/store/userStore";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Icon } from "@design-system/Icon";
import {
    AssignButton,
    type AssignButtonSize,
    type AssignedAvatar,
} from "@design-system/Inputs/AssignButton";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { getRandomBgColorForWhiteText } from "@design-system/Utilities";
import { useAtomValue } from "jotai";
import { useMemo, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys } from "./i18n";

type UserSelectProps = {
    enableNone?: boolean;
    selectedUsers: string[];
    onSelect: (userIds: string[]) => void;
    selectMode: "single" | "multiple";
    triggerSize?: AssignButtonSize;
    placeholders?: {
        label?: string;
        trigger?: string;
        warning?: string;
        unassign?: string;
    };
};

export const UserAssign: FC<UserSelectProps> = ({
    enableNone,
    onSelect,
    selectedUsers,
    selectMode,
    triggerSize,
    placeholders,
}) => {
    const { t } = useTranslation("UserAssign");
    const [searchString, setSearchString] = useState<string | undefined>("");
    const { data: { users: usersList } = { users: [] }, isPending } =
        useAtomValue(usersAtom);

    const usersMap = useMemo(() => {
        if (isPending || !usersList) return {};
        return usersList.reduce(
            (acc, { id, firstName, lastName, avatar }) => {
                acc[id] = {
                    id,
                    label: `${firstName} ${lastName}`,
                    color: getRandomBgColorForWhiteText(),
                    imgUrl: avatar?.signedUrl,
                };
                return acc;
            },
            {} as Record<string, AssignedAvatar>,
        );
    }, [usersList, isPending]);

    const avatars = useMemo(
        () => selectedUsers.map((id) => usersMap[id]).filter((elt) => elt),
        [selectedUsers, usersMap],
    );

    const selectableUsers = useMemo(() => {
        return usersList?.filter(({ firstName, lastName }) =>
            `${firstName} ${lastName}`
                .toLowerCase()
                .includes((searchString ?? "").toLowerCase()),
        );
    }, [searchString, usersList]);

    /**
     * This function looks more complex than it should,
     * but it is because the MultiSelect component only
     * handles multiple selections; so we need to handle
     * ourselves the logic for single selection.
     */
    const handleValuesChange = (values: (string | null)[]) => {
        if (values.includes(NULL_TOKEN)) return onSelect([]);

        const cleanValues = values.filter(Boolean) as string[];

        /**
         * If the selectMode is multiple, we can just call
         * the onSelect function with the values.
         */
        if (selectMode === "multiple") return onSelect(cleanValues);

        /**
         * If the selectMode is single, we need to handle
         * the logic for single selection.
         * If we have more than one value selected, and we select
         * an already selected value, MultiSelect will pass the values minus
         * the clicked value. We have to find it by cross checking
         * the selectedUsers and the cleanValues and pass it as param.
         */
        const deselectedValue =
            selectedUsers.length > 1
                ? selectedUsers.filter((id) => !cleanValues.includes(id))[0]
                : undefined;

        /**
         * If we select a non-already selected value, MultiSelect
         * will pass the values as they are, plus the newly selected
         * value as last. We have to take it and pass it as param.
         */
        if (typeof deselectedValue === "string")
            return onSelect([deselectedValue]);

        const lastValue = cleanValues[cleanValues.length - 1];
        onSelect([...(lastValue ? [lastValue] : [])]);
    };

    const showWarning = selectMode === "single" && selectedUsers.length > 1;

    return (
        <div>
            <MultiSelect
                onValuesChange={handleValuesChange}
                values={selectedUsers}
            >
                <MultiSelect.Trigger>
                    <AssignButton
                        avatars={avatars}
                        label={placeholders?.label ?? t(keys.label)}
                        placeholder={
                            placeholders?.trigger ?? t(keys.trigger_placeholder)
                        }
                        size={triggerSize}
                        className="bg-white"
                    />
                </MultiSelect.Trigger>
                <MultiSelect.Content avoidCollisions align="end">
                    <MultiSelect.Search
                        className="mb-1"
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                    {showWarning && (
                        <Box p="2">
                            <Text variant="body-sm-bold">
                                {placeholders?.warning ?? t(keys.warning)}
                            </Text>
                        </Box>
                    )}
                    <FlexCol className="scroll-auto" gap="2">
                        {!!enableNone && selectedUsers.length > 0 ? (
                            <MultiSelect.Item
                                key={NULL_TOKEN}
                                value={NULL_TOKEN}
                            >
                                <FlexRow
                                    br="lg"
                                    className="hover:text-danger hover:bg-red-50"
                                    gap="3"
                                    p="2"
                                >
                                    <Icon
                                        className="text-danger"
                                        name="userDelete"
                                        size="sm"
                                    />
                                    <FlexRow w="full">
                                        <Text>
                                            {placeholders?.unassign ??
                                                t(keys.unassign)}
                                        </Text>
                                    </FlexRow>
                                </FlexRow>
                            </MultiSelect.Item>
                        ) : null}
                        {selectableUsers?.map((user) => (
                            <MultiSelect.Item key={user.id} value={user.id}>
                                {({ isSelected }) => (
                                    <FlexRow
                                        br="lg"
                                        className="hover:text-beavrGreen hover:bg-tertiary"
                                        gap="3"
                                        p="2"
                                    >
                                        <Box
                                            className="place-content-center"
                                            display="grid"
                                            size="5"
                                        >
                                            {isSelected ? (
                                                <Icon
                                                    className="text-beavrGreen"
                                                    name="check"
                                                    size="sm"
                                                />
                                            ) : null}
                                        </Box>
                                        <FlexRow
                                            gap="2"
                                            justifyContent="between"
                                            w="full"
                                        >
                                            <Text>
                                                {usersMap[user.id].label}
                                            </Text>
                                            <Avatar
                                                letter={user.firstName[0]}
                                                imageUrl={
                                                    usersMap[user.id].imgUrl
                                                }
                                                hexColor={
                                                    usersMap[user.id].color
                                                }
                                                variant="circle"
                                            />
                                        </FlexRow>
                                    </FlexRow>
                                )}
                            </MultiSelect.Item>
                        ))}
                    </FlexCol>
                </MultiSelect.Content>
            </MultiSelect>
        </div>
    );
};
