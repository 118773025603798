import { useContext } from "react";
import { useDatapointGroupsOfEntity } from "../../data";
import { YearContext } from ".";
import { months, quarters } from "@app/usecases/ReportingUseCases";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME } from "./i18n";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import Checkbox from "@app/components/Checkbox";
import { Text } from "@design-system/Typography/Text";

export const PeriodBox = ({ type }: { type: "year" | "month" | "quarter" }) => {
    const ctx = useContext(YearContext);
    if (!ctx) throw new Error("Context must not be null");
    const { selectedYear, newPeriods, setNewPeriods } = ctx;
    const datapointGroupsOfEntity = useDatapointGroupsOfEntity();
    const isPeriodActive = (period: {
        year: number;
        month?: string;
        quarter?: string;
    }) => {
        return !!datapointGroupsOfEntity?.find(
            (group) =>
                group.period.year === period.year &&
                group.period.quarter === period.quarter &&
                group.period.month === period.month,
        );
    };
    const isPeriodInNewPeriods = (period: {
        year: number;
        month?: string;
        quarter?: string;
    }) => {
        return !!newPeriods.find(
            (newPeriod) =>
                newPeriod.year === period.year &&
                newPeriod.quarter === period.quarter &&
                newPeriod.month === period.month,
        );
    };
    const firstCheckbocCheckedStatus = (type: string) => {
        if (type === "month") {
            const isAllChecked = Object.values(months).every(
                (month) =>
                    isPeriodActive({
                        year: selectedYear ?? 0,
                        month,
                    }) ||
                    isPeriodInNewPeriods({
                        year: selectedYear ?? 0,
                        month,
                    }),
            );
            const isSomeChecked = Object.values(months).some(
                (month) =>
                    isPeriodActive({
                        year: selectedYear ?? 0,
                        month,
                    }) ||
                    isPeriodInNewPeriods({
                        year: selectedYear ?? 0,
                        month,
                    }),
            );
            return isAllChecked ? true : isSomeChecked ? "partial" : false;
        }
        if (type === "quarter") {
            const isAllChecked = Object.values(quarters).every(
                (quarter) =>
                    isPeriodActive({
                        year: selectedYear ?? 0,
                        quarter,
                    }) ||
                    isPeriodInNewPeriods({
                        year: selectedYear ?? 0,
                        quarter,
                    }),
            );
            const isSomeChecked = Object.values(quarters).some(
                (quarter) =>
                    isPeriodActive({
                        year: selectedYear ?? 0,
                        quarter,
                    }) ||
                    isPeriodInNewPeriods({
                        year: selectedYear ?? 0,
                        quarter,
                    }),
            );
            return isAllChecked ? true : isSomeChecked ? "partial" : false;
        }
        return (
            isPeriodActive({
                year: ctx.selectedYear ?? 0,
            }) || isPeriodInNewPeriods({ year: ctx.selectedYear ?? 0 })
        );
    };

    const handleCheckboxChange =
        (period: { year: number; month?: string; quarter?: string }) => () => {
            if (isPeriodActive(period)) return;
            if (isPeriodInNewPeriods(period)) {
                setNewPeriods(
                    newPeriods.filter(
                        (newPeriod) =>
                            newPeriod.year !== period.year ||
                            newPeriod.month !== period.month ||
                            newPeriod.quarter !== period.quarter,
                    ),
                );
                return;
            }
            setNewPeriods([...newPeriods, period]);
        };

    const handleFirstCheckboxClick = (type: string) => () => {
        if (type === "month") {
            if (firstCheckbocCheckedStatus(type) === true) {
                setNewPeriods(
                    newPeriods.filter(
                        (newPeriod) =>
                            newPeriod.year !== selectedYear ||
                            !newPeriod.month ||
                            newPeriod.quarter,
                    ),
                );
                return;
            }
            const allMonthPeriods = Object.values(months).map((month) => ({
                year: selectedYear ?? 0,
                month,
            }));
            const periodsToAdd = allMonthPeriods.filter(
                (period) =>
                    !isPeriodActive(period) && !isPeriodInNewPeriods(period),
            );
            setNewPeriods([...newPeriods, ...periodsToAdd]);
            return;
        }
        if (type === "quarter") {
            if (firstCheckbocCheckedStatus(type) === true) {
                setNewPeriods(
                    newPeriods.filter(
                        (newPeriod) =>
                            newPeriod.year !== selectedYear ||
                            newPeriod.month ||
                            !newPeriod.quarter,
                    ),
                );
                return;
            }
            const allQuarterPeriods = Object.values(quarters).map(
                (quarter) => ({
                    year: selectedYear ?? 0,
                    quarter,
                }),
            );
            const periodsToAdd = allQuarterPeriods.filter(
                (period) =>
                    !isPeriodActive(period) && !isPeriodInNewPeriods(period),
            );
            setNewPeriods([...newPeriods, ...periodsToAdd]);
            return;
        }
        if (firstCheckbocCheckedStatus(type) === false) {
            setNewPeriods([...newPeriods, { year: selectedYear ?? 0 }]);
            return;
        }
        setNewPeriods(
            newPeriods.filter(
                (newPeriod) =>
                    newPeriod.year !== selectedYear ||
                    newPeriod.month ||
                    newPeriod.quarter,
            ),
        );
    };

    const isFirstCheckboxDisabled = (type: string) => {
        if (type === "month") {
            return Object.values(months).every((month) =>
                isPeriodActive({ year: selectedYear ?? 0, month }),
            );
        }
        if (type === "quarter") {
            return Object.values(quarters).every((quarter) =>
                isPeriodActive({ year: selectedYear ?? 0, quarter }),
            );
        }
        return isPeriodActive({ year: selectedYear ?? 0 });
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            p="1"
            className="bg-neutral-25 border border-neutral-150"
            br="lg"
        >
            <FlexRow gap="2" px="2" py="1.5">
                <Checkbox
                    checked={firstCheckbocCheckedStatus(type)}
                    disabled={isFirstCheckboxDisabled(type)}
                    allowPartialClick
                    onClick={handleFirstCheckboxClick(type)}
                />
                <Text variant="body" color="primary">
                    {t("period_box_title", {
                        context: type,
                    })}
                </Text>
            </FlexRow>
            {type === "month" &&
                Object.values(months).map((month) => (
                    <FlexRow key={month} gap="2" ml="6" px="2" py="1">
                        <Checkbox
                            checked={
                                isPeriodActive({
                                    year: ctx.selectedYear ?? 0,
                                    month: month,
                                }) ||
                                isPeriodInNewPeriods({
                                    year: ctx.selectedYear ?? 0,
                                    month: month,
                                })
                            }
                            disabled={isPeriodActive({
                                year: ctx.selectedYear ?? 0,
                                month: month,
                            })}
                            onCheckedChange={handleCheckboxChange({
                                year: ctx.selectedYear ?? 0,
                                month: month,
                            })}
                        />
                        <Text variant="body" color="primary">
                            {t("month", { context: month })}
                        </Text>
                    </FlexRow>
                ))}
            {type === "quarter" &&
                Object.values(quarters).map((quarter) => (
                    <FlexRow key={quarter} gap="2" ml="6" px="2" py="1">
                        <Checkbox
                            checked={
                                isPeriodActive({
                                    year: ctx.selectedYear ?? 0,
                                    quarter: quarter,
                                }) ||
                                isPeriodInNewPeriods({
                                    year: ctx.selectedYear ?? 0,
                                    quarter: quarter,
                                })
                            }
                            disabled={isPeriodActive({
                                year: ctx.selectedYear ?? 0,
                                quarter: quarter,
                            })}
                            onCheckedChange={handleCheckboxChange({
                                year: ctx.selectedYear ?? 0,
                                quarter: quarter,
                            })}
                        />
                        <Text variant="body" color="primary">
                            {quarter}
                        </Text>
                    </FlexRow>
                ))}
        </FlexCol>
    );
};
