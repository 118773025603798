import { ReactComponent as ExtensionPdf } from "../../../assets/illustrations/document-extension-pdf.svg"
import { ReactComponent as ExtensionPpt } from "../../../assets/illustrations/document-extension-ppt.svg"
import { ReactComponent as ExtensionXls } from "../../../assets/illustrations/document-extension-xls.svg"
import { ReactComponent as ExtensionTxt } from "../../../assets/illustrations/document-extension-txt.svg"
import { cn, type ClassValue } from "@design-system/Utilities"
import { forwardRef } from "react"
import { FileFormatCategory } from "@app/usecases/VersionUseCases/utils"

const extensionMap = {
    PDF: ExtensionPdf,
    PPT: ExtensionPpt,
    XLS: ExtensionXls,
    TXT: ExtensionTxt,
} as const

type ExtensionTagProps = {
    name: FileFormatCategory;
    className?: ClassValue;
};

export const ExtensionTag = forwardRef<SVGSVGElement, ExtensionTagProps>(
    ({ name, className }, ref) => {
        if (name === "UNKNOWN") {
            return null;
        }
        const Comp = extensionMap[name];

        return <Comp ref={ref} className={cn(className)} />;
    },
);