import { useQueryClient } from "@tanstack/react-query";
import { Trash } from "iconoir-react";
import { useAtom } from "jotai";
import { FileText } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetSustainabilityCenterDocument,
    ScDocument,
    SustainabilityCenter,
    UpdateScDocumentsDocument,
} from "../../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../../components/Button/ButtonSmall";

import {
    CenterModal,
    CenterModalContent,
} from "../../../../../../components/Modal/CenterModal";
import ItemsListEditor, {
    selectedIndexAtom,
} from "../../../../../../components/SustainabilityCenter/ItemsLitEditor";
import { useSetToast } from "../../../../../../components/Toast";
import TextInput from "../../../../../../components/inputs_v2/Text";
import { orderSort } from "../../../../../../lib/utils";
import SelectDocumentsModal from "./SelectDocumentsModal";
import "./i18n";

export default function EditDocumentsModal({
    sustainabilityCenter,
    open,
    setOpen,
}: {
    sustainabilityCenter: SustainabilityCenter | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterEditDocumentsModal");
    const { setToastSuccess, setToastError } = useSetToast();
    const client = useQueryClient();
    const { mutate: updateSustainabilityCenter } = useGraphQLMutation(
        UpdateScDocumentsDocument,
    );

    const [openSelectDocumentsModal, setOpenSelectDocumentsModal] =
        useState(false);
    const [documents, setDocuments] = useState<ScDocument[]>(
        orderSort(sustainabilityCenter?.scDocuments || []),
    );
    const [displayedDocument, setDisplayedDocument] = useState<
        ScDocument | undefined
    >(undefined);
    const [selectedIdx, setSelectedIdx] = useAtom(selectedIndexAtom);

    useEffect(() => {
        setDocuments(orderSort(sustainabilityCenter?.scDocuments || []));
        setDisplayedDocument(
            sustainabilityCenter?.scDocuments
                ? sustainabilityCenter?.scDocuments[selectedIdx]
                : undefined,
        );
    }, [sustainabilityCenter?.scDocuments]);

    useEffect(() => {
        setDisplayedDocument(documents[selectedIdx]);
    }, [documents, selectedIdx]);

    const addDocument = () => {
        setOpenSelectDocumentsModal(true);
    };

    const deleteDocument = () => {
        setDocuments(
            documents
                .filter((_, index) => index !== selectedIdx)
                .map((elt, idx) => {
                    return { ...elt, order: idx };
                }),
        );
        setSelectedIdx(0);
    };

    const setName = (name: string | undefined) => {
        setDocuments(
            documents.map((elt, idx) => {
                return idx === selectedIdx ? { ...elt, name: name || "" } : elt;
            }),
        );
    };

    const handleSave = () => {
        if (!sustainabilityCenter) return;
        const updatePPInput = {
            id: sustainabilityCenter.id,
            inputs:
                documents?.map((elt) => {
                    return {
                        name: elt.name,
                        documentId: elt.documentId,
                        order: elt.order,
                    };
                }) || [],
        };
        updateSustainabilityCenter(updatePPInput, {
            onSuccess: () => {
                setToastSuccess(t("update_success"));
                client.invalidateQueries({
                    queryKey: useGraphQL.getKey(
                        GetSustainabilityCenterDocument,
                    ),
                });
                setOpen(false);
            },
            onError: () => {
                setToastError(t("update_error"));
            },
        });
    };

    return (
        <>
            <CenterModal open={open} onOpenChange={setOpen} modal={false}>
                <CenterModalContent
                    title={t("title")}
                    actions={
                        <>
                            <ButtonLarge
                                text={t("save")}
                                variant={ButtonType.ELEVATED}
                                onClick={() => {
                                    handleSave();
                                    setOpen(false);
                                }}
                            />
                        </>
                    }
                >
                    <ItemsListEditor
                        items={documents}
                        setItems={setDocuments}
                        addItem={addDocument}
                        itemType={t("document")}
                        getItemTitle={(publicDoc: ScDocument) =>
                            publicDoc?.document?.name || ""
                        }
                    >
                        <div className="flex min-h-[380px] items-start w-[820px]">
                            {documents?.length > 0 && (
                                <div className="flex flex-1 p-5 flex-col items-start gap-6">
                                    <div className="flex justify-between items-start self-stretch">
                                        <div className="font-bold text-lg flex-1">
                                            {displayedDocument?.document?.name}
                                        </div>
                                        <ButtonSmall
                                            IconRight={Trash}
                                            variant={ButtonType.OUTLINED}
                                            onClick={deleteDocument}
                                        />
                                    </div>
                                    <div className="w-full gap-4 flex flex-col">
                                        <TextInput
                                            placeholder={t(
                                                "document_name_input_placeholder",
                                            )}
                                            value={displayedDocument?.name}
                                            setValue={setName}
                                        ></TextInput>
                                        <div
                                            className="flex items-center gap-3 self-stretch cursor-pointer"
                                            onClick={(e) => {
                                                const signedUrl =
                                                    displayedDocument?.document
                                                        ?.lastVersion?.finalFile
                                                        ?.signedUrl;
                                                signedUrl &&
                                                    window
                                                        .open(
                                                            signedUrl,
                                                            "_blank",
                                                        )
                                                        ?.focus();

                                                e.stopPropagation();
                                            }}
                                        >
                                            <div className="p-3 rounded-lg border border-secondary">
                                                <FileText className="w-4 h-4" />
                                            </div>
                                            <div className="text-brand font-bold">
                                                {
                                                    displayedDocument?.document
                                                        ?.name
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ItemsListEditor>
                    <SelectDocumentsModal
                        publicDocuments={documents}
                        setDocuments={setDocuments}
                        open={openSelectDocumentsModal}
                        setOpen={setOpenSelectDocumentsModal}
                    />
                </CenterModalContent>
            </CenterModal>
        </>
    );
}
