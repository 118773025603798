import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import {
    Indicator,
    UpdateIndicatorDocument,
} from "../../../../../generated/client/graphql";

import { useTranslation } from "react-i18next";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../Button/ButtonRegular";
import UnitInputSetter from "../../../Dropdown/dropdowns/Unit/inputSetter";
import { CenterModal, CenterModalContent } from "../../../Modal/CenterModal";
import InputSimple from "../../../inputs/InputSimple/InputSimple";
import TextAreaInput from "../../../inputs_v2/TextArea";
import "./i18n";

export const updateIndicatorAtom = atom<Indicator | undefined>(undefined);
const UpdateIndicatorModal = ({ refetch }: { refetch: () => void }) => {
    const { t } = useTranslation("UpdateIndicatorModal");
    const [indicator, setIndicator] = useAtom(updateIndicatorAtom);
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [unitId, setUnitId] = useState<string>();
    const [options, setOptions] = useState<string>();

    const updateIndicatorMutation = useGraphQLMutation(UpdateIndicatorDocument);
    useEffect(() => {
        setName(indicator?.name || "");
        setDescription(indicator?.description || "");
        setUnitId(indicator?.unit?.id || "");
        setOptions(indicator?.options?.map((o) => o.name).join(";") || "");
    }, [JSON.stringify(indicator)]);

    const canSave =
        indicator &&
        (name !== indicator.name ||
            description !== indicator.description ||
            options !== indicator?.options?.map((o) => o.name).join(";") ||
            unitId !== indicator.unit?.bvId);

    const onSave = () => {
        canSave &&
            updateIndicatorMutation.mutate(
                {
                    id: indicator.id,
                    patch: {
                        ...(name !== indicator.name ? { name } : undefined),
                        ...(description !== indicator.description
                            ? { description }
                            : undefined),
                        ...(unitId !== indicator.unit?.bvId
                            ? { cmsUnitId: unitId }
                            : undefined),
                        ...(options !==
                        indicator?.options?.map((o) => o.name).join(";")
                            ? { options: options?.split(";") }
                            : undefined),
                    },
                },
                {
                    onSuccess: () => {
                        refetch();
                        setIndicator(undefined);
                    },
                },
            );
    };
    const ref = useRef(null);
    return (
        <CenterModal
            open={indicator !== undefined}
            onOpenChange={(open) => {
                if (!open) setIndicator(undefined);
            }}
        >
            <CenterModalContent
                ref={ref}
                title={t("title")}
                actions={
                    <ButtonRegular
                        onClick={onSave}
                        loading={updateIndicatorMutation.isPending}
                        disabled={!canSave}
                    >
                        {t("save_button")}
                    </ButtonRegular>
                }
            >
                <InputSimple
                    label={t("name")}
                    input={name}
                    setInput={setName}
                />
                <TextAreaInput
                    placeholder={t("description")}
                    text={description}
                    setText={setDescription}
                />
                {ref.current && (
                    <UnitInputSetter
                        unitId={unitId}
                        onUnitIdChange={setUnitId}
                        portalContainer={ref.current}
                    />
                )}
                {["SELECT", "MUTLI_SELECT"].includes(
                    indicator?.unit?.type || "",
                ) && (
                    <div>
                        <p>{t("set_options")}</p>
                        <InputSimple
                            label={t("options")}
                            input={options}
                            setInput={setOptions}
                        />
                    </div>
                )}
            </CenterModalContent>
        </CenterModal>
    );
};

export default UpdateIndicatorModal;
