export type FileFormatCategory = "PPT" | "XLS" | "PDF" | "TXT" | "UNKNOWN";


const powerpointLikeExtensions = [
    "ppt", // File extension for PowerPoint 97-2003 presentations
    "pptx", // File extension for PowerPoint 2007 and later presentations
    "pot", // File extension for PowerPoint templates
    "potx", // File extension for PowerPoint templates in PowerPoint 2007 and later
    "pps", // File extension for PowerPoint slide shows
    "ppsx", // File extension for PowerPoint slide shows in PowerPoint 2007 and later
] as const;

const excelLikeExtensions = [
    "xls", // File extension for Excel 97-2003 workbooks
    "xlsx", // File extension for Excel 2007 and later workbooks
    "xlsm", // File extension for Excel 2007 and later workbooks with macros enabled
    "xlsb", // File extension for Excel binary workbooks
    "xlt", // File extension for Excel 97-2003 templates
    "xltx", // File extension for Excel 2007 and later templates
    "xltm", // File extension for Excel 2007 and later templates with macros enabled
] as const;

const textLikeExtensions = [
    "txt",
    "text",
 ] as const

export function isPowerPointLikeFile(
    extension: unknown,
): extension is (typeof powerpointLikeExtensions)[number] {
    return (
        typeof extension === "string" &&
        powerpointLikeExtensions.includes(
            extension as (typeof powerpointLikeExtensions)[number],
        )
    );
}

export function isExcelLikeFile(
    extension: unknown,
): extension is (typeof excelLikeExtensions)[number] {
    return (
        typeof extension === "string" &&
        excelLikeExtensions.includes(
            extension as (typeof excelLikeExtensions)[number],
        )
    );
}

export function isPdfFile(extension: unknown): extension is "pdf" {
    return typeof extension === "string" && extension === "pdf";
}

export function isTextLikeFile(
    extension: unknown,
): extension is (typeof textLikeExtensions)[number] {
    return (
        typeof extension === "string" &&
        textLikeExtensions.includes(
            extension as (typeof textLikeExtensions)[number],
        )
    );
}

export function getFileFormatCategory(extension: string): FileFormatCategory {
    if (isPowerPointLikeFile(extension)) {
        return "PPT";
    }
    if (isExcelLikeFile(extension)) {
        return "XLS";
    }
    if (isPdfFile(extension)) {
        return "PDF";
    }
    if (isTextLikeFile(extension)) {
        return "TXT"
    }
    return "UNKNOWN";
}
