import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { QueryClientWithHeadersWrapper } from "../QueryClientWithHeaders.tsx";
import Orgs from "../screens/Orgs/index.tsx";

import { Provider as ToastProvider } from "@radix-ui/react-toast";
import { TooltipProvider } from "@radix-ui/react-tooltip";

import { useSetDatadogRumUser } from "@app/datadog.tsx";
import { ClerkProvider } from "@clerk/clerk-react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import LoginRequired from "../authentication/LoginRequired.tsx";
import SupplierContribution from "../screens/Contributor/SupplierContribution/index.tsx";
import SustainabilityCenter from "../screens/SustainabilityCenter/SustainabilityCenter/index.tsx";
import { AdminLayout, adminRoutes } from "./admin.tsx";
import { contributeRoutes } from "./contributor.tsx";

const Root = () => {
    // const navigate = useNavigate();
    // const onRedirectCallback = (appState?: AppState) => {
    //     navigate(appState?.returnTo || window.location.pathname);
    // };
    useSetDatadogRumUser();

    const rootNode = (
        <div className="relative text-primary scroll-smooth">
            <ClerkProvider
                publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
                afterSignOutUrl="https://beavr.tech"
            >
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <QueryClientWithHeadersWrapper>
                        <TooltipProvider delayDuration={300}>
                            <ToastProvider duration={2000}>
                                <Outlet />
                            </ToastProvider>
                        </TooltipProvider>
                    </QueryClientWithHeadersWrapper>
                </QueryParamProvider>
            </ClerkProvider>
        </div>
    );
    const underMaintenanceNode = (
        <div className="bg-green-100 w-screen h-screen flex justify-center items-center text-lg">
            We are under maintenance. Pleace come back in a few minutes. The
            Beavr team.
        </div>
    );

    const maintenanceFlag = useFeatureFlagEnabled("maintenance");

    return maintenanceFlag ? underMaintenanceNode : rootNode;
};

export const router = createBrowserRouter([
    {
        element: <Root />,
        children: [
            {
                path: "/o",
                element: <LoginRequired />,
                children: [
                    {
                        path: "",
                        element: <Orgs />,
                    },
                    {
                        path: ":org_uname",
                        children: [
                            {
                                path: "admin",
                                children: adminRoutes,
                                element: <AdminLayout />,
                            },
                            {
                                path: "contribute",
                                children: contributeRoutes,
                            },
                            {
                                path: "*",
                                element: <Navigate to="admin" />,
                            },
                            {
                                index: true,
                                element: <Navigate to="admin" />,
                            },
                            {
                                path: "sustainability/edit",
                                element: (
                                    <SustainabilityCenter editMode={true} />
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/contribute/:org_uname/supplier/:questionnaire_id",
                element: <SupplierContribution />,
            },
            {
                path: "/contribute/supplier/:questionnaire_id", // This needs to be deleted soon
                element: <SupplierContribution />,
            },
            {
                path: "*",
                element: <Navigate to="/o" />,
            },
            {
                path: "/:org_uname",
                element: <SustainabilityCenter editMode={false} />,
            },
            {
                index: true,
                element: <Navigate to="o" />,
            },
        ],
    },
]);
