import { useS3FilesManagement } from "@app/hooks/createFileHook";
import {
    createCustomDocumentMutationAtom,
    documentsForSearchAtom,
} from "@app/store/documentStore";
import { createOrUpdateManyVersionsFromBulkS3FilesUploadMutationAtom } from "@app/store/versionStore";
import { newS3FileToCreateFileInput } from "@app/usecases/FileUseCases";
import { type S3FileFromBulkUpload } from "@app/usecases/VersionUseCases";
import { useAtom, useAtomValue } from "jotai";
import {
    bulkUploadInputsAtom,
    newS3FilesAtom,
} from "./DocumentsUploadModalContext";

export function useModalActions() {
    const [newS3Files, setNewS3Files] = useAtom(newS3FilesAtom);
    const { uploadFilesToS3, isLoading } = useS3FilesManagement();
    const [bulkUploadInputs, setBulkUploadInputs] =
        useAtom(bulkUploadInputsAtom);

    const createOrUpdateManyVersionsMutation = useAtomValue(
        createOrUpdateManyVersionsFromBulkS3FilesUploadMutationAtom,
    );

    const createCustomDocumentMutation = useAtomValue(
        createCustomDocumentMutationAtom,
    );

    const handleDropFiles = async (files: File[] | File) => {
        if (!files || isLoading) return;
        const toUploadFiles: File[] = Array.isArray(files) ? files : [files];
        const newS3Files = await uploadFilesToS3(toUploadFiles);
        setNewS3Files((prev) => [...prev, ...newS3Files]);
    };

    const associateFileToDocuments = (
        fileKey: string,
        documents: NonNullable<
            NonNullable<S3FileFromBulkUpload["associatedDocuments"]>[number]
        >[],
    ) => {
        setBulkUploadInputs((prevBulkUploadInputs) => {
            const updatedBulkUploadInputs = prevBulkUploadInputs.filter(
                (i) => i.file.key !== fileKey,
            );
            const newFile = newS3Files.find((i) => i.key === fileKey);

            const file = newFile
                ? newS3FileToCreateFileInput(newFile)
                : undefined;
            if (file && documents.length)
                updatedBulkUploadInputs.push({
                    file,
                    associatedDocuments: documents,
                });
            return updatedBulkUploadInputs;
        });
    };

    const dissociateFileFromDocuments = (fileKey: string) => {
        setBulkUploadInputs((prevBulkUploadInputs) =>
            prevBulkUploadInputs.filter((i) => i.file.key !== fileKey),
        );
    };

    const { data } = useAtomValue(documentsForSearchAtom);
    const documentsForSearch = data?.documents ?? [];

    const createCustomDocument = (name: string) => {
        if (createCustomDocumentMutation.isPending) return;

        return createCustomDocumentMutation.mutateAsync({ name });
    };

    const handleCreateVersions = async () => {
        // Call mutation
        await createOrUpdateManyVersionsMutation.mutateAsync(bulkUploadInputs);

        // Remove files that have been associated with documents
        setNewS3Files((prev) =>
            prev.filter(
                (i) => !bulkUploadInputs.some((b) => b.file.key === i.key),
            ),
        );
        setBulkUploadInputs([]);
    };

    const deleteFile = (fileKey: string) => {
        // Remove from state
        setNewS3Files((prev) => prev.filter((i) => i.key !== fileKey));

        // Not removing from S3. That s ok.
    };
    return {
        associateFileToDocuments,
        createCustomDocument,
        documentsForSearch,
        handleDropFiles,
        deleteFile,
        handleCreateVersions,
        isCreateVersionLoading: createOrUpdateManyVersionsMutation.isPending,
        isCreateCustomLoading: createCustomDocumentMutation.isPending,
        isUploading: isLoading,
        dissociateFileFromDocuments,
    };
}
