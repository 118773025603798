import { useSetToast } from "@app/components/Toast";
import { GraphQLDatapointGroupRepository } from "@app/repositories/GraphQLRepositories/DatapointGroupRepository";
import { GraphQLDatapointRepository } from "@app/repositories/GraphQLRepositories/DatapointRepository";
import { GraphQLUserRepository } from "@app/repositories/GraphQLRepositories/UserRepository";
import { GraphQLIndicatorEntityConfigRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorEntityConfigRepository";
import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import {
    exportReporting,
    exportReportingFiles,
    filterContributors,
    filterIndicators,
} from "@app/usecases/ReportingUseCases";
import {
    ColumnName,
    CreateDatapointGroupInput,
    CreateIndicatorInput,
    DatapointUpsertInput,
    ExportDataType,
    ExportFormat,
    IndicatorEntityConfigPatch,
    IndicatorPatch,
    ReportingExportFilesInput,
    ReportingExportInput,
    ReportingRemindersInput,
    UpdateIndicatorInput,
    User,
} from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import {
    activeReportingEntityIdAtom,
    activeReportingTableColumnsAtom,
    reportingContributorsSearchFilterAtom,
    selectedActiveFrameworkIdsAtom,
    selectedSubthemesAtom,
    selectedTagsAtom,
    selectedUsersAtom,
    toFillSwitchAtom,
} from "./context";
import { useActiveDatatpointGroupIds } from "./hooks";
import {
    ALL_ENTITIES_TOKEN,
    ExportData,
    exportData,
} from "./components/ExportModal";

export const reportingKeys = {
    all: ["reporting"] as const,
    indicators: () => [...reportingKeys.all, "indicators"] as const,
    indicatorsByEntityId: (entityId: string) =>
        [...reportingKeys.indicators(), entityId] as const,
    periods: () => [...reportingKeys.all, "periods"] as const,
    datapointGroups: () => ["datapointGroups"] as const,
    reportingContributors: () => ["reportingContributors"] as const,
    createDatapointGroup: () => ["createDatapointGroup"] as const,
    sendReminders: () => ["sendReminders"] as const,
    updateIndicatorEntityConfig: () => ["updateIndicatorEntityConfig"] as const,
    updateManyIndicatorEntityConfig: () =>
        ["updateManyIndicatorEntityConfig"] as const,
    upsertDatapoint: () => ["upsertDatapointV2"] as const,
    updateIndicator: () => ["updateIndicator"] as const,
    updateIndicators: () => ["updateIndicators"] as const,
};

const indicatorRepository = new GraphQLIndicatorRepository();
const datapointGroupRepository = new GraphQLDatapointGroupRepository();
const datapointRepository = new GraphQLDatapointRepository();
const indicatorEntityConfigRepository =
    new GraphQLIndicatorEntityConfigRepository();
const userRepository = new GraphQLUserRepository();

export const useCreateCustomIndicators = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation("ReportingPage");
    const { setToastSuccess, setToastError } = useSetToast();

    return useMutation({
        mutationFn: async (inputs: CreateIndicatorInput[]) => {
            return indicatorRepository.createManyCustomIndicators(inputs);
        },
        onSettled() {
            queryClient.invalidateQueries({ queryKey: reportingKeys.all });
        },
        onSuccess(data) {
            setToastSuccess(
                t("create_indicators_success", {
                    count: data.createIndicators,
                }),
            );
        },
        onError() {
            setToastError(t("create_indicators_error"));
        },
    });
};

export const useDatapointGroups = () => {
    const { data } = useQuery({
        queryKey: reportingKeys.periods(),
        queryFn: datapointGroupRepository.get,
    });

    return data?.datapointGroups;
};

export const useActiveReportingIndicators = () => {
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const { data, isPending } = useQuery({
        queryKey: reportingKeys.indicatorsByEntityId(activeReportingEntityId),
        queryFn: () => indicatorRepository.get(activeReportingEntityId),
    });
    const [urlSearchString, _] = useQueryParam("search", StringParam);
    const toFillSwitch = useAtomValue(toFillSwitchAtom);
    const groupsOfEntity = useDatapointGroupsOfEntity();
    const activeDatapointGroupsIds = useActiveDatatpointGroupIds();

    return {
        data: filterIndicators(
            data?.indicators2,
            urlSearchString || undefined,
            toFillSwitch,
            groupsOfEntity,
            activeDatapointGroupsIds,
        ),
        isPending,
    };
};

export const useInactiveIndicators = () => {
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    const { data } = useQuery({
        queryKey: reportingKeys.indicatorsByEntityId(activeReportingEntityId),
        queryFn: () => indicatorRepository.get(activeReportingEntityId),
    });

    return (
        data?.indicators2?.filter(
            (indicator) => !indicator?.entityConfig?.active,
        ) || []
    );
};

export const useExportReporting = () => {
    const indicators = useActiveReportingIndicators();
    const inactiveIndicators = useInactiveIndicators();
    const activeDatapointGroupIds = useActiveDatatpointGroupIds();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const activeTagsId = useAtomValue(selectedTagsAtom);
    const activeFrameworkIds = useAtomValue(selectedActiveFrameworkIdsAtom);
    const activeSubthemesIds = useAtomValue(selectedSubthemesAtom);
    const activeUserIds = useAtomValue(selectedUsersAtom);
    const activeColumns = useAtomValue(activeReportingTableColumnsAtom);
    const datapointGroupsData = useDatapointReportingGroups();
    const allDatapointGroupsIds =
        datapointGroupsData?.data?.datapointGroups?.map((group) => group.id) ||
        [];
    const groupsIdsOfNonActiveEntities =
        datapointGroupsData?.data?.datapointGroups
            ?.filter((group) => group.entity?.id !== activeReportingEntityId)
            .map((group) => group.id) || [];

    const mutation = useMutation({
        mutationFn: async ({
            exportFormat,
            entityIds,
            filterValue,
        }: {
            exportFormat: ExportFormat;
            entityIds: string[];
            filterValue: ExportData;
        }) => {
            let input: ReportingExportInput;
            input = {
                indicatorIds: [
                    ...indicators.data.map((indicator) => indicator.id),
                    ...inactiveIndicators.map((indicator) => indicator.id),
                ],
                groupIds: allDatapointGroupsIds,
                entityIds: entityIds.filter((id) => id !== ALL_ENTITIES_TOKEN),
                exportFormat,
                exportDataType: ExportDataType.All,
            };
            if (filterValue === exportData.Filtered) {
                input.groupIds = [
                    ...activeDatapointGroupIds,
                    ...groupsIdsOfNonActiveEntities,
                ];
                input.exportDataType = ExportDataType.Filtered;
                input.columnsIds = activeColumns.map(
                    (column) => column?.toUpperCase() as ColumnName,
                );
                input.selectedUsersIds = activeUserIds.map((id) =>
                    id === null ? "null" : id,
                );
                input.selectedTagsIds = activeTagsId.filter(
                    (tagId) => tagId !== null,
                ) as string[];
                input.selectedFrameworksIds = activeFrameworkIds.filter(
                    (frameworkId) => frameworkId !== null,
                ) as string[];
                input.selectedSubthemesIds = activeSubthemesIds.filter(
                    (subthemeId) => subthemeId !== null,
                );
            }
            return exportReporting(datapointRepository, input);
        },
    });

    return mutation;
};

export const useExportReportingFiles = () => {
    const indicators = useActiveReportingIndicators();
    const activeDatapointGroupIds = useActiveDatatpointGroupIds();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    const mutation = useMutation({
        mutationFn: async () => {
            const input: ReportingExportFilesInput = {
                indicatorIds: indicators.data.map((indicator) => indicator.id),
                groupIds: activeDatapointGroupIds,
                entityId: activeReportingEntityId,
            };

            return exportReportingFiles(datapointRepository, input);
        },
    });

    return mutation;
};

export const useDatapointReportingGroups = () => {
    const { data } = useQuery({
        queryKey: [...reportingKeys.datapointGroups(), {}],
        queryFn: datapointGroupRepository.get,
    });

    return { data };
};

export const useReportingContributorsData = () => {
    const { data, isPending } = useQuery({
        queryKey: [...reportingKeys.reportingContributors(), {}],
        queryFn: userRepository.getWithReportingStats,
    });

    return { data, isPending };
};

export function getFilteredReportingContributors() {
    const { data, isPending } = useReportingContributorsData();
    const reportingContributorsSearch = useAtomValue(
        reportingContributorsSearchFilterAtom,
    );

    return {
        data: filterContributors(
            (data?.users || []) as User[],
            reportingContributorsSearch,
        ),
        isPending,
    };
}

export const useCreateDatapointGroup = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...reportingKeys.createDatapointGroup()],
        mutationFn: async (input: CreateDatapointGroupInput) => {
            return await datapointGroupRepository.create(input);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.datapointGroups(),
            });
        },
    });

    return mutation;
};

export function useDatapointGroupsOfEntity() {
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const { data: datapointGroupsData } = useDatapointReportingGroups();

    const sortedGroups =
        datapointGroupsData?.datapointGroups
            ?.filter((group) => group?.entity?.id === activeReportingEntityId)
            .sort(
                (d1, d2) =>
                    datapointGroupsData?.datapointGroups?.find(
                        (elt) => elt.id === d1.id,
                    )?.period?.year -
                    datapointGroupsData?.datapointGroups?.find(
                        (elt) => elt.id === d2.id,
                    )?.period?.year,
            ) || [];

    return sortedGroups;
}

export const useSendReminders = () => {
    const queryClient = useQueryClient();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    const mutation = useMutation({
        mutationKey: [...reportingKeys.sendReminders()],
        mutationFn: async (input: ReportingRemindersInput) => {
            await indicatorRepository.sendReminders(input);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.indicatorsByEntityId(
                    activeReportingEntityId,
                ),
            });
        },
    });

    return mutation;
};

export const useUpdateIndicatorEntityConfig = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...reportingKeys.updateIndicatorEntityConfig()],
        mutationFn: async ({
            indicatorEntityConfigId,
            set,
        }: {
            indicatorEntityConfigId: string;
            set: IndicatorEntityConfigPatch;
        }) => {
            await indicatorEntityConfigRepository.update({
                indicatorEntityConfigId,
                set,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.indicators(),
            });
        },
    });

    return mutation;
};

export const useUpdateManyIndicatorEntityConfig = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...reportingKeys.updateManyIndicatorEntityConfig()],
        mutationFn: async ({
            indicatorEntityConfigIds,
            set,
        }: {
            indicatorEntityConfigIds: string[];
            set: IndicatorEntityConfigPatch;
        }) => {
            await indicatorEntityConfigRepository.updateMany({
                indicatorEntityConfigIds,
                set,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.indicators(),
            });
        },
    });

    return mutation;
};

export const useUpsertDatapoint = () => {
    const queryClient = useQueryClient();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    const mutation = useMutation({
        mutationKey: [...reportingKeys.upsertDatapoint()],
        mutationFn: async ({ set }: { set: DatapointUpsertInput }) => {
            await datapointRepository.upsert({
                set,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.indicatorsByEntityId(
                    activeReportingEntityId,
                ),
            });
        },
    });

    return mutation;
};

export const useUpdateIndicator = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...reportingKeys.updateIndicator()],
        mutationFn: async ({
            id,
            patch,
        }: {
            id: string;
            patch: IndicatorPatch;
        }) => {
            await indicatorRepository.update({
                id,
                patch,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.indicators(),
            });
        },
    });

    return mutation;
};

export const useUpdateIndicators = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...reportingKeys.updateIndicators()],
        mutationFn: async ({ input }: { input: UpdateIndicatorInput[] }) => {
            await indicatorRepository.updateMany(input);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: reportingKeys.indicators(),
            });
        },
    });

    return mutation;
};
