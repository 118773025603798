import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import {
    GetAllSupplierQuestionnaireTemplatesDocument,
    SupplierQuestionnaireTemplate,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import BaseTrigger from "../../components/triggers/Base";
import SupplierQuestionnaireTemplatesDropdownMultiSelect from "./dropdown_multi_select";
import "./i18n";

const TemplatesSetterFloatbar = ({
    templateIds,
    onTemplateIdsChange,
}: {
    templateIds?: string[] | undefined;
    onTemplateIdsChange: (templateIds: string[]) => void;
}) => {
    const { t } = useTranslation("SupplierQuestionnaireTemplatesDropdown");
    const getTemplates = useGraphQL(
        GetAllSupplierQuestionnaireTemplatesDocument,
    );

    return (
        <DropdownMenu>
            <BaseTrigger>
                {t("setter_text", { count: templateIds?.length || 0 })}
            </BaseTrigger>
            <SupplierQuestionnaireTemplatesDropdownMultiSelect
                templateIds={templateIds}
                onTemplateIdsChange={onTemplateIdsChange}
                templates={
                    getTemplates.data?.supplierQuestionnaireTemplates as
                        | SupplierQuestionnaireTemplate[]
                        | undefined
                }
            />
        </DropdownMenu>
    );
};

export default TemplatesSetterFloatbar;
