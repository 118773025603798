import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { Textarea } from "@design-system/Inputs/Textarea";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { MaterialityStatus } from "@generated/client/graphql";
import { useAtomValue, useSetAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

import { RESOURCE_NAME, keys } from "./i18n";

import { useSetToast } from "@app/components/Toast";
import {
    closeMaterialityModalAtom,
    materialityModalCtxAtom,
    openMaterialityModalAtom,
} from "../../ctx";
import {
    useUpdateDatapoint,
    useUpdateDisclosureRequirementMateriality,
} from "../../data";

export const MaterialityModal = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { setToastSuccess, setToastError } = useSetToast();
    const modalCtx = useAtomValue(materialityModalCtxAtom);
    const title = modalCtx.datapointId ? keys.datapoint_title : keys.dr_title;
    const [status, setStatus] = useState<MaterialityStatus>(modalCtx.status);
    const [comment, setComment] = useState<string | undefined>(
        modalCtx.comment,
    );
    const [error, setSerror] = useState<string | undefined>(undefined);
    const closeMaterialityModal = useSetAtom(closeMaterialityModalAtom);
    const openMaterialityModal = useSetAtom(openMaterialityModalAtom);
    const onOpenChange = (open: boolean) => {
        if (!open) {
            closeMaterialityModal();
        } else {
            openMaterialityModal(MaterialityStatus.Unknown);
        }
    };
    useEffect(() => {
        setStatus(modalCtx.status);
        setComment(modalCtx.comment);
        setSerror(undefined);
    }, [modalCtx]);

    const updateError = (
        comment: string | undefined,
        status: MaterialityStatus,
    ) => {
        if (status === MaterialityStatus.NotMaterial && !comment) {
            setSerror(t("comment_error"));
        } else {
            setSerror(undefined);
        }
    };
    const handleStatusChange = (status: MaterialityStatus) => {
        setStatus(status);
        updateError(comment, status);
    };
    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setComment(event.target.value);
        updateError(event.target.value, status);
    };

    const { isPending: isDatapointPending, mutate: datapointMutate } =
        useUpdateDatapoint(modalCtx.datapointId || "");

    const {
        isPending: isDisclosureRequirementPending,
        mutate: disclosureRequirementMutate,
    } = useUpdateDisclosureRequirementMateriality(
        modalCtx.disclosureRequirementId,
    );

    const onConfirm = () => {
        if (modalCtx.datapointId) {
            datapointMutate(
                {
                    id: modalCtx.datapointId,
                    patch: { materialityPatch: { status, comment } },
                },
                {
                    onSuccess: () => {
                        closeMaterialityModal();
                        setToastSuccess(t("success"));
                    },
                    onError: () => {
                        closeMaterialityModal();
                        setToastError(t("error"));
                    },
                },
            );
        }
        if (modalCtx.disclosureRequirementId) {
            disclosureRequirementMutate(
                { status, comment },
                {
                    onSuccess: () => {
                        closeMaterialityModal();
                        setToastSuccess(t("success"));
                    },
                    onError: () => {
                        closeMaterialityModal();
                        setToastError(t("error"));
                    },
                },
            );
        }
    };

    const disabled =
        !!error || (status === modalCtx.status && comment === modalCtx.comment);

    return (
        <Modal open={modalCtx.open} onOpenChange={onOpenChange}>
            <Modal.Overlay>
                <Modal.Content className="w-[660px]">
                    <Modal.Header className="gap-2">
                        <Modal.Title>{t(title)}</Modal.Title>
                        <Modal.Subtitle>{modalCtx.description}</Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body className="h-[200px]">
                        <FlexCol gap="4">
                            <MaterialitySelect
                                status={status}
                                setStatus={handleStatusChange}
                            />
                            <Textarea
                                placeholder={t("comment_placeholder")}
                                value={comment}
                                onChange={handleCommentChange}
                                className="w-full"
                                error={error}
                            />
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t("cancel")}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={
                                isDatapointPending ||
                                isDisclosureRequirementPending
                            }
                            onClick={onConfirm}
                            preventClose
                            disabled={disabled}
                        >
                            {t("save")}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

const MaterialitySelect: FC<{
    status?: MaterialityStatus;
    setStatus: (status: MaterialityStatus) => void;
}> = ({ status, setStatus }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const handleValueChange = (value: string) => {
        setStatus(value as MaterialityStatus);
    };
    return (
        <SingleSelect
            value={status}
            defaultValue={MaterialityStatus.Unknown}
            onValueChange={handleValueChange}
        >
            <SingleSelect.Trigger>
                <Button variant={"tonal"} className="bg-white h-[52px] py-0">
                    <FlexRow
                        justifyContent="between"
                        alignItems="center"
                        w="full"
                        px="3"
                    >
                        <FlexCol alignItems="start">
                            <Text variant="body-sm" color="secondary">
                                {t("materiality")}
                            </Text>
                            <Text>
                                {status === MaterialityStatus.Material
                                    ? t("yes")
                                    : status === MaterialityStatus.NotMaterial
                                      ? t("no")
                                      : ""}
                            </Text>
                        </FlexCol>
                        <Icon name="angleDown" />
                    </FlexRow>
                </Button>
            </SingleSelect.Trigger>
            <SingleSelect.Content hasPortal={true} className="w-[200px]">
                <SingleSelect.Option
                    key={MaterialityStatus.Material}
                    value={MaterialityStatus.Material}
                    label={t("material")}
                ></SingleSelect.Option>
                <SingleSelect.Option
                    key={MaterialityStatus.NotMaterial}
                    value={MaterialityStatus.NotMaterial}
                    label={t("not_material")}
                ></SingleSelect.Option>
            </SingleSelect.Content>
        </SingleSelect>
    );
};
