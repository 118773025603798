import { useQueryClient } from "@tanstack/react-query";
import {
    CloseOngoingQuestionnairesDocument,
    CreateQuestionnairesDocument,
    GetSuppliersDocument,
    GetSuppliersQuery,
    Supplier,
    SupplierPatch,
    UpdateSuppliersDocument,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import { useSetToast } from "../../../../components/Toast";

export const useUpdateSuppliers = () => {
    const client = useQueryClient();
    const getSuppliersKey = useGraphQL.getKey(GetSuppliersDocument);
    const updateSuppliersMutation = useGraphQLMutation(UpdateSuppliersDocument);

    const updateSuppliersCache = (
        suppliersIds: string[],
        patch: SupplierPatch,
    ) => {
        client.setQueryData<GetSuppliersQuery>(getSuppliersKey, () => {
            const data =
                client.getQueryData<GetSuppliersQuery>(getSuppliersKey);
            if (!data?.suppliers) {
                return undefined;
            }
            const updatedSuppliers = data.suppliers?.map((s) => {
                if (suppliersIds.includes(s.id)) {
                    return {
                        ...s,
                        ...(patch.tagIds
                            ? {
                                  tags: patch.tagIds.map((id) => ({
                                      ...(s.tags?.find((t) => t.id === id) || {
                                          name: "...",
                                      }),
                                      id,
                                  })),
                              }
                            : undefined),
                    } as Supplier;
                } else {
                    return s;
                }
            });
            return { suppliers: updatedSuppliers } as GetSuppliersQuery;
        });
    };

    const updateSuppliers = (suppliersIds: string[], patch: SupplierPatch) => {
        updateSuppliersCache(suppliersIds, patch);

        updateSuppliersMutation.mutate(
            {
                inputs: suppliersIds.map((id) => ({
                    id,
                    set: patch,
                })),
            },
            {
                onSuccess: (data) => {
                    client.invalidateQueries({ queryKey: getSuppliersKey });
                    const oldSuppliersData =
                        client.getQueryData<GetSuppliersQuery>(getSuppliersKey);
                    if (!oldSuppliersData?.suppliers) {
                        return undefined;
                    }
                    const updatedSuppliers = oldSuppliersData.suppliers?.map(
                        (s) => {
                            const updatedSupplier =
                                data.updateSuppliers.findIndex(
                                    (su) => su.id === s.id,
                                );
                            return updatedSupplier || s;
                        },
                    );
                    return { suppliers: updatedSuppliers } as GetSuppliersQuery;
                },
                onError: () => {
                    // Add error handling
                },
            },
        );
    };

    return { updateSuppliers };
};

export const useCreateQuestionnaires = () => {
    const client = useQueryClient();
    const getSuppliersKey = useGraphQL.getKey(GetSuppliersDocument);
    const createQuestionnaireMutation = useGraphQLMutation(
        CreateQuestionnairesDocument,
    );
    const { setToastError } = useSetToast();

    const createQuestionnaire = (supplierIds: string[], templateId: string) => {
        createQuestionnaireMutation.mutate(
            {
                inputs: supplierIds.map((id) => ({
                    supplierId: id,
                    questionnaireTemplateId: templateId,
                    year: new Date().getFullYear(),
                })),
            },
            {
                onSuccess: () => {
                    client.invalidateQueries({ queryKey: getSuppliersKey });
                },
                onError: (e) => {
                    // @ts-ignore
                    const error = e.response.errors[0].message as string;
                    if (error === "QUESTIONNAIRE_ALREADY_OPEN") {
                        setToastError("Please close open questionnaires first");
                    } else {
                        setToastError("Something went wrong");
                    }
                },
            },
        );
    };

    return { ...createQuestionnaireMutation, mutate: createQuestionnaire };
};

export const useCloseOngoingQuestionnaires = () => {
    const client = useQueryClient();
    const getSuppliersKey = useGraphQL.getKey(GetSuppliersDocument);
    const closeOngoingQuestionnairesMutation = useGraphQLMutation(
        CloseOngoingQuestionnairesDocument,
    );
    const { setToastError } = useSetToast();

    const closeOngoingQuestionnaires = (supplierIds: string[]) => {
        closeOngoingQuestionnairesMutation.mutate(
            {
                supplierIds: supplierIds,
            },
            {
                onSuccess: () => {
                    client.invalidateQueries({ queryKey: getSuppliersKey });
                },
                onError: (e) => {
                    // @ts-ignore
                    const error = e.response.errors[0].message as string;
                    setToastError("Something went wrong: " + error);
                },
            },
        );
    };

    return {
        ...closeOngoingQuestionnairesMutation,
        mutate: closeOngoingQuestionnaires,
    };
};
