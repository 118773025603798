import i18next from "i18next";

i18next.addResourceBundle("en", "TopBar", {
    help_center: "Help center",
    referral: "Learn more about our referral program",
    profile_settings: "Account settings",
    profile_log_out: "Log out",
});

i18next.addResourceBundle("fr", "TopBar", {
    help_center: "Centre d'aide",
    referral: "En savoir plus sur notre programme de parrainage",
    profile_settings: "Paramètres du compte",
    profile_log_out: "Se déconnecter",
});
