import { Icon } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { Column } from "@tanstack/react-table";

interface HeaderProps<TData> {
    title?: string;
    children?: React.ReactNode;
    column: Column<TData, unknown>;
    disableSort?: boolean;
}

const SortBtn = <T,>({ column }: { column: Column<T, unknown> }) => (
    <button
        className={cn(
            "cursor-pointer rounded-full border min-h-5 min-w-5 grid place-items-center",
            "text-primary",
            "bg-secondary border-secondary",
            "hover:bg-tertiary hover:border-primary",
        )}
        onClick={column.getToggleSortingHandler()}
    >
        {!column.getIsSorted() && <Icon name="angleUpDown" size="xs" />}
        {column.getIsSorted() === "desc" && <Icon name="arrowUp" size="xs" />}
        {column.getIsSorted() === "asc" && <Icon name="arrowDown" size="xs" />}
    </button>
);

export default function Header<TData>({
    children,
    column,
    title,
    disableSort,
}: HeaderProps<TData>) {
    return (
        <Box
            className="flex items-center space-x-2 text-sm font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
            {!disableSort && column.getCanSort() && <SortBtn column={column} />}
            {title ? <span>{title}</span> : null}
            {children}
        </Box>
    );
}
