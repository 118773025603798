import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { RequirementRepository } from "@app/repositories/RequirementRepository";
import {
    GetRequirementsDocument,
    GetRequirementsQuery,
    GetRequirementsQueryVariables,
    GetRequirementStatsDocument,
    Relevance,
    RequirementFilter,
    RequirementPatch,
    UpdateRequirementsDocument,
} from "@generated/client/graphql";

export class GraphQLRequirementRepository implements RequirementRepository {
    getMany = async (
        input: GetRequirementsQueryVariables,
    ): Promise<GetRequirementsQuery> => {
        return graphqlClientKoyeb.request(
            GetRequirementsDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getStats = async (filter: RequirementFilter) => {
        return graphqlClientKoyeb.request(
            GetRequirementStatsDocument,
            { filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getAllNotRelevant = async (): Promise<GetRequirementsQuery> => {
        return graphqlClientKoyeb.request(
            GetRequirementsDocument,
            { filter: { relevance: Relevance.NotRelevant }, take: 1000 },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateMany = async ({
        ids,
        patch,
    }: {
        ids: string[];
        patch: RequirementPatch;
    }) => {
        return graphqlClientKoyeb.request(
            UpdateRequirementsDocument,
            { ids, set: patch },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
