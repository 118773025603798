import "./i18n";

export enum Variant {
    Done,
    Validated,
    InProgress,
    PendingReview,
    Answered,
    Suggestion,
    Base,
    InProgressVariant,
    NotStarted,
    Transfered,
    ArrowDown,
    Nothing,
}
