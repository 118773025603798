import i18next from "@app/i18n";

const enResources = {
    datapoint_title: "Datapoint materiality",
    dr_title: "Disclosure requirement materiality",
    materiality: "Materiality",
    comment_placeholder: "Describe why it is not material",
    cancel: "Cancel",
    save: "Save",
    yes: "Yes",
    no: "No",
    material: "Material",
    not_material: "Not material",
    comment_error: "A comment is mandatory",
    success: "Sucessfully updated materiality",
    error: "Error updating materiality",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    datapoint_title: "materialité du point de donnée",
    dr_title: "materialité de l'exigence de divulgation",
    materiality: "Matérialité",
    comment_placeholder: "Expliquer pourquoi ce n'est pas matériel",
    cancel: "Annuler",
    save: "Enregistrer",
    yes: "Oui",
    no: "Non",
    material: "Matériel",
    not_material: "Non matériel",
    comment_error: "Un commentaire est requis",
    success: "Matérialité mise à jour avec succès",
    error: "Erreur lors de la mise à jour de la matérialité",
} as const;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "MaterialityModal";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
