import i18next from "@app/i18n";

const enResources = {
    card_datapoints_completion: "Datapoints Completion",
    complteted: "{{count}} datapoints completed",
    total: "{{count}} total",
} as const;

const frResources: Record<ResourcesKeys, string> = {
    card_datapoints_completion: "Remplissage des datapoints",
    complteted: "{{count}} datapoints completés",
    total: "{{count}} total",
} as const;

type ResourcesKeys = keyof typeof enResources;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResources("en", "DatapointsCompletionCard", enResources);
i18next.addResources("fr", "DatapointsCompletionCard", frResources);
