import { useEffect, useMemo, useState } from "react";

// import Ai from '@tiptap-pro/extension-ai'
import { TiptapCollabProvider, WebSocketStatus } from "@hocuspocus/provider";
import * as Y from "yjs";

import { currentUserAtom } from "@app/screens/Global/TopBar/TopBarProfil";
import {
    TableOfContentData,
    TableOfContents,
    getHierarchicalIndexes,
} from "@tiptap-pro/extension-table-of-contents";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { Editor, useEditor } from "@tiptap/react";
import { useAtomValue } from "jotai";
import { EditorUser } from "../components/BlockEditor/types";
import ExtensionKit from "../extensions/extension-kit";
import { userColors } from "../lib/constants";
import { randomElement } from "../lib/utils";

// const TIPTAP_AI_APP_ID = process.env.NEXT_PUBLIC_TIPTAP_AI_APP_ID;
// const TIPTAP_AI_BASE_URL =
//     process.env.NEXT_PUBLIC_TIPTAP_AI_BASE_URL ||
//     "https://api.tiptap.dev/v1/ai";

declare global {
    interface Window {
        editor: any;
    }
}

export const useBlockEditor = ({
    // aiToken,
    ydoc,
    provider,
    editable,
}: {
    aiToken?: string;
    ydoc: Y.Doc;
    provider?: TiptapCollabProvider | null | undefined;
    editable?: boolean;
}) => {
    const [items, setItems] = useState<TableOfContentData>([]);
    const currentUser = useAtomValue(currentUserAtom);
    // const { setIsAiLoading, setAiError } = useContext(EditorContext)

    const editor = useEditor(
        {
            editable,
            autofocus: true,
            onCreate: () => {
                provider?.on("synced", () => {});
            },

            extensions: [
                ...ExtensionKit({
                    provider,
                }),
                Collaboration.configure({
                    document: ydoc,
                }),
                ...(provider
                    ? [
                          CollaborationCursor.configure({
                              provider: provider || undefined,
                              user: {
                                  name: `${currentUser?.firstName} ${currentUser?.lastName}`,
                                  color: randomElement(userColors),
                              },
                          }),
                      ]
                    : []),
                TableOfContents.configure({
                    getIndex: getHierarchicalIndexes,
                    onUpdate(content) {
                        setItems(content);
                    },
                }),
                // Ai.configure({
                //   appId: TIPTAP_AI_APP_ID,
                //   token: aiToken,
                //   baseUrl: TIPTAP_AI_BASE_URL,
                //   autocompletion: true,
                //   onLoading: () => {
                //     setIsAiLoading(true)
                //     setAiError(null)
                //   },
                //   onSuccess: () => {
                //     setIsAiLoading(false)
                //     setAiError(null)
                //   },
                //   onError: error => {
                //     setIsAiLoading(false)
                //     setAiError(error.message)
                //   },
                // }),
            ],
            editorProps: {
                attributes: {
                    autocomplete: "off",
                    autocorrect: "off",
                    autocapitalize: "off",
                    class: "min-h-full",
                },
                transformPastedHTML(html) {
                    var el = document.createElement("utils");
                    el.innerHTML = html;
                    [...el.attributes].forEach((attr) => {
                        el.removeAttribute(attr.name);
                    });
                    return el.innerHTML;
                },
            },
        },
        [ydoc, provider, currentUser?.id, editable],
    );

    window.editor = editor;

    return { editor, tableOfContentsItems: items };
};

export const useEditorState = ({
    provider,
    editor,
}: {
    editor?: Editor | null;
    provider?: TiptapCollabProvider | null | undefined;
}) => {
    const [collabState, setCollabState] = useState<WebSocketStatus>(
        WebSocketStatus.Connecting,
    );

    useEffect(() => {
        provider?.on("status", (event: { status: WebSocketStatus }) => {
            setCollabState(event.status);
        });
    }, [provider]);

    const users = useMemo(() => {
        if (!editor?.storage.collaborationCursor?.users) {
            return [];
        }

        return editor.storage.collaborationCursor?.users.map(
            (user: EditorUser) => {
                const names = user.name?.split(" ");
                const firstName = names?.[0];
                const lastName = names?.[names.length - 1];
                const initials = `${firstName?.[0] || "?"}${
                    lastName?.[0] || "?"
                }`;

                return {
                    ...user,
                    initials: initials.length ? initials : "?",
                } as { initials: string } & EditorUser & { color: string };
            },
        );
    }, [editor?.storage.collaborationCursor?.users]);

    const characterCount = editor?.storage.characterCount || {
        characters: () => 0,
        words: () => 0,
    };

    return { collabState, users, characterCount };
};
