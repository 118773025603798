import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "OrgTypeDropdown", {
    placeholder: "Type",
    TEST: "Test",
    DEMO: "Demo",
    CUSTOMER: "Customer",
    CUSTOMER_CHURNED: "Churned customer",
    ARCHIVED: "Archived",
});

i18next.addResourceBundle("fr", "OrgTypeDropdown", {
    placeholder: "Type",
    TEST: "Test",
    DEMO: "Démo",
    CUSTOMER: "Client",
    CUSTOMER_CHURNED: "Churned client",
    ARCHIVED: "Archivé",
});
