import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useSetAtom } from "jotai";
import { Bell, EyeOff, Link2, Trash2 } from "lucide-react";
import { useParams } from "react-router-dom";
import {
    DeleteSupplierDocument,
    GetSuppliersDocument,
    Supplier,
    SupplierQuestionnaireStatus,
    Tag,
    TagType,
} from "../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../QueryClientWithHeaders";
import { ActionDropdownMenuItem } from "../../../../../components/ActionDropdown";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../components/Button/ButtonSmall";
import Checkbox from "../../../../../components/Checkbox";
import TagsFilter from "../../../../../components/Dropdown/dropdowns/Tags/filter";
import ActionWithConfirmation from "../../../../../components/Modal/ActionWithConfirmation";
import ProgressBar from "../../../../../components/Progress/ProgressBar";
import SupplierReminder from "../../../../../components/Reminder/SupplierReminder";
import ActionDropdown from "../../../../../components/Table/ActionDropdown";
import ActionItemDanger from "../../../../../components/Table/ActionItemDanger";
import Header from "../../../../../components/Table/Header";
import TagList from "../../../../../components/Tags/TagList";
import { useSetToast } from "../../../../../components/Toast";
import { Tooltip } from "../../../../../components/Tooltip";
import { Actions, supplierSaveBarActionAtom } from "./components/Savebar";
import { useUpdateSupplierQuestionnaire } from "./hooks/updateSupplierQuestionnaire";

const columns: ColumnDef<Supplier>[] = [
    {
        id: "select",
        meta: {
            headerClassName: "w-[34px]",
        },
        header: ({ table }) => {
            return (
                <div className="flex flex-row space-x-2">
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onClick={(e) => {
                            e.stopPropagation();
                            table.toggleAllRowsSelected(
                                !table.getIsAllRowsSelected(),
                            );
                        }}
                        aria-label="Select all"
                    />
                </div>
            );
        },
        cell: ({ row }) => {
            return (
                <div className="flex flex-row space-x-2">
                    <Checkbox
                        checked={row.getIsSelected()}
                        onClick={(e) => {
                            e.stopPropagation();
                            row.toggleSelected();
                        }}
                        aria-label="Select"
                    />
                </div>
            );
        },
    },
    {
        id: "name",
        accessorFn: (row) => row.name,
        header: ({ column }) => {
            const { t } = useTranslation("SupplierTable");
            return <Header column={column} title={t("name")} />;
        },
        cell: ({ row }) => {
            const { t } = useTranslation("SupplierTable");

            return (
                <div>
                    <div className="text-sm font-bold text-green">
                        {row.original.name}
                    </div>
                    <Tooltip
                        tooltipContent={
                            <div>
                                {row.original.users?.map((user) => (
                                    <div className="text-sm w-fit text-white">
                                        {user?.firstName} {user?.lastName}
                                    </div>
                                ))}
                            </div>
                        }
                        side="right"
                        sideOffset={10}
                    >
                        <div className="text-sm w-fit">
                            {t("n_users", {
                                count: row.original.users?.length,
                            })}
                        </div>
                    </Tooltip>
                </div>
            );
        },
    },
    {
        id: "tags",
        filterFn: "arrIncludesSome",
        accessorFn: (row) => row.tags.map((t) => t.id),
        header: () => {
            return <TagsFilter type={TagType.Supplier} />;
        },
        cell: ({ row }) => {
            return (
                <div className="group">
                    <TagList
                        tags={row.original.tags as Tag[]}
                        // extra={
                        //     <div className="invisible group-hover:visible focus-within:visible">
                        //         <TagsSetter
                        //             type={TagType.Supplier}
                        //             tagIds={row.original.tags.map((t) => t.id)}
                        //             onTagIdsChange={() => {}}
                        //         />
                        //     </div>
                        // }
                    />
                </div>
            );
        },
    },
    {
        id: "lastQuestionnaire",
        header: () => {
            const { t } = useTranslation("SupplierTable");
            return (
                <div className="text-sm font-bold text-neutral-700">
                    {t("last_questionnaire")}
                </div>
            );
        },
        cell: ({ row }) => {
            const { t } = useTranslation("SupplierTable");
            dayjs.extend(LocalizedFormat);

            const questionnaire = row.original.lastQuestionnaire;
            return questionnaire ? (
                <div>
                    <div className="text-base font-regular">
                        {questionnaire.template?.name}
                    </div>
                    <div className="text-sm text-neutral-700 ">
                        {questionnaire.status === "CLOSED" ? (
                            <div className="text-sm text-neutral-700 ">
                                {/* <Lock className="w-3 h-3 text-secondary" />
                                 */}
                                <span className="text-base mr-2">🔒</span>
                                <span>{t("closed_on")}</span>
                                {dayjs(questionnaire.closedAt).format("LL")}
                            </div>
                        ) : questionnaire.lastReminderSentAt ? (
                            <>
                                <span>{t("last_reminder_sent_on")}</span>
                                {dayjs(questionnaire.lastReminderSentAt).format(
                                    "LL",
                                )}
                            </>
                        ) : (
                            <>
                                <span>{t("created_on")}</span>
                                {dayjs(questionnaire.createdAt).format("LL")}
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="text-sm font-regular">
                    {t("no_questionnaire")}
                </div>
            );
        },
    },
    {
        id: "stats",
        accessorFn: (row) => row.lastQuestionnaire?.stats.progress,
        header: ({ column }) => {
            const { t } = useTranslation("SupplierTable");
            return (
                <Header title={t("last_questionnaire_stats")} column={column} />
            );
        },
        cell: ({ row }) => {
            const stats = row.original.lastQuestionnaire?.stats;
            return (
                stats?.progress !== undefined &&
                stats.progress !== null && (
                    <div className="flex space-x-3 items-center">
                        <ProgressBar progress={stats.progress} />
                        <div className="text-sm whitespace-nowrap">
                            {stats.progress} %
                        </div>
                    </div>
                )
            );
        },
    },
    {
        id: "actions",
        accessorFn: (row) => row.lastQuestionnaire?.stats.progress,
        header: () => {
            const { t } = useTranslation("SupplierTable");
            return (
                <div className="text-sm font-bold text-neutral-700">
                    {t("action")}
                </div>
            );
        },
        cell: ({ row, table }) => {
            const { org_uname } = useParams();
            const { setToastSuccess } = useSetToast();
            const { t } = useTranslation("SupplierTable");

            const copyToClipboard = (text: string) => {
                let isCopy = copy(text);

                if (isCopy) {
                    setToastSuccess(t("copied_to_clipboard"));
                }
            };

            const client = useQueryClient();
            const deleteMutation = useGraphQLMutation(DeleteSupplierDocument);

            const { updateQuestionnaire } = useUpdateSupplierQuestionnaire(
                row.original.lastQuestionnaire?.id,
            );
            const setActiveAction = useSetAtom(supplierSaveBarActionAtom);
            const onDelete = () => {
                deleteMutation.mutate(
                    { supplierId: row.original.id },
                    {
                        onSuccess: () => {
                            setToastSuccess(t("supplier_deleted"));
                            client.invalidateQueries({
                                queryKey:
                                    useGraphQL.getKey(GetSuppliersDocument),
                            });
                        },
                    },
                );
            };

            return (
                <div
                    className="flex space-x-1 justify-end"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {row.original.lastQuestionnaire && (
                        <ButtonSmall
                            IconLeft={Link2}
                            variant={ButtonType.OUTLINED}
                            onClick={() => {
                                copyToClipboard(
                                    `${window.location.origin}/contribute/${org_uname}/supplier/${row.original.lastQuestionnaire?.id}`,
                                );
                            }}
                        />
                    )}
                    <ActionDropdown>
                        <ActionWithConfirmation
                            TriggerButton={({ onClick }) => (
                                <ActionItemDanger
                                    icon={Trash2}
                                    title={t("delete_button")}
                                    onClick={onClick}
                                />
                            )}
                            title={t("delete_title")}
                            confirmButton={
                                <ButtonRegular
                                    variant={ButtonType.FLAT_DANGER}
                                    onClick={onDelete}
                                    loading={deleteMutation.isPending}
                                >
                                    {t("delete_confirm_button")}
                                </ButtonRegular>
                            }
                        >
                            <div>
                                {t("delete_text_1", {
                                    supplier: row.original.name,
                                })}
                                <b>{t("delete_text_2")}</b>.
                                <br />
                                <span className="text-error-500 font-bold">
                                    {t("delete_text_3")}
                                </span>
                            </div>
                        </ActionWithConfirmation>
                        {row.original.lastQuestionnaire &&
                            row.original.lastQuestionnaire?.status !==
                                "CLOSED" && (
                                <>
                                    <ActionDropdownMenuItem
                                        Icon={EyeOff}
                                        text={t("close_questionnaire_button")}
                                        onClick={() => {
                                            updateQuestionnaire({
                                                status: SupplierQuestionnaireStatus.Closed,
                                            });
                                        }}
                                    />
                                    <SupplierReminder
                                        questionnaires={[
                                            row.original.lastQuestionnaire,
                                        ]}
                                        suppliers={[row.original]}
                                        Trigger={({ onClick }) => (
                                            <ActionDropdownMenuItem
                                                onClick={onClick}
                                                Icon={Bell}
                                                text={t("send_reminder_button")}
                                            />
                                        )}
                                    />
                                </>
                            )}
                        {row.original.lastQuestionnaire &&
                            row.original.lastQuestionnaire?.status ===
                                "CLOSED" && (
                                <ActionDropdownMenuItem
                                    Icon={EyeOff}
                                    text={t("reopen_last_questionnaire_button")}
                                    onClick={() => {
                                        updateQuestionnaire({
                                            status: SupplierQuestionnaireStatus.InProgress,
                                        });
                                    }}
                                />
                            )}
                        {!row.original.lastQuestionnaire ||
                            (row.original.lastQuestionnaire?.status ===
                                "CLOSED" && (
                                <ActionDropdownMenuItem
                                    Icon={EyeOff}
                                    text={t("start_questionnaire_button")}
                                    onClick={() => {
                                        const state: RowSelectionState = {};
                                        state[row.index] = true;
                                        table.setRowSelection(() => state);
                                        setActiveAction(
                                            Actions.START_QUESTIONNAIRES,
                                        );
                                    }}
                                />
                            ))}
                    </ActionDropdown>
                </div>
            );
        },
        meta: {
            headerClassName: "w-[100px]",
        },
    },
];

// contribute/supplier/:questionnaire_id
export default columns;
