"use client"

import { type FC } from "react";
import { DayPicker, type DayPickerProps } from "react-day-picker";
import { fr } from "react-day-picker/locale";
import { format } from "date-fns";

import { cn } from "@design-system/Utilities"
import { buttonVariants } from "@design-system/Inputs/Button"

const currentYear = new Date().getFullYear();

export type CalendarProps = DayPickerProps;

const Calendar: FC<CalendarProps> = ({
    className,
    classNames,
    showOutsideDays = false,
    locale = fr,
    ...props
  }) => {
    return (
      <DayPicker
        showOutsideDays={showOutsideDays}
        captionLayout="dropdown-years"
        startMonth={new Date(currentYear-2, 0)}
        endMonth={new Date(currentYear+10, 11)}
        locale={locale}
        weekStartsOn={1}
        formatters={{
            formatCaption: (date, options) => 
                format(date, "LLLL yyyy", options).charAt(0).toUpperCase() + format(date, "LLLL yyyy", options).slice(1),
            formatWeekdayName: (weekday, options) =>
                format(weekday, "ccc", options).replace(".", "").charAt(0).toUpperCase() + format(weekday, "ccc", options).slice(1, 3)
            }}
        className={cn("p-4 bg-white", className)}
        classNames={{
          months: "flex flex-col items-center space-y-2",
          nav: "flex items-bottom relative",
          button_previous: cn(
            buttonVariants({ variant: "outlined", size:"xs" }),
            "[&>svg]:h-[28px] [&>svg]:w-[28px] [&>svg]:p-1.5 p-0 bg-transparent opacity-50 hover:opacity-100 absolute right-20 top-1.5"
          ),
          button_next: cn(
            buttonVariants({ variant: "outlined", size:"xs" }),
            "[&>svg]:h-[28px] [&>svg]:w-[28px] [&>svg]:p-1.5 p-0 bg-transparent opacity-50 hover:opacity-100 absolute absolute left-20 top-1.5"
          ),
          month_caption: "flex items-center justify-center w-full ml-2",
          dropdowns: "text-lg text-primary font-bold flex items-center",
          years_dropdown: "border-0 py-0 pr-7 pl-1",
          caption_label: "text-lg text-primary font-bold hidden",
          month_grid: "w-full border-collapse space-y-1 ",
          weekdays: "flex mt-[14px]",
          weekday: " rounded-md w-7 text-primary text-sm",
          week: "flex w-full mt-2",
          day: cn(
            buttonVariants({ variant: "plain", size: "sm"}),
            "w-7 p-0 hover:bg-tertiary"
          ),
          day_button: "text-secondary text-sm",
          selected: "[&>button]:text-white bg-beavrGreen data-[selected=true]:hover:bg-beavrGreen",
          hidden: "invisible",
          ...classNames,
        }}
        {...props}
      />
    )
  }
Calendar.displayName = "Calendar"

export { Calendar }

