import { FlexCol } from "@design-system/Layout/Flex";
import { FC, useRef } from "react";
import { useCsrdPillar } from "../../data";
import Esrs from "../Esrs";
import { SectionSkeleton } from "@app/screens/Frameworks/framework/components/Section";
import { filterAtom } from "../../ctx";
import { useAtomValue } from "jotai";
import { Box } from "@design-system/Layout/Box";

export const PillarBody: FC<{
    pillarId?: string;
}> = ({ pillarId }) => {
    const filter = useAtomValue(filterAtom);
    const { pillar, isPending } = useCsrdPillar(pillarId, filter);
    const pillarBodyContainerRef = useRef<HTMLDivElement>(null);
    return pillar && !isPending ? (
        <FlexCol
            w="full"
            h="full"
            className="min-w-0 overflow-auto"
            gap="4"
            ref={pillarBodyContainerRef}
        >
            {pillar.esrss.map((esrs) => (
                <Box key={esrs.cmsId}>
                    <Esrs
                        cmsId={esrs.cmsId}
                        pillarId={pillarId}
                        pillarBodyContainerRef={pillarBodyContainerRef}
                    />
                </Box>
            ))}
        </FlexCol>
    ) : (
        <FlexCol gap="4" w="full" p="4">
            {Array.from({ length: 2 }).map((_, i) => (
                <SectionSkeleton key={i} />
            ))}
        </FlexCol>
    );
};
