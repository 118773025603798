import { Icon, type IconProps } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import {
    Fragment,
    forwardRef,
    type FC,
    type HTMLAttributes,
    type PropsWithChildren,
} from "react";
import "./index.css";

const Trigger = RadixDropdown.Trigger;

const Content = forwardRef<
    HTMLDivElement,
    PropsWithChildren<
        RadixDropdown.DropdownMenuContentProps & { hasPortal?: boolean }
    >
>(({ children, className, ...props }, ref) => {
    const Wrapper = props.hasPortal ? RadixDropdown.Portal : Fragment;
    return (
        <Wrapper>
            <RadixDropdown.Content {...props} ref={ref}>
                <FlexCol
                    elevation="b-300"
                    className={cn(
                        "__DropdownMenu__content",
                        "bg-white",
                        className,
                    )}
                    p="1"
                >
                    {children}
                </FlexCol>
            </RadixDropdown.Content>
        </Wrapper>
    );
});

const Item = forwardRef<
    HTMLDivElement,
    PropsWithChildren<RadixDropdown.DropdownMenuItemProps>
>(({ children, className, ...props }, ref) => {
    return (
        <RadixDropdown.Item
            {...props}
            className={cn(
                "outline-none w-full cursor-pointer hover:bg-tertiary hover:text-brand data-[highlighted]:bg-tertiary data-[highlighted]:text-brand",
                props.disabled &&
                    "text-disabled hover:bg-white hover:text-disabled",
                className,
            )}
            ref={ref}
        >
            <FlexRow gap="3" p="2" w="full">
                {children}
            </FlexRow>
        </RadixDropdown.Item>
    );
});

const ItemIcon: FC<IconProps> = (props) => {
    return (
        <Box p="0.5">
            <Icon {...props} />
        </Box>
    );
};

const Separator: FC<HTMLAttributes<HTMLHRElement>> = ({
    className,
    ...props
}) => {
    return (
        <RadixDropdown.Separator asChild>
            <hr
                className={cn("mx-2 my-1 border-tertiary", className)}
                {...props}
            />
        </RadixDropdown.Separator>
    );
};

type ArrowProps = React.ComponentProps<typeof RadixDropdown.Arrow> & {
    className?: string;
};

const Arrow = forwardRef<SVGSVGElement, ArrowProps>(
    ({ className, ...props }, ref) => {
        return (
            <RadixDropdown.Arrow
                ref={ref}
                width={16}
                height={6}
                className={cn("fill-white", className)}
                {...props}
            />
        );
    },
);

type DropdownMenuComponentType = typeof RadixDropdown.Root & {
    Trigger: typeof Trigger;
    Content: typeof Content;
    Item: typeof Item;
    ItemIcon: typeof ItemIcon;
    Separator: typeof Separator;
    Arrow: typeof Arrow;
};

export const DropdownMenu: DropdownMenuComponentType = (props) => {
    return <RadixDropdown.Root {...props} />;
};

DropdownMenu.Trigger = Trigger;
DropdownMenu.Content = Content;
DropdownMenu.Item = Item;
DropdownMenu.ItemIcon = ItemIcon;
DropdownMenu.Separator = Separator;
DropdownMenu.Arrow = Arrow;
