import { type ColumnDef } from "@tanstack/react-table";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentExpirationStatus,
    DocumentType,
    GetDocumentsV2Document,
    TagType,
    UpdateDocumentV2Document,
} from "../../../../../generated/client/graphql";
import UserSelect from "../../../../components/Dropdown/dropdowns/User";

import { Variant } from "../../../../components/Tags/StatusTag";
import Status from "../../../../components/Tags/StatusTag/Status";

import { useQueryClient } from "@tanstack/react-query";
import { Rocket } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { filterUserIdsAtom } from "@app/components/Filters/MultiFilter/AssignedTo";
import {
    previewTemplateModalOpenAtom,
    previewTemplateModalTemplatesAtom,
} from "@app/screens/Document/components/CreateVersionBlock/ctx";
import { ExtensionTag } from "@app/shared/components/ExtensionTag";
import SubthemeMultiSelect from "@app/shared/components/SubthemeMultiSelect";
import TagListEditor from "@app/shared/components/TagListEditor";
import { TagsMultiSelect } from "@app/shared/components/TagsMultiSelect";
import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import {
    compareUser,
    firstTagAlphabetical,
    nameNormalized,
} from "@app/shared/utils/tableSortingFns";
import {
    searchThemesFilterAtom,
    selectedSubthemesAtom,
    selectedTagsAtom,
    updateDocumentMutation,
} from "@app/store/documentStore";
import { getFileFormatCategory } from "@app/usecases/VersionUseCases/utils";
import { ColorTag } from "@design-system/DataDisplay/Tag/ColorTag";
import {
    Tooltip as DSTooltip,
    Tooltip,
    TooltipProvider,
} from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { useAtom, useSetAtom } from "jotai";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import Checkbox from "../../../../components/Checkbox";
import DocumentExpirationStatusFilter from "../../../../components/Dropdown/dropdowns/ExpirationStatus/filter";
import { cn } from "../../../../lib/utils";
import { useDocumentPageState } from "../../hooks/pageState";
import "./i18n";

const selectCol: ColumnDef<Document> = {
    id: "select",
    meta: {
        headerClassName: "w-[50px] min-w-[50px] max-w-[50px]",
        cellClassName: "w-[50px] min-w-[50px] max-w-[50px]",
        colWidth: "5%",
    },
    size: 50,
    header: ({ table }) => {
        const handleChechboxClick = (e: MouseEvent) => {
            e.stopPropagation();
            table.toggleAllRowsSelected(!table.getIsAllRowsSelected());
        };
        return (
            <Checkbox
                checked={table.getIsAllRowsSelected()}
                onClick={handleChechboxClick}
                aria-label="Select all"
            />
        );
    },
    cell: ({ row }) => {
        const handleChechboxClick = () => {
            row.toggleSelected();
        };
        return (
            <Checkbox
                checked={row.getIsSelected()}
                onClick={handleChechboxClick}
                aria-label="Select"
            />
        );
    },
};

const nameCol = (active: boolean): ColumnDef<Document> => {
    return {
        id: "name",
        accessorKey: "name",
        sortingFn: nameNormalized,
        meta: {
            colWidth: active ? "45%" : "80%",
        },
        header: () => {
            const { t } = useTranslation("Documents2Table");
            const { state, setState } = useDocumentPageState();
            const handleRocketClick = (e: MouseEvent) => {
                e.stopPropagation();
                setState({
                    tier1Filter: !state.tier1Filter,
                });
            };
            return (
                <Box className="flex items-center gap-2 text-sm font-bold">
                    {t("document")}
                    <Tooltip delayDuration={0}>
                        <Tooltip.Trigger asChild>
                            <Button variant="text" onClick={handleRocketClick}>
                                <Rocket
                                    className={cn(
                                        "w-4 h-4",
                                        state.tier1Filter && " text-green ",
                                    )}
                                />
                            </Button>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t("rocket_header_tooltip")}
                        </Tooltip.Content>
                    </Tooltip>
                </Box>
            );
        },
        cell: ({ row }) => {
            const { t } = useTranslation("Documents2Table");
            const fileExtension =
                row.original.lastVersion?.finalFile?.extension;
            return (
                <FlexCol className="group">
                    <Link
                        to={`${window.location.pathname}/${row.original.uniqueUrl}`}
                        style={{ color: "inherit", textDecoration: "none" }}
                        className="flex flex-col gap-1 items-start justify-center max-w-full overflow-hidden"
                    >
                        <div className="flex items-center gap-2 overflow-hidden max-w-full group-hover:underline">
                            <Ellipsis asChild hasTooltip>
                                <div className="text-sm font-bold text-primary">
                                    {row.original.name}
                                </div>
                            </Ellipsis>
                            {row.original.tier === 1 && (
                                <Tooltip delayDuration={0}>
                                    <Tooltip.Trigger asChild>
                                        <Rocket className="w-[14px] h-[14px]" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>
                                        {t("text_tier_1")}
                                    </Tooltip.Content>
                                </Tooltip>
                            )}
                            {fileExtension ? (
                                <ExtensionTag
                                    name={getFileFormatCategory(fileExtension)}
                                />
                            ) : null}
                        </div>
                        {row.original.type === DocumentType.MasterPolicy && (
                            <ColorTag
                                color="#03A365"
                                backgroundColor="#EEFFF2"
                                borderColor="#03A365"
                                size="xs"
                                text={t("master_policy")}
                                className="py-0 px-1"
                            />
                        )}
                        {row.original.type === DocumentType.Policy && (
                            <ColorTag
                                color={"#A0278C"}
                                size="xs"
                                text={t("policy")}
                                className="py-0 px-1"
                            />
                        )}
                    </Link>
                </FlexCol>
            );
        },
    };
};

const tagsCol = (documentType?: DocumentType): ColumnDef<Document> => ({
    id: "tags",
    accessorKey: "tags",
    meta: {
        colWidth: "12%",
    },
    filterFn: (row, _, filterValue) => {
        const tagIds = row.original.tags?.map((tag) => tag.id);
        return (
            filterValue.filter((elt: string) => tagIds.includes(elt)).length > 0
        );
    },
    sortingFn: firstTagAlphabetical,
    header: () => {
        const [selectedTagsIds, setSelectedTagsIds] = useAtom(selectedTagsAtom);

        // Reset selected tags when document type changes
        // because this values from an atom and changing
        // the document type will not trigger a re-render
        useEffect(() => {
            setSelectedTagsIds([]);
        }, [documentType]);
        return (
            <TagsMultiSelect
                tagsIds={selectedTagsIds}
                setTagsIds={setSelectedTagsIds}
                type={TagType.Document}
                isHeader
            />
        );
    },
    cell: ({ row }) => {
        const [{ mutate }] = useAtom(updateDocumentMutation);
        const [selectedTagIds, setSelectedTagsIds] = useState<string[]>(
            row.original.tags?.map((tag) => tag.id) || [],
        );
        const updateDocumentTags = (tagIds: string[]) => {
            setSelectedTagsIds(tagIds);
            mutate({
                ids: [row.original.id],
                patch: {
                    tagIds,
                },
            });
        };
        useEffect(() => {
            setSelectedTagsIds(row.original.tags.map((tag) => tag.id));
        }, [row.original.tags]);

        return (
            <Box p="1">
                <TagListEditor
                    values={selectedTagIds}
                    onValuesChange={updateDocumentTags}
                    tagType={TagType.Document}
                />
            </Box>
        );
    },
});

const ownerCol = (documentType?: DocumentType): ColumnDef<Document> => {
    return {
        id: "owner",
        filterFn: (row, _, filterValue) => {
            if (filterValue.length === 0) {
                return true;
            }
            return (
                filterValue.filter((elt: string) => {
                    if (elt === null) {
                        return !row.original.owner;
                    } else {
                        return row.original.owner?.id === elt;
                    }
                }).length > 0
            );
        },
        accessorKey: "owner.id",
        meta: {
            colWidth: "20%",
        },
        sortingFn: (rowA, rowB) =>
            compareUser(rowA.original.owner, rowB.original.owner),
        header: () => {
            const [selectedUserIds, setSelectedUserIds] =
                useAtom(filterUserIdsAtom);
            const [searchFilter, setSearchFilter] = useState<string>();
            return (
                <UserMultiSelect
                    values={selectedUserIds}
                    onValuesChange={setSelectedUserIds}
                    searchFilter={searchFilter}
                    onSearchFilterChange={setSearchFilter}
                    includeNone={true}
                    isHeader
                />
            );
        },
        cell: ({ row }) => {
            const updateDocumentMutation = useGraphQLMutation(
                UpdateDocumentV2Document,
            );
            const client = useQueryClient();
            return (
                <div className="flex space-x-2 items-center">
                    <UserSelect
                        onChange={(id) => {
                            updateDocumentMutation.mutate(
                                {
                                    documentId: row.original.id,
                                    patch: { assignedToId: id || null },
                                },
                                {
                                    onSuccess: () =>
                                        client.invalidateQueries({
                                            queryKey: useGraphQL.getKey(
                                                GetDocumentsV2Document,
                                                { type: documentType },
                                            ),
                                        }),
                                },
                            );
                        }}
                        selectedUserId={row.original.owner?.id}
                    />
                </div>
            );
        },
    };
};

const subThemeCol: ColumnDef<Document> = {
    id: "subtheme",
    accessorKey: "subtheme.id",
    filterFn: "arrIncludesSome",
    meta: {
        colWidth: "30%",
    },
    header: () => {
        const [selectedSubthemesIds, setSelectedSubthemesIds] = useAtom(
            selectedSubthemesAtom,
        );
        const [searchFilter, setSearchFilter] = useAtom(searchThemesFilterAtom);
        return (
            <SubthemeMultiSelect
                hasPortal
                isModal={false}
                values={selectedSubthemesIds}
                onValuesChange={setSelectedSubthemesIds}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
                isHeader
            />
        );
    },
    cell: ({ row }) => {
        return (
            <ColorTag
                color={row.original.subtheme?.theme?.color || "green"}
                text={row.original.subtheme?.name || ""}
            />
        );
    },
};

const actionCol: ColumnDef<Document> = {
    id: "actions",
    meta: {
        colWidth: "15%",
    },
    cell: ({ row }) => {
        const { t } = useTranslation("Documents2Table");
        const navigate = useNavigate();
        const handleStartButtonClick = () => {
            navigate(`${row.original.uniqueUrl}`);
        };
        const handleEditButtonClick = () => {
            navigate(`${row.original.uniqueUrl}/edit`);
        };
        const handleDownloadButtonClick = () => {
            const signedUrl = row.original.lastVersion?.finalFile?.signedUrl;
            signedUrl && window.open(signedUrl, "_blank")?.focus();
        };

        const hasTemplate = !!row.original?.templateLocales?.length;
        const SetOpen = useSetAtom(previewTemplateModalOpenAtom);
        const setTemplates = useSetAtom(previewTemplateModalTemplatesAtom);
        const handlePreviewTemplateOpenClick = () => {
            SetOpen(true);
            setTemplates(row.original.templates ?? undefined);
        };
        return (
            <div className="flex space-x-2 items-center justify-end">
                {row.original.lastVersion === null ? (
                    <FlexRow gap="3" alignItems="center">
                        {hasTemplate && (
                            <TooltipProvider>
                                <DSTooltip delayDuration={0}>
                                    <DSTooltip.Trigger>
                                        <FlexCol
                                            onClick={
                                                handlePreviewTemplateOpenClick
                                            }
                                        >
                                            <Icon name="eyeOn"></Icon>
                                        </FlexCol>
                                    </DSTooltip.Trigger>
                                    <DSTooltip.Content>
                                        {t("preview_template")}
                                    </DSTooltip.Content>
                                </DSTooltip>
                            </TooltipProvider>
                        )}
                        <Button size="sm" onClick={handleStartButtonClick}>
                            {t("start")}
                        </Button>
                    </FlexRow>
                ) : row.original.lastVersion?.expirationStatus ===
                  DocumentExpirationStatus.Draft ? (
                    <Button
                        size="sm"
                        variant="tonal"
                        onClick={handleEditButtonClick}
                    >
                        <Icon name="pencil" size="sm" />
                    </Button>
                ) : row.original.lastVersion?.finalFile?.signedUrl ? (
                    <Button
                        size="sm"
                        variant="tonal"
                        onClick={handleDownloadButtonClick}
                    >
                        <Icon name="download" size="sm" />
                    </Button>
                ) : (
                    <div>{row.original.lastVersion?.finalFile?.signedUrl}</div>
                )}
            </div>
        );
    },
};

const statusCol: ColumnDef<Document> = {
    id: "status",
    filterFn: "arrIncludesSome",
    accessorKey: "status",
    meta: {
        colWidth: "15%",
    },
    header: () => {
        const { state, setState } = useDocumentPageState();
        return (
            <DocumentExpirationStatusFilter
                status={state.statusFilter}
                setStatus={(s) => setState({ statusFilter: s })}
                isHeader
            />
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation("Documents2Table");

        return row.original.lastVersion?.expirationStatus ===
            DocumentExpirationStatus.Ok ? (
            <Status variant={Variant.Done}>{t("done")}</Status>
        ) : row.original.lastVersion?.expirationStatus ===
          DocumentExpirationStatus.Draft ? (
            <Status variant={Variant.InProgress}>{t("draft")}</Status>
        ) : row.original.lastVersion?.expirationStatus ===
          DocumentExpirationStatus.ReadyForApproval ? (
            <Status variant={Variant.ArrowDown}>
                {t("ready_for_approval")}
            </Status>
        ) : row.original.lastVersion?.expirationStatus ===
          DocumentExpirationStatus.PendingBeavrReview ? (
            <Status variant={Variant.PendingReview}>
                {t("pending_bv_review")}
            </Status>
        ) : row.original.lastVersion?.expirationStatus ===
          DocumentExpirationStatus.Rejected ? (
            <Status variant={Variant.Nothing}>{t("rejected")}</Status>
        ) : !row.original.lastVersion ? (
            <div className="text-sm text-tertiary">{t("not_started")}</div>
        ) : (
            <>{JSON.stringify(row.original.lastVersion.status)}</>
        );
    },
};

export const useColumns = (
    type: DocumentType | undefined,
): ColumnDef<Document>[] => {
    const columns = useMemo(() => {
        return [
            selectCol,
            nameCol(true),
            {
                id: "tier",
                filterFn: "equals",
                accessorKey: "tier",
            },
            {
                id: "subtheme",
                filterFn: "arrIncludesSome",
                accessorKey: "subtheme.id",
            },
            {
                id: "theme",
                filterFn: "equals",
                accessorKey: "subtheme.themeId",
            },
            tagsCol(type),
            ownerCol(type),
            statusCol,
            actionCol,
        ] as ColumnDef<Document>[];
    }, [type]);

    return columns;
};

export const useUnrelevantColumns = (
    type?: DocumentType,
): ColumnDef<Document>[] => {
    return [
        selectCol,
        nameCol(false),
        {
            id: "tier",
            filterFn: "equals",
            accessorKey: "tier",
        },
        ...(type === DocumentType.CustomDocument ? [] : [subThemeCol]),
    ] as ColumnDef<Document>[];
};

export const useContributorColumns = (type: DocumentType | undefined) => {
    const columns = useMemo(() => {
        return [
            nameCol(true),
            {
                id: "tier",
                filterFn: "equals",
                accessorKey: "tier",
            },
            {
                id: "subtheme",
                filterFn: "arrIncludesSome",
                accessorKey: "subtheme.id",
            },
            {
                id: "theme",
                filterFn: "equals",
                accessorKey: "subtheme.themeId",
            },
            statusCol,
            actionCol,
        ] as ColumnDef<Document>[];
    }, [type]);
    return columns;
};
