import { DropdownTrigger } from "@app/components/Dropdown/components";
import DropdownItemSearch from "@app/components/Dropdown/components/dropdowns/items/Search";
import DropdownContentLayout from "@app/components/Dropdown/components/dropdowns/layout";
import { Variant } from "@app/components/Tags/StatusTag";
import TinyStatusTag from "@app/components/Tags/StatusTag/Tiny";
import { tagsAtomFamily } from "@app/store/tagStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { TagType } from "@generated/client/graphql";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import NewTagDropdownItem from "./NewTagDropdownItem";
import TagDropdownItem from "./TagDropdownItem";

const TagListEditor: FC<{
    values: string[] | undefined;
    onValuesChange: (val: string[]) => void;
    tagType: TagType;
}> = ({ values, onValuesChange, tagType }) => {
    const { data, refetch } = useAtomValue(tagsAtomFamily(tagType));

    const [open, setOpen] = useState(false);
    const [searchFilterString, setSearchFilterString] = useState("");

    const selectedTags = (values || []).map((tagId) =>
        data?.tags?.find((tag) => tag.id === tagId),
    );

    const filteredTags = (data?.tags || [])?.filter(
        ({ name }) =>
            !searchFilterString ||
            (name &&
                name.toLowerCase().includes(searchFilterString.toLowerCase())),
    );
    const onToggleTag = (id: string) => {
        if (values?.includes(id)) {
            const newValues = [...values];
            newValues.splice(values.indexOf(id), 1);
            onValuesChange(newValues);
        } else {
            onValuesChange([...(values || []), id]);
        }
    };

    const onSaveNewTagSettled = (name: string) => {
        refetch().then((data) => {
            const newTag = data?.data?.tags?.find((tag) => tag.name === name);
            if (newTag) {
                onToggleTag(newTag.id);
            }
        });
    };

    return (
        <div>
            <DropdownMenu open={open} onOpenChange={setOpen}>
                <DropdownTrigger asChild>
                    <FlexRow wrap="wrap" className="-m-0.5">
                        {(selectedTags || []).map((tag) => (
                            <Box p="0.5" key={tag?.id}>
                                <TinyStatusTag variant={Variant.Base}>
                                    {tag?.name}
                                </TinyStatusTag>
                            </Box>
                        ))}
                        <Box p="0.5">
                            <Button variant="tonal" size="xs" className="h-6">
                                <Icon name="plus" size="xs" />
                            </Button>
                        </Box>
                    </FlexRow>
                </DropdownTrigger>
                <DropdownContentLayout
                    align="start"
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <DropdownItemSearch
                        searchFilterString={searchFilterString}
                        setSearchFilterString={setSearchFilterString}
                    />
                    <NewTagDropdownItem
                        type={tagType}
                        onSaveNewTagSettled={onSaveNewTagSettled}
                    />

                    {filteredTags.map((tag) => (
                        <TagDropdownItem
                            key={tag.id}
                            tagId={tag.id}
                            tagName={tag.name}
                            checked={values?.includes(tag.id)}
                            onToggle={onToggleTag}
                        />
                    ))}
                </DropdownContentLayout>
            </DropdownMenu>
        </div>
    );
};

export default TagListEditor;
