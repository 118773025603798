import {
    Table,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useColumnPinning,
} from "@design-system/Table";
import { Indicator, User } from "generated/client/graphql";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState, type FC } from "react";
import {
    selectedActiveFrameworkIdsAtom,
    selectedSubthemesAtom,
    selectedTagsAtom,
    selectedUsersAtom,
} from "../../context";
import { useActiveReportingIndicators } from "../../data";
import ReportingTableActionBar from "./actionBar";
import { pinnedColumnsIds, useColumns } from "./columns";
import SendReminderModal from "./sendReminderModal";
import { StringParam, useQueryParam } from "use-query-params";

const IndicatorsTable: FC = () => {
    const columns = useColumns();
    const { data, isPending } = useActiveReportingIndicators();
    const [selectedUserIds] = useAtom(selectedUsersAtom);
    const [selectedSubthemesIds] = useAtom(selectedSubthemesAtom);
    const [selecteTagIds] = useAtom(selectedTagsAtom);
    const [selectedFrameworkIds] = useAtom(selectedActiveFrameworkIdsAtom);
    const [urlSearchString] = useQueryParam("search", StringParam);
    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const { columnPinning } = useColumnPinning({
        initialColumnPinningState: {
            left: pinnedColumnsIds,
        },
    });

    const columnFilters = useMemo(() => {
        const filters = [];

        if (selectedSubthemesIds.length) {
            filters.push({ id: "subtheme", value: selectedSubthemesIds });
        }

        if (selectedFrameworkIds.length) {
            filters.push({ id: "frameworks", value: selectedFrameworkIds });
        }

        if (selectedUserIds.length) {
            filters.push({ id: "assignedTo", value: selectedUserIds });
        }

        if (selecteTagIds.length) {
            filters.push({ id: "tags", value: selecteTagIds });
        }

        return filters;
    }, [
        selectedUserIds,
        selectedSubthemesIds,
        selecteTagIds,
        selectedFrameworkIds,
    ]);

    const memoData = useMemo(
        () =>
            (data?.sort((i1, i2) => i1?.order - i2?.order) ||
                []) as Indicator[],
        [data],
    );

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            columnFilters,
            pagination,
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 });
    }, [
        selectedSubthemesIds,
        selectedFrameworkIds,
        selectedUserIds,
        selecteTagIds,
        setPagination,
        urlSearchString,
    ]);

    const selectedIndicators = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return memoData[Number(idx)];
        });

    const selectedUsers = selectedIndicators
        .map((elt) => elt?.entityConfig?.assignedTo)
        .filter((elt) => elt?.id) as User[];
    const uniqueSelectedUsers = [
        ...new Map(selectedUsers.map((item) => [item.id, item])).values(),
    ];

    const unset = () => setRowSelection({});

    return (
        <>
            <Table
                columnPinning={columnPinning}
                columns={columns}
                data={memoData}
                loading={isPending}
                options={tableOptions}
                variant="grid"
            />
            <ReportingTableActionBar
                indicators={selectedIndicators || []}
                unset={unset}
            />
            <SendReminderModal users={uniqueSelectedUsers}></SendReminderModal>
        </>
    );
};

export default IndicatorsTable;
