import { Box } from "@design-system/Layout/Box";
import { ClassValue, cn } from "@design-system/Utilities";
import {
    forwardRef,
    type HTMLAttributes,
    type TdHTMLAttributes,
    type ThHTMLAttributes,
} from "react";

export type Variant = "regular" | "compact" | "grid";
export const Table = forwardRef<
    HTMLTableElement,
    HTMLAttributes<HTMLTableElement> & {
        containerClassName?: ClassValue;
        containerRef?: React.RefObject<HTMLDivElement>;
    }
>(({ className, containerClassName, containerRef, ...props }, ref) => (
    <Box
        className={cn(
            "border border-tertiary",
            "rounded-lg",
            "overflow-y-hidden overflow-x-auto",
            containerClassName,
        )}
        br="lg"
        w="full"
        // This is necessary because we may wanna interact with the container e.g. for scroll reasons.
        ref={containerRef}
    >
        <table
            ref={ref}
            className={cn(
                "htmltable",
                "w-full rounded-lg overflow-auto",
                "border-separate border-spacing-0",
                "text-sm",
                className,
            )}
            {...props}
        />
    </Box>
));

export const TableHeader = forwardRef<
    HTMLTableSectionElement,
    HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
    <thead ref={ref} className={className} {...props} />
));
export const TableBody = forwardRef<
    HTMLTableSectionElement,
    HTMLAttributes<HTMLTableSectionElement>
>((props, ref) => <tbody ref={ref} {...props} />);

export const TableRow = forwardRef<
    HTMLTableRowElement,
    HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
    <tr
        ref={ref}
        className={cn(
            "group/tablerow",
            "border-b border-tertiary last:border-b-0 transition-colors",
            className,
        )}
        {...props}
    />
));

export const TableHead = forwardRef<
    HTMLTableCellElement,
    ThHTMLAttributes<HTMLTableCellElement> & { variant?: Variant }
>(({ className, variant = "regular", ...props }, ref) => (
    <th
        ref={ref}
        className={cn(
            "border-b border-tertiary",
            variant === "grid" && "border-r last:border-r-0",
            "h-[40px] bg-secondary text-left text-sm align-middle [&:has([role=checkbox])]:pr-0",
            variant === "compact" ? "px-2 py-1.5" : "px-4",
            className,
        )}
        {...props}
    />
));

export const TableCell = forwardRef<
    HTMLTableCellElement,
    TdHTMLAttributes<HTMLTableCellElement> & { variant?: Variant }
>(({ className, variant = "regular", ...props }, ref) => (
    <td
        ref={ref}
        className={cn(
            "relative align-middle [&:has([role=checkbox])]:pr-0",
            "border-b border-tertiary group-last/tablerow:border-b-0",
            variant === "grid" && "border-r last:border-r-0",
            "bg-white group-hover/tablerow:bg-secondary data-[state=selected]:bg-secondary",
            variant === "compact"
                ? "px-2 py-1 h-5 text-sm"
                : "px-4 py-3 text-base",
            className,
        )}
        {...props}
    />
));
