import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
    useColumns,
    useContributorColumns,
    useUnrelevantColumns,
} from "./columns";

import {
    selectedSubthemesAtom,
    selectedTagsAtom,
} from "@app/store/documentStore";
import { currentUserRoleAtom } from "@app/store/userStore";
import { useAtom, useAtomValue } from "jotai";
import {
    Document,
    DocumentActivationStatus,
    DocumentType,
    GetDocumentsV2Document,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import LoadingTable from "../../../../components/Table/LoadingTable";
import { orderSort } from "../../../../lib/utils";
import {
    DocPageFilterState,
    useDocumentPageState,
} from "../../hooks/pageState";
import ActionBar from "./components/Savebar";
import {
    Table,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    SortingState,
    PaginationState,
} from "@design-system/Table";
import { filterUserIdsAtom } from "@app/components/Filters/MultiFilter/AssignedTo";

function DocumentsTableInner({
    type,
    documents,
    state,
    setState,
}: {
    type?: DocumentType;
    documents: Document[];
    state: DocPageFilterState;
    setState: (state: Partial<DocPageFilterState>) => void;
}) {
    const { isContributor } = useAtomValue(currentUserRoleAtom);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selecteTagIds] = useAtom(selectedTagsAtom);
    const selectedUsersIds = useAtomValue(filterUserIdsAtom);

    const columnFilters = useMemo(() => {
        const filters = [];

        if (state.subthemeIdsFilter?.length) {
            filters.push({ id: "subtheme", value: state.subthemeIdsFilter });
        }

        if (state.themeIdFilter) {
            filters.push({ id: "theme", value: state.themeIdFilter });
        }
        if (state.tier1Filter) {
            filters.push({ id: "tier", value: 1 });
        }
        if (state.statusFilter) {
            filters.push({ id: "status", value: state.statusFilter });
        }
        if (selecteTagIds.length) {
            filters.push({ id: "tags", value: selecteTagIds });
        }
        if (selectedUsersIds?.length) {
            filters.push({ id: "owner", value: selectedUsersIds });
        }
        return filters;
    }, [
        state.subthemeIdsFilter,
        state.themeIdFilter,
        state.tier1Filter,
        state.statusFilter,
        selectedUsersIds,
        selecteTagIds,
    ]);

    const columns = isContributor
        ? useContributorColumns(type)
        : useColumns(type);

    const selectedDocuments = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return documents[Number(idx)];
        });

    const setPagination: Dispatch<SetStateAction<PaginationState>> = (
        updater,
    ) => {
        const nextState =
            typeof updater === "function" ? updater(state.pagination) : updater;
        nextState && setState({ pagination: nextState });
    };

    useEffect(() => {
        setState({ pagination: { ...state.pagination, pageIndex: 0 } });
    }, [selecteTagIds, selectedUsersIds]);

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            columnFilters,
            pagination: state.pagination,
            sorting,
            globalFilter: state.searchFilter,
            columnVisibility: { subtheme: false, theme: false, tier: false },
        },
        autoResetPageIndex: false,
    };

    const unset = () => setRowSelection({});

    return (
        <>
            <Table columns={columns} data={documents} options={tableOptions} />
            <ActionBar documents={selectedDocuments} unset={unset} />
        </>
    );
}

export default function DocumentsTable({
    type,
    state,
    setState,
}: {
    type?: DocumentType;
    state: DocPageFilterState;
    setState: (state: Partial<DocPageFilterState>) => void;
}) {
    const { data, isLoading } = useGraphQL(
        GetDocumentsV2Document,
        { type, activationStatus: DocumentActivationStatus.Relevant },
        { enabled: type !== undefined },
    );
    return !data && isLoading ? (
        <LoadingTable />
    ) : data?.documents ? (
        <DocumentsTableInner
            type={type}
            documents={orderSort(data.documents || []) as Document[]}
            state={state}
            setState={setState}
        />
    ) : (
        <div>Error, refresh ...</div>
    );
}

export const useUnrelevantDocuments = (type?: DocumentType) => {
    const { data } = useGraphQL(
        GetDocumentsV2Document,
        { type, activationStatus: DocumentActivationStatus.NotRelevant },
        { enabled: type !== undefined },
    );

    return (data?.documents || []) as Document[];
};
export const useUnrelevantDocumentsTable = (
    documents: Document[],
    type: DocumentType,
) => {
    const { state, setState } = useDocumentPageState();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selecteSubthemesIds, setSelectedSubthemesIds] = useAtom(
        selectedSubthemesAtom,
    );
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 5, //default page size
    });

    const columnFilters = useMemo(() => {
        const filters = [];

        if (selecteSubthemesIds?.length) {
            filters.push({ id: "subtheme", value: selecteSubthemesIds });
        }
        if (state.tier1Filter) {
            filters.push({ id: "tier", value: 1 });
        }
        return filters;
    }, [state.tier1Filter, selecteSubthemesIds]);

    const setGlobalFilter = (unrelevantSearchFilter: string) =>
        setState({ unrelevantSearchFilter });

    const columns = useUnrelevantColumns(type);
    const data = documents;
    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        state: {
            rowSelection,
            columnFilters,
            pagination,
            sorting,
            globalFilter: state.unrelevantSearchFilter,
            columnVisibility: { tier: false },
        },
    };

    useEffect(() => {
        setSelectedSubthemesIds(selecteSubthemesIds);
    }, [selecteSubthemesIds]);
    return { columns, data, tableOptions };
};
