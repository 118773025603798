import DataTable from "@app/components/Table/DataTable";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { documentModalActiveDatapoint } from "../../ctx";
import { useGetEvidenceFiles, useGetSuggestedFiles } from "../../data";
import { getFilteredEvidenceFiles, getToAttachFileIds } from "../../services";
import { FileWithLinkedItems } from "../../types";
import suggestedFilesColumns, { evidenceFilesColumns } from "./columns";
import { RESOURCE_NAME, keys } from "./i18n";

export const LibraryTab = ({
    setFileIds,
}: {
    setFileIds: (fileIds: string[]) => void;
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const datapoint = useAtomValue(documentModalActiveDatapoint);
    const { evidenceFiles } = useGetEvidenceFiles();
    const { suggestedFiles } = useGetSuggestedFiles(datapoint);
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );

    const filteredEvidenceFiles = useMemo(() => {
        return getFilteredEvidenceFiles(
            suggestedFiles,
            evidenceFiles,
            datapoint?.evidenceFiles?.map((file) => file.id) ?? [],
            searchString,
        );
    }, [evidenceFiles, suggestedFiles, datapoint]);

    const [rowSelectionSuggested, setRowSelectionSuggested] = useState({});
    const suggestedFilesTable = useReactTable<FileWithLinkedItems>({
        data: suggestedFiles || [],
        columns: suggestedFilesColumns,
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelectionSuggested,
        state: {
            rowSelection: rowSelectionSuggested,
        },
        autoResetPageIndex: false,
    });

    const [rowSelectionAll, setRowSelectionAll] = useState({});
    const evidenceFilesTable = useReactTable<FileWithLinkedItems>({
        data: filteredEvidenceFiles || [],
        columns: evidenceFilesColumns,
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelectionAll,
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            rowSelection: rowSelectionAll,
        },
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 5,
            },
        },
        autoResetPageIndex: false,
    });

    useEffect(() => {
        setFileIds(getToAttachFileIds(suggestedFilesTable, evidenceFilesTable));
    }, [
        suggestedFilesTable.getSelectedRowModel(),
        evidenceFilesTable.getSelectedRowModel(),
    ]);

    return (
        <FlexCol gap="4" className="overflow-scroll" h="full">
            {!!suggestedFiles?.length && (
                <DataTable
                    table={suggestedFilesTable}
                    className="border-beavrGreen"
                    headerClassName="bg-brand_subtle"
                />
            )}
            <SearchBar
                searchString={searchString}
                setSearchString={setSearchString}
                placeholder={t(keys.search_placeholder)}
                className="w-[236px]"
            />
            <DataTable table={evidenceFilesTable} />
        </FlexCol>
    );
};
