import Checkbox from "@app/components/Checkbox";
import { usersAtom } from "@app/store/userStore";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Icon } from "@design-system/Icon";
import { AssignedAvatar } from "@design-system/Inputs/AssignButton";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { cn, getRandomBgColorForWhiteText } from "@design-system/Utilities";
import { useAtom } from "jotai";
import { FC, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

const UserMultiSelect: FC<
    PropsWithChildren<{
        isHeader?: boolean;
        values: (string | null)[] | undefined;
        onValuesChange: (val: (string | null)[]) => void;
        searchFilter?: string;
        onSearchFilterChange?: (val: string | undefined) => void;
        includeNone?: boolean;
    }>
> = ({
    isHeader,
    values,
    onValuesChange,
    searchFilter,
    onSearchFilterChange,
    includeNone,
}) => {
    const [{ data, isPending }] = useAtom(usersAtom);

    const { t } = useTranslation("UserMultiSelect");

    const usersMap = useMemo(() => {
        if (isPending || !data?.users) return {};
        return data?.users
            .filter(
                ({ firstName, lastName, email }) =>
                    !searchFilter ||
                    [firstName, lastName, email].some(
                        (s) =>
                            s &&
                            s
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase()),
                    ),
            )
            .reduce(
                (acc, { id, firstName, lastName, avatar }) => {
                    acc[id] = {
                        id,
                        label: `${firstName} ${lastName}`,
                        color: getRandomBgColorForWhiteText(),
                        imgUrl: avatar?.signedUrl,
                    };
                    return acc;
                },
                {} as Record<string, AssignedAvatar>,
            );
    }, [data]);

    const filteredUsers = useMemo(() => {
        return (
            data?.users.filter(
                ({ firstName, lastName, email }) =>
                    !searchFilter ||
                    [firstName, lastName, email].some(
                        (s) =>
                            s &&
                            s
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase()),
                    ),
            ) || []
        );
    }, [data, searchFilter]);

    const active = !!values?.length;

    return (
        <MultiSelect values={values || []} onValuesChange={onValuesChange}>
            <MultiSelect.Trigger>
                {isHeader ? (
                    <HeaderFilter active={active}>
                        {t("placeholder", {
                            context: active && "active",
                            count: values?.length,
                        })}
                    </HeaderFilter>
                ) : (
                    <Button active={active} variant="outlined" size="sm">
                        {t("placeholder")}
                        <Icon name="angleDown" />
                    </Button>
                )}
            </MultiSelect.Trigger>
            <MultiSelect.Content>
                {onSearchFilterChange && (
                    <MultiSelect.Search
                        searchString={searchFilter || ""}
                        setSearchString={onSearchFilterChange}
                        placeholder={t("searchbar_placeholder")}
                    ></MultiSelect.Search>
                )}
                {includeNone && (
                    <MultiSelect.Item value={null}>
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                px="1.5"
                                py="2"
                                gap="3"
                                br="lg"
                                className={cn(
                                    "flex-1 hover:bg-secondary hover:text-green",
                                )}
                            >
                                <Box alignItems="center">
                                    <Checkbox checked={isSelected} />
                                </Box>
                                <Text className={cn("group-hover:text-green")}>
                                    {t("no_user")}
                                </Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                )}
                {filteredUsers.map((user, idx) => (
                    <MultiSelect.Item key={idx} value={user.id}>
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                px="1.5"
                                py="2"
                                gap="3"
                                br="lg"
                                className={cn(
                                    "flex-1 hover:bg-secondary hover:text-green",
                                )}
                            >
                                <Box alignItems="center">
                                    <Checkbox checked={isSelected} />
                                </Box>
                                <FlexRow
                                    gap="2"
                                    justifyContent="between"
                                    w="full"
                                >
                                    <Text>{usersMap[user.id].label}</Text>
                                    <Avatar
                                        letter={user.firstName[0]}
                                        imageUrl={usersMap[user.id].imgUrl}
                                        hexColor={usersMap[user.id].color}
                                        variant="circle"
                                    />
                                </FlexRow>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
};

export default UserMultiSelect;
