import i18next from "../../../i18n";

i18next.addResources("en", "SubthemeMultiSelect", {
    placeholder: "Subtheme",
    placeholder_active: "Subtheme ({{count}})",
    searchbar_placeholder: "search",
});
i18next.addResources("fr", "SubthemeMultiSelect", {
    placeholder: "Sous-thème",
    placeholder_active: "Sous-thème ({{count}})",
    searchbar_placeholder: "chercher",
});
