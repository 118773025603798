import { useTranslation } from "react-i18next";
import {
    CreateTagsDocument,
    GetTagsDocument,
    TagType,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { onlyUnique } from "../../../../lib/onlyUnique";
import { IndicatorImport } from "./columns";
import "../i18n";

const LoadTags = ({ newIndicators }: { newIndicators?: IndicatorImport[] }) => {
    const { t } = useTranslation("LoadIndicators");
    const getTags = useGraphQL(GetTagsDocument, { type: TagType.Indicator });
    const createTag = useGraphQLMutation(CreateTagsDocument);

    const allTags = newIndicators
        ?.flatMap((indicator) => indicator.csv.tags?.split(","))
        .filter(onlyUnique)
        .map((t) => t?.trim())
        .filter((t) => t && t !== "");

    const existingTags = allTags?.filter(
        (tag) => getTags?.data?.tags.find((t) => t.name === tag) !== undefined,
    );
    const newTags = allTags?.filter(
        (tag) => getTags?.data?.tags.find((t) => t.name === tag) === undefined,
    );

    const handleCreateTags = () => {
        newTags &&
            createTag.mutate(
                {
                    inputs: newTags.map((t) => ({
                        name: t!,
                        type: TagType.Indicator,
                    })),
                },
                { onSuccess: () => getTags.refetch() },
            );
    };
    return newTags ? (
        <div>
            <div>
                <b>{t("existing_tags")} </b>
                {existingTags}
            </div>
            <div>
                <b>{t("new_tags")} </b>
                {newTags.sort().map((t) => (
                    <div>{t}</div>
                ))}
            </div>
            <ButtonRegular onClick={handleCreateTags}>
                {t("add_new_tags")}
            </ButtonRegular>
        </div>
    ) : (
        <></>
    );
};

export default LoadTags;
