import { Modal } from "@design-system/Modal";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import "../../i18n";

import { RESOURCE_NAME, keys } from "./i18n";

import { useSetToast } from "@app/components/Toast";
import {
    NewS3File,
    newS3FileToCreateFileInput,
} from "@app/usecases/FileUseCases";
import { FlexCol } from "@design-system/Layout/Flex";
import { Tabs } from "@design-system/Tabs";
import { useEffect, useState } from "react";
import { documentModalActiveDatapoint } from "../../ctx";
import { useUpdateDatapoint } from "../../data";
import { LibraryTab } from "./LibraryTab";
import { UploadTab } from "./UploadTab";

export const DocumentsModal = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [activeTab, setActiveTab] = useState<"library" | "upload">("library");
    const [datapoint, setDatapoint] = useAtom(documentModalActiveDatapoint);
    const { setToastError, setToastSuccess } = useSetToast();
    const { isPending, mutate } = useUpdateDatapoint(datapoint?.id || "");

    const onOpenChange = (open: boolean) => {
        if (!open) {
            setDatapoint(undefined);
        }
    };

    const onConfirm = async () => {
        if (!datapoint?.id) return;
        if (activeTab === "library") {
            mutate(
                {
                    id: datapoint?.id,
                    patch: { connectEvidenceFileIds: fileIds },
                },
                {
                    onSuccess: () => {
                        onOpenChange(false);
                        setToastSuccess(t(keys.success));
                    },
                    onError: () => {
                        setToastError(t(keys.error));
                    },
                },
            );
        }
        if (activeTab === "upload") {
            mutate(
                {
                    id: datapoint?.id,
                    patch: {
                        createAndConnectEvidenceFiles: newS3Files.map(
                            newS3FileToCreateFileInput,
                        ),
                    },
                },
                {
                    onSuccess: () => {
                        onOpenChange(false);
                        setToastSuccess(t(keys.success));
                    },
                    onError: () => {
                        setToastError(t(keys.error));
                    },
                },
            );
        }
    };
    const [fileIds, setFileIds] = useState<string[]>([]);
    const [newS3Files, setNewS3Files] = useState<NewS3File[]>([]);

    useEffect(() => {
        setFileIds([]);
        setNewS3Files([]);
    }, [datapoint?.id]);

    return (
        <Modal open={datapoint?.id !== undefined} onOpenChange={onOpenChange}>
            <Modal.Overlay className="py-5">
                <Modal.Content className="w-[860px]">
                    <Modal.Header className="pb-0">
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="max-h-[590px] pt-0">
                        <FlexCol gap="4" h="full">
                            <Tabs
                                activeTab={activeTab}
                                onActiveTabChange={setActiveTab}
                                className="flex-grow"
                                size="md"
                            >
                                <Tabs.Trigger
                                    value={"library"}
                                    label={t(keys.tab_library)}
                                />
                                <Tabs.Trigger
                                    value={"upload"}
                                    label={t(keys.tab_upload)}
                                />
                            </Tabs>
                            {activeTab === "library" ? (
                                <LibraryTab setFileIds={setFileIds} />
                            ) : (
                                <UploadTab
                                    newS3Files={newS3Files}
                                    setNewS3Files={setNewS3Files}
                                />
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isPending}
                            onClick={onConfirm}
                            preventClose
                            disabled={
                                activeTab === "library" && fileIds.length === 0
                            }
                        >
                            {t(keys.save)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
