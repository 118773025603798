import i18next from "i18next";

i18next.addResourceBundle("en", "SubthemeFilter", {
    placeholder: "Theme",
    placeholder_active: "Theme ({{count}})",
});

i18next.addResourceBundle("fr", "SubthemeFilter", {
    placeholder: "Theme",
    placeholder_active: "Theme ({{count}})",
});
