import { currentUserAtom } from "@app/screens/Global/TopBar/TopBarProfil";
import { dashboardPlanStepsAtom } from "@app/store/dashboardStore";
import { getExpiringOrOvedueStepsCount } from "@app/usecases/DashboardUseCases";
import { UserRole } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { agendaCards } from "../../utils";

export function usePlanSection() {
    const { t } = useTranslation("NewDashboard");

    const currentUser = useAtomValue(currentUserAtom);
    const lang: "en" | "fr" = currentUser?.locale === "fr" ? "fr" : "en";
    const isCS = currentUser?.permission?.role === UserRole.BeavrAdmin;

    const { data: planStepsData, isPending } = useAtomValue(
        dashboardPlanStepsAtom,
    );

    const expiringOrOverdueSteps = getExpiringOrOvedueStepsCount(planStepsData);
    const showFloatingBadge = !!expiringOrOverdueSteps;
    const floatingBadgeContent = expiringOrOverdueSteps.toString(10);

    return {
        agendaCards,
        isCS,
        isPending,
        lang,
        floatingBadgeContent,
        planStepsData,
        showFloatingBadge,
        t,
    };
}
