import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLDashboardRepository } from "@app/repositories/GraphQLRepositories/DashboardRepository";
import { currentUserAtom } from "@app/screens/Global/TopBar/TopBarProfil";
import {
    getDashboardPlanSteps,
    updateDashboardPlanStep,
    type Step,
} from "@app/usecases/DashboardUseCases";
import { UpsertDashboardPlanStepMutation } from "@generated/client/graphql";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { dashboardKeys } from "./queryKeys";

// init
const dashboardRepository = new GraphQLDashboardRepository();

// queries
export const dashboardPlanStepsAtom = atomWithQuery(() => {
    return {
        queryKey: [dashboardKeys.dashboardPlanSteps],
        queryFn: () => getDashboardPlanSteps({ dashboardRepository }),
        staleTime: Infinity,
    };
});

// mutations
export const updateDashboardPlanStepAtom = atomWithMutation<
    UpsertDashboardPlanStepMutation,
    Step,
    Error
>((get) => ({
    mutationKey: [dashboardKeys.updateDashboardPlanStep],
    mutationFn: (updatedPlanStep) => {
        const userRole = get(currentUserAtom)?.permission?.role;

        return updateDashboardPlanStep(dashboardRepository, updatedPlanStep, {
            userRole,
        });
    },
    onSettled: () => {
        queryClient.invalidateQueries({
            queryKey: [dashboardKeys.dashboardPlanSteps],
        });
    },
}));
