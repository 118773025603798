import { CreateFileInput, PutS3FileOutput } from "@generated/client/graphql";
import mime from "mime/lite";

export type NewS3File = CreateFileInput & { signedUrl: string };

export const enrichS3File = async (
    file: File,
    putS3FileOutput: PutS3FileOutput,
): Promise<NewS3File> => {
    const fileBuffer = await file.arrayBuffer();

    const crypto = window.crypto.subtle;
    const sha256 = await crypto.digest("SHA-256", new Uint8Array(fileBuffer));

    const decoder = new TextDecoder("utf-8");
    const checksum = decoder.decode(sha256);
    const mimeType =
        mime.getType(file.name.split(".").pop() || "") ||
        "application/octet-stream";

    return {
        bucket: putS3FileOutput.bucket,
        key: putS3FileOutput.key,
        signedUrl: putS3FileOutput.signedUrl,
        mimeType,
        fileSize: file.size,
        name: file.name,
        checksum,
    };
};

export const newS3FileToCreateFileInput = (
    newS3File: NewS3File,
): CreateFileInput => {
    return {
        bucket: newS3File.bucket,
        key: newS3File.key,
        mimeType: newS3File.mimeType,
        fileSize: newS3File.fileSize,
        name: newS3File.name,
        checksum: newS3File.checksum,
    };
};
