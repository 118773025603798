import Checkbox from "@app/components/Checkbox";
import { tagsAtomFamily } from "@app/store/tagStore";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { TagType } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import "../../../pages/Reporting/i18n";

export const TagsMultiSelect: FC<{
    isHeader?: boolean;
    setTagsIds: (tagsIds: (string | null)[]) => void;
    tagsIds: (string | null)[] | undefined;
    type: TagType;
    side?: "left" | "right" | "top" | "bottom";
}> = ({ isHeader, tagsIds, setTagsIds, type, side }) => {
    const { t } = useTranslation("ReportingPage");
    const { data } = useAtomValue(tagsAtomFamily(type));

    const active = !!tagsIds?.length;

    return (
        <MultiSelect values={tagsIds || []} onValuesChange={setTagsIds}>
            <MultiSelect.Trigger>
                {isHeader ? (
                    <HeaderFilter active={active}>
                        {t("indicator_table_tags_column_title", {
                            context: active && "active",
                            count: tagsIds?.length,
                        })}
                    </HeaderFilter>
                ) : (
                    <Button active={active} variant="outlined" size="sm">
                        {t("indicator_table_tags_column_title")}
                        <Button.Icon name={"angleDown"} />
                    </Button>
                )}
            </MultiSelect.Trigger>
            <MultiSelect.Content side={side}>
                {data?.tags.map((tag) => (
                    <MultiSelect.Item key={tag.id} value={tag.id}>
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                px="1.5"
                                py="2"
                                gap="3"
                                br="lg"
                                className="flex-1 hover:bg-secondary"
                            >
                                <FlexRow alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </FlexRow>
                                <Text variant="body-sm">{tag.name}</Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
};
