import i18next from "i18next";

i18next.addResourceBundle("en", "ReportingContributorsPage", {
    page_title: "Contributors",
    page_subtitle: "Monitor the advancement of all contributors",
    contributor_column_title: "Contributors",
    search_placeholder: "Search",
    progress_bar_no_data: "No data",
    success_copy: "Link copied to clipboard",
    send_reminder: "Send reminder",
    month_January: "January",
    month_February: "February",
    month_March: "March",
    month_April: "April",
    month_May: "May",
    month_June: "June",
    month_July: "July",
    month_August: "August",
    month_September: "September",
    month_October: "October",
    month_November: "November",
    month_December: "December",
});

i18next.addResourceBundle("fr", "ReportingContributorsPage", {
    page_title: "Contributeurs",
    page_subtitle: "Suivez l'avancement de tous les contributeurs",
    contributor_column_title: "Contributeurs",
    search_placeholder: "Rechercher",
    progress_bar_no_data: "Pas de donnée",
    success_copy: "Lien copié dans le presse-papier",
    send_reminder: "Envoyer un rappel",
    month_January: "Janvier",
    month_February: "Février",
    month_March: "Mars",
    month_April: "Avril",
    month_May: "Mai",
    month_June: "Juin",
    month_July: "Juillet",
    month_August: "Août",
    month_September: "Septembre",
    month_October: "Octobre",
    month_November: "Novembre",
    month_December: "Décembre",
});
