import {
    RegularTabs,
    useEditorContext,
} from "@app/screens/Document/Edit/hooks/useEditor";
import {
    approveAtom,
    editValidationTypeAtom,
    rejectDocumentVersionAtom,
    sendForReviewAtom,
    versionChecksRatioAtom,
} from "@app/store/versionStore";
import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexRow } from "@design-system/Layout/Flex";
import { useAtomValue, useSetAtom } from "jotai/react";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys } from "./i18n";

import {
    ValidationModal,
    validationModalAtom,
} from "@app/screens/Document/Edit/ctx";
import { currentUserRoleAtom } from "@app/store/userStore";
import { EditValidationType } from "@app/usecases/VersionUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { UserRole } from "@generated/client/graphql";
import { useUnsendReviewRequest } from "../../../screens/Document/Edit/data";
import "./i18n";

export const ValidationButton: FC = () => {
    const { t } = useTranslation("VersionValidation");
    const setModal = useSetAtom(validationModalAtom);
    const { setRegularModeActiveTab } = useEditorContext();
    const {
        data: { ratio },
        isPending: isRatioPending,
    } = useAtomValue(versionChecksRatioAtom);

    const validationType = useAtomValue(editValidationTypeAtom);

    const { role } = useAtomValue(currentUserRoleAtom);

    const { isPending: isRequestForApprovalPending } =
        useAtomValue(sendForReviewAtom);

    const { mutate: rejectMutation, isPending: isRejectRequestPending } =
        useAtomValue(rejectDocumentVersionAtom);
    const reject = () => rejectMutation();

    const { isPending: isUnsendReviewRequestPending } =
        useUnsendReviewRequest();

    const { mutate: approveMutation, isPending: isApproveMutationPending } =
        useAtomValue(approveAtom);
    const approve = () => approveMutation();

    const openChecklist = () => setRegularModeActiveTab(RegularTabs.CHECKS);

    const handleSendForReviewClick = () => {
        setModal(ValidationModal.sendForReview);
    };

    const handleUnsendReviewRequestClick = () => {
        setModal(ValidationModal.unsendReviewRequest);
    };

    const isLoading =
        isRatioPending ||
        isRequestForApprovalPending ||
        isRejectRequestPending ||
        isApproveMutationPending ||
        isUnsendReviewRequestPending;

    return validationType === EditValidationType.SendForReview ? (
        <FlexRow w="full" br="lg" className="border border-secondary">
            {ratio.num === 1 ? (
                <Button
                    size="sm"
                    loading={isLoading}
                    className="w-full rounded-none "
                    onClick={handleSendForReviewClick}
                >
                    {t(keys.send_for_review, {
                        count: ratio.total,
                        ratio: ratio.str,
                    })}
                </Button>
            ) : (
                <Tooltip delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <Button
                            className="border-none rounded-none w-full"
                            loading={isLoading}
                            onClick={openChecklist}
                            size="sm"
                            variant="plain"
                        >
                            {t(keys.send_for_review, {
                                count: ratio.total,
                                ratio: ratio.str,
                            })}
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        {t(keys.send_for_review_tooltip)}
                    </Tooltip.Content>
                </Tooltip>
            )}

            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <Button
                        className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                        loading={isLoading}
                        size="sm"
                        variant="text"
                    >
                        <Icon name="angleDown" />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end" side="bottom" sideOffset={10}>
                    <DropdownMenu.Item onSelect={approve}>
                        <DropdownMenu.ItemIcon
                            name="warning"
                            className="text-warning-500"
                        />
                        {t(keys.force_approve)}
                    </DropdownMenu.Item>
                    {role === UserRole.BeavrAdmin && (
                        <DropdownMenu.Item onSelect={reject}>
                            <DropdownMenu.ItemIcon
                                name="close"
                                className="text-danger"
                            />
                            {t(keys.reject)}
                        </DropdownMenu.Item>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu>
        </FlexRow>
    ) : validationType === EditValidationType.PendingCsReview ? (
        <FlexRow
            w="full"
            br="lg"
            className="overflow-hidden border border-secondary"
        >
            <Tooltip delayDuration={0}>
                <Tooltip.Trigger asChild>
                    <Box h="full" w="full">
                        <Button
                            className="border-none rounded-none w-full min-h-[30px]"
                            disabled
                            size="sm"
                            variant="plain"
                        >
                            {t(keys.under_review)}
                        </Button>
                    </Box>
                </Tooltip.Trigger>
                <Tooltip.Content className="w-[350px]">
                    {t(keys.under_review_tooltip)}
                </Tooltip.Content>
            </Tooltip>
            <Tooltip delayDuration={0}>
                <Tooltip.Trigger asChild>
                    <Button
                        className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                        loading={isLoading}
                        size="sm"
                        variant="text"
                        onClick={handleUnsendReviewRequestClick}
                    >
                        <Icon name="close" />
                    </Button>
                </Tooltip.Trigger>
                <Tooltip.Content>
                    {t(keys.cancel_review_request_tooltip)}
                </Tooltip.Content>
            </Tooltip>
        </FlexRow>
    ) : validationType === EditValidationType.Approved ? (
        <Button loading={isLoading} size="sm" className="w-full " disabled>
            <span>{t(keys.approved)}</span>
            <Icon name="doubleCheck" className="text-success-500" />
        </Button>
    ) : validationType === EditValidationType.CsReview ? (
        <FlexRow
            w="full"
            br="lg"
            className="overflow-hidden border border-secondary"
        >
            <Button
                className="border-none rounded-none w-full"
                disabled={ratio.num != 1}
                loading={isLoading}
                onClick={approve}
                size="sm"
                variant="plain"
            >
                {t(keys.approve)}
            </Button>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <Button
                        className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                        loading={isLoading}
                        size="sm"
                        variant="text"
                    >
                        <Icon name="angleDown" />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    hasPortal
                    align="end"
                    side="bottom"
                    sideOffset={10}
                >
                    {ratio.num < 1 && (
                        <DropdownMenu.Item onSelect={approve}>
                            <DropdownMenu.ItemIcon
                                name="cloudWithValidate"
                                className="text-warning-500"
                            />
                            {t(keys.force_approve)}
                        </DropdownMenu.Item>
                    )}
                    {role === UserRole.BeavrAdmin && (
                        <DropdownMenu.Item onSelect={reject}>
                            <DropdownMenu.ItemIcon
                                name="close"
                                className="text-danger"
                            />
                            {t(keys.reject)}
                        </DropdownMenu.Item>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu>
        </FlexRow>
    ) : validationType === EditValidationType.ApproveDirectly ? (
        <Button
            loading={isLoading}
            size="sm"
            className="w-full"
            onClick={approve}
        >
            {t(keys.approve)}
        </Button>
    ) : (
        <></>
    );
};
