import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ExpirationStatusDropdown", {
    placeholder: "Status",
    placeholder_active: "Status ({{count}})",
    NOT_STARTED: "Not started",
    DRAFT: "Draft",
    PENDING_BEAVR_REVIEW: "Pending review",
    REJECTED: "Needs improvement",
    EXPIRES_SOON: "Expires soon",
    OK: "Approved",
});

i18next.addResourceBundle("fr", "ExpirationStatusDropdown", {
    placeholder: "Statut",
    placeholder_active: "Statut ({{count}})",
    NOT_STARTED: "A faire",
    DRAFT: "Brouillon",
    PENDING_BEAVR_REVIEW: "En cours de révision",
    REJECTED: "Ameliorations nécessaires",
    EXPIRES_SOON: "Expire bientôt",
    OK: "Approuvé",
});
