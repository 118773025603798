import { AssistantStream } from "openai/lib/AssistantStream";

type AskAiInput = { prompt: string; threadId: string };
export class RestAiRepository {
    decoder = new TextDecoder();

    sendMessage = async (
        prompt: string,
        threadId: string,
    ): Promise<AssistantStream | undefined> => {
        const askAiInput: AskAiInput = { prompt, threadId };

        const response = await fetch(
            import.meta.env.VITE_REACT_APP_REST_URL + "ai/sendMessage",
            {
                method: "POST",
                body: JSON.stringify(askAiInput),
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        if (!response.body) return;
        return AssistantStream.fromReadableStream(response.body);
    };

    createThread = async (): Promise<string> => {
        const response = await fetch(
            import.meta.env.VITE_REACT_APP_REST_URL + "ai/createThread",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        const data = await response.json();
        return data.threadId;
    };
}
