import ProgressStepBar from "@design-system/DataDisplay/ProgressStepBar";

import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";

import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import { Clock } from "lucide-react";
import { type FC } from "react";
import { DashboardCard } from "../DashboardCard";
import { useConfigurationSection } from "./useConfigurationSection";

export const ConfigurationSection: FC = () => {
    const {
        cards,
        isOnboardingSectionCollapsed,
        handleLinkClick,
        showDiscoveryCards,
        user,
        t,
        completedSteps,
        totalSteps,
        percentage,
    } = useConfigurationSection();

    return (
        <DashboardCard
            as="section"
            className="flex flex-col px-0"
            elevation={200}
        >
            <header className="w-full px-7 flex justify-between">
                <div className="flex flex-col gap-2">
                    <h1 className="text-3xl">
                        {t("configuration_hi", {
                            name: user?.firstName ?? "Beavr",
                        })}
                    </h1>
                    <p className="text-base font-regular text-secondary">
                        {t("configuration_welcome")}
                    </p>
                </div>
                <div className="flex flex-col gap-2 w-[270px]">
                    <a
                        className="text-black cursor-pointer inline-flex gap-2 hover:no-underline"
                        onClick={handleLinkClick}
                    >
                        <p className="text-base font-bold">
                            {t("configuration_complete")}
                        </p>
                        <Icon
                            name="angleDown"
                            size="sm"
                            className="text-secondary"
                        />
                    </a>
                    <ProgressStepBar
                        total={totalSteps}
                        completed={completedSteps}
                    />
                    <p className="text-base font-regular text-secondary">
                        {t("configuration_almost_there", { percentage })}
                    </p>
                </div>
            </header>
            {showDiscoveryCards && (
                <div
                    className={cn(
                        "flex gap-4 justify-center mt-4",
                        isOnboardingSectionCollapsed
                            ? "max-h-0 overflow-hidden"
                            : "max-h-[1000px]",
                        "transition-[max-height] duration-300 ease-in-out",
                    )}
                >
                    <FlexRow
                        className="h-full overflow-x-scroll scroll scroll-smooth scrollbar-hide px-4"
                        gap="4"
                    >
                        {cards.map(
                            ({
                                bgClassname,
                                descriptionI18nKey,
                                handleCardClick,
                                handleLearnMoreClick,
                                id,
                                Illustration,
                                readingTimeI18nKey,
                                titleI18nKey,
                            }) => (
                                <DashboardCard
                                    as="article"
                                    className="flex flex-col gap-4 min-w-[256px] max-w-[270px]"
                                    key={id}
                                    onClick={handleCardClick}
                                >
                                    <div
                                        className={cn(
                                            "w-full h-[148px] rounded-xl grid place-content-center",
                                            bgClassname,
                                        )}
                                    >
                                        <Illustration />
                                    </div>
                                    <h3>{t(titleI18nKey)}</h3>
                                    <p className="text-base">
                                        {t(descriptionI18nKey)}
                                    </p>
                                    <footer className="flex justify-between mt-auto">
                                        <div className="flex items-center gap-1 font-bold text-secondary">
                                            <Clock size={16} />
                                            {t(readingTimeI18nKey)}
                                        </div>
                                        <ButtonSmall
                                            onClick={handleLearnMoreClick}
                                            variant={ButtonType.TONAL}
                                        >
                                            {t("learn_more")}
                                        </ButtonSmall>
                                    </footer>
                                </DashboardCard>
                            ),
                        )}
                    </FlexRow>
                </div>
            )}
        </DashboardCard>
    );
};
