import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, InfoEmpty, Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    GetSupplierIndicatorsDocument,
    Indicator,
    IndicatorType,
    TagType,
} from "../../../generated/client/graphql";

import { useEffect, useMemo, useRef, useState } from "react";
import { useGraphQL } from "../../QueryClientWithHeaders";
import { SubthemeFilter } from "../../components/Filters/MultiFilter/Subtheme";

import { useHover } from "@uidotdev/usehooks";
import { useSetAtom } from "jotai";
import ActionDropdown from "../../components/Table/ActionDropdown";
import ActionItemDanger from "../../components/Table/ActionItemDanger";
import CheckboxCell from "../../components/Table/CheckboxCell";
import Header from "../../components/Table/Header";
import CheckboxHeader from "../../components/Table/Header/Checkbox";
import ColorTag from "../../components/Tags/ColorTag";
import TagList from "../../components/Tags/TagList";
import { Tooltip } from "../../components/Tooltip";
import { ActionDropdownMenuItem } from "../ActionDropdown";
import DocumentModeFilter from "../Dropdown/dropdowns/DocumentMode/filter";
import DocumentModeSelect from "../Dropdown/dropdowns/DocumentMode/select";
import TagsFilter from "../Dropdown/dropdowns/Tags/filter";
import TagsSetter from "../Dropdown/dropdowns/Tags/setter";
import ThemesFilter from "../Dropdown/dropdowns/Themes/filter";
import { useQuestionnairesCol } from "./columns_suppliers";
import { updateIndicatorAtom } from "./components/Update";
import { useUpdateIndicator } from "./hooks";
import "./i18n";

const selectCol: ColumnDef<Indicator> = {
    id: "select",
    header: ({ table }) => {
        const { t } = useTranslation("IndicatorTable");
        return <CheckboxHeader table={table} title={t("idx")} />;
    },
    cell: ({ row }) => (
        <CheckboxCell row={row} text={`${row.original.order}`} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: {
        colWidth: "7%",
    },
};
const subthemeCol: ColumnDef<Indicator> = {
    id: "subtheme",
    filterFn: "arrIncludesSome",
    accessorKey: "subtheme.id",
    header: () => {
        return <SubthemeFilter />;
    },
    meta: {
        colWidth: "15%",
    },
    cell: ({ row }) => {
        return (
            <div className=" max-w-fit">
                <ColorTag
                    color="green"
                    text={row.original.subtheme?.name || ""}
                />
            </div>
        );
    },
};
const themeCol: ColumnDef<Indicator> = {
    id: "theme",
    filterFn: "arrIncludesSome",
    accessorKey: "theme.id",
    header: () => {
        return <ThemesFilter />;
    },
    meta: {
        colWidth: "15%",
    },
    cell: ({ row }) => {
        return (
            <div className=" max-w-fit">
                <ColorTag
                    color="green"
                    text={
                        row.original.subtheme?.name ||
                        row.original.theme?.name ||
                        ""
                    }
                />
            </div>
        );
    },
};
const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "40%",
    },
    header: ({ column }) => {
        const { t } = useTranslation("IndicatorTable");
        return <Header title={t("indicator")} column={column} />;
    },
    cell: ({ row }) => {
        const { t } = useTranslation("IndicatorTable");
        return (
            <div className="flex space-x-2 items-center justify-between">
                <div className="text-sm font-bold">{row.original.name}</div>

                <div>
                    <Tooltip
                        side="left"
                        sideOffset={10}
                        tooltipContent={
                            <div className="space-y-2  ">
                                <p className="text-white text-justify">
                                    <span className="font-bold">
                                        {t("unit")}:{" "}
                                    </span>
                                    {row.original.unit?.name}
                                </p>
                                {["SELECT", "MULTI_SELECT"].includes(
                                    row.original.unit?.type || "",
                                ) && (
                                    <div>
                                        <div className="text-sm text-white font-bold">
                                            Options:
                                        </div>
                                        {row.original.options?.map((o) => (
                                            <div className="text-sm text-white">
                                                {o.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {row.original.description && (
                                    <p className="text-white text-justify w-[300px]">
                                        <span className="font-bold">
                                            {t("description")}:{" "}
                                        </span>
                                        {row.original.description}
                                    </p>
                                )}
                            </div>
                        }
                    >
                        <InfoEmpty
                            className="h-4 w-4"
                            id={`desc-${row.original.id}`}
                        />
                    </Tooltip>
                </div>
            </div>
        );
    },
};

const useTagsCol = (getIndicatorsKey: any[]): ColumnDef<Indicator> =>
    useMemo(
        () => ({
            id: "tags",
            accessorFn: (row) => row.tags.map((t) => t.id),
            filterFn: "arrIncludesSome",
            meta: {
                headerClassName: "w-[20%]",
            },
            header: () => {
                return <TagsFilter type={TagType.Indicator} />;
            },
            cell: ({ row }) => {
                const { updateIndicator } = useUpdateIndicator(
                    row.original.id,
                    getIndicatorsKey,
                );
                const [divRef, hovering] = useHover<HTMLDivElement>();
                const setterRef = useRef<HTMLDivElement>(null);
                const [open, setOpen] = useState(false);

                useEffect(() => {
                    if (!hovering && !open) {
                        setterRef.current?.style.setProperty("display", "none");
                    } else {
                        setterRef.current?.style.setProperty(
                            "display",
                            "block",
                        );
                    }
                }, [hovering, open]);

                return (
                    <>
                        <div ref={divRef}>
                            {divRef?.current?.getAttributeNames()}
                            <TagList
                                tags={row.original.tags}
                                extra={
                                    <div ref={setterRef}>
                                        <TagsSetter
                                            type={TagType.Indicator}
                                            tagIds={row.original.tags.map(
                                                (t) => t.id,
                                            )}
                                            onTagIdsChange={(ids) =>
                                                updateIndicator({
                                                    tagIds: ids,
                                                })
                                            }
                                            open={open}
                                            setOpen={setOpen}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </>
                );
            },
        }),
        [getIndicatorsKey],
    );

const useDocumentAcceptanceCol = (
    getIndicatorsKey: any[],
): ColumnDef<Indicator> =>
    useMemo(
        () => ({
            accessorKey: "documentAcceptance",
            header: () => {
                return <DocumentModeFilter />;
            },
            cell: ({ row }) => {
                const { updateIndicator } = useUpdateIndicator(
                    row.original.id,
                    getIndicatorsKey,
                );

                return (
                    <DocumentModeSelect
                        mode={row.original.documentAcceptance || undefined}
                        onModeChange={(m) =>
                            updateIndicator({ documentAcceptance: m })
                        }
                    />
                );
            },
        }),
        [getIndicatorsKey],
    );
const actionCol: ColumnDef<Indicator> = {
    id: "actions",
    meta: {
        colWidth: "5%",
    },
    cell: ({ row }) => {
        const { t } = useTranslation("IndicatorTable");
        const setUpdateIndicator = useSetAtom(updateIndicatorAtom);
        return (
            <ActionDropdown>
                <ActionItemDanger icon={Trash} title={t("delete")} disabled />
                <ActionDropdownMenuItem
                    Icon={EditPencil}
                    text={t("edit")}
                    onClick={() =>
                        setUpdateIndicator(row.original as Indicator)
                    }
                />
            </ActionDropdown>
        );
    },
};

export const useColumns = (type: IndicatorType): ColumnDef<Indicator>[] => {
    const key = useGraphQL.getKey(GetSupplierIndicatorsDocument);
    const documentAcceptanceCol = useDocumentAcceptanceCol(key);
    const tagsCol = useTagsCol(key);
    const questionnaireCol = useQuestionnairesCol(key);
    return useMemo(() => {
        if (type === IndicatorType.Reporting) {
            return [
                selectCol,
                subthemeCol,
                nameCol,
                tagsCol,
                documentAcceptanceCol,
                actionCol,
            ];
        } else {
            return [
                selectCol,
                themeCol,
                nameCol,
                documentAcceptanceCol,
                questionnaireCol,
                actionCol,
            ];
        }
    }, [type]);
};
