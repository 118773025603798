import { Is } from "@app/lib/utils";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    Dispatch,
    HTMLAttributes,
    Ref,
    SetStateAction,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { InputContext } from ".";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { DataValueTable } from "@app/shared/components/DataValueTable";

export type ValueInputProps = (
    | {
          type: "string";
          value: string | undefined;
          onValueChange: (value: string) => void;
      }
    | {
          type: "number";
          value: number | undefined;
          onValueChange: (value: number | undefined) => void;
      }
    | {
          type: "table";
          value: { key: string; value: string }[] | undefined;
          onValueChange: Dispatch<
              SetStateAction<{ key: string; value: string }[] | undefined>
          >;
      }
) & {
    placeholder?: string;
    unit?: string;
    calculation?: string;
    datapointHasValue: boolean;
    datapointHasComment: boolean;
    datapointHasProofs: boolean;
    noDataStateText?: string;
    noData?: boolean;
    onBlur?: (
        value: string | number | { key: string; value: string }[] | undefined,
    ) => void;
} & Omit<HTMLAttributes<HTMLInputElement>, "onBlur" | "onValueChange">;

const noArrowClass =
    "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border-none outline-none focus:ring-0";

const ValueInput = forwardRef(
    (
        {
            type,
            value,
            onValueChange,
            onBlur,
            unit,
            className,
            ...props
        }: ValueInputProps,
        ref: Ref<HTMLInputElement | null>,
    ) => {
        const ctx = useContext(InputContext);
        if (!ctx) throw new Error("Context must not null");
        const inputRef = useRef<HTMLInputElement>(null);
        const valueInput = Is(value) ? value?.toString() : "";

        const [isTablePopoverOpen, setIsTablePopoverOpen] = useState(false);

        useImperativeHandle(ref, () => inputRef.current, []);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (type === "number") {
                const floatValue = parseFloat(e.target.value);
                onValueChange(isNaN(floatValue) ? undefined : floatValue);
            } else if (type === "string") {
                onValueChange(e.target.value);
            }
            const numChars = e.target.value.toString().length;
            inputRef.current?.style.setProperty("width", `${numChars + 1}ch`);
        };

        const handleBlur = () => {
            //@ts-ignore
            onBlur?.(value);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                inputRef.current?.blur();
            }
        };

        // Prevent scrolling on number input and set input width
        useEffect(() => {
            if (inputRef.current) {
                inputRef.current?.style.setProperty(
                    "width",
                    `${(valueInput?.toString().length || 0) + 1}ch`,
                );
                if (type === "number") {
                    inputRef.current.addEventListener(
                        "wheel",
                        (e) => {
                            e.preventDefault();
                        },
                        { passive: false },
                    );
                }
            }
        }, [ref, type]);

        const focusInput = () => {
            inputRef.current?.focus();
            inputRef.current?.style.setProperty("display", "block");
        };

        const displayBorder = ctx.focuswithin || ctx.popoverOpen;
        const hideInput = !!props.noData && !ctx.focuswithin;
        const displayUnit =
            !props.noData &&
            (ctx.focuswithin || value !== undefined) &&
            unit &&
            unit !== "table";
        const showNoDataHint =
            !ctx?.focuswithin &&
            !props.datapointHasValue &&
            (!value || (typeof value === "object" && value && !value.length));
        const handleTablePopoverOpenChange = (open: boolean) => {
            setIsTablePopoverOpen(open);
            if (!open) {
                onBlur?.(value);
            }
        };

        return (
            <Tooltip delayDuration={0} defaultOpen={true} open={true}>
                <Tooltip.Trigger className="h-full w-full">
                    <FlexRow
                        h="full"
                        w="full"
                        alignItems="center"
                        justifyContent={
                            props.datapointHasValue ? "start" : "between"
                        }
                        px="1"
                        className={cn(
                            "relative rounded border-[3px] rounded-br-none",
                            ctx.focuswithin && "bg-secondary",
                            displayBorder
                                ? "border-warning-600 "
                                : "border-transparent",
                            props.noData ? "cursor-default" : "cursor-text",
                            showNoDataHint &&
                                "group-hover/tablerow:bg-secondary",
                            className,
                        )}
                        onClick={focusInput}
                    >
                        {showNoDataHint && (
                            <Icon
                                name="pencil"
                                className="hidden group-hover/tablerow:block text-tertiary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                            />
                        )}
                        <Box
                            className={cn(
                                "px-0 py-1 text-base font-bold bg-transparent flex-grow flex-shrink min-w-0",
                                !hideInput && "hidden",
                                noArrowClass,
                            )}
                        >
                            <Text
                                color="secondary"
                                variant="body-sm"
                                align="start"
                            >
                                {props.noDataStateText}
                            </Text>
                        </Box>

                        {(type === "string" || type === "number") && (
                            <input
                                value={valueInput}
                                onChange={handleChange}
                                ref={inputRef}
                                type={type}
                                className={cn(
                                    "px-0 py-1 text-base font-bold bg-transparent caret-accent-1-300",
                                    hideInput && "hidden",
                                    noArrowClass,
                                )}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                {...props}
                            />
                        )}
                        {type === "table" && (
                            <Popover
                                open={isTablePopoverOpen}
                                onOpenChange={handleTablePopoverOpenChange}
                            >
                                <PopoverTrigger asChild>
                                    <div
                                        className={cn(
                                            "h-[70px] w-full overflow-clip relative",
                                            hideInput && "hidden",
                                        )}
                                    >
                                        {value?.map((pair, index) => (
                                            <div
                                                key={index}
                                                className="flex mb-1"
                                            >
                                                <span className="font-bold">
                                                    {pair.key}:{" "}
                                                </span>
                                                <span className="ml-0.5 text-secondary text-sm">
                                                    {pair.value}
                                                </span>
                                            </div>
                                        ))}
                                        {value && value.length > 3 && (
                                            <div className="absolute h-4 w-4 bottom-1 right-6 bg-beavrGreen text-white text-[11px] font-bold rounded-full flex items-center justify-center">
                                                {`+${value?.length - 3}`}
                                            </div>
                                        )}
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="center"
                                    side="left"
                                    sideOffset={100}
                                    className="z-10"
                                >
                                    <DataValueTable
                                        keyValuePairs={value || []}
                                        setKeyValuePairs={
                                            onValueChange || (() => {})
                                        }
                                    />
                                </PopoverContent>
                            </Popover>
                        )}

                        {displayUnit && (
                            <Text color="secondary" variant="body-sm">
                                {unit}
                            </Text>
                        )}
                        {(props.datapointHasComment ||
                            props.datapointHasProofs) && (
                            <FlexRow gap="2" className="absolute top-2 right-2">
                                {props.datapointHasProofs && (
                                    <Icon
                                        name="document"
                                        size="sm"
                                        className="text-secondary"
                                    ></Icon>
                                )}
                                {props.datapointHasComment && (
                                    <Icon
                                        name="comment"
                                        size="sm"
                                        className="text-secondary"
                                    ></Icon>
                                )}
                            </FlexRow>
                        )}
                    </FlexRow>
                </Tooltip.Trigger>
                {ctx.focuswithin && props?.calculation && (
                    <Tooltip.Content className="w-[388px]" side="top">
                        {props?.calculation}
                    </Tooltip.Content>
                )}
            </Tooltip>
        );
    },
);

export default ValueInput;
