import ImageUpload from "@app/shared/components/Upload/ImageUpload";
import {
    NewS3File,
    newS3FileToCreateFileInput,
} from "@app/usecases/FileUseCases";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    File,
    GetSustainabilityCenterDocument,
    SustainabilityCenter,
    UpdateSustainabilityCenterDocument,
} from "../../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../../components/Button/ButtonShared";
import InputSimple from "../../../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../../../components/Toast";
import TextAreaInput from "../../../../../../components/inputs_v2/TextArea";
import { validateEmail } from "../../../../../../utils/emailRegex";
import "./i18n";

export default function EditOverviewModal({
    sustainabilityCenter,
    open,
    setOpen,
}: {
    sustainabilityCenter: SustainabilityCenter | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterEditOverviewModal");

    const [name, setName] = useState<string | undefined>(
        sustainabilityCenter?.name || undefined,
    );
    const [description, setDescription] = useState<string | undefined>(
        sustainabilityCenter?.description || undefined,
    );
    const [contact, setContact] = useState<string | undefined>(
        sustainabilityCenter?.contact || undefined,
    );
    const [color, setColor] = useState<string | undefined>(
        sustainabilityCenter?.hexColor || undefined,
    );

    const [newS3File, setNewS3File] = useState<NewS3File>();

    const client = useQueryClient();
    const { mutate: updateSustainabilityCenter } = useGraphQLMutation(
        UpdateSustainabilityCenterDocument,
    );

    const { setToastSuccess, setToastError } = useSetToast();

    const isChanged =
        name !== sustainabilityCenter?.name ||
        description !== sustainabilityCenter?.description ||
        contact !== sustainabilityCenter?.contact ||
        color !== sustainabilityCenter?.hexColor ||
        newS3File?.signedUrl;

    const canSave =
        name &&
        description &&
        (!contact || validateEmail(contact)) &&
        isChanged;

    useEffect(() => {
        setName(sustainabilityCenter?.name || undefined);
        setDescription(sustainabilityCenter?.description || undefined);
        setContact(sustainabilityCenter?.contact || undefined);
        setColor(sustainabilityCenter?.hexColor || undefined);
    }, [
        sustainabilityCenter?.name,
        sustainabilityCenter?.description,
        sustainabilityCenter?.contact,
        sustainabilityCenter?.hexColor,
    ]);

    const handleSave = () => {
        if (!sustainabilityCenter) return;
        const updatePPInput =
            newS3File === undefined
                ? {
                      id: sustainabilityCenter.id,
                      name: name || "",
                      description: description || "",
                      contact: contact || "",
                      hexColor: color || "",
                  }
                : {
                      id: sustainabilityCenter.id,
                      name: name || "",
                      description: description || "",
                      contact: contact || "",
                      hexColor: color || "",
                      logoFile: newS3FileToCreateFileInput(newS3File),
                  };
        updateSustainabilityCenter(updatePPInput, {
            onSuccess: () => {
                setToastSuccess(t("update_success"));
                client.invalidateQueries({
                    queryKey: useGraphQL.getKey(
                        GetSustainabilityCenterDocument,
                    ),
                });
                setOpen(false);
            },
            onError: () => {
                setToastError(t("update_error"));
            },
        });
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("edit_overview_title")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                        />
                    </>
                }
            >
                <div className="w-[780px]">
                    <div className="w-full space-y-3">
                        <InputSimple
                            title={t("company_name")}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextAreaInput
                            placeholder={t("description")}
                            text={description}
                            setText={setDescription}
                        />
                        <InputSimple
                            title={t("contact")}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                    </div>
                    <div className="w-full my-6">
                        <div className="text-base font-bold text-primary ">
                            {t("branding")}
                        </div>
                        <div className="flex justify-between mt-4">
                            <ImageUpload
                                file={sustainabilityCenter?.logo as File}
                                newS3File={newS3File}
                                setNewS3File={setNewS3File}
                            />

                            <div className="w-[382px]">
                                <InputSimple
                                    title={t("color")}
                                    value={color}
                                    iconLeft={
                                        <div
                                            className="w-4 h-4 rounded border border-secondary"
                                            style={{
                                                backgroundColor: color || "#",
                                            }}
                                        />
                                    }
                                    onChange={(e) => {
                                        setColor(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
