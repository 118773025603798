import { useTranslation } from "react-i18next";
import { DocumentExpirationStatus } from "../../../../../generated/client/graphql";
import FilterTrigger from "../../components/triggers/Filter";
import ExpirationStatusDropdown from "./dropdown";
import "./i18n";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";

const DocumentExpirationStatusFilter = ({
    status,
    setStatus,
    isHeader,
}: {
    status: DocumentExpirationStatus[] | undefined;
    setStatus: (status: DocumentExpirationStatus[] | undefined) => void;
    isHeader?: boolean;
}) => {
    const { t } = useTranslation("ExpirationStatusDropdown");
    const active = status !== undefined;

    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={() => setStatus(undefined)}
                active={active}
                isHeader={isHeader}
            >
                {t("placeholder", {
                    context: active && "active",
                    count: status?.length,
                })}
            </FilterTrigger>
            <ExpirationStatusDropdown
                status={status}
                onStatusChange={setStatus}
            />
        </DropdownMenu>
    );
};

export default DocumentExpirationStatusFilter;
