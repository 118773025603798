import { Box } from "@design-system/Layout/Box";
import { Text } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import chroma from "chroma-js";
import { forwardRef } from "react";

interface ColorTagProps {
    color: string;
    size?: "xs" | "sm";
    text: string;
    className?: ClassValue;
    backgroundColor?: string;
    borderColor?: string;
}

export const ColorTag = forwardRef<HTMLDivElement, ColorTagProps>(
    ({ color, size = "xs", text, backgroundColor, borderColor, className }, ref) => {
        return (
            <Box
                className={cn(
                    "border rounded-lg box-border",
                    size === "xs" ? "h-[20px]" : "py-1",
                    className,
                )}
                display="flex"
                alignItems="center"
                maxW="fit"
                ref={ref}
                style={{
                    backgroundColor: backgroundColor ?? chroma(color).alpha(0.1).hex(),
                    borderColor: borderColor ?? chroma(color).alpha(0.3).hex(),
                }}
                px="2"
            >
                <Text
                    className="truncate"
                    variant="body-xs-bold"
                    style={{
                        color: color,
                    }}
                >
                    {text}
                </Text>
            </Box>
        );
    },
);
