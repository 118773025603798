import FrameworkSection from "@app/screens/Frameworks/framework/components/Section";
import { Icon } from "@design-system/Icon/index.tsx";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { filterAtom } from "../../ctx";
import { useEsrs } from "../../data";
import { RESOURCE_NAME, keys } from "../../i18n.ts";
import { DisclosureRequirement } from "../DisclosureRequirement";
import { SectionHeader } from "./SectionHeader";
import { RefObject } from "react";

const Esrs = ({
    cmsId,
    pillarId,
    pillarBodyContainerRef,
}: {
    cmsId: string;
    pillarId?: string;
    pillarBodyContainerRef?: RefObject<HTMLElement>;
}) => {
    const filter = useAtomValue(filterAtom);
    const { esrs } = useEsrs(cmsId, pillarId, filter);
    const { t } = useTranslation(RESOURCE_NAME);

    return esrs ? (
        <FrameworkSection>
            <SectionHeader esrs={esrs} />
            {esrs.disclosureRequirements.some(
                (requirement) => requirement.datapoints.length > 0,
            ) ? (
                esrs.disclosureRequirements.map(
                    (dr, idx) =>
                        !!dr.datapoints?.length && (
                            <DisclosureRequirement
                                key={idx}
                                disclosureRequirement={dr}
                                pillarBodyContainerRef={pillarBodyContainerRef}
                            />
                        ),
                )
            ) : (
                <FlexCol
                    py="20"
                    h="11"
                    mx="4"
                    my="3"
                    alignItems="center"
                    justifyContent="center"
                    className="bg-neutral-25"
                >
                    <Icon name="search" size="sm" className="text-primary" />
                    <Text
                        mt="2"
                        variant="body-sm-bold"
                        className="text-primary"
                    >
                        {t(keys.no_datapoint)}
                    </Text>
                </FlexCol>
            )}
        </FrameworkSection>
    ) : null;
};

export default Esrs;
