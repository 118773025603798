import { Row, Table as TableType, flexRender } from "@tanstack/react-table";

import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { ClassValue } from "clsx";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/Table";
import Loading from "../../Loading";
import Pagination from "../Pagination";
import "./i18n";

interface DataTableProps<TData> {
    table: TableType<TData>;
    isLoading?: boolean;
    rowClassName?: (row: Row<TData>) => string;
    onRowClick?: (row: Row<TData>) => void;
    type?: "small" | "medium" | "no-padding" | "no-v-padding";
    noBorder?: boolean;
    className?: ClassValue;
    headerClassName?: ClassValue;
}

export default function DataTable<TData>({
    table,
    isLoading,
    rowClassName,
    onRowClick,
    type,
    noBorder,
    className,
    headerClassName,
}: DataTableProps<TData>) {
    const { t } = useTranslation("DataTable");

    return (
        <Box className="space-y-4 max-w-full">
            <Box
                className={cn(
                    "px-py", // To avoid cropping the corners of the rounded border https://markheath.net/post/keep-inside-rounded-corners-css
                    noBorder ? "" : "rounded-lg border border-tertiary",
                    className,
                )}
            >
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow
                                key={headerGroup.id}
                                className="bg-secondary"
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            className={cn(
                                                header.column.columnDef.meta
                                                    ?.headerClassName,
                                                headerClassName,
                                            )}
                                            style={{
                                                width: header.column.columnDef
                                                    .meta?.colWidth,
                                            }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && "selected"
                                    }
                                    className={rowClassName?.(row)}
                                    onClick={() => onRowClick?.(row)}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            className={cn(
                                                cell.column.columnDef.meta
                                                    ?.cellClassName,
                                            )}
                                            key={cell.id}
                                            type={type}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={table.getAllColumns().length}
                                    className="h-24 text-center"
                                    type={type}
                                >
                                    {isLoading ? (
                                        <Loading size="medium" />
                                    ) : (
                                        t("no_results")
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            <Pagination table={table} />
        </Box>
    );
}
