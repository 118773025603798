import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import Checkbox from "@app/components/Checkbox";
import { reportingTableColumns } from "@app/usecases/ReportingUseCases";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import SearchBar from "@design-system/Inputs/SearchBar";
import { Flex, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { ChevronDown } from "lucide-react";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { activeReportingTableColumnsAtom } from "../../context";
import "./i18n";

const ReportingFilters: FC<PropsWithChildren> = (props) => {
    const { t } = useTranslation("ReportingFilters");
    const [activeReportingTableColumns, setActiveReportingTableColumns] =
        useAtom(activeReportingTableColumnsAtom);
    const [urlSearchString, setUrlSearchString] = useQueryParam(
        "search",
        StringParam,
    );
    return (
        <FlexRow
            justifyContent="between"
            alignItems="center"
            alignSelf="stretch"
        >
            <FlexRow alignItems="center" gap="5">
                {props?.children}
            </FlexRow>
            <FlexRow gap="4">
                <SearchBar
                    searchString={urlSearchString || undefined}
                    setSearchString={setUrlSearchString}
                    placeholder={t("search_placeholder")}
                />
                <MultiSelect
                    values={activeReportingTableColumns}
                    onValuesChange={setActiveReportingTableColumns}
                >
                    <MultiSelect.Trigger>
                        <ButtonSmall
                            variant={ButtonType.TONAL}
                            className=" bg-white hover:bg-secondary shadow-sm cursor-pointer focus:outline-none"
                            IconLeft={ChevronDown}
                        >
                            {t("select_columns")}
                        </ButtonSmall>
                    </MultiSelect.Trigger>
                    <MultiSelect.Content>
                        {reportingTableColumns.map((column) => (
                            <MultiSelect.Item key={column} value={column}>
                                {({ isSelected }) => (
                                    <FlexRow
                                        alignItems="center"
                                        alignSelf="stretch"
                                        className="p-2"
                                        gap="3"
                                    >
                                        <Flex alignItems="center">
                                            <Checkbox
                                                checked={isSelected}
                                            ></Checkbox>
                                        </Flex>
                                        <Text
                                            variant="body-sm"
                                            className="capitalize"
                                        >
                                            {t(column)}
                                        </Text>
                                    </FlexRow>
                                )}
                            </MultiSelect.Item>
                        ))}
                    </MultiSelect.Content>
                </MultiSelect>
            </FlexRow>
        </FlexRow>
    );
};

export default ReportingFilters;
