import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLVersionRepository } from "@app/repositories/GraphQLRepositories/VersionRepository";
import { documentURLAtom } from "@app/store/documentStore";
import { versionKeys } from "@app/store/queryKeys";
import { DocumentVersionStatus } from "@generated/client/graphql";
import { useMutation } from "@tanstack/react-query";
import { useAtomValue } from "jotai/react";

const versionRepository = new GraphQLVersionRepository();

export function useUnsendReviewRequest() {
    const documentUrl = useAtomValue(documentURLAtom);
    
    return useMutation({
        mutationFn: (id: string) =>
            versionRepository.update({
                id,
                patch: {
                    status: DocumentVersionStatus.Draft,
                },
            }),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [versionKeys.versionByDocumentURL, { documentUrl }],
            });
        },
    });
}
