import { ReactComponent as PDFDocumentIllustration } from "@app/assets/illustrations/document-pdf.svg";
import { ReactComponent as PPTDocumentIllustration } from "@app/assets/illustrations/document-ppt.svg";
import { ReactComponent as UnknownDocumentIllustration } from "@app/assets/illustrations/document-unknown-format.svg";
import { ReactComponent as XLSDocumentIllustration } from "@app/assets/illustrations/document-xls.svg";
import { hasUnapprovedLastVersion } from "@app/usecases/DocumentUseCases";
import { DocumentVersionStatus } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    bulkUploadInputsAtom,
    newS3FilesAtom,
} from "./DocumentsUploadModalContext";
import { useModalActions } from "./useModalActions";

export function useDocumentUploadModalItem(key: string) {
    const { t } = useTranslation("DocumentsUploadModal");
    const statusRef = useRef<DocumentVersionStatus>(
        DocumentVersionStatus.Draft,
    );

    const {
        associateFileToDocuments,
        documentsForSearch,
        dissociateFileFromDocuments,
        deleteFile,
    } = useModalActions();

    const bulkUploadInputs = useAtomValue(bulkUploadInputsAtom);
    const newS3Files = useAtomValue(newS3FilesAtom);

    const currentInput = bulkUploadInputs.find((i) => i.file.key === key);
    const currentS3File = newS3Files.find((i) => i.key === key);

    const Illustration = useMemo(() => {
        switch (currentS3File?.mimeType) {
            case "application/pdf":
                return PDFDocumentIllustration;
            case "application/vnd.ms-powerpoint":
                return PPTDocumentIllustration;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
                return XLSDocumentIllustration;
            default:
                return UnknownDocumentIllustration;
        }
    }, [currentS3File?.mimeType]);

    const errorMsg = currentInput?.associatedDocuments?.some(
        (doc) => doc.hasAlreadyEntry,
    )
        ? t("error_already_an_entry")
        : undefined;

    const handleStatusChange = (status: DocumentVersionStatus) => {
        statusRef.current = status;
        const associatedDocs = currentInput?.associatedDocuments?.map(
            (doc) => ({
                ...doc,
                status,
            }),
        );
        if (!associatedDocs) return;

        dissociateFileFromDocuments(key);
        associateFileToDocuments(key, associatedDocs);
    };

    const handleButtonClick = () => {
        if (key)
            currentInput?.associatedDocuments?.length
                ? dissociateFileFromDocuments(key)
                : deleteFile(key);
    };

    const handleAssociatedDocumentsChange = (docIds: (string | null)[]) => {
        if (!documentsForSearch) return;
        const docs = docIds
            .map((id) => {
                const rawDoc = documentsForSearch.find((doc) => doc.id === id);
                if (!rawDoc) throw new Error("Document not found");
                return {
                    lastVersionId: rawDoc.lastVersion?.id,
                    id: rawDoc.id,
                    hasAlreadyEntry: hasUnapprovedLastVersion(rawDoc),
                    name: rawDoc.name,
                    status: statusRef.current,
                    theme: rawDoc.theme
                        ? {
                              color: rawDoc.theme?.color ?? "",
                              name: rawDoc.theme?.name,
                          }
                        : undefined,
                };
            })
            .filter((doc) => doc);

        dissociateFileFromDocuments(key);
        associateFileToDocuments(key, docs);
    };

    const buttonIconName: "close" | "trash" = currentInput?.associatedDocuments
        ?.length
        ? "close"
        : "trash";

    return {
        buttonIconName,
        currentInput,
        currentS3File,
        documentsForSearch,
        errorMsg,
        handleButtonClick,
        handleAssociatedDocumentsChange,
        handleStatusChange,
        Illustration,
    };
}
