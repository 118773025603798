import { useTranslation } from "react-i18next";
import { InputSelect, InputSelectProps } from "..";

import { GetAllUsersDocument } from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "SelectInput", {
    owner: "Owner",
});

i18next.addResourceBundle("fr", "SelectInput", {
    owner: "Propriétaire",
});

export default function SelectInputOwner(props: InputSelectProps) {
    const { data } = useGraphQL(GetAllUsersDocument);

    const { t } = useTranslation("SelectInput");

    return (
        <InputSelect
            {...props}
            placeholder={t("owner")}
            values={data?.users.map((user) => ({
                id: user.id,
                name: user.firstName + " " + user.lastName,
            }))}
        />
    );
}
