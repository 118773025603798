export const NULL_TOKEN = "NULL_TOKEN" as const;
export type NULL_TOKEN_TYPE = typeof NULL_TOKEN;

export function isNullToken(token: unknown): token is NULL_TOKEN_TYPE {
    return token === NULL_TOKEN;
}

export function convertToNullIfNullToken<T>(
    token: NULL_TOKEN_TYPE | T,
): T | null {
    return isNullToken(token) ? null : token;
}

export function convertToUndefinedIfNullToken<T>(
    token: NULL_TOKEN_TYPE | T,
): T | undefined {
    return isNullToken(token) ? undefined : token;
}