import { useFrameworkPageState } from "@app/screens/Frameworks/framework/hooks/pageState";
import { canBeAccessed, releaseStatus } from "@app/screens/Frameworks/services";
import { currentUserIsBeavrAdminAtom } from "@app/store/userStore";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { FrameworkActivation } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { memo, type FC } from "react";
import { Link } from "react-router-dom";
import { useFrameworkInitialize, useSetFrameworkActivation } from "../../data";
import { FrameworkItem } from "../../types";
import { FrameworkCardProps, useFrameworkCard } from "./useFrameworkCard";

const BeavrAdminActions = ({ framework }: { framework: FrameworkItem }) => {
    const { mutate: toggleActivate, isPending: isSettingActivation } =
        useSetFrameworkActivation();
    const { mutate: initialise, isPending: isInitializing } =
        useFrameworkInitialize();
    const active = framework.activation === FrameworkActivation.Active;
    const disabled =
        framework.cmsId !== "csrd" &&
        !(framework.releaseStatus === releaseStatus.released);

    const showInitialize = framework.cmsId === "csrd";
    const handleToggleActivate = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        toggleActivate({ frameworkId: framework.id, isActive: !active });
    };

    const handleInitialise = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        initialise(framework.id);
    };
    return (
        <FlexCol className="absolute top-2 right-2" gap="2">
            <Button
                variant="tonal"
                size="xs"
                onClick={handleToggleActivate}
                disabled={disabled}
                loading={isSettingActivation}
            >
                {active ? (
                    <>
                        <Icon name="eyeOff" size="xs" /> Deactivate
                    </>
                ) : (
                    <>
                        <Icon name="plus" size="xs" />
                        Activate
                    </>
                )}
            </Button>

            {active && showInitialize && (
                <Button
                    variant="tonal"
                    size="xs"
                    onClick={handleInitialise}
                    loading={isInitializing}
                    disabled={disabled}
                >
                    <Icon name="loader" size="xs" />
                    Initialise
                </Button>
            )}
        </FlexCol>
    );
};
const FrameworkCardContent: FC<FrameworkCardProps> = memo(({ framework }) => {
    const {
        betaOrReleased,
        headerClass,
        headerStyle,
        progress,
        subtitleClass,
        t,
        titleClass,
        wrapperClass,
    } = useFrameworkCard({ framework });

    const currentUserIsBeavrAdmin = useAtomValue(currentUserIsBeavrAdminAtom);

    return (
        <FlexCol
            as="article"
            br="lg"
            className={wrapperClass}
            elevation="b-200"
            h="full"
        >
            {/* Header */}
            <FlexCol
                as="header"
                h="full"
                py="6"
                px="4"
                className={cn(
                    headerClass,
                    currentUserIsBeavrAdmin && "relative",
                )}
                style={headerStyle}
            >
                <Text as="h6" variant="hero6" className={titleClass}>
                    {framework.name}
                </Text>
                <Text className={subtitleClass}>{framework.description}</Text>
                {currentUserIsBeavrAdmin && (
                    <BeavrAdminActions framework={framework} />
                )}
            </FlexCol>

            {/* Body */}
            <FlexCol p="4" gap="2">
                {betaOrReleased ? (
                    <>
                        <Text variant="header2">
                            {framework.releaseStatus === releaseStatus.released
                                ? canBeAccessed(framework)
                                    ? `${progress.toFixed(0)}%`
                                    : t("available_sales")
                                : t("coming_soon_beta")}
                            {}
                        </Text>
                        <Box w="full" py="1">
                            <ProgressBar
                                color={framework.color?.primary}
                                className="w-full rounded-sm"
                                completed={framework.stats.reqOk}
                                noDataPlaceholder={t("no_data")}
                                total={framework.stats.reqTotal}
                            />
                        </Box>
                        <FlexRow justifyContent="between">
                            <Text>
                                {t("reqs_ok", {
                                    count: framework.stats.reqOk,
                                })}
                            </Text>
                            <Text>
                                {t("reqs_total", {
                                    count: framework.stats.reqTotal,
                                })}
                            </Text>
                        </FlexRow>
                    </>
                ) : (
                    <Text variant="header2" color="secondary">
                        {framework.releaseStatus === releaseStatus.comingSoon
                            ? t("coming_soon_Q2_2025")
                            : t("coming_soon")}
                    </Text>
                )}
            </FlexCol>
        </FlexCol>
    );
});

export const FrameworkCard = ({ framework }: FrameworkCardProps) => {
    const { resetDefaultState } = useFrameworkPageState();

    if (canBeAccessed(framework)) {
        return (
            <Link
                className="[all:unset] no-underline ![text-decoration:none]"
                onClick={resetDefaultState}
                to={framework.cmsId}
            >
                <FrameworkCardContent framework={framework} />
            </Link>
        );
    }
    return (
        <div>
            <FrameworkCardContent framework={framework} />
        </div>
    );
};

export { FrameworkCardSkeleton } from "./FrameworkCardSkeleton";
