import i18next from "@app/i18n";

const enResources = {
    ask_ai: "Ask AI",
    ask_me_anything: "Ask me anything",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    ask_ai: "Demander à l'IA",
    ask_me_anything: "Posez-moi une question",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AskAiModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
