import {
    GetDashboardPlanStepsDocument,
    GetDashboardPlanStepsQuery,
    UpsertDashboardPlanStepDocument,
    UpsertDashboardPlanStepMutationVariables,
} from "../../../generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { DashboardRepository } from "../DashboardRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLDashboardRepository implements DashboardRepository {
    getPlanSteps = async (): Promise<GetDashboardPlanStepsQuery> => {
        return graphqlClientKoyeb.request(
            GetDashboardPlanStepsDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    upsertPlanStep = async (
        payload: UpsertDashboardPlanStepMutationVariables,
    ) => {
        return graphqlClientKoyeb.request(
            UpsertDashboardPlanStepDocument,
            payload,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
