import { Period } from "@app/utils/periodToUrl";
import { User } from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const addPeriodModalIsOpenAtom = atom<boolean>(false);
export const inactiveIndicatorsModalIsOpenAtom = atom<boolean>(false);
export const createCustomIndicatorsModalIsOpenAtom = atom<boolean>(false);
export const exportModalIsOpenAtom = atom<boolean>(false);

export const activePeriodsAtom = atomWithStorage<Period[]>(
    "reportingPeriods",
    [],
);

export const activeReportingEntityIdAtom = atom<string>("");
export const usersToSendReminderAtom = atom<User[]>([]);
export const selectedUsersAtom = atom<(string | null)[]>([]);
export const openReminderModalAtom = atom<boolean>(false);
export const openPeriodManagementModalAtom = atom<boolean>(false);

export const reportingContributorsSearchFilterAtom = atom<string | undefined>(
    undefined,
);
export const toFillSwitchAtom = atom<boolean>(false);
export const activeReportingTableColumnsAtom = atomWithStorage<
    (string | null)[]
>("__beavr_activeReportingTableColumnsAtom", ["subtheme", "assignedTo"]);

export const selectedSubthemesAtom = atom<string[]>([]);
export const selectedTagsAtom = atom<(string | null)[]>([]);
export const selectedInactiveSubthemesAtom = atom<(string | null)[]>([]);
export const searchThemesFilterAtom = atom<string>("");
export const searchThemesActiveFilterAtom = atom<string | undefined>(undefined);

export const selectedActiveFrameworkIdsAtom = atom<(string | null)[]>([]);
export const selectedInactiveFrameworkIdsAtom = atom<(string | null)[]>([]);
