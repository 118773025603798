import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document } from "../../../../../generated/client/graphql";
import { DocumentRelationType, Relations } from "./InfoTab";
import RelatedDocumentListElement from "./RelatedDocumentsListElement";
import "./i18n";

const RelatedDocumentList = ({
    relatedDocuments,
    relationType,
    insertDocument,
}: {
    relatedDocuments: Document[];
    relationType: DocumentRelationType;
    insertDocument: ((relatedDocument: Document) => void) | null;
}) => {
    const { t } = useTranslation("DocumentEditorSideBar");

    const [activeDocIdx, setActiveDocIdx] = useState<number | undefined>(
        undefined,
    );
    const toggleActive = (activeDocIdx: number | undefined, idx: number) => {
        activeDocIdx === idx
            ? setActiveDocIdx(undefined)
            : setActiveDocIdx(idx);
    };

    return (
        <>
            {relatedDocuments && relatedDocuments.length > 0 && (
                <div className="flex flex-col items-start gap-2 self-stretch">
                    <div className="font-bold text-sm">
                        {relationType === Relations.CHILDREN
                            ? t("children_documents")
                            : t("parent_documents")}
                    </div>
                    <div className="flex flex-col items-start self-stretch">
                        {relatedDocuments.map((doc, idx) => (
                            <RelatedDocumentListElement
                                key={idx}
                                isActive={activeDocIdx === idx}
                                relatedDocument={doc}
                                isFirst={idx === 0}
                                isLast={idx === relatedDocuments.length - 1}
                                relationType={relationType}
                                insertDocument={insertDocument}
                                toggleActive={() =>
                                    toggleActive(activeDocIdx, idx)
                                }
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default RelatedDocumentList;
