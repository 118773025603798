import { Icon } from "@design-system/Icon";
import { cn } from "@design-system/Utilities";
import { type ButtonHTMLAttributes, type FC } from "react";

export const HelpButton: FC<
    ButtonHTMLAttributes<HTMLButtonElement> & {
        dataChameleonKey: string;
        theme?: "dark" | "light";
    }
> = ({ dataChameleonKey, theme = "light", ...props }) => {
    return (
        <button
            data-chameleon={`header-help-button-${dataChameleonKey}`}
            {...props}
        >
            <Icon
                name="help"
                className={cn(
                    theme === "light" ? "text-secondary" : "text-white",
                )}
            />
        </button>
    );
};
