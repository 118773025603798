import { UserAssign } from "@app/shared/components/UserAssign";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { FC } from "react";
import { useUpdateEsrs } from "../../data";
import { PillarEsrs } from "../../types";

export const SectionHeader: FC<{ esrs: PillarEsrs }> = ({ esrs }) => {
    const users = esrs.ownerIds ?? [];
    const { mutate: handleSelect } = useUpdateEsrs(esrs.cmsId);

    return (
        <FlexRow
            className="border-t-4 border-t-beavrGreen bg-tertiary h-[84px] box-border"
            p="4"
            id={esrs?.cmsId}
            justifyContent="between"
        >
            <Text variant="hero6">{esrs?.name}</Text>
            {!!esrs?.disclosureRequirements.flatMap((dr) => dr?.datapoints)
                ?.length && (
                <UserAssign
                    enableNone
                    selectedUsers={users}
                    onSelect={handleSelect}
                    selectMode="single"
                />
            )}
        </FlexRow>
    );
};
