import { useContext, MouseEvent } from "react";
import { YearContext } from ".";
import { useDatapointGroupsOfEntity } from "../../data";
import { getPeriodsOfYear, getYearsOfPeriods } from "../../services";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Box } from "@design-system/Layout/Box";
import { Button } from "@design-system/Inputs/Button";
import Checkbox from "@app/components/Checkbox";
import { Text } from "@design-system/Typography/Text";

export const Sidebar = () => {
    //This is the earliest year that someone is using in reporting
    const yearsFromNowTo2018 = Array.from(
        { length: new Date().getFullYear() - 2017 },
        (_, i) => new Date().getFullYear() - i,
    );
    const ctx = useContext(YearContext);
    if (!ctx) throw new Error("Context must not be null");
    const { selectedYear, setSelectedYear, newPeriods, setNewPeriods } = ctx;
    const handleYearClick = (year: number) => () => {
        setSelectedYear(year);
    };
    const datapointGroupsOfEntity = useDatapointGroupsOfEntity();
    const filteredGroups = (year: number) => {
        return getPeriodsOfYear(datapointGroupsOfEntity, year);
    };
    const isFullYearActive = (year: number) => {
        return !!filteredGroups(year)?.find(
            (group) => !group.period.month && !group.period.quarter,
        );
    };
    const yearsOfPeriods = getYearsOfPeriods(datapointGroupsOfEntity);

    const isFullYearInNewPeriods = (year: number) => {
        return !!newPeriods.find(
            (newPeriod) =>
                newPeriod.year === year &&
                !newPeriod.month &&
                !newPeriod.quarter,
        );
    };

    const isSomePeriodOfYearInNewPeriods = (year: number) => {
        return !!newPeriods.find((newPeriod) => newPeriod.year === year);
    };

    const handleCheckboxClick = (year: number) => (e: MouseEvent) => {
        e.stopPropagation();
        if (isFullYearActive(year)) {
            return;
        }
        if (isFullYearInNewPeriods(year)) {
            setNewPeriods(
                newPeriods.filter(
                    (newPeriod) =>
                        newPeriod.year !== year ||
                        newPeriod.month ||
                        newPeriod.quarter,
                ),
            );
            return;
        }
        setNewPeriods([...newPeriods, { year }]);
    };

    return (
        <FlexCol className="w-[224px]">
            <Box p="4" pb="3">
                <Text variant="header3" color="primary">
                    Year
                </Text>
            </Box>
            <FlexCol px="2">
                {yearsFromNowTo2018.map((year) => (
                    <Button
                        variant="text"
                        size="sm"
                        className="h-[36px] cursor-pointer hover:shadow-none hover:bg-secondary hover:text-green justify-start"
                        onClick={handleYearClick(year)}
                        active={selectedYear === year}
                        asChild
                    >
                        <FlexRow gap="2.5" alignItems="center">
                            <Checkbox
                                checked={
                                    isFullYearActive(year) ||
                                    isFullYearInNewPeriods(year)
                                        ? true
                                        : yearsOfPeriods.includes(year) ||
                                            isSomePeriodOfYearInNewPeriods(year)
                                          ? "partial"
                                          : false
                                }
                                disabled={isFullYearActive(year)}
                                onClick={handleCheckboxClick(year)}
                                allowPartialClick
                            />
                            <Text variant="body" color="primary">
                                {year}
                            </Text>
                        </FlexRow>
                    </Button>
                ))}
            </FlexCol>
        </FlexCol>
    );
};
