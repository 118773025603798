import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { RulerAdd } from "iconoir-react";

import { useGraphQL } from "../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../components/Button/ButtonLarge";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import InputSimple from "../../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
    CenterModalTrigger,
} from "../../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../../components/Toast";

import { GetAllEntitiesDocument } from "../../../../../../generated/client/graphql";
import { useCreateEntity } from "../../data";
import "./i18n";

export const newSitesModalOpenAtom = atom<boolean>(false);

export default function NewSiteModal() {
    const { t } = useTranslation("NewSiteModal");

    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate, isPending } = useCreateEntity();
    const client = useQueryClient();
    const [open, setOpen] = useAtom(newSitesModalOpenAtom);
    const [name, setName] = useState<string | undefined>(undefined);

    const canSave = name;
    const handleSave = () => {
        canSave &&
            mutate(
                { name: name, type: "site" },
                {
                    onSuccess() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetAllEntitiesDocument),
                        });
                        setOpen(false);
                        setToastSuccess(t("site_create_success"));
                    },
                    onError() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetAllEntitiesDocument),
                        });
                        setToastError(t("site_create_error"));
                    },
                },
            );
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalTrigger asChild>
                <ButtonRegular text={t("open_modal")} IconRight={RulerAdd} />
            </CenterModalTrigger>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("create")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="space-y-4">
                    <InputSimple
                        title={t("name")}
                        value={name || ""}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
