import i18next from "@app/i18n";

i18next.addResourceBundle("en", "FrameworkDatapoint", {
    comment: "Comment ({{charCount}} characters)",
    materiality_comment: "Materiality comment",
    ownership: "Ownership",
    validate: "Validate datapoint",
    value: "Value ({{ unit }})",
    yes_no: "Value (Yes/No)",
    yes: "Yes",
    no: "No",
    narrative_answer: "Answer",
    manage_materiality: "Manage materiality",
    update_success: "Update successful",
    update_error: "Error updating",
    owner: "Owner :",
    required_error: "Value is required",
    required_comment_error: "Comment is required",
    no_data: "No data",
    data_available: "Data available",
    data_is_required: "Data is mandatory for this datapoint",
    mandatory: "Mandatory",
    voluntary: "Voluntary",
    phaseInYears_one: "{{count}} year",
    phaseInYears_other: "{{count}} years",
    phaseInLessThan750_one:
        "{{count}} year if less than 750 full time employees",
    phaseInLessThan750_other:
        "{{count}} years if less than 750 full time employees",
    add_documents: "Add documents",
});

i18next.addResourceBundle("fr", "FrameworkDatapoint", {
    comment: "Commentaire ({{charCount}} caractères)",
    materiality_comment: "Commentaire sur la materialité",
    ownership: "Assigné à:",
    validate: "Valider le datapoint",
    value: "Valeur ({{ unit }})",
    yes_no: "Valeur (Oui/Non)",
    yes: "Oui",
    no: "Non",
    narrative_answer: "Réponse",
    manage_materiality: "Gérer la matérialité",
    update_success: "Mis à jour avec succès",
    update_error: "Erreur lors de la mise à jour",
    owner: "Assigné à :",
    required_comment_error: "Un commentaire est obligatoire",
    no_data: "Pas de donnée",
    data_available: "Donnée disponible",
    data_is_required:
        "Remplir la donnée est obligatoire pour ce point de données",
    mandatory: "Obligatoire",
    voluntary: "Facultatif",
    phaseInYears_one: "{{count}} an",
    phaseInYears_other: "{{count}} ans",
    phaseInLessThan750_one:
        "{{count}} an si moins de 750 employés à temps plein",
    phaseInLessThan750_other:
        "{{count}} ans si moins de 750 employés à temps plein",
    add_documents: "Ajouter des documents",
});
