import BodyHeader from "@app/shared/components/BodyHeader";
import { usePageviewTrack } from "@app/shared/planhat/index.ts";
import { Box } from "@design-system/Layout/Box";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import {
    FrameworkCard,
    FrameworkCardSkeleton,
} from "./components/FrameworkCard/index.tsx";
import { useFrameworks } from "./data.ts";
import "./i18n";

export const Frameworks: FC = () => {
    const { frameworks, isPending } = useFrameworks();
    const { t } = useTranslation("Frameworks");

    usePageviewTrack("pageview_frameworks");

    return (
        <PageContainer>
            <BodyHeader title={t("title")} subTitle={t("subtitle")} />
            <Box p="4" display="grid" className="grid-cols-2 gap-y-4 gap-x-2">
                {!isPending && frameworks
                    ? frameworks.map((framework) => (
                          <FrameworkCard
                              framework={framework}
                              key={framework.id}
                          />
                      ))
                    : new Array(7)
                          .fill(null)
                          .map((_, idx) => <FrameworkCardSkeleton key={idx} />)}
            </Box>
        </PageContainer>
    );
};
