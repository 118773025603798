import { useAtom } from "jotai";
import { EyeOff, ThumbsUp } from "lucide-react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import {
    GetRequirementDocument,
    Proof,
    Relevance,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import UserSelect from "../../../../components/Dropdown/dropdowns/User";
import {
    RightModal,
    RightModalContent,
} from "../../../../components/RightModal";
import RightModalBody from "../../../../components/RightModal/RightModalBody";
import RightModalHeader from "../../../../components/RightModal/RightModalHeader";

import { useUpdateRequirement } from "../../hooks/data";
import "./i18n";
import { modalRequirementIdAtom } from "./modal-hooks";
import ProofTable from "./proofTable";

// Note that this modal should be moved to its own "featture" folder
const RequirementModal = () => {
    const [requirementId, setRequirementId] = useAtom(modalRequirementIdAtom);

    const { t } = useTranslation("RequirementModal");

    const getRequirement = useGraphQL(
        GetRequirementDocument,
        { id: requirementId! },
        { enabled: !!requirementId },
    );
    const requirement = getRequirement.data?.requirement;
    const { mutate } = useUpdateRequirement();
    const onChange = (userId: string | undefined) => {
        requirement && mutate(requirement.id, { ownerId: userId || null });
    };

    const onSetRelevance = (relevance: Relevance) => {
        requirement && mutate(requirement.id, { relevance: relevance });
    };
    return (
        <RightModal
            open={!!requirementId}
            onOpenChange={(open) => {
                !open && setRequirementId(undefined);
            }}
        >
            <RightModalContent>
                {requirement && (
                    <>
                        <RightModalHeader title={requirement.name} />
                        <RightModalBody>
                            <>
                                <div className="flex justify-between">
                                    <div className="flex justify-center items-center border border-secondary rounded-xl text-sm font-bold px-2 h-[28px]">
                                        {requirement.subtheme?.name}
                                    </div>
                                    {requirement.relevance ==
                                        Relevance.Relevant && (
                                        <ButtonLarge
                                            variant={ButtonType.TEXT}
                                            IconRight={EyeOff}
                                            className="flex justify-center items-center border border-secondary rounded-xl px-2 h-[28px]"
                                            onClick={() =>
                                                onSetRelevance(
                                                    Relevance.NotRelevant,
                                                )
                                            }
                                        >
                                            <div className="text-sm font-bold">
                                                {" "}
                                                {t("mark_not_relevant")}{" "}
                                            </div>
                                        </ButtonLarge>
                                    )}
                                    {requirement.relevance ==
                                        Relevance.NotRelevant && (
                                        <ButtonLarge
                                            variant={ButtonType.TEXT}
                                            IconRight={ThumbsUp}
                                            className="flex justify-center items-center border border-green border-2 rounded-xl px-2 h-[28px] test-green"
                                            onClick={() =>
                                                onSetRelevance(
                                                    Relevance.Relevant,
                                                )
                                            }
                                        >
                                            <div className="text-sm font-bold">
                                                {" "}
                                                {t("mark_relevant")}{" "}
                                            </div>
                                        </ButtonLarge>
                                    )}
                                </div>
                                <ReactMarkdown
                                    className="markdown text-base font-medium"
                                    children={requirement.description || ""}
                                    remarkPlugins={[remarkGfm, remarkBreaks]}
                                />
                                <div>
                                    <div className="h-7 flex space-x-1.5 items-center ">
                                        <div className="w-28">ID:</div>
                                        <div className=" font-bold">
                                            {requirement.cmsId}
                                        </div>
                                    </div>
                                    {requirement.relevance ===
                                        Relevance.Relevant && (
                                        <div className="h-7 flex space-x-1.5 items-center ">
                                            <div className="w-28">
                                                {t("owner")}:
                                            </div>

                                            <UserSelect
                                                selectedUserId={
                                                    requirement.ownerId ||
                                                    undefined
                                                }
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                    <div className="min-h-7 flex space-x-1.5 ">
                                        <div className="w-28 self-center">
                                            Frameworks:
                                        </div>
                                        <div>
                                            {requirement.frameworkSections?.map(
                                                (fs) => (
                                                    <div className="h-7 flex items-center font-bold">
                                                        {fs.framework?.name} -{" "}
                                                        {fs.tagName}
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                            {requirement.relevance === Relevance.Relevant && (
                                <ProofTable
                                    requirementId={requirement.id}
                                    proofs={requirement.proofs as Proof[]}
                                />
                            )}
                        </RightModalBody>
                    </>
                )}
            </RightModalContent>
        </RightModal>
    );
};

export default RequirementModal;
