import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import {
    GetTagsDocument,
    Tag,
    TagType,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import BaseTrigger from "../../components/triggers/Base";
import TagDropdown from "./dropdown";
import "./i18n";

const TagsSetterFloatbar = ({
    type,
    tagIds,
    onTagIdsChange,
}: {
    type: TagType;
    tagIds: string[] | undefined;
    onTagIdsChange: (ids: string[]) => void;
}) => {
    const { t } = useTranslation("TagsDropdown");
    const getTags = useGraphQL(GetTagsDocument, { type: type });

    return (
        <DropdownMenu>
            <BaseTrigger>
                {t("setter_text", { count: tagIds?.length || 0 })}
            </BaseTrigger>
            <TagDropdown
                tagIds={tagIds}
                onTagIdsChange={onTagIdsChange}
                tags={getTags.data?.tags as Tag[] | undefined}
                type={type}
            />
        </DropdownMenu>
    );
};

export default TagsSetterFloatbar;
