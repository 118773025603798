import * as chameleon from "@chamaeleonidae/chmln";

import { currentUserAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";
import posthog from "posthog-js";
import { useEffect } from "react";
import { UserRole } from "../../../generated/client/graphql";

export const useTrackingIdentify = () => {
    const { data } = useAtomValue(currentUserAtom);
    useEffect(() => {
        if (data?.currentUser.id) {
            posthog.init(
                import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY as string,
                {
                    api_host: import.meta.env
                        .VITE_REACT_APP_PUBLIC_POSTHOG_HOST as string,
                    persistence: "memory",
                    bootstrap: {
                        distinctID: data.currentUser.id,
                    },
                    loaded: function (ph) {
                        if (import.meta.env.DEV) {
                            ph.opt_out_capturing();
                        }
                    },
                },
            );
            posthog.setGroupPropertiesForFlags({
                tenant: { name: data.currentUser.permission!.tenant.name },
            });
            posthog.identify(data.currentUser.id, {
                email: data?.currentUser.email,
                firstname: data?.currentUser.firstName,
                lastname: data?.currentUser.lastName,
            });
            posthog.group("tenant", data.currentUser.permission!.tenant.id, {
                name: data.currentUser.permission!.tenant.name,
                uname: data.currentUser.permission!.tenant.uname,
            });

            // Initialise Chameleon

            chameleon.init(import.meta.env.VITE_REACT_APP_CHAMELEON_ID!, {
                fastUrl: "https://fast.chameleon.io/",
            });

            chameleon.identify(data.currentUser.id, {
                email: data.currentUser.email,
                name:
                    data.currentUser.firstName +
                    " " +
                    data.currentUser.lastName,
                created: data.currentUser.createdAt,
                locale: data.currentUser.locale,
                role: data.currentUser.permission?.role ?? UserRole.Contributor,
                company: {
                    uid: data.currentUser.permission?.tenant.uname,
                    name: data.currentUser.permission?.tenant.name,
                },
            });
        }
    }, [data?.currentUser.id]);
};

export const useCapturePageView = (page: string | undefined) => {
    useEffect(() => {
        if (page) posthog.capture(`pageview_${page}`); // new
    }, [page]);
};
