import { FrameworkGroup, FrameworkSection } from "@generated/client/graphql";

import SectionBlock from "./SectionBlock";
import { RefObject } from "react";

const GroupBlocks = ({
    group,
    containerAnchorRef,
}: {
    group: FrameworkGroup;
    containerAnchorRef?: RefObject<HTMLElement>;
}) => {
    return group?.subgroups?.flatMap((subgroup) => [
        subgroup?.sections
            .filter((s) => !!s.requirements.length)
            .map((section) => (
                <SectionBlock
                    key={section.id}
                    anchor={subgroup.name}
                    groupName={group.name}
                    subgroupName={subgroup.name}
                    section={section as FrameworkSection}
                    containerAnchorRef={containerAnchorRef}
                />
            )),
    ]);
};

export default GroupBlocks;
