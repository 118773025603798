import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useState } from "react";
import { modalIsMountedAtom, themeIdAtom } from ".";
import {
    CreateSubThemeDocument,
    GetAvailableThemesDocument,
} from "../../../generated/client/graphql";
import { useGraphQL, useGraphQLMutation } from "../../QueryClientWithHeaders";
import ButtonLarge from "../../components/Button/ButtonLarge";
import { ButtonType } from "../../components/Button/ButtonShared";
import InputSimple from "../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../components/Modal/CenterModal";

export default function CreateModal() {
    const { mutate } = useGraphQLMutation(CreateSubThemeDocument);

    const client = useQueryClient();
    const [parentThemeId] = useAtom(themeIdAtom);
    const [showModal, setShowModal] = useAtom(modalIsMountedAtom);
    const [subThemeNameValue, setSubthemeNameValue] = useState("");
    const handleCreateSubtheme = () =>
        mutate(
            {
                input: {
                    name: subThemeNameValue,
                    themeId: parentThemeId,
                },
            },
            {
                onSuccess() {
                    client.invalidateQueries({
                        queryKey: useGraphQL.getKey(GetAvailableThemesDocument),
                    });
                },
            },
        );
    return (
        <CenterModal open={showModal} onOpenChange={setShowModal}>
            <CenterModalContent
                title="Create subtheme"
                subtitle=""
                actions={
                    <>
                        <ButtonLarge
                            text="Create"
                            variant={ButtonType.ELEVATED}
                            onClick={() => {
                                handleCreateSubtheme();
                                setShowModal(false);
                            }}
                        />
                    </>
                }
            >
                <div className="text-gray-800 text-medium not-italic font-regular leading-5 mb-3">
                    Associated to theme
                </div>
                <InputSimple
                    type="text"
                    id="NameSubtheme"
                    placeholder=""
                    title="Name subtheme"
                    value={subThemeNameValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSubthemeNameValue(e.target.value)
                    }
                />
            </CenterModalContent>
        </CenterModal>
    );
}
