import {
    AddTipTapDocDocument,
    CreateManyVersionsDocument,
    CreateManyVersionsInput,
    DocumentVersionPatch,
    GetLastVersionByDocumentUrlDocument,
    UpdateDocumentDataDocument,
    UpdateVersionCheckDocument,
    UpdateVersionDocument,
    type GetLastVersionByDocumentUrlQuery,
    type MutationUpdateDocumentVersionCheckArgs,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { type VersionRepository } from "../VersionRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLVersionRepository implements VersionRepository {
    async addTipTapDoc({ versionId }: { versionId: string }) {
        return graphqlClientKoyeb.request(
            AddTipTapDocDocument,
            { versionId },
            await AccessHeadersHolder.getHeaders(),
        );
    }
    async createMany({ documentVersions }: CreateManyVersionsInput) {
        return graphqlClientKoyeb.request(
            CreateManyVersionsDocument,
            { set: { documentVersions } },
            await AccessHeadersHolder.getHeaders(),
        );
    }
    getLastByDocumentURL = async (
        documentUrl: string,
    ): Promise<GetLastVersionByDocumentUrlQuery> =>
        graphqlClientKoyeb.request(
            GetLastVersionByDocumentUrlDocument,
            { documentUrl },
            await AccessHeadersHolder.getHeaders(),
        );
    async update({ id, patch }: { id: string; patch: DocumentVersionPatch }) {
        return graphqlClientKoyeb.request(
            UpdateVersionDocument,
            { versionId: id, patch },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async updateDocumentData(args: { id: string }) {
        return graphqlClientKoyeb.request(
            UpdateDocumentDataDocument,
            { versionId: args.id },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async updateVersionCheck({
        id,
        set,
    }: MutationUpdateDocumentVersionCheckArgs) {
        return graphqlClientKoyeb.request(
            UpdateVersionCheckDocument,
            { id, set },
            await AccessHeadersHolder.getHeaders(),
        );
    }
}
