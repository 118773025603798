import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { EyeOff, Trash } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    DocumentActivationStatus,
    GetDocumentByUrlQuery,
    UpdateDocumentV2Document,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import BaseItem from "../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import ActionWithConfirmation from "../../../../components/Modal/ActionWithConfirmation";

const SetRelevanceButton = ({
    getDocumentQuery,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
}) => {
    const { t } = useTranslation("DocumentScreen");
    const updateDocument = useGraphQLMutation(UpdateDocumentV2Document);

    const document = getDocumentQuery.data.documentByUrl;
    const navigate = useNavigate();
    const onSetRelevance = (activationStatus: DocumentActivationStatus) => {
        document &&
            updateDocument.mutate(
                {
                    documentId: document.id,
                    patch: { activationStatus },
                },
                {
                    onSuccess: () => {
                        getDocumentQuery.refetch();
                        navigate("..");
                    },
                },
            );
    };

    if (document?.activationStatus === DocumentActivationStatus.NotRelevant) {
        return (
            <BaseItem
                IconLeft={() => <Trash className="text-red-600 w-4 h-4" />}
                onClick={() => {
                    onSetRelevance(DocumentActivationStatus.Relevant);
                }}
            >
                {t("set_as_relevant")}
            </BaseItem>
        );
    } else {
        return (
            <ActionWithConfirmation
                title={t("not_relevant_title")}
                confirmButton={
                    <ButtonRegular
                        variant={ButtonType.FLAT_DANGER}
                        loading={updateDocument.isPending}
                        onClick={() =>
                            onSetRelevance(DocumentActivationStatus.NotRelevant)
                        }
                    >
                        {t("not_relevant_confirmation")}
                    </ButtonRegular>
                }
                TriggerButton={({ onClick }) => (
                    <BaseItem
                        IconLeft={() => (
                            <EyeOff className="text-secondary w-4 h-4" />
                        )}
                        onClick={onClick}
                    >
                        {t("not_relevant")}
                    </BaseItem>
                )}
            >
                <span>{t("not_relevant_description_line1")}</span>
                <br />
                <b>{t("not_relevant_description_line2")}</b>
            </ActionWithConfirmation>
        );
    }
};

export default SetRelevanceButton;
