import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Icon } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";

export type AssignButtonSize = "sm" | "md";
export type AssignedAvatar = {
    id: string;
    label: string;
    color?: string;
    imgUrl?: string;
};

const assignButtonVariants = cva(
    "flex items-center rounded-full  border p-0.5 box-border min-w-max",
    {
        variants: {
            size: {
                sm: "h-[26px]",
                md: "h-[30px]",
            },

            active: {
                true: "border-primary",
                false: "border-tertiary",
            },

            loading: {
                true: "cursor-wait pointer-events-none",
            },
            disabled: {
                true: "bg-primary",
                false: "bg-tertiary",
            },
        },
        /*
         * loading props must be flagged with "!" as a loading button
         * is also disabled and the disabled selector is more specific
         */

        defaultVariants: {
            size: "md",
        },
    },
);

interface AssignButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof assignButtonVariants> {
    size?: AssignButtonSize;
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    avatars: AssignedAvatar[];
    asChild?: boolean;
}

type AssignButtonComponentType = ForwardRefExoticComponent<
    AssignButtonProps & RefAttributes<HTMLButtonElement>
>;

const Plus = ({ size }: { size: AssignButtonSize }) => {
    return (
        <Box
            w={size === "md" ? "6" : "5"}
            h={size === "md" ? "6" : "5"}
            className="bg-primary rounded-full border border-secondary flex items-center justify-center"
        >
            <Icon name="plus" size="sm" className="text-secondary" />
        </Box>
    );
};

const Ellipsis = ({ size, n }: { size: AssignButtonSize; n: number }) => {
    return (
        <Box
            w={size === "md" ? "6" : "5"}
            h={size === "md" ? "6" : "5"}
            className="bg-secondary rounded-full flex items-center justify-center whitespace-nowrap"
        >
            <Text
                variant={size === "sm" ? "body-sm-bold" : "body-bold"}
                color="primary"
            >
                +{n}
            </Text>
        </Box>
    );
};

const AssignButton = forwardRef<HTMLButtonElement, AssignButtonProps>(
    (
        {
            active,
            className,
            loading,
            size = "md",
            placeholder,
            avatars,
            label,
            disabled,
            asChild,
            ...props
        },
        ref,
    ) => {
        const showPlus = !avatars?.length;
        const showAvatarEllipsis = avatars?.length > 5;
        const Comp = asChild ? Slot : "button";
        const text =
            avatars && avatars.length > 1
                ? `${avatars.length} ${label}`
                : avatars && avatars.length === 1
                  ? avatars[0].label
                  : placeholder;
        return (
            <Comp
                className={cn(
                    assignButtonVariants({
                        size,
                        active,
                        loading,
                        disabled,
                        className,
                    }),
                    "hover:bg-neutral-50",
                )}
                ref={ref}
                {...props}
                disabled={loading || disabled}
            >
                {showPlus && <Plus size={size} />}
                {!!avatars?.length && (
                    <FlexRow>
                        {avatars
                            ?.slice(0, showAvatarEllipsis ? 4 : avatars.length)
                            .map((user) => (
                                <Box
                                    className="rounded-full border relative -mr-[7px] last:mr-0 overflow-hidden"
                                    key={user.id}
                                >
                                    <Avatar
                                        hexColor={user.color}
                                        imageUrl={user.imgUrl}
                                        letter={user.label[0]}
                                        size={size}
                                        variant="circle"
                                    />
                                </Box>
                            ))}
                        {showAvatarEllipsis && (
                            <Box className="rounded-full border relative -mr-[7px] last:mr-0 overflow-hidden">
                                <Ellipsis size={size} n={avatars.length - 4} />
                            </Box>
                        )}
                    </FlexRow>
                )}
                <Text
                    variant={size === "sm" ? "body-sm-bold" : "body-bold"}
                    color="primary"
                    px="1.5"
                >
                    {text}
                </Text>
            </Comp>
        );
    },
) as AssignButtonComponentType;

export { AssignButton };
