import Loading from "@app/components/Loading";
import { useS3FilesManagement } from "@app/hooks/createFileHook";
import { FileIcon } from "@app/shared/components/FileIcon";
import { NewS3File } from "@app/usecases/FileUseCases";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import DropZone from "@design-system/Inputs/DropZone";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import * as Popover from "@radix-ui/react-popover";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";

export const UploadTab = ({
    newS3Files,
    setNewS3Files,
}: {
    newS3Files: NewS3File[];
    setNewS3Files: Dispatch<SetStateAction<NewS3File[]>>;
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { uploadFilesToS3, isLoading } = useS3FilesManagement();
    const [openPopoverInd, setOpenPopoverInd] = useState<number | null>(null);
    const [nameInput, setNameInput] = useState<string>("");

    const handleDropFiles = async (files: File[]) => {
        const newS3Files = await uploadFilesToS3(files);
        setNewS3Files((prev) => [...prev, ...newS3Files]);
    };
    const handleDeleteClick = (idx: number) => {
        return () => {
            setNewS3Files((prev) => prev.filter((_, i) => i !== idx));
        };
    };
    const handleOnOpenChange = (idx: number) => {
        return (open: boolean) => {
            if (open) {
                setNameInput("");
                setOpenPopoverInd(idx);
            } else {
                setOpenPopoverInd(null);
            }
        };
    };

    const handleTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setNameInput(event.target.value);
    };

    const handleNameClick = (idx: number) => {
        return () => {
            setOpenPopoverInd(idx);
        };
    };

    const handleNameSave = (idx: number) => {
        return () => {
            setNewS3Files(
                newS3Files.map((file, i) =>
                    i === idx ? { ...file, name: nameInput } : file,
                ),
            );
            setOpenPopoverInd(null);
        };
    };

    return (
        <FlexCol gap="5" className="overflow-scroll" h="full">
            <DropZone
                buttonText={t("dropzone_button")}
                multiple
                onChange={handleDropFiles}
                size={newS3Files.length === 0 ? "lg" : "md"}
            >
                <Text as="span">{t(keys.dropzone_title)}</Text>
            </DropZone>
            {isLoading && <Loading size="small" />}
            {newS3Files.map((file, idx) => (
                <FlexRow alignItems="center" gap="6" key={file.name}>
                    <FlexRow gap="3" alignItems="center">
                        <FileIcon mimeType={file.mimeType} />
                        <Popover.Root
                            open={openPopoverInd === idx}
                            onOpenChange={handleOnOpenChange(idx)}
                        >
                            <Popover.Trigger>
                                <FlexRow
                                    className="hover:bg-neutral-100 rounded-lg cursor-pointer p-1"
                                    onClick={handleNameClick(idx)}
                                >
                                    <Text>{file.name}</Text>
                                </FlexRow>
                            </Popover.Trigger>
                            <Popover.Content
                                side="bottom"
                                align="start"
                                asChild
                            >
                                <FlexRow
                                    p="2"
                                    gap="2"
                                    alignItems="start"
                                    justifyContent="center"
                                    h="9"
                                    className="rounded-xl shadow-md bg-white w-[350px]"
                                >
                                    <Textfield
                                        value={nameInput}
                                        onChange={handleTextInputChange}
                                        type="text"
                                    />
                                    <Button
                                        onClick={handleNameSave(idx)}
                                        size="xs"
                                        className="rounded-[20px] absolute right-3 bottom-2.5 h-[24px] w-[24px]"
                                    >
                                        <Icon
                                            name="arrowUp"
                                            className="w-3 text-white"
                                        ></Icon>
                                    </Button>
                                </FlexRow>
                            </Popover.Content>
                        </Popover.Root>
                    </FlexRow>
                    <Box w="fit">
                        <Button
                            onClick={handleDeleteClick(idx)}
                            size="sm"
                            variant="outlined"
                        >
                            <Button.Icon name="trash" />
                        </Button>
                    </Box>
                    {idx !== newS3Files.length - 1 && <hr />}
                </FlexRow>
            ))}
        </FlexCol>
    );
};
