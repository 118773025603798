import i18next from "i18next";

const enResources = {
    modal_title: "Period settings",
    modal_subtitle: "Activate periods you need for your reporting.",
    save_button: "Save",
    sidebar_title: "Years",
    period_box_title_year: "Full year",
    period_box_title_quarter: "Quarter",
    period_box_title_month: "Monthly",
    month_January: "January",
    month_February: "February",
    month_March: "March",
    month_April: "April",
    month_May: "May",
    month_June: "June",
    month_July: "July",
    month_August: "August",
    month_September: "September",
    month_October: "October",
    month_November: "November",
    month_December: "December",
    add_period_success: "Period added successfully",
    add_period_error: "An error occurred while adding the period",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    modal_title: "Paramètres de période",
    modal_subtitle:
        "Activez les périodes dont vous avez besoin pour votre reporting.",
    save_button: "Enregistrer",
    sidebar_title: "Années",
    period_box_title_year: "Année complète",
    period_box_title_quarter: "Trimestre",
    period_box_title_month: "Mensuel",
    month_January: "Janvier",
    month_February: "Février",
    month_March: "Mars",
    month_April: "Avril",
    month_May: "Mai",
    month_June: "Juin",
    month_July: "Juillet",
    month_August: "Août",
    month_September: "Septembre",
    month_October: "Octobre",
    month_November: "Novembre",
    month_December: "Décembre",
    add_period_success: "Période ajoutée avec succès",
    add_period_error: "Une erreur s'est produite lors de l'ajout de la période",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;

export const RESOURCE_NAME = "PeriodManagementModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
