import { Search } from "iconoir-react";
import { useTranslation } from "react-i18next";
import "./i18n";

const DropdownItemSearch = ({
    searchFilterString,
    setSearchFilterString,
}: {
    searchFilterString: string;
    setSearchFilterString: (v: string) => void;
}) => {
    const { t } = useTranslation("DropdownItem");
    return (
        <div className="rounded-lg p-1 border border-secondary focus-within:border-green h-[20px] box-content flex space-x-1 items-center">
            <div className="p-0.5">
                <Search className="w-3 h-3" />
            </div>
            <input
                className="w-full border-0 p-0 focus:outline-none focus:ring-0"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    e.stopPropagation()
                }
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    e.stopPropagation()
                }
                value={searchFilterString}
                onChange={(e) => {
                    setSearchFilterString(e.target.value);
                }}
                placeholder={t("search")}
                onClick={(e) => e.stopPropagation()}
            />
        </div>
    );
};

export default DropdownItemSearch;
