import { Modal } from "@design-system/Modal";
import "../../../i18n";

import { Icon } from "@design-system/Icon";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";

import { Button } from "@design-system/Inputs/Button";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";

import { AssistantStream } from "openai/lib/AssistantStream";
import { TextDelta } from "openai/resources/beta/threads/messages";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { isAiChatModalOpenAtom } from "./ctx";
import { useAskAi } from "./data";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import {
    Message,
    annotateLastMessage,
    appendMessage,
    appendToLastMessage,
} from "./services";

export const AskAIModal = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [isOpen, setIsOpen] = useAtom(isAiChatModalOpenAtom);
    const [messages, setMessages] = useState<Message[]>([]);
    const [question, setQuestion] = useState<string>("");
    const [threadId, setThreadId] = useState<string | undefined>(undefined);
    const [inputDisabled, setInputDisabled] = useState<boolean>(false);
    const { createThread, sendMessage } = useAskAi();

    // create a new threadID when modal is opened
    useEffect(() => {
        if (!isOpen) return;
        createThread().then((threadId) => {
            setThreadId(threadId);
        });
    }, [isOpen]);

    const handleTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setQuestion(event.target.value);
    };

    const handleSendQuestionClick = async () => {
        if (!threadId) return;
        setQuestion("");
        appendMessage({ role: "user", content: question }, setMessages);
        setInputDisabled(true);
        const stream = await sendMessage(question, threadId);
        if (!stream) return;
        handleReadableStream(stream);
    };

    const handledTextFieldOnKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (event.key === "Enter") {
            handleSendQuestionClick();
        }
    };

    const handleRunCompleted = () => {
        setInputDisabled(false);
    };

    const handleTextCreated = () => {
        appendMessage({ role: "assistant", content: "" }, setMessages);
    };

    const handleTextDelta = (delta: TextDelta) => {
        if (delta.value != null) {
            appendToLastMessage(delta.value, setMessages);
        }
        if (delta.annotations != null) {
            // For now, when nestor tries to quote one of our files, just remove that quote
            // In the future, we might want to precisely define which files can be quoted and redirect to these files
            annotateLastMessage(delta.annotations, setMessages);
        }
    };

    const handleReadableStream = (stream: AssistantStream) => {
        stream.on("textCreated", handleTextCreated);
        stream.on("textDelta", handleTextDelta);
        stream.on("event", (event) => {
            if (event.event === "thread.run.completed") handleRunCompleted();
        });
    };

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <Modal.Overlay className="py-5">
                <Modal.Content className="w-[860px]">
                    <Modal.Header className="py-4 px-5">
                        <FlexRow justifyContent="center" w="full" gap="2">
                            <Text variant="body-bold" color="brand">
                                {t(keys.ask_ai)}
                            </Text>
                            <Icon
                                name="stars"
                                className="text-beavrGreen"
                                size="md"
                            ></Icon>
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body className="h-[550px] p-5 flex-col bg-secondary border-t border-tertiary overflow-scroll scrollbar-hide">
                        <FlexCol gap="4" w="full" alignItems="end">
                            {messages.map((msg) => (
                                <FlexRow key={msg.content}>
                                    {msg.role === "user" && (
                                        <FlexRow
                                            px="3"
                                            py="3"
                                            alignItems="center"
                                            justifyContent="end"
                                            className="rounded-b-[16px] rounded-tl-[16px] border border-tertiary bg-primary shadow-sm max-w-[250px]"
                                        >
                                            <Text variant="body">
                                                {msg.content}
                                            </Text>
                                        </FlexRow>
                                    )}
                                    {msg.role === "assistant" && (
                                        <div className="text-sm break-words whitespace-pre-wrap">
                                            <ReactMarkdown
                                                className="markdown text-base font-regular"
                                                children={msg.content}
                                                remarkPlugins={[remarkGfm]}
                                            />
                                        </div>
                                    )}
                                </FlexRow>
                            ))}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer className="bg-secondary pt-0 flex align-middle justify-between">
                        <FlexRow w="full">
                            <Textfield
                                value={question}
                                onChange={handleTextInputChange}
                                onKeyDown={handledTextFieldOnKeyDown}
                                placeholder={t(keys.ask_me_anything)}
                                type="text"
                                className="w-full"
                                disabled={!threadId || inputDisabled}
                            />
                            <Button
                                onClick={handleSendQuestionClick}
                                size="sm"
                                className="rounded-[120px] relative right-8 top-3 h-[32px] w-[32px]"
                            >
                                <Icon
                                    name="arrowUp"
                                    className="text-white"
                                    size="sm"
                                ></Icon>
                            </Button>
                        </FlexRow>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
