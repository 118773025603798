import Alert from "@app/components/Alert";
import ButtonRegular from "@app/components/Button/ButtonRegular";
import { ButtonType } from "@app/components/Button/ButtonShared";
import InputSimple from "@app/components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "@app/components/Modal/CenterModal";
import { useSetToast } from "@app/components/Toast";
import { Tooltip } from "@app/components/Tooltip";
import { User } from "@generated/client/graphql";
import { MessageText } from "iconoir-react";
import { useAtom } from "jotai";
import { Info } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TipTap, { useReminderTextEditor } from "../../../../components/TipTap";
import { openReminderModalAtom } from "../../context";
import { useFilteredUrl } from "../../hooks";
import "./i18n";
import { useSendReminders } from "../../data";

interface IProps {
    users: User[];
}

const SendReminderModal = ({ users }: IProps) => {
    const { t } = useTranslation("ReportingReminderModal");
    const [open, setOpen] = useAtom(openReminderModalAtom);
    const [subject, setSubject] = useState<string>(t("default_subject"));

    const editor = useReminderTextEditor("reporting");

    const { mutate, isPending } = useSendReminders();
    const { setToastSuccess, setToastError } = useSetToast();

    const handleSendTest = () => {
        subject && handleSend(true);
    };
    const handleSendReal = () => {
        subject && handleSend(false);
    };

    const filteredUrl = useFilteredUrl();
    const handleSend = async (isTest: boolean) => {
        mutate(
            {
                subject: subject,
                text: editor?.getText() || "",
                html: editor?.getHTML() || "",
                sendToIds: users.map((user) => user.id),
                test: isTest,
                url: filteredUrl,
            },
            {
                onError: () => {
                    setToastError(
                        isTest
                            ? t("send_test_error")
                            : t("send_reminder_error"),
                    );
                },
                onSuccess: () => {
                    setToastSuccess(
                        isTest
                            ? t("send_test_success", {
                                  count: users.length,
                              })
                            : t("send_reminder_success", {
                                  count: users.length,
                              }),
                    );
                    !isTest && setOpen(false);
                },
            },
        );
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen}>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonRegular
                            loading={isPending}
                            variant={ButtonType.OUTLINED}
                            text={t("send_test")}
                            onClick={handleSendTest}
                        />
                        <ButtonRegular
                            loading={isPending}
                            text={t("send_reminder")}
                            onClick={handleSendReal}
                            disabled={!subject || !editor?.getText()}
                        />
                    </>
                }
            >
                <InputSimple
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    title={t("subject")}
                />
                <TipTap editor={editor} />

                <Alert
                    title={
                        <div className="flex items-center space-x-1">
                            <div>
                                {t("alert_title", { count: users.length })}
                            </div>
                            <Tooltip
                                tooltipContent={users.map((user) => (
                                    <div className="text-white">
                                        {user.firstName} {user.lastName} -
                                        {user.email}
                                    </div>
                                ))}
                            >
                                <Info className="w-3 h-3" />
                            </Tooltip>
                        </div>
                    }
                    subtitle={t("alert_subtitle")}
                    Icon={MessageText}
                />

                <div className="text-sm font-bold">{t("test_before_send")}</div>
            </CenterModalContent>
        </CenterModal>
    );
};

export default SendReminderModal;
