import { useQueryClient } from "@tanstack/react-query";
import { Trash } from "iconoir-react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetSustainabilityCenterDocument,
    ScFaq,
    SustainabilityCenter,
    UpdateScFaqsDocument,
} from "../../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../../components/Button/ButtonSmall";

import {
    CenterModal,
    CenterModalContent,
} from "../../../../../../components/Modal/CenterModal";
import ItemsListEditor, {
    selectedIndexAtom,
} from "../../../../../../components/SustainabilityCenter/ItemsLitEditor";
import { useSetToast } from "../../../../../../components/Toast";
import { orderSort } from "../../../../../../lib/utils";

import TextAreaInput from "../../../../../../components/inputs_v2/TextArea";
import "./i18n";

export default function EditFaqsModal({
    sustainabilityCenter,
    open,
    setOpen,
}: {
    sustainabilityCenter: SustainabilityCenter | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterEditFaqsModal");
    const { setToastSuccess, setToastError } = useSetToast();
    const client = useQueryClient();
    const { mutate: updateSustainabilityCenter } =
        useGraphQLMutation(UpdateScFaqsDocument);

    const [faqs, setFaqs] = useState<ScFaq[]>(
        orderSort(sustainabilityCenter?.scFaqs || []),
    );
    const [displayedFaq, setDisplayedFaq] = useState<ScFaq | undefined>(
        undefined,
    );
    const [selectedIdx, setSelectedIdx] = useAtom(selectedIndexAtom);

    useEffect(() => {
        setFaqs(orderSort(sustainabilityCenter?.scFaqs || []));
        setDisplayedFaq(
            sustainabilityCenter?.scFaqs
                ? sustainabilityCenter?.scFaqs[selectedIdx]
                : undefined,
        );
    }, [sustainabilityCenter?.scFaqs]);

    useEffect(() => {
        setDisplayedFaq(faqs[selectedIdx]);
    }, [faqs, selectedIdx]);

    const addFaq = () => {
        setFaqs([
            ...faqs,
            {
                question: "",
                answer: "",
                order: faqs.length,
            },
        ] as ScFaq[]);
        setSelectedIdx(faqs.length);
    };

    const deleteFaq = () => {
        setFaqs(
            faqs
                .filter((_, index) => index !== selectedIdx)
                .map((elt, idx) => {
                    return { ...elt, order: idx };
                }),
        );
        setSelectedIdx(0);
    };

    const setQuestion = (question: string | undefined) => {
        setFaqs(
            faqs.map((elt, idx) => {
                return idx === selectedIdx
                    ? { ...elt, question: question || "" }
                    : elt;
            }),
        );
    };

    const setAnswer = (answer: string | undefined) => {
        setFaqs(
            faqs.map((elt, idx) => {
                return idx === selectedIdx
                    ? { ...elt, answer: answer || "" }
                    : elt;
            }),
        );
    };

    const handleSave = () => {
        if (!sustainabilityCenter) return;
        const updatePPInput = {
            id: sustainabilityCenter.id,
            inputs:
                faqs?.map((elt) => {
                    return {
                        question: elt.question,
                        answer: elt.answer,
                        order: elt.order,
                    };
                }) || [],
        };
        updateSustainabilityCenter(updatePPInput, {
            onSuccess: () => {
                setToastSuccess(t("update_success"));
                client.invalidateQueries({
                    queryKey: useGraphQL.getKey(
                        GetSustainabilityCenterDocument,
                    ),
                });
                setOpen(false);
            },
            onError: () => {
                setToastError(t("update_error"));
            },
        });
    };

    return (
        <>
            <CenterModal open={open} onOpenChange={setOpen} modal={false}>
                <CenterModalContent
                    title={t("title")}
                    actions={
                        <>
                            <ButtonLarge
                                text={t("save")}
                                variant={ButtonType.ELEVATED}
                                onClick={() => {
                                    handleSave();
                                    setOpen(false);
                                }}
                            />
                        </>
                    }
                >
                    <ItemsListEditor
                        items={faqs}
                        setItems={setFaqs}
                        addItem={addFaq}
                        itemType={t("faq")}
                        getItemTitle={(publicFaq: ScFaq) =>
                            "Question " + (publicFaq.order + 1).toString() || ""
                        }
                    >
                        <div className="flex min-h-[380px] items-start w-[820px]">
                            {faqs?.length > 0 && (
                                <div className="flex flex-1 p-5 flex-col items-start gap-6">
                                    <div className="flex justify-between items-start self-stretch">
                                        <div className="font-bold text-lg flex-1">
                                            {"Question " +
                                                (
                                                    (displayedFaq?.order || 0) +
                                                    1
                                                ).toString()}
                                        </div>
                                        <ButtonSmall
                                            IconRight={Trash}
                                            variant={ButtonType.OUTLINED}
                                            onClick={deleteFaq}
                                        />
                                    </div>
                                    <div className="w-full gap-4 flex flex-col">
                                        <TextAreaInput
                                            placeholder={t(
                                                "faq_question_input_placeholder",
                                            )}
                                            text={displayedFaq?.question || ""}
                                            setText={setQuestion}
                                        ></TextAreaInput>
                                        <TextAreaInput
                                            placeholder={t(
                                                "faq_answer_input_placeholder",
                                            )}
                                            text={displayedFaq?.answer}
                                            setText={setAnswer}
                                            className="min-h-20"
                                        ></TextAreaInput>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ItemsListEditor>
                </CenterModalContent>
            </CenterModal>
        </>
    );
}
