import { isHandleWithCrumb, makeAbsoluteUrl } from "@app/routing/utils";
import { Breadcrumb as BreadCrumbBase } from "@design-system/Navigation/Breadcrumb";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { useAtomValue } from "jotai";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatches } from "react-router-dom";
import { breadcrumbAtom } from "./ctx";

export const Breadcrumb = () => {
    const matches = useMatches();
    const { pathname: currentPathname } = useLocation();
    const breadcrumbState = useAtomValue(breadcrumbAtom);
    const { t } = useTranslation("Navbar");

    const crumbs = useMemo(() => {
        return matches
            .filter(({ handle }) => isHandleWithCrumb(handle))
            .map(({ handle, pathname }) => {
                // necessary check for typescript
                if (!isHandleWithCrumb(handle)) {
                    throw new Error("Handle is not defined.");
                }

                const { crumb: Crumb, page } = handle;
                const { name, list = [] } = breadcrumbState[page] ?? {};

                const pageName = name ?? t(page);

                const to = makeAbsoluteUrl(pathname);
                const preventScrollReset = shouldPreventScrollReset(
                    currentPathname,
                    pathname,
                );

                const formattedList = list.map(({ name, param }) => ({
                    name: name ?? param,
                    to: makeAbsoluteUrl(computePathname(pathname, param)),
                }));

                return {
                    Crumb,
                    pageName,
                    to,
                    preventScrollReset,
                    list: formattedList,
                };
            });
    }, [matches, t, currentPathname, breadcrumbState]);

    const hide = crumbs.length < 2;
    if (hide) return null;

    return (
        <BreadCrumbBase>
            <BreadCrumbBase.List>
                {crumbs.map(({ Crumb, pageName, list, ...props }, idx) =>
                    list.length > 0 ? (
                        <Fragment key={pageName}>
                            <BreadCrumbBase.SelectableItem
                                label={pageName}
                                value={props.to}
                            >
                                {list.map((opt) => (
                                    <BreadCrumbBase.SelectableItemOption
                                        asChild
                                        key={opt.to}
                                        value={opt.to}
                                    >
                                        <Ellipsis
                                            asChild
                                            className="inline-block max-w-[200px]"
                                            hasTooltip
                                        >
                                            <Crumb
                                                {...(opt.to === props.to
                                                    ? props
                                                    : { to: opt.to })}
                                            >
                                                {opt.name}
                                            </Crumb>
                                        </Ellipsis>
                                    </BreadCrumbBase.SelectableItemOption>
                                ))}
                            </BreadCrumbBase.SelectableItem>
                            {idx < crumbs.length - 1 && (
                                <BreadCrumbBase.Separator />
                            )}
                        </Fragment>
                    ) : (
                        <Fragment key={pageName}>
                            <BreadCrumbBase.Item>
                                <BreadCrumbBase.Link asChild>
                                    <Ellipsis
                                        asChild
                                        className="inline-block max-w-[200px]"
                                        hasTooltip
                                    >
                                        <Crumb {...props}>{pageName}</Crumb>
                                    </Ellipsis>
                                </BreadCrumbBase.Link>
                            </BreadCrumbBase.Item>
                            {idx < crumbs.length - 1 && (
                                <BreadCrumbBase.Separator />
                            )}
                        </Fragment>
                    ),
                )}
            </BreadCrumbBase.List>
        </BreadCrumbBase>
    );
};

function shouldPreventScrollReset(currentPathname: string, pathname: string) {
    if (currentPathname.endsWith("/"))
        currentPathname = currentPathname.slice(0, -1);
    if (pathname.endsWith("/")) pathname = pathname.slice(0, -1);

    return currentPathname === pathname;
}

function computePathname(pathname: string, param: string) {
    if (pathname.endsWith("/")) {
        pathname = pathname.slice(0, -1);
    }

    const pathArray = pathname.split("/");
    pathArray.pop();

    return pathArray.join("/") + "/" + param;
}
