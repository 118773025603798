import { Box, BoxProps } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";

import React, {
    forwardRef,
    ForwardRefExoticComponent,
    PropsWithChildren,
} from "react";

const Block = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{ className?: string }>
>((props, ref) => {
    return <FlexCol px="4" py="3" gap="3" {...props} ref={ref} />;
});

const BlockHeader = Box;

const BlockBody: typeof Box = forwardRef<HTMLDivElement, BoxProps>(
    ({ className, ...props }, ref) => {
        return (
            <Box
                h="fit"
                className={cn(
                    "bg-white rounded border border-secondary",
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);

const SectionSkeletonBodyRow = () => {
    return (
        <FlexRow justifyContent="between" h="11" w="full" className="border-b-[1px] border-neutral-150">
            <FlexCol px="4" py="3">
                <Skeleton h="2"  br="lg" my="1.5" className="w-[340px]"/>
                <Skeleton h="2"  br="lg" my="1.5" className="w-[164px]"/>
            </FlexCol>
            <Box display="inline-flex" alignItems="center">
                <Skeleton h="2" br="lg" className="w-[48px]"/>
            </Box>
        </FlexRow>
    )
}

export const SectionSkeleton = () => {
    return (
        <FlexCol 
            br="lg"
            px="4"
            py="3"
            w="full"
            className="bg-tertiary rounded-lg box-border"
            >
                <FlexCol px="1" mb="3">
                    <Skeleton h="2" br="lg" my="1.5" className="w-[340px] from-neutral-300 to-[rgba(212, 212, 212, 0.30)]" />
                    <Skeleton h="2" br="lg" my="1.5" className="w-[164px] from-neutral-300 to-[rgba(212, 212, 212, 0.30)]"/>
                </FlexCol>

                <FlexCol br="lg" className="bg-white border-x-[1px] border-t-[1px] border-neutral-150">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <SectionSkeletonBodyRow key={i} />
                    ))}
                </FlexCol>
        </FlexCol>

        
    )
}

type SectionProps = PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & {
    isLoading?: boolean;
};
type SectionComponent = ForwardRefExoticComponent<SectionProps> & {
    Block: typeof Block;
    BlockHeader: typeof BlockHeader;
    BlockBody: typeof BlockBody;
};

export const Section: SectionComponent = forwardRef<
    HTMLDivElement,
    SectionProps
>(({ className, children, isLoading}, ref) => {
    return (isLoading
        ? <SectionSkeleton />
        : <FlexCol
            className={cn(
                "border border-tertiary bg-tertiary rounded-lg box-border overflow-hidden",
                className,
            )}
            ref={ref}
        >
            {children}
        </FlexCol>
    );
}) as SectionComponent;

Section.Block = Block;
Section.BlockHeader = BlockHeader;
Section.BlockBody = BlockBody;

export default Section;
