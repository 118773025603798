import i18next from "@app/i18n";

i18next.addResourceBundle("en", "PreviewTemplateModal", {
    cancel: "Cancel",
    template: "Template",
    create_version: "Create version",
});

i18next.addResourceBundle("fr", "PreviewTemplateModal", {
    cancel: "Annuler",
    template: "Modèle",
    create_version: "Créer une version",
});
