import {
    getPeriodsOfYear,
    getYearsOfPeriods,
} from "@app/pages/Reporting/services";
import { NULL_TOKEN } from "@app/shared/utils/NULL_TOKEN";
import {
    BooleanEnum,
    CsrdDatapointPatch,
    CsrdDatapointStatus,
    CsrdDatapointType,
    CsrdDatapointValue,
    DatapointValue,
    GetCsrdPillarDataQuery,
    GetUsersQuery,
    MaterialityPatch,
    MaterialityStatus,
    ValidationStatus,
    type ReportingPeriod,
} from "@generated/client/graphql";
import { Table } from "@tanstack/react-table";
import {
    type CsrdDatapoint,
    type CsrdPillarDatapoint,
    type Pillar,
} from "../types";
import { FileWithLinkedItems, PillarDisclosureRequirement } from "./types";

export function isNumDatapoint(datapoint: CsrdDatapoint): boolean {
    return (
        datapoint.type === CsrdDatapointType.Reporting &&
        ["FLOAT", "INTEGER", "NUMBER"].includes(datapoint.unit?.type || "")
    );
}

export function isStrDatapoint(datapoint: CsrdDatapoint): boolean {
    return (
        datapoint.type === CsrdDatapointType.Reporting &&
        !["FLOAT", "INTEGER", "NUMBER", "TABLE"].includes(
            datapoint.unit?.type || "",
        )
    );
}

export function isNarrativeDatapoint(datapoint: CsrdDatapoint): boolean {
    return datapoint.type === CsrdDatapointType.Narrative;
}

export function isBoolDatapoint(datapoint: CsrdDatapoint): boolean {
    return datapoint.type === CsrdDatapointType.Seminarrative;
}

export function isTableDatapoint(datapoint: CsrdDatapoint): boolean {
    return (
        datapoint.type === CsrdDatapointType.Reporting &&
        ["TABLE"].includes(datapoint.unit?.type || "")
    );
}

/**
 * Updates the ownerIds of a pillar's esrss, disclosureRequirements, and datapoints, based on the provided where clause.
 * @param pillar The pillar to update
 * @param ownerId The new ownerId
 * @param users The list of users to enrich the owner data with.
 * @param where.esrsId The esrsId to update. If not provided, all disclosure esrs will be updated, with the constraints of the othere where clauses.
 * @param where.disclosureRequirementId The disclosureRequirementId to update. If not provided, all disclosure requirements will be updated with the constraints of the othere where clauses.
 * @param where.datapointId The datapointId to update. If not provided, all datapoints will be updated with the constraints of the othere where clauses.
 */
function updateOwnerIds(
    pillar: Pillar,
    ownerId: string | null,
    users: GetUsersQuery["users"],
    {
        esrsId,
        disclosureRequirementId,
        datapointId,
    }: {
        esrsId?: string;
        disclosureRequirementId?: string;
        datapointId?: string;
    },
) {
    const updatedEsrss = pillar.esrss.map((esrss) => {
        const { cmsId, disclosureRequirements } = esrss;

        if (esrsId && cmsId !== esrsId) {
            return esrss;
        }

        const updatedDisclosureRequirements = disclosureRequirements.map(
            (disclosureRequirement) => {
                if (
                    disclosureRequirementId &&
                    disclosureRequirement.id !== disclosureRequirementId
                ) {
                    return disclosureRequirement;
                }

                const { datapoints } = disclosureRequirement;
                const updatedDatapoints = datapoints.map((datapoint) => {
                    if (datapointId && datapoint.id !== datapointId) {
                        return datapoint;
                    }

                    const user = users.find((user) => user.id === ownerId);

                    return {
                        ...datapoint,
                        ownerId: ownerId,
                        owner: user
                            ? {
                                  id: user.id,
                                  firstName: user.firstName,
                                  lastName: user.lastName,
                              }
                            : null,
                    };
                });
                const datarequirementsOwnerIds = updatedDatapoints.reduce(
                    (acc, dp) => {
                        if (dp.ownerId && !acc.includes(dp.ownerId)) {
                            acc.push(dp.ownerId);
                        }
                        return acc;
                    },
                    [] as string[],
                );
                return {
                    ...disclosureRequirement,
                    datapoints: updatedDatapoints,
                    ownerIds: datarequirementsOwnerIds,
                };
            },
        );
        const esrsOwnerIds = updatedDisclosureRequirements.reduce((acc, dr) => {
            dr.ownerIds.forEach((ownerId) => {
                if (!acc.includes(ownerId)) {
                    acc.push(ownerId);
                }
            });
            return acc;
        }, [] as string[]);
        return {
            ...esrss,
            ownerIds: esrsOwnerIds,
            disclosureRequirements: updatedDisclosureRequirements,
        };
    });

    return {
        ...pillar,
        esrss: updatedEsrss,
    };
}

export function makeUpdateEsrsOwnerIdsCacheCb(
    getEsrsId: () => string,
    getUsers: () => GetUsersQuery | undefined,
) {
    return function (patch: string[], previousQuery: GetCsrdPillarDataQuery) {
        const esrsId = getEsrsId();
        const usersQuery = getUsers();

        if (!esrsId) throw new Error("No ersId provided");
        const ownerId = patch[0] ?? null;

        const previousPillar = previousQuery.pillar;
        if (!previousPillar) throw new Error("No previous pillar found");

        if (!usersQuery) throw new Error("No users query found");
        const users = ownerId ? usersQuery.users : []; // so we avoid iterating over all users if we don't need to

        // Optimistically update the new value
        const updatedPillar = updateOwnerIds(previousPillar, ownerId, users, {
            esrsId,
        });

        const updatedQuery: GetCsrdPillarDataQuery = {
            ...previousQuery,
            pillar: updatedPillar,
        };
        return updatedQuery;
    };
}

export function makeUpdateDisclosureRequirementOwnerIdsCacheCb(
    getDisclosureRequirementId: () => string,
    getUsers: () => GetUsersQuery | undefined,
) {
    return function (patch: string[], previousQuery: GetCsrdPillarDataQuery) {
        const disclosureRequirementId = getDisclosureRequirementId();
        const usersQuery = getUsers();

        if (!disclosureRequirementId)
            throw new Error("No disclosureRequirementId provided");
        const ownerId = patch[0] ?? null;

        const previousPillar = previousQuery.pillar;
        if (!previousPillar) throw new Error("No previous pillar found");

        if (!usersQuery) throw new Error("No users query found");
        const users = ownerId ? usersQuery.users : []; // so we avoid iterating over all users if we don't need to

        // Optimistically update the new value
        const updatedPillar = updateOwnerIds(previousPillar, ownerId, users, {
            disclosureRequirementId,
        });

        const updatedQuery: GetCsrdPillarDataQuery = {
            ...previousQuery,
            pillar: updatedPillar,
        };
        return updatedQuery;
    };
}

function computeStatus(datapoint: CsrdDatapoint): CsrdDatapointStatus {
    if (datapoint.materiality.status === MaterialityStatus.NotMaterial) {
        return CsrdDatapointStatus.NotMaterial;
    }
    if (datapoint.validationStatus === ValidationStatus.Validated) {
        return CsrdDatapointStatus.Validated;
    }
    if (
        datapoint.value?.number != undefined ||
        datapoint.value?.string != undefined ||
        datapoint.value?.boolean != undefined ||
        datapoint.value?.table != undefined ||
        datapoint.value?.noData === BooleanEnum.True ||
        !!datapoint?.comment
    ) {
        return CsrdDatapointStatus.InProgress;
    } else {
        return CsrdDatapointStatus.NotStarted;
    }
}

function buildNewdatapoint(
    datapoint: CsrdPillarDatapoint,
    patch: CsrdDatapointPatch,
) {
    const updatedDatapoint = {
        ...datapoint,
        ...(patch.status
            ? {
                  validationStatus: patch.status as ValidationStatus,
              }
            : {}),
        ...(patch?.value?.number !== undefined
            ? {
                  value: {
                      ...datapoint.value,
                      number: patch.value.number,
                  },
              }
            : {}),
        ...(patch?.value?.string !== undefined
            ? {
                  value: {
                      ...datapoint.value,
                      string: patch.value.string,
                  },
              }
            : {}),
        ...(patch?.value?.boolean !== undefined
            ? {
                  value: {
                      ...datapoint.value,
                      boolean: patch.value.boolean,
                  },
              }
            : {}),
        ...(patch?.value?.table !== undefined
            ? {
                  value: {
                      ...datapoint.value,
                      table: patch.value.table?.map((pair) => ({
                          key: pair.key,
                          value: { string: pair.value.string ?? "" },
                      })),
                  },
              }
            : {}),
        ...(patch?.value?.noData !== undefined
            ? {
                  value: {
                      ...datapoint.value,
                      noData: patch.value.noData,
                  },
              }
            : {}),
        ...(patch?.comment !== undefined
            ? {
                  comment: patch.comment,
              }
            : {}),
        ...(patch?.materialityPatch?.status !== undefined
            ? { materiality: { status: patch?.materialityPatch?.status } }
            : {}),
        ...(!!patch?.disconnectEvidenceFileIds?.length
            ? {
                  evidenceFiles: datapoint?.evidenceFiles?.filter(
                      (file) =>
                          !patch?.disconnectEvidenceFileIds?.includes(file.id),
                  ),
              }
            : {}),
    };

    return {
        ...updatedDatapoint,
        status: computeStatus(updatedDatapoint),
    };
}

export function makeUpdateDatapointCache(
    getDatapointId: () => string,
    getUsers: () => GetUsersQuery | undefined,
) {
    return function (
        { patch }: { patch: CsrdDatapointPatch },
        previousQuery: GetCsrdPillarDataQuery,
    ) {
        const datapointId = getDatapointId();
        let updatedPillar = previousQuery.pillar;
        if (!updatedPillar) throw new Error("No previous pillar found");

        if (patch.ownerId !== undefined) {
            const usersQuery = getUsers();
            if (!usersQuery) throw new Error("No users query found");
            const users = patch.ownerId ? usersQuery.users : [];
            updatedPillar = updateOwnerIds(
                updatedPillar,
                patch.ownerId === NULL_TOKEN ? null : patch.ownerId,
                users,
                {
                    datapointId,
                },
            );
        }

        const updatedEsrss = updatedPillar.esrss.map((esrs) => {
            const esrsDatapointIds = esrs.disclosureRequirements.flatMap((dr) =>
                dr.datapoints.map((dp) => dp.id),
            );
            if (!esrsDatapointIds.includes(datapointId)) {
                return esrs;
            }

            const updatedDisclosureRequirements =
                esrs.disclosureRequirements.map((disclosureRequirement) => {
                    if (
                        !disclosureRequirement.datapoints.find(
                            (dp) => dp.id === datapointId,
                        )
                    ) {
                        return disclosureRequirement;
                    }
                    const { datapoints } = disclosureRequirement;
                    const updatedDatapoints = datapoints.map((datapoint) => {
                        if (datapoint.id !== datapointId) {
                            return datapoint;
                        }

                        return buildNewdatapoint(datapoint, patch);
                    });
                    return {
                        ...disclosureRequirement,
                        datapoints: updatedDatapoints,
                    };
                });
            return {
                ...esrs,
                disclosureRequirements: updatedDisclosureRequirements,
            };
        });

        const updatedQuery: GetCsrdPillarDataQuery = {
            ...previousQuery,
            pillar: {
                ...updatedPillar,
                esrss: updatedEsrss,
            },
        };
        return updatedQuery;
    };
}

export function updatedDisclosureRequirementMateriality(
    disclosureRequirement: PillarDisclosureRequirement,
    materialityPatch: MaterialityPatch,
) {
    const updatedDatapoints = disclosureRequirement.datapoints.map(
        (datapoint) => {
            const temp = {
                ...datapoint,
                materiality: {
                    ...datapoint.materiality,
                    status: materialityPatch.status,
                },
            };
            return {
                ...temp,
                status: computeStatus(temp),
            };
        },
    );

    return {
        ...disclosureRequirement,
        datapoints: updatedDatapoints,
        materiality: materialityPatch,
    };
}

export function makeDisclosureRequirementUpdateMaterialityCacheCb(
    getDisclosureRequirementId: () => string | undefined,
) {
    return function (
        materialityPatch: MaterialityPatch,
        previousQuery: GetCsrdPillarDataQuery,
    ) {
        const disclosureRequirementId = getDisclosureRequirementId();
        if (!disclosureRequirementId) {
            throw new Error("No disclosureRequirementId provided");
        }

        const updatedPillar = previousQuery.pillar;
        if (!updatedPillar) throw new Error("No previous pillar found");

        const updatedEsrss = updatedPillar.esrss.map((esrs) => {
            const updatedDisclosureRequirements =
                esrs.disclosureRequirements.map((disclosureRequirement) => {
                    if (disclosureRequirement.id !== disclosureRequirementId) {
                        return disclosureRequirement;
                    }
                    return updatedDisclosureRequirementMateriality(
                        disclosureRequirement,
                        materialityPatch,
                    );
                });

            return {
                ...esrs,
                disclosureRequirements: updatedDisclosureRequirements,
            };
        });

        const updatedQuery: GetCsrdPillarDataQuery = {
            ...previousQuery,
            pillar: {
                ...updatedPillar,
                esrss: updatedEsrss,
            },
        };
        return updatedQuery;
    };
}

export function makeAssociatedReportingDatapointGroups<
    T extends {
        id: string;
        datapointGroup?:
            | {
                  entity?: { id: string } | null | undefined;
              }
            | null
            | undefined;
    },
>(associatedReportingDatapoints: T[]) {
    const list: (T["datapointGroup"] & { datapointId: string })[] =
        associatedReportingDatapoints.map(({ datapointGroup, id }) => ({
            ...datapointGroup,
            datapointId: id,
        }));
    const byEntity = list.reduce(
        (acc, datapointGroup) => {
            if (!datapointGroup.entity?.id) return acc;
            if (!acc[datapointGroup.entity.id]) {
                acc[datapointGroup.entity.id] = [];
            }

            acc[datapointGroup.entity.id].push(datapointGroup);
            return acc;
        },
        {} as Record<string, typeof list>,
    );

    return { list, byEntity };
}

export function makeAssociatedReportingEntities<
    T extends Record<
        string,
        {
            entity?: { id: string } | null | undefined;
        }[]
    >,
>(associatedReportingDatapointGroupsByEntity: T) {
    const entities = Object.keys(associatedReportingDatapointGroupsByEntity)
        .map(
            (entityId) =>
                associatedReportingDatapointGroupsByEntity[entityId][0]?.entity,
        )
        .filter(Boolean);

    if (entities.some((entity) => !entity)) {
        throw new Error("Associated reporting entity not found");
    }

    return entities as Exclude<
        T[keyof T][number]["entity"],
        null | undefined
    >[];
}

export function reportingDatapointValueToCsrdDatapointValue(
    reportingValue: DatapointValue,
): CsrdDatapointValue {
    const { boolean: bool, float, noData, ...value } = reportingValue;

    return {
        ...value,
        boolean: booleanToBooleanEnum(bool),
        noData: booleanToBooleanEnum(noData),
        number: float,
    };
}

function booleanToBooleanEnum(
    bool?: boolean | null | undefined,
): BooleanEnum | undefined {
    return bool == null
        ? undefined
        : bool
          ? BooleanEnum.True
          : BooleanEnum.False;
}

export function getPeriodsOfAssociatedReportingDatapoints<
    T extends {
        datapointId: string;
        period: ReportingPeriod;
    },
>(
    entityId: string | undefined,
    associatedReportingDatapointGroups: { byEntity: Record<string, T[]> },
) {
    if (!entityId) {
        return { years: [], periodsByYear: {} };
    }

    const datapointGroupsOfEntity =
        associatedReportingDatapointGroups.byEntity[entityId];

    const years = getYearsOfPeriods(datapointGroupsOfEntity);

    const periodsByYear = years.reduce(
        (acc, year) => {
            const periods = getPeriodsOfYear(datapointGroupsOfEntity, year).map(
                (datapointGroup) => ({
                    reportingDatapointId: datapointGroup.datapointId,
                    period: datapointGroup.period,
                }),
            );
            acc[year] = periods;
            return acc;
        },
        {} as Record<
            number,
            { reportingDatapointId: string; period: ReportingPeriod }[]
        >,
    );

    return { years, periodsByYear };
}

export function getReportingDatapointOfCurrentYear<
    T extends { reportingDatapointId: string; period: ReportingPeriod },
>(periodsByYear: Record<number, T[]>) {
    const currYear = new Date().getFullYear();

    return periodsByYear[currYear]?.find(({ period: { month, quarter } }) => {
        if (month) return false;
        if (quarter) return false;
        return true;
    })?.reportingDatapointId;
}

export function getToAttachFileIds(
    suggestedFilesTable: Table<FileWithLinkedItems>,
    evidenceFilesTable: Table<FileWithLinkedItems>,
) {
    return suggestedFilesTable
        .getSelectedRowModel()
        .flatRows.map((r) => r.original.id)
        .concat(
            evidenceFilesTable
                .getSelectedRowModel()
                .flatRows.map((r) => r.original.id),
        );
}

export function getFilteredEvidenceFiles(
    suggestedFiles: FileWithLinkedItems[],
    evidenceFiles: FileWithLinkedItems[],
    attachedEvidenceFileIds: string[],
    searchString: string | undefined,
) {
    const suggestedFileIds = suggestedFiles.map((file) => file.id);

    return evidenceFiles.filter((file) => {
        const searchMatchesFileName =
            !searchString ||
            file.filename.toLocaleLowerCase().includes(searchString);
        const searchMatchesDocumentName =
            !searchString ||
            !file.documentVersions?.length ||
            file.documentVersions[0]?.document?.name
                .toLocaleLowerCase()
                .includes(searchString);
        return (
            (searchMatchesFileName || searchMatchesDocumentName) &&
            !suggestedFileIds.includes(file.id) &&
            !attachedEvidenceFileIds.includes(file.id)
        );
    });
}
