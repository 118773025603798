import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    GetTagsDocument,
    Tag,
    TagType,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import FilterTrigger from "../../components/triggers/Filter";
import TagDropdown from "./dropdown";
import "./i18n";

export const filterTagIdsAtom = atom<string[] | undefined>(undefined);
const TagsFilter = ({ type }: { type: TagType }) => {
    const [tagIds, setTagIds] = useAtom(filterTagIdsAtom);
    const { t } = useTranslation("TagsDropdown");

    const getTags = useGraphQL(GetTagsDocument, { type: type });
    useEffect(() => {
        setTagIds(undefined);
    }, []);
    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={tagIds ? () => setTagIds(undefined) : undefined}
                active={tagIds !== undefined}
                isHeader
            >
                {t("placeholder", {
                    context: !!tagIds && "active",
                    count: tagIds?.length,
                })}
            </FilterTrigger>
            <TagDropdown
                tagIds={tagIds}
                onTagIdsChange={(ids: string[]) =>
                    setTagIds(ids.length > 0 ? ids : undefined)
                }
                tags={getTags.data?.tags as Tag[] | undefined}
                type={type}
                hidePlus
            />
        </DropdownMenu>
    );
};

export default TagsFilter;
