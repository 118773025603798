import { normalizeString } from "@app/utils/string";
import SearchBar from "@design-system/Inputs/SearchBar";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Pencil } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetAllRequirementsForDocTableDocument,
    Requirement,
    UpdateDocumentRequirementLinkDocument,
} from "../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../QueryClientWithHeaders";
import { ReactComponent as GreenPlusIcon } from "../../../../../assets/green-plus.svg";
import { ReactComponent as RedCancelIcon } from "../../../../../assets/red-cancel.svg";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonXSmall from "../../../../../components/Button/ButtonXSmall";
import ThemeTabFilter from "../../../../../components/Filters/ThemeTabFilter";
import {
    CenterModal,
    CenterModalContent,
    CenterModalTrigger,
} from "../../../../../components/Modal/CenterModal";
import { orderSort } from "../../../../../lib/utils";
import { useDocument } from "../../../hooks";
import "./i18n";

const ChooseRequirementModal = ({
    documentId,
    requirements,
}: {
    documentId: string;
    requirements?: Requirement[];
}) => {
    const { t } = useTranslation("ChooseRequirementTableModal");
    const [themeId, setThemeId] = useState<string | undefined>(undefined);
    const [subthemeIds, setSubthemeIds] = useState<string[] | undefined>(
        undefined,
    );
    const [open, setOpen] = useState(false);
    const [activeRequirementIds, setActiveRequirementIds] = useState<string[]>(
        [],
    );
    const [searchString, setSearchString] = useState<string | undefined>();
    const { data } = useGraphQL(GetAllRequirementsForDocTableDocument);
    const { mutate, isPending } = useGraphQLMutation(
        UpdateDocumentRequirementLinkDocument,
    );
    const activeRequirements = data?.requirements.data.filter((r) =>
        activeRequirementIds.includes(r.id),
    );
    const inactiveRequirements = data?.requirements.data.filter((r) => {
        if (activeRequirementIds.includes(r.id)) {
            return false;
        }

        if (themeId !== undefined && r.subtheme?.themeId !== themeId) {
            return false;
        }

        if (
            subthemeIds !== undefined &&
            !subthemeIds.includes(r.subtheme?.id || "")
        ) {
            return false;
        }

        if (
            searchString !== undefined &&
            !normalizeString(r.name).includes(normalizeString(searchString))
        ) {
            return false;
        }

        return true;
    });
    const isDiff = useMemo(() => {
        const set1 = new Set(activeRequirementIds);
        const set2 = new Set(requirements?.map((r) => r.id) || []);
        return set1.size !== set2.size || [...set1].some((r) => !set2.has(r));
    }, [activeRequirementIds, requirements]);

    const addReqId = (id: string) => {
        setActiveRequirementIds([...activeRequirementIds, id]);
    };
    const removeReqId = (id: string) => {
        setActiveRequirementIds(activeRequirementIds.filter((r) => r !== id));
    };
    const { invalidateQuery } = useDocument();

    const handleSetRequirements = () => {
        isDiff &&
            mutate(
                {
                    documentId: documentId,
                    requirementIds: activeRequirementIds,
                },
                {
                    onSuccess: () => {
                        setOpen(false);
                        invalidateQuery();
                    },
                },
            );
    };
    useEffect(() => {
        setActiveRequirementIds(requirements?.map((r) => r.id) || []);
    }, [requirements]);

    return (
        <CenterModal open={open} onOpenChange={setOpen}>
            <CenterModalTrigger asChild>
                <ButtonXSmall variant={ButtonType.TONAL} IconRight={Pencil}>
                    {t("open_button")}
                </ButtonXSmall>
            </CenterModalTrigger>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                contentClassName="w-[80%]"
            >
                <div className=" text-error-500 ">{t("warning")}</div>
                <FlexRow
                    className="overflow-hidden"
                    justifyContent="between"
                    maxW="full"
                    gap="3"
                >
                    <Box w="3/4">
                        <ThemeTabFilter
                            themeId={themeId}
                            setThemeId={setThemeId}
                            subthemeIds={subthemeIds}
                            setSubthemeIds={setSubthemeIds}
                        />
                    </Box>
                    <SearchBar
                        className="w-1/4"
                        placeholder={t("search_placeholder")}
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                </FlexRow>
                <div className="flex w-full rounded-lg border border-tertiary">
                    <div className="w-1/2 space-y-3 px-3 pt-4 border-r border-tertiary">
                        <div className="text-lg font-bold">
                            {t("header_attached")}
                        </div>
                        <div className="space-y-1">
                            {orderSort(activeRequirements || [])?.map((r) => (
                                <div
                                    className="flex items-center p-1 space-x-2 cursor-pointer hover:bg-secondary"
                                    onClick={() => removeReqId(r.id)}
                                >
                                    <button>
                                        <RedCancelIcon className="flex-shrink-0" />
                                    </button>
                                    <div
                                        key={r.id}
                                        className="flex-shrink truncate"
                                    >
                                        {r.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-1/2 space-y-3 px-3 pt-4">
                        <div className="text-lg font-bold">
                            {t("header_not_attached")}
                        </div>
                        <div className="space-y-1 max-h-[300px] overflow-auto scrollbar-hide">
                            {orderSort(inactiveRequirements || []).map((r) => (
                                <div
                                    className="flex items-center p-1 space-x-2 cursor-pointer hover:bg-secondary"
                                    onClick={() => addReqId(r.id)}
                                >
                                    <button>
                                        <GreenPlusIcon className="flex-shrink-0" />
                                    </button>
                                    <div key={r.id} className="truncate">
                                        {r.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <ButtonRegular
                        disabled={!isDiff}
                        loading={isPending}
                        onClick={handleSetRequirements}
                    >
                        {t("set_requirements")}
                    </ButtonRegular>
                </div>
            </CenterModalContent>
        </CenterModal>
    );
};

export default ChooseRequirementModal;
