import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { CsrdRepository } from "@app/repositories/CsrdRepository";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import {
    GetCsrdDatapointDocument,
    GetCsrdDatapointsDocument,
    GetCsrdPillarDataDocument,
    GetCsrdPillarsDocument,
    GetCsrdSectionsAndStatsDocument,
    GetCsrdStatsDocument,
    UpdateCsrdDatapointDocument,
    UpdateCsrdDisclosureRequirementDocument,
    UpdateCsrdEsrsDocument,
    type CsrdDatapointPatch,
    type CsrdDatapointsFilter,
    type CsrdDisclosureRequirementPatch,
    type CsrdEsrsPatch,
    type GetCsrdDatapointQueryVariables,
    type GetCsrdDatapointsQueryVariables,
    type GetCsrdSectionsAndStatsQuery,
    type GetCsrdStatsQuery,
    type UpdateCsrdDatapointMutation,
    type UpdateCsrdDisclosureRequirementMutation,
} from "@generated/client/graphql";

export class GraphQLCsrdRepository implements CsrdRepository {
    getGlobalStats = async (): Promise<GetCsrdStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetCsrdStatsDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getSectionsAndStats = async (): Promise<GetCsrdSectionsAndStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetCsrdSectionsAndStatsDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getPillar = async (id: string, filter?: CsrdDatapointsFilter) => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarDataDocument,
            { pillarCmsId: id, filter: filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getPillars = async () => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarsDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoint = async (input: GetCsrdDatapointQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoints = async (input: GetCsrdDatapointsQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointsDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateDatapoint = async (input: {
        id: string;
        patch: CsrdDatapointPatch;
    }): Promise<UpdateCsrdDatapointMutation> => {
        return graphqlClientKoyeb.request(
            UpdateCsrdDatapointDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateDisclosureRequirement = async (input: {
        id: string;
        patch: CsrdDisclosureRequirementPatch;
    }): Promise<UpdateCsrdDisclosureRequirementMutation> => {
        return graphqlClientKoyeb.request(
            UpdateCsrdDisclosureRequirementDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateEsrs = async (id: string, patch: CsrdEsrsPatch) =>
        graphqlClientKoyeb.request(
            UpdateCsrdEsrsDocument,
            { id, patch },
            await AccessHeadersHolder.getHeaders(),
        );
}
