import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Requirement } from "../../../../../generated/client/graphql";
import UserSelect from "../../../../components/Dropdown/dropdowns/User";
import { GaugeCircular } from "../../../../components/Progress/ProgressCircular/GaugeCircular";
import CheckboxCell from "../../../../components/Table/CheckboxCell";
import Header from "../../../../components/Table/Header";
import CheckboxHeader from "../../../../components/Table/Header/Checkbox";
import ColorTag from "../../../../components/Tags/ColorTag";

import { FlexCol } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { ownerIdsFilterAtom } from "../../context";
import { useUpdateRequirement } from "../../hooks/data";
import "./i18n";
import UserMultiSelect from "@app/shared/components/UserMultiSelect";

const selectCol: ColumnDef<Requirement> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} text={`${row.index}`} />,
    enableSorting: false,
    enableHiding: false,
    meta: {
        colWidth: "7%",
    },
};

const nameCol: ColumnDef<Requirement> = {
    id: "name",
    accessorKey: "name",

    meta: {
        colWidth: "50%",
    },
    header: ({ column }) => {
        const { t } = useTranslation("RequirementTable");
        return <Header title={t("requirement")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <FlexCol gap="1">
                <Ellipsis asChild hasTooltip key={row.original.name}>
                    <Text variant="body-bold">{row.original.name}</Text>
                </Ellipsis>

                <Ellipsis
                    asChild
                    hasTooltip
                    className="line-clamp-2 text-wrap"
                    key={row.original.description}
                >
                    <Text variant="body-sm">{row.original.description}</Text>
                </Ellipsis>
            </FlexCol>
        );
    },
};

const subthemeCol: ColumnDef<Requirement> = {
    id: "subtheme",
    filterFn: "arrIncludesSome",
    accessorKey: "subtheme.id",
    meta: {
        colWidth: "20%",
    },
    header: ({ column }) => {
        const { t } = useTranslation("RequirementTable");
        return <Header title={t("subtheme")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <div className="text-sm font-regular">
                <ColorTag
                    color={row.original.subtheme?.theme?.color || "green"}
                    text={row.original.subtheme?.name || ""}
                />
            </div>
        );
    },
};

const themeCol: ColumnDef<Requirement> = {
    id: "theme",
    filterFn: "equals",
    accessorKey: "subtheme.themeId",
};

const ownerCol: ColumnDef<Requirement> = {
    id: "owner",
    filterFn: "arrIncludesSome",
    accessorKey: "ownerId",
    meta: {
        colWidth: "20%",
    },
    header: () => {
        const [ownerIdsFilter, setOwnerIdsFilter] = useAtom(ownerIdsFilterAtom);
        const handleSelectedOwnerIdsChange = (
            ids: (string | null)[] | undefined,
        ) => {
            if (!ids?.length) {
                setOwnerIdsFilter(undefined);
                return;
            }
            setOwnerIdsFilter(ids);
        };
        return (
            <UserMultiSelect
                isHeader={true}
                values={ownerIdsFilter}
                onValuesChange={handleSelectedOwnerIdsChange}
                includeNone={false}
            />
        );
    },
    cell: ({ row }) => {
        const { mutate } = useUpdateRequirement();
        const onChange = (userId: string | undefined) => {
            mutate(row.original.id, { ownerId: userId || null });
        };
        return (
            <UserSelect
                selectedUserId={row.original.ownerId || undefined}
                onChange={onChange}
            />
        );
    },
};

const tagNames: ColumnDef<Requirement> = {
    id: "tags",
    accessorFn: ({ frameworkSections }) =>
        frameworkSections?.map((fs) => fs.tagName),
    meta: {
        colWidth: "20%",
    },
    header: ({ column }) => {
        const { t } = useTranslation("RequirementTable");
        return <Header title={t("framework")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <div>
                {row.original.frameworkSections?.map((fs, idx) => (
                    <div key={idx} className="text-sm font-regular">
                        {fs.framework.name} - {fs.tagName}
                    </div>
                ))}
            </div>
        );
    },
};

const proofs: ColumnDef<Requirement> = {
    id: "proofs",
    accessorFn: ({ frameworkSections }) =>
        frameworkSections?.map((fs) => `${fs.framework.name} - ${fs.tagName}`),
    meta: {
        colWidth: "10%",
    },
    header: ({ column }) => {
        const { t } = useTranslation("RequirementTable");
        return <Header title={t("proofs")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <div className="flex space-x-3">
                <div>
                    {row.original.proofStat?.ok!}/
                    {row.original.proofStat?.total!}
                </div>
                {row.original.proofStat?.total! > 0 && (
                    <GaugeCircular
                        size={16}
                        progress={
                            (100 * row.original.proofStat?.ok!) /
                            row.original.proofStat?.total!
                        }
                    />
                )}
            </div>
        );
    },
};

export const nonRelevantRequirementsColumns = (): ColumnDef<Requirement>[] => {
    return [selectCol, nameCol, themeCol, subthemeCol, tagNames];
};

export const useColumns = (): ColumnDef<Requirement>[] => {
    return useMemo(
        () => [
            selectCol,
            nameCol,
            themeCol,
            subthemeCol,
            ownerCol,
            tagNames,
            proofs,
        ],
        [],
    );
};
