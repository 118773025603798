import i18next from "../../../i18n";

i18next.addResources("en", "UserMultiSelect", {
    placeholder: "Owner",
    placeholder_active: "Owner ({{count}})",
    searchbar_placeholder: "Search",
    no_user: "Unassigned",
});
i18next.addResources("fr", "UserMultiSelect", {
    placeholder: "Assigné à",
    placeholder_active: "Assigné à ({{count}})",
    searchbar_placeholder: "Recherche",
    no_user: "Non assigné",
});
