import { MessageText } from "iconoir-react";
import { ArrowDown } from "lucide-react";
import { ComponentType, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    SendSupplierQuestionnairesReminderDocument,
    Supplier,
    SupplierQuestionnaire,
} from "../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../QueryClientWithHeaders";
import Alert from "../../Alert";
import ButtonRegular from "../../Button/ButtonRegular";
import { ButtonType } from "../../Button/ButtonShared";
import InputSimple from "../../InputSimple";
import { CenterModal, CenterModalContent } from "../../Modal/CenterModal";
import Tiptap, { useReminderTextEditor } from "../../TipTap";
import { useSetToast } from "../../Toast";
import { Tooltip } from "../../Tooltip";
import "./i18n";

interface IProps {
    Trigger: ComponentType<Partial<{ onClick: (e: any) => void }>>;
    questionnaires: SupplierQuestionnaire[];
    suppliers: Supplier[];
    onSuccessCallback?: () => void;
}

const SupplierReminder = ({ questionnaires, suppliers, Trigger }: IProps) => {
    const { t } = useTranslation("SupplierCampaignReminder");
    const [open, setOpen] = useState(false);
    const [subject, setSubject] = useState<string>(t("default_subject"));
    const [senderName, setSenderName] = useState<string>();
    const [ccs, setCcs] = useState<string>();
    const ccsEmail = ccs ? ccs?.split(",").map((cc) => cc.trim()) : undefined;

    const editor = useReminderTextEditor("supplier");

    const sendSupplierQuestionnairesReminderMuation = useGraphQLMutation(
        SendSupplierQuestionnairesReminderDocument,
    );
    const { setToastSuccess, setToastError } = useSetToast();

    const targetUsers = suppliers.flatMap((supplier) => supplier.users);

    const handleTest = () => {
        subject &&
            editor?.getText() &&
            sendSupplierQuestionnairesReminderMuation.mutate(
                {
                    input: {
                        questionnaireIds: questionnaires.map(
                            (questionnaire) => questionnaire.id,
                        ),
                        subject: subject,
                        senderName: senderName || undefined,
                        ccs: !!ccsEmail?.length ? ccsEmail : undefined,
                        text: editor?.getText() || "",
                        html: editor?.getHTML() || "",
                        test: true,
                    },
                },
                {
                    onError: () => {
                        setToastError(t("send_test_error"));
                    },
                    onSuccess: () => {
                        setToastSuccess(t("send_test_success"));
                    },
                },
            );
    };
    const handleSave = async () => {
        subject &&
            editor?.getText() &&
            sendSupplierQuestionnairesReminderMuation.mutate(
                {
                    input: {
                        questionnaireIds: questionnaires.map(
                            (questionnaire) => questionnaire.id,
                        ),
                        subject: subject,
                        senderName: senderName || undefined,
                        ccs: !!ccsEmail?.length ? ccsEmail : undefined,
                        text: editor?.getText() || "",
                        html: editor?.getHTML() || "",
                        test: false,
                    },
                },
                {
                    onError: () => {
                        setToastError(t("send_reminder_error"));
                    },
                    onSuccess: () => {
                        setToastSuccess(
                            t("send_reminder_success", {
                                count: targetUsers.length,
                            }),
                        );
                    },
                },
            );
    };

    return (
        <>
            <Trigger
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }}
            />
            <CenterModal open={open} onOpenChange={setOpen} modal={true}>
                <CenterModalContent
                    title={t("title")}
                    subtitle={t("subtitle")}
                    actions={
                        <>
                            <ButtonRegular
                                variant={ButtonType.OUTLINED}
                                text={t("send_test")}
                                onClick={handleTest}
                                tooltipContent={t("send_test_tooltip")}
                            />
                            <ButtonRegular
                                text={t("send_reminder")}
                                onClick={handleSave}
                                disabled={!subject || !editor?.getText()}
                            />
                        </>
                    }
                >
                    <InputSimple
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        title={t("subject")}
                    />
                    <InputSimple
                        value={senderName}
                        onChange={(e) => setSenderName(e.target.value)}
                        title={t("sender_name")}
                    />
                    <InputSimple
                        value={ccs}
                        onChange={(e) => setCcs(e.target.value)}
                        title={t("email_ccs")}
                    />
                    <Tiptap editor={editor} supplier={true} />
                    <Alert
                        title={
                            <div className="flex items-center space-x-1">
                                <div>
                                    {t("alert_title", {
                                        count: suppliers.length,
                                    })}
                                </div>

                                <Tooltip
                                    tooltipContent={suppliers
                                        .map((supplier) =>
                                            supplier.users.map((user) => (
                                                <div className="text-white">
                                                    {supplier.name} -{" "}
                                                    {user?.firstName}{" "}
                                                    {user?.lastName} -{" "}
                                                    {user?.email}
                                                </div>
                                            )),
                                        )
                                        .flat()}
                                >
                                    <ArrowDown className="w-4 h-4" />
                                </Tooltip>
                            </div>
                        }
                        subtitle={t("alert_subtitle")}
                        Icon={MessageText}
                    />
                </CenterModalContent>
            </CenterModal>
        </>
    );
};

export default SupplierReminder;
