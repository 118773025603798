import signin from "@app/assets/image/signin.jpeg";
import { SignIn } from "@clerk/clerk-react";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";

export default function SignInPage() {
    return (
        <FlexRow className="h-screen">
            <FlexRow
                className="w-1/2"
                justifyContent="center"
                alignItems="center"
            >
                <SignIn
                    forceRedirectUrl={window.location.pathname}
                    appearance={{
                        elements: {
                            formButtonPrimary:
                                "bg-green text-white borber-none rounded-none font-bold outline-none focus:outline-none",
                        },
                    }}
                />
            </FlexRow>
            <Box
                className={`w-1/2 h-full bg-cover bg-center `}
                style={{ backgroundImage: `url('${signin}')` }}
            />
        </FlexRow>
    );
}
