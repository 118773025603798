import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { reportingKeys } from "@app/pages/Reporting/data";
import {
    CreateIndicatorDocument,
    Unit,
} from "../../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../../components/Toast";
import FloatInput from "../../../../../components/inputs_v2/Float";
import ThemeAndSubthemeInput from "../../../../../components/inputs_v2/Select/ThemeAndSubtheme";
import UnitInput from "../../../../../components/inputs_v2/Select/Unit";
import TextInput from "../../../../../components/inputs_v2/Text";
import TextAreaInput from "../../../../../components/inputs_v2/TextArea";
import "./i18n";

export const newIndicatorModalOpenAtom = atom<boolean>(false);

export default function CreateIndicatorModal() {
    const { t } = useTranslation("CreateIndicatorModal");

    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate, isPending } = useGraphQLMutation(CreateIndicatorDocument);
    const client = useQueryClient();
    const [open, setOpen] = useAtom(newIndicatorModalOpenAtom);

    const [uname, setUname] = useState<string>();
    const [order, setOrder] = useState<number>();
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [unit, setUnit] = useState<Unit>();
    const [options, setOptions] = useState<string[]>();
    const [themeId, setThemeId] = useState<string>();
    const [subthemeId, setSubthemeId] = useState<string>();

    const canSave =
        uname && name && description && unit && themeId && subthemeId;

    const handleSave = () => {
        canSave &&
            mutate(
                {
                    uname,
                    order,
                    name,
                    description,
                    unitId: unit.id,
                    options: options,
                    themeId,
                    subthemeId,
                },
                {
                    onSuccess: () => {
                        setToastSuccess(t("success"));
                        client.invalidateQueries({
                            queryKey: reportingKeys.indicators(),
                        });
                        setOpen(false);
                    },
                    onError: () => {
                        setToastError(t("error"));
                    },
                },
            );
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("create")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="space-y-4">
                    <TextInput
                        placeholder={t("uname")}
                        value={uname}
                        setValue={setUname}
                    />
                    <FloatInput
                        placeholder={t("order")}
                        value={order}
                        onBlurCallback={(n) => setOrder(n || undefined)}
                    />
                    <TextInput
                        placeholder={t("name")}
                        value={name}
                        setValue={setName}
                    />
                    <TextAreaInput
                        placeholder={t("description")}
                        text={description}
                        setText={setDescription}
                    />
                    <UnitInput
                        unitId={unit?.id || undefined}
                        onUnitChange={setUnit}
                    />
                    {(unit?.type === "MULTI_SELECT" ||
                        unit?.type === "SELECT") && (
                        <TextAreaInput
                            placeholder={t("options")}
                            text={options?.join("\n")}
                            setText={(t) =>
                                t && setOptions(t.split("\n").filter((o) => o))
                            }
                        />
                    )}
                    <ThemeAndSubthemeInput
                        themeId={themeId}
                        onThemeIdChange={setThemeId}
                        subthemeId={subthemeId}
                        onSubthemeIdChange={setSubthemeId}
                    />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
