import i18next from "../../../i18n";

i18next.addResourceBundle("en", "StatusTag", {
    textDone: "Done",
    textValidated: "Validated",
    textInProgress: "In progress",
    textAnswered: "Answered",
    textSuggestion: "Suggestion",
    textNotStarted: "Not started",
});

i18next.addResourceBundle("fr", "StatusTag", {
    textDone: "Fait",
    textValidated: "Validé",
    textInProgress: "En cours",
    textAnswered: "Répondu",
    textSuggestion: "Suggestion",
    textNotStarted: "Pas commencé",
});
