import { Clerk } from "@clerk/clerk-js";

type Maybe<T> = T | undefined | null;

export type BeavrHeaders = Headers | string[][] | Record<string, string>;

let _org_uname: Maybe<string> = undefined;
let _headers: BeavrHeaders = {};

let clerk = new Clerk(import.meta.env.VITE_CLERK_PUBLISHABLE_KEY);

export class AccessHeadersHolder {
    static presetHeaders(arg: Headers | string[][] | Record<string, string>) {
        _headers = arg;
    }

    static setOrgUname(org_uname: Maybe<string>) {
        _org_uname = org_uname;
    }

    static async getHeaders(): Promise<BeavrHeaders> {
        let headers: BeavrHeaders = { ..._headers };

        await clerk.load();
        const bearer = await clerk.session?.getToken();

        if (window === undefined) {
            return headers;
        }

        headers = { ...headers, pathname: window.location.pathname };

        if (bearer) {
            headers = headers = {
                ...headers,
                authorization: `Bearer ${bearer}`,
                pathname: window.location.pathname,
            };
        }

        if (_org_uname) {
            headers = { ...headers, "x-org-uname": _org_uname };
        }

        if (import.meta.env.VITE_REACT_APP_DEV_NO_AUTH === "true") {
            headers = { ...headers, "x-role-test": "admin" };
        }

        return headers;
    }
}
