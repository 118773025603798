import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { OrgType } from "../../../../../generated/client/graphql";
import SingleSelectDropdown from "../../components/dropdowns/SingleSelect";
import "./i18n";

const OrgTypeDropdown = ({
    type,
    onTypeChange,
    ...props
}: {
    type?: OrgType;
    onTypeChange: (type: OrgType | undefined) => void;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("OrgTypeDropdown");
    return (
        <SingleSelectDropdown
            disableSearch
            value={type}
            onValueChange={onTypeChange}
            values={[
                {
                    value: OrgType.Test,
                    node: t(OrgType.Test),
                },
                {
                    value: OrgType.Demo,
                    node: t(OrgType.Demo),
                },
                {
                    value: OrgType.Customer,
                    node: t(OrgType.Customer),
                },
                {
                    value: OrgType.CustomerChurned,
                    node: t(OrgType.CustomerChurned),
                },
                {
                    value: OrgType.Archived,
                    node: t(OrgType.Archived),
                },
            ]}
            {...props}
        />
    );
};

export default OrgTypeDropdown;
