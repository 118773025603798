import { useS3FilesManagement } from "@app/hooks/createFileHook";
import { newS3FileToCreateFileInput } from "@app/usecases/FileUseCases";
import { ArrowSeparateVertical, ArrowUnionVertical } from "iconoir-react";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFragment } from "../../../../../generated/client/fragment-masking";
import {
    AnswerDatapointFieldsFragmentDoc,
    DataRequestPatch,
    DataRequestStatus,
    Datapoint,
    DeleteFileDocument,
    DocumentAcceptance,
    IndicatorToFillFieldsFragmentDoc,
    RequestBlockFieldsFragment,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import Checkbox from "../../../../components/Checkbox";
import Banner, { BannerVariant } from "../../../../components/Document/Banner";
import ClickDragNDrop from "../../../../components/Document/ClickDragNDrop";
import { Variant } from "../../../../components/Tags/StatusTag";
import TinyStatusTag from "../../../../components/Tags/StatusTag/Tiny";
import FloatInput from "../../../../components/inputs_v2/Float";
import MultiSelectInput from "../../../../components/inputs_v2/MultiSelect";
import SelectInput from "../../../../components/inputs_v2/Select";
import BooleanInput from "../../../../components/inputs_v2/Select/Boolean";
import TextInput from "../../../../components/inputs_v2/Text";
import TextAreaInput from "../../../../components/inputs_v2/TextArea";
import { cn } from "../../../../lib/utils";
import CopyValue from "./CopyValue";
import "./i18n";

const useFileManagement = (
    request: RequestBlockFieldsFragment,
    updateRequest: (requestId: string, patch: DataRequestPatch) => void,
) => {
    const { uploadFilesToS3AsContributor } = useS3FilesManagement();
    const deleteMutation = useGraphQLMutation(DeleteFileDocument);
    const { user_id, questionnaire_id } = useParams();

    const onNewFilesUpload = async (files: File[]) => {
        if (!user_id && !questionnaire_id) return;

        const newS3Files = await uploadFilesToS3AsContributor(
            files,
            user_id,
            questionnaire_id,
        );
        updateRequest(request.id, {
            datapoint: {
                createFiles: newS3Files.map(newS3FileToCreateFileInput),
            },
        });
    };
    const onDeleteFile = (s3FileId: string) => {
        if (!user_id && !questionnaire_id) return;
        deleteMutation.mutate(
            {
                id: s3FileId,
            },
            {
                onSuccess: () => {
                    updateRequest(request.id, {
                        datapoint: {
                            removeS3FileIds: [s3FileId],
                        },
                    });
                },
            },
        );
    };

    return { onNewFilesUpload, onDeleteFile };
};

const useRequestChange = (
    request: RequestBlockFieldsFragment,
    updateRequest: (requestId: string, patch: DataRequestPatch) => void,
) => {
    const { onNewFilesUpload, onDeleteFile } = useFileManagement(
        request,
        updateRequest,
    );
    const onFloatChange = (valueFloat: number | null) => {
        updateRequest(request.id, {
            datapoint: {
                valueFloat: valueFloat,
            },
        });
    };
    const onStringChange = (valueString: string | null) => {
        updateRequest(request.id, {
            datapoint: {
                valueString: valueString,
            },
        });
    };
    const onBooleanChange = (valueBool: boolean | null) => {
        updateRequest(request.id, {
            datapoint: {
                valueBool: valueBool,
            },
        });
    };
    const onSelectChange = (optionId: string | null) => {
        updateRequest(request.id, {
            datapoint: {
                optionIds: optionId ? [optionId] : [],
            },
        });
    };
    const onMultiSelectChange = (optionIds: string[] | null) => {
        updateRequest(request.id, {
            datapoint: {
                optionIds,
            },
        });
    };
    const onCommentChange = (comment: string | null) => {
        updateRequest(request.id, {
            datapoint: {
                comment: comment,
            },
        });
    };
    const onNoDataChange = (valueNoData: boolean) => {
        updateRequest(request.id, {
            datapoint: {
                valueNoData: valueNoData,
                valueFloat: null,
                valueString: null,
                valueBool: null,
            },
        });
    };
    const onNotTheRightContributorChange = (
        valueContributorChange: boolean,
    ) => {
        updateRequest(request.id, {
            valueWrongPerson: valueContributorChange,
        });
    };
    const onReassignToChange = (reassign_to: string | null) => {
        updateRequest(request.id, {
            toTransferComment: reassign_to,
        });
    };

    return {
        onFloatChange,
        onStringChange,
        onBooleanChange,
        onSelectChange,
        onMultiSelectChange,
        onCommentChange,
        onNoDataChange,
        onNotTheRightContributorChange,
        onReassignToChange,
        onNewFilesUpload,
        onDeleteFile,
    };
};
const RequestBlock = (props: {
    hideWrongContributor?: boolean;
    request: RequestBlockFieldsFragment;
    updateRequest: (requestId: string, patch: DataRequestPatch) => void;
}) => {
    const { t } = useTranslation("RequestBlock");
    const request = props.request;
    const indicator = useFragment(
        IndicatorToFillFieldsFragmentDoc,
        request.indicator,
    );
    const answer = useFragment(
        AnswerDatapointFieldsFragmentDoc,
        request.answer,
    );
    const onRequestChange = useRequestChange(request, props.updateRequest);
    const {
        onFloatChange,
        onStringChange,
        onBooleanChange,
        onSelectChange,
        onMultiSelectChange,
        onCommentChange,
        onNoDataChange,
        onNotTheRightContributorChange,
        onReassignToChange,
        onNewFilesUpload,
        onDeleteFile,
    } = onRequestChange;

    const [open, setOpen] = useState(false);

    const inputDisabled =
        answer?.valueNoData || request.status === DataRequestStatus.Transfered;

    const error =
        (answer?.valueNoData && !answer?.comment) ||
        request.status === DataRequestStatus.Transfered ||
        false;
    const openOrIssue = open || error;

    const hideAlways =
        request.status === DataRequestStatus.Transfered || answer?.valueNoData;

    const showDragAndDropSingle =
        !hideAlways &&
        (indicator?.documentAcceptance === DocumentAcceptance.Optional ||
            indicator?.documentAcceptance === DocumentAcceptance.Required) &&
        (!answer?.s3Files || answer.s3Files.length === 0);
    const showDragAndDropMultiple =
        !hideAlways &&
        (indicator?.documentAcceptance === DocumentAcceptance.Several ||
            indicator?.documentAcceptance ===
                DocumentAcceptance.SeveralRequired);

    const showRequired =
        !hideAlways &&
        (answer?.s3Files?.length || 0) === 0 &&
        (indicator?.documentAcceptance === DocumentAcceptance.Required ||
            indicator?.documentAcceptance ===
                DocumentAcceptance.SeveralRequired);

    return (
        <div
            className={cn(
                "px-3 py-4 rounded-lg border border-tertiary bg-secondary ",
                "flex space-x-2",
                openOrIssue ? "items-start" : "items-center",
            )}
        >
            <div
                className={cn(
                    "flex-grow flex-shrink",
                    "flex space-x-3",
                    openOrIssue ? "items-start" : "items-center",
                )}
            >
                <ButtonLarge
                    tabIndex={-1} // do not focuss on shift tab
                    IconRight={
                        openOrIssue ? ArrowSeparateVertical : ArrowUnionVertical
                    }
                    variant={ButtonType.TONAL}
                    className="border-0 disabled:bg-transparent"
                    onClick={() => setOpen(!open)}
                    disabled={error}
                />

                <div className="space-y-3">
                    <div className=" text-lg font-bold">{indicator?.name}</div>
                    {openOrIssue && (
                        <>
                            <div className="text-small font-regular">
                                {indicator?.description}
                            </div>
                            <div className="flex flex-wrap -m-1">
                                {indicator?.tags.map((tag, idx) => (
                                    <div className="p-1" key={idx}>
                                        <TinyStatusTag variant={Variant.Base}>
                                            {tag.name}
                                        </TinyStatusTag>
                                    </div>
                                ))}
                            </div>
                            {request.lastPeriodDatapoint && (
                                <CopyValue
                                    lastDatapoint={
                                        request.lastPeriodDatapoint as Datapoint
                                    }
                                    {...onRequestChange}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            <div
                className="w-1/2 flex-shrink-0 flex-grow-0 space-y-3"
                onFocus={() => {
                    setOpen(true);
                }}
            >
                {answer?.valueNoData ? (
                    <></>
                ) : indicator?.unit?.type === "TEXT" ? (
                    <TextInput
                        value={
                            answer?.valueString === null
                                ? undefined
                                : answer?.valueString
                        }
                        success={
                            request.status === DataRequestStatus.Answered &&
                            answer?.valueString
                                ? true
                                : false
                        }
                        disabled={inputDisabled}
                        placeholder={indicator.unit?.name || ""}
                        onBlurCallback={onStringChange}
                    />
                ) : indicator?.unit?.type === "NUMBER" ||
                  indicator?.unit?.type === "INTEGER" ||
                  indicator?.unit?.type === "FLOAT" ? (
                    <FloatInput
                        value={
                            answer?.valueFloat === null
                                ? undefined
                                : answer?.valueFloat
                        }
                        placeholder={indicator?.unit?.name || ""}
                        onBlurCallback={onFloatChange}
                        success={
                            request.status === DataRequestStatus.Answered &&
                            answer?.valueFloat !== undefined
                                ? true
                                : false
                        }
                        disabled={inputDisabled}
                    />
                ) : indicator?.unit?.type === "BOOLEAN" ? (
                    <BooleanInput
                        value={
                            answer?.valueBool === null
                                ? undefined
                                : answer?.valueBool
                        }
                        onValueChange={onBooleanChange}
                        success={request.status === DataRequestStatus.Answered}
                    />
                ) : indicator?.unit?.type === "SELECT" ? (
                    <SelectInput
                        optionId={
                            answer?.options && answer.options.length > 0
                                ? answer?.options[0].id
                                : undefined
                        }
                        options={indicator?.options || undefined}
                        onValueChange={(optionId) => onSelectChange(optionId)}
                        disabled={inputDisabled}
                        success={
                            request.status === DataRequestStatus.Answered &&
                            answer?.options &&
                            answer.options.length > 0
                                ? true
                                : false
                        }
                    />
                ) : indicator?.unit?.type === "MULTI_SELECT" ? (
                    <MultiSelectInput
                        optionIds={
                            answer?.options?.map((o) => o.id) || undefined
                        }
                        options={indicator?.options || undefined}
                        onOptionIdsChange={(optionIds) =>
                            onMultiSelectChange(optionIds)
                        }
                        disabled={inputDisabled}
                        success={
                            request.status === DataRequestStatus.Answered &&
                            answer?.options &&
                            answer.options.length > 0
                                ? true
                                : false
                        }
                    />
                ) : (
                    <></>
                )}
                {openOrIssue && (
                    <>
                        <TextAreaInput
                            text={answer?.comment || undefined}
                            placeholder={t("comment")}
                            onBlurCallback={(comment) =>
                                onCommentChange(comment)
                            }
                            className="min-h-[20px]"
                            success={
                                request.status === DataRequestStatus.Answered &&
                                answer?.comment
                                    ? true
                                    : false
                            }
                            error={
                                answer?.valueNoData && !answer?.comment
                                    ? t("no_data_comment_error")
                                    : undefined
                            }
                            disabled={
                                request.status === DataRequestStatus.Transfered
                            }
                        />
                        {answer?.s3Files?.map((s3File, idx) => (
                            <Banner
                                key={idx}
                                name={s3File.filename}
                                docUrl={s3File.signedUrl || undefined}
                                subtitle=""
                                variant={BannerVariant.CHECK}
                                IconRight={Trash2}
                                onClick={() =>
                                    s3File?.id && onDeleteFile(s3File.id)
                                }
                            />
                        ))}
                        <div>
                            {showDragAndDropSingle && (
                                <ClickDragNDrop
                                    multiple={false}
                                    onFileChange={(f) => onNewFilesUpload([f])}
                                    required={showRequired}
                                />
                            )}
                            {showDragAndDropMultiple && (
                                <ClickDragNDrop
                                    multiple={true}
                                    onFilesChange={onNewFilesUpload}
                                    required={showRequired}
                                />
                            )}
                            {showRequired && (
                                <div className="text-error-500 text-sm">
                                    {t("document_required")}
                                </div>
                            )}
                        </div>
                        <div>
                            {!props.hideWrongContributor && (
                                <div className="py-0.5 flex space-x-2">
                                    <Checkbox
                                        checked={
                                            request.status ===
                                            DataRequestStatus.Transfered
                                                ? true
                                                : false
                                        }
                                        onCheckedChange={(checked) =>
                                            onNotTheRightContributorChange(
                                                checked,
                                            )
                                        }
                                    />
                                    <div className="text-sm font-regular">
                                        {t("not_the_right_contributor")}
                                    </div>
                                </div>
                            )}
                            {request.status ===
                                DataRequestStatus.Transfered && (
                                <TextInput
                                    value={request.reassignToStr || ""}
                                    placeholder={t("reassign_to")}
                                    onBlurCallback={(reassign_to) =>
                                        onReassignToChange(reassign_to)
                                    }
                                    success={
                                        request.reassignToStr ? true : false
                                    }
                                    error={
                                        !request.reassignToStr
                                            ? t(
                                                  "not_the_right_contributor_comment_error",
                                              )
                                            : undefined
                                    }
                                />
                            )}
                            <div className="py-0.5 flex space-x-2">
                                <Checkbox
                                    checked={answer?.valueNoData || false}
                                    onCheckedChange={(checked) =>
                                        onNoDataChange(checked)
                                    }
                                />
                                <div className="text-sm font-regular">
                                    {t("no_data")}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default RequestBlock;
