import { orderedYears } from "@app/usecases/ReportingUseCases";
import { type ReportingPeriod } from "@generated/client/graphql";

/**
 * Returns the years of the periods of the given datapoint groups.
 * The years are sorted in the order of the orderedYears array.
 * @param datapointGroupsOfEntity The datapoint groups to get the years from.
 * @returns The years of the periods of the given datapoint groups.
 */
export function getYearsOfPeriods<
    T extends {
        period: { year: number };
    },
>(datapointGroupsOfEntity: T[]) {
    return [
        ...new Set(datapointGroupsOfEntity.map((group) => group.period.year)),
    ].sort((a, b) => orderedYears.indexOf(a) - orderedYears.indexOf(b));
}

/**
 * Returns the periods of the given year of the given datapoint groups.
 * @param datapointGroupsOfEntity The datapoint groups to get the periods from.
 * @param year The year to get the periods of.
 * @returns The periods of the given year of the given datapoint groups.
 */
export function getPeriodsOfYear<
    T extends {
        period: { year: number };
    },
>(datapointGroupsOfEntity: T[], year: number) {
    return datapointGroupsOfEntity.filter(
        (group) => group.period.year === year,
    );
}

/**
 * Returns the displayed period of the given reporting period.
 * @param period The reporting period to get the displayed period from.
 * @param opts.yearLabel The label to display for the year period.
 * @param opts.monthLabel The label to display for the month period.
 * @returns The displayed period of the given reporting period.
 */
export function getDisplayedPeriod(
    period: ReportingPeriod,
    opts: { yearLabel: string; monthLabel: string },
) {
    const displayedPeriod = !period.quarter && !period.month
        ? opts.yearLabel
        : period.month
          ? opts.monthLabel
          : period.quarter;

          if (!displayedPeriod) {
              throw new Error("Period not found");
          }

            return displayedPeriod;
};
