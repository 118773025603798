import { getDisplayedPeriod } from "@app/pages/Reporting/services";
import { PrefillButton } from "@app/shared/components/PrefillButton";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import {
    useAssociatedReportingDatapointEntities,
    useAssociatedReportingDatapointPeriodsByEntity,
} from "../../data";
import { getReportingDatapointOfCurrentYear } from "../../services";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";

type PrefillModalProps = {
    datapointId: string;
    onConfirm: (reportingDatapointId: string) => void;
};

export const PrefillModal: FC<PrefillModalProps> = ({
    datapointId,
    onConfirm,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { associatedReportingDatapointEntities } =
        useAssociatedReportingDatapointEntities(datapointId);

    const [selectedEntityId, setSelectedEntityId] = useState<
        string | undefined
    >(
        associatedReportingDatapointEntities.find((entity) => entity.isDefault)
            ?.id,
    );

    const { associatedReportingDatapointPeriods } =
        useAssociatedReportingDatapointPeriodsByEntity(
            datapointId,
            selectedEntityId,
        );
    associatedReportingDatapointPeriods.periodsByYear;
    const [selectedReportingDatapointId, setSelectedReportingDatapointId] =
        useState<string | undefined>(
            getReportingDatapointOfCurrentYear(
                associatedReportingDatapointPeriods.periodsByYear,
            ),
        );

    const handleEntityChange = (value: string) => {
        setSelectedEntityId(value);
        setSelectedReportingDatapointId(undefined);
    };

    const handlePeriodChange = (value: string) => {
        setSelectedReportingDatapointId(value);
    };

    const handleClick = () => {
        if (!selectedReportingDatapointId) {
            return;
        }

        onConfirm(selectedReportingDatapointId);
    };

    return (
        <Modal>
            <Modal.Trigger asChild>
                <PrefillButton tooltipContent={t(keys.tooltip)} />
            </Modal.Trigger>
            <Modal.Overlay centerContent>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{t(keys.modal_title)}</Modal.Title>
                        <Modal.Subtitle>
                            {t(keys.modal_subtitle)}
                        </Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="2">
                            <FlexRow gap="4">
                                <Box w="40">
                                    <Text variant="body-lg">
                                        {t(keys.modal_entity_label)}
                                    </Text>
                                </Box>
                                <SingleSelect
                                    onValueChange={handleEntityChange}
                                    value={selectedEntityId}
                                >
                                    <SingleSelect.Trigger
                                        placeholder={t(
                                            keys.modal_entity_trigger_placeholder,
                                        )}
                                    />
                                    <SingleSelect.Content>
                                        {associatedReportingDatapointEntities.map(
                                            (entity) => (
                                                <SingleSelect.Option
                                                    key={entity.id}
                                                    label={entity.name}
                                                    value={entity.id}
                                                />
                                            ),
                                        )}
                                    </SingleSelect.Content>
                                </SingleSelect>
                            </FlexRow>
                            <FlexRow gap="4">
                                <Box w="40">
                                    <Text variant="body-lg">
                                        {t(keys.modal_period_label)}
                                    </Text>
                                </Box>
                                <SingleSelect
                                    onValueChange={handlePeriodChange}
                                    value={selectedReportingDatapointId}
                                >
                                    <SingleSelect.Trigger
                                        disabled={!selectedEntityId}
                                        placeholder={t(
                                            keys.modal_period_trigger_placeholder,
                                        )}
                                    />
                                    <SingleSelect.Content>
                                        {associatedReportingDatapointPeriods.years.map(
                                            (year) => (
                                                <SingleSelect.Group
                                                    key={year}
                                                    label={year.toString()}
                                                >
                                                    {associatedReportingDatapointPeriods.periodsByYear[
                                                        year
                                                    ].map(
                                                        ({
                                                            reportingDatapointId,
                                                            period,
                                                        }) => (
                                                            <SingleSelect.Option
                                                                key={
                                                                    reportingDatapointId
                                                                }
                                                                label={getDisplayedPeriod(
                                                                    period,
                                                                    {
                                                                        yearLabel:
                                                                            t(
                                                                                keys.modal_period_select_year,
                                                                            ),
                                                                        monthLabel:
                                                                            t(
                                                                                period.month ??
                                                                                    keys.modal_period_select_month,
                                                                            ),
                                                                    },
                                                                )}
                                                                value={
                                                                    reportingDatapointId
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </SingleSelect.Group>
                                            ),
                                        )}
                                    </SingleSelect.Content>
                                </SingleSelect>
                            </FlexRow>
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>Cancel</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            disabled={!selectedReportingDatapointId}
                            onClick={handleClick}
                        >
                            Confirm
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
