import {
    IndicatorEntityConfigPatch,
    UpdateIndicatorEntityConfigDocument,
    UpdateIndicatorEntityConfigMutation,
    UpdateManyIndicatorEntityConfigDocument,
    UpdateManyIndicatorEntityConfigMutation,
} from "../../../../generated/client/graphql";
import { AccessHeadersHolder } from "../../AccessHeadersHolder";
import { IndicatorEntityConfigRepository } from "../../IndicatorEntityConfigRepository";
import { graphqlClientKoyeb } from "../../clients";
export class GraphQLIndicatorEntityConfigRepository
    implements IndicatorEntityConfigRepository
{
    update = async (input: {
        indicatorEntityConfigId: string;
        set: IndicatorEntityConfigPatch;
    }): Promise<UpdateIndicatorEntityConfigMutation> => {
        return graphqlClientKoyeb.request(
            UpdateIndicatorEntityConfigDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateMany = async (input: {
        indicatorEntityConfigIds: string[];
        set: IndicatorEntityConfigPatch;
    }): Promise<UpdateManyIndicatorEntityConfigMutation> => {
        return graphqlClientKoyeb.request(
            UpdateManyIndicatorEntityConfigDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
