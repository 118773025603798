import i18next from "../../../i18n";

const enResources = {
    category: "Category",
    value: "Value",
    add_value: "Add value",
    add_category_placeholder: "Add category",
    add_value_placeholder: "Add value",
};

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    category: "Catégorie",
    value: "Valeur",
    add_value: "Ajouter une valeur",
    add_category_placeholder: "Ajouter une catégorie",
    add_value_placeholder: "Ajouter une valeur",
};

export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "DataValueTable";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
