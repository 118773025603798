import { useS3FilesManagement } from "@app/hooks/createFileHook";
import { updateFinalFileAtom } from "@app/store/versionStore";
import { newS3FileToCreateFileInput } from "@app/usecases/FileUseCases";
import { useAtomValue } from "jotai";
import { useRef } from "react";

export function useUpdateDocumentBar() {
    const inputRef = useRef<HTMLInputElement>(null);
    const { mutate: updateFinalFile, isPending: isUploading } =
        useAtomValue(updateFinalFileAtom);

    const handleClick = () => {
        inputRef.current?.click();
    };

    const { uploadFiletoS3 } = useS3FilesManagement();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        const newS3File = await uploadFiletoS3(file);
        const createFileInput = newS3FileToCreateFileInput(newS3File);

        if (!createFileInput) {
            return;
        }
        updateFinalFile({ createFileInput });
    };
    return {
        handleChange,
        handleClick,
        inputRef,
        isUploading,
    };
}
