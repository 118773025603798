import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetSustainabilityCenterDocument,
    SustainabilityCenter,
    UpdateSustainabilityCenterDocument,
} from "../../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../../components/Button/ButtonShared";
import InputSimple from "../../../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../../../components/Toast";
import TextAreaInput from "../../../../../../components/inputs_v2/TextArea";
import "./i18n";

export default function EditStrategyModal({
    sustainabilityCenter,
    open,
    setOpen,
}: {
    sustainabilityCenter: SustainabilityCenter | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterEditStrategyModal");
    const client = useQueryClient();
    const { mutate: updateSustainabilityCenter } = useGraphQLMutation(
        UpdateSustainabilityCenterDocument,
    );
    const { setToastSuccess, setToastError } = useSetToast();

    const [title, setTitle] = useState<string | undefined>(
        sustainabilityCenter?.strategyTitle || undefined,
    );
    const [description, setDescription] = useState<string | undefined>(
        sustainabilityCenter?.strategyDescription || undefined,
    );

    const isChanged =
        title !== sustainabilityCenter?.strategyTitle ||
        description !== sustainabilityCenter?.strategyDescription;

    const canSave = title && description && isChanged;

    useEffect(() => {
        setTitle(sustainabilityCenter?.strategyTitle || undefined);
        setDescription(sustainabilityCenter?.strategyDescription || undefined);
    }, [
        sustainabilityCenter?.strategyTitle,
        sustainabilityCenter?.strategyDescription,
    ]);

    const handleSave = () => {
        if (!sustainabilityCenter) return;
        const updatePPInput = {
            id: sustainabilityCenter.id,
            strategyTitle: title || "",
            strategyDescription: description || "",
        };
        updateSustainabilityCenter(updatePPInput, {
            onSuccess: () => {
                setToastSuccess(t("update_success"));
                client.invalidateQueries({
                    queryKey: useGraphQL.getKey(
                        GetSustainabilityCenterDocument,
                    ),
                });
                setOpen(false);
            },
            onError: () => {
                setToastError(t("update_error"));
            },
        });
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title="Edit strategy"
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                        />
                    </>
                }
            >
                <div className="w-[780px]">
                    <div className="w-full space-y-3">
                        <InputSimple
                            title={t("strategy_title")}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <TextAreaInput
                            placeholder={t("strategy_description")}
                            text={description}
                            setText={setDescription}
                            className="min-h-20"
                        />
                    </div>
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
