import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, MoreHoriz } from "iconoir-react";
import { useSetAtom } from "jotai";
import { Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
    DeleteUserDocument,
    User,
    UserRole,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import {
    ActionDropdownMenu,
    ActionDropdownMenuContent,
    ActionDropdownMenuItem,
    ActionDropdownMenuTrigger,
} from "../../../../components/ActionDropdown";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import ActionWithConfirmation from "../../../../components/Modal/ActionWithConfirmation";
import ActionItemDanger from "../../../../components/Table/ActionItemDanger";
import Header from "../../../../components/Table/Header";
import Avatar from "../../../../components/User/Avatar";
import { toUpdateUserIdAtom } from "../UpdateUserModal";
import "./i18n";

const HeaderTitle = ({ i18n_key }: { i18n_key: string }) => {
    const { t } = useTranslation("UsersTable");
    return <span>{t(i18n_key)}</span>;
};

const emailCol: ColumnDef<User> = {
    accessorKey: "email",
    header: ({ column }) => {
        const { t } = useTranslation("UsersTable");
        return <Header title={t("user")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <div className="flex space-x-2 items-center">
                <Avatar
                    initials={row.original.initials}
                    avatarUrl={row.original.avatar?.signedUrl}
                    size="large"
                />
                <div className="text-sm font-bold">
                    {row.original.firstName} {row.original.lastName}
                </div>
                <div className="text-xs font-regular">{row.original.email}</div>
            </div>
        );
    },
    meta: {
        colWidth: "30%",
    },
};

const roleCol: ColumnDef<User> = {
    accessorKey: "permission.role",
    header: ({ column }) => {
        const { t } = useTranslation("UsersTable");
        return <Header title={t("role")} column={column} />;
    },
    cell: ({ row }) => {
        const { t } = useTranslation("UsersTable");

        return (
            <div className="text-sm font-bold">
                {row.original.permission?.role &&
                    t(row.original.permission?.role)}
            </div>
        );
    },
};
const departmentCol: ColumnDef<User> = {
    accessorKey: "department",
    header: ({ column }) => {
        const { t } = useTranslation("UsersTable");
        return <Header title={t("department")} column={column} />;
    },
};

const statusCol: ColumnDef<User> = {
    id: "status",
    header: () => <HeaderTitle i18n_key="status" />,

    cell: ({ row }) => {
        const { t } = useTranslation("UsersTable");
        if (row.original?.permission?.role === UserRole.Contributor) {
            return <></>;
        } else if (row.original.clerkUser?.emailVerified) {
            return <span className="text-green-500">{t("verified")}</span>;
        } else {
            return (
                <span className="text-secondary text-sm font-regular">
                    {t("pending")}
                </span>
            );
        }
    },
};
const actionCol = (refetch: () => void): ColumnDef<User> => ({
    id: "actions",
    cell: ({ row }) => {
        const { t } = useTranslation("UsersTable");
        const setToUpdateUserId = useSetAtom(toUpdateUserIdAtom);
        const deleteMutation = useGraphQLMutation(DeleteUserDocument);
        return (
            <div className="flex justify-end">
                <ActionDropdownMenu modal={false}>
                    <ActionDropdownMenuTrigger asChild>
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            IconLeft={MoreHoriz}
                        />
                    </ActionDropdownMenuTrigger>
                    <ActionDropdownMenuContent>
                        <ActionDropdownMenuItem
                            onSelect={() => {
                                setToUpdateUserId(row.original.id);
                            }}
                            Icon={({ className }) => (
                                <EditPencil className={className} />
                            )}
                            text={t("edit_contributor")}
                        />

                        <ActionWithConfirmation
                            TriggerButton={({ onClick }) => (
                                <ActionItemDanger
                                    icon={Trash2}
                                    title={t("delete_user")}
                                    onClick={onClick}
                                />
                            )}
                            title={t("delete_title")}
                            confirmButton={
                                <ButtonRegular
                                    variant={ButtonType.FLAT_DANGER}
                                    onClick={() =>
                                        deleteMutation.mutate(
                                            {
                                                id: row.original.id,
                                            },
                                            { onSuccess: refetch },
                                        )
                                    }
                                    loading={deleteMutation.isPending}
                                >
                                    {t("delete_confirm_button")}
                                </ButtonRegular>
                            }
                        >
                            <div>
                                {t("delete_text_1", {
                                    supplier: row.original.email,
                                })}
                                <br />
                            </div>
                        </ActionWithConfirmation>
                    </ActionDropdownMenuContent>
                </ActionDropdownMenu>
            </div>
        );
    },
    meta: {
        colWidth: "60px",
    },
});
export const columns = (
    refetch: () => void,
    supplierMode?: boolean,
): ColumnDef<User>[] =>
    supplierMode
        ? [emailCol, actionCol(refetch)]
        : [emailCol, roleCol, departmentCol, statusCol, actionCol(refetch)];
