import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, MoreHoriz, Trash } from "iconoir-react";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Entity } from "../../../../../../generated/client/graphql";
import {
    ActionDropdownMenu,
    ActionDropdownMenuContent,
    ActionDropdownMenuItem,
    ActionDropdownMenuItemDanger,
    ActionDropdownMenuTrigger,
} from "../../../../../components/ActionDropdown";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../components/Button/ButtonSmall";
import Header from "../../../../../components/Table/Header";
import { useDeleteEntity } from "../../data";
import { toUpdateSiteAtom } from "../UpdateSiteModal";
import "./i18n";
import { useSetToast } from "@app/components/Toast";

export const columns: ColumnDef<Entity>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            const { t } = useTranslation("SitesTable");
            return <Header title={t("site")} column={column} />;
        },
        cell: ({ getValue }) => (
            <span className=" font-bold">{getValue() as string}</span>
        ),
        meta: {
            colWidth: "60%",
        },
    },
    {
        accessorKey: "type",
        header: ({ column }) => {
            const { t } = useTranslation("SitesTable");
            return <Header title={t("type")} column={column} />;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const { t } = useTranslation("SitesTable");
            const setToUpdateSite = useSetAtom(toUpdateSiteAtom);
            const { mutate } = useDeleteEntity();
            const { setToastError } = useSetToast();

            const { isDeletable } = row.original;

            const handleDelete = () => {
                if (!isDeletable) return;
                mutate(row.original.id, {
                    onSettled: (data) => {
                        if (data?.deleteEntity?.error) {
                            setToastError(t("delete_error"));
                        }
                    },
                });
            };

            return (
                <ActionDropdownMenu modal={false}>
                    <ActionDropdownMenuTrigger asChild>
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            IconLeft={MoreHoriz}
                        />
                    </ActionDropdownMenuTrigger>
                    <ActionDropdownMenuContent>
                        <ActionDropdownMenuItem
                            onSelect={() => {
                                setToUpdateSite(row.original);
                            }}
                            Icon={({ className }) => (
                                <EditPencil className={className} />
                            )}
                            text={t("edit_site")}
                        />
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <ActionDropdownMenuItemDanger
                                    disabled={!isDeletable}
                                    onSelect={handleDelete}
                                    Icon={({ className }) => (
                                        <Trash className={className} />
                                    )}
                                    text={t("delete_site")}
                                />
                            </Tooltip.Trigger>
                            {isDeletable ? null : (
                                <Tooltip.Content>
                                    {t("delete_tooltip")}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </ActionDropdownMenuContent>
                </ActionDropdownMenu>
            );
        },
        meta: {
            colWidth: "60px",
        },
    },
];
