import { useNavigate, useParams } from "react-router-dom";

export const useNavigation = () => {
    const { org_uname } = useParams();

    const navigate = useNavigate();

    const navigateFromHome = (path: string) => {
        navigate(`/o/${org_uname}/admin/${path}`);
    };

    return { navigateFromHome };
};
