import i18next from "@app/i18n";

const enResources = {
    export: "Export",
    selected_data: "Selected data",
    all_data: "All data",
    current_display: "Current display",
    from_entities: "From entities",
    from_entity: "From entity",
    select_filetype: "Select the file type you would like to download",
    pdf_file: "PDF file",
    xls_file: "XLS file",
    cancel: "Cancel",
    will_export: "You will export",
    from: "from",
    all: "All",
    into_a: "into a",
    data_selected_filters: "data from selected filters",
    please_select_entity: "Please select at least one entity to export data",
    all_entities: "all entities",
    and: "and",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    export: "Exporter",
    selected_data: "Données sélectionnées",
    all_data: "Toutes les données",
    current_display: "Affichage actuel",
    from_entities: "Des entités",
    from_entity: "De l'entité",
    select_filetype:
        "Sélectionnez le type de fichier que vous souhaitez télécharger",
    pdf_file: "fichier PDF",
    xls_file: "fichier XLS",
    cancel: "Annuler",
    will_export: "Vous exporterez",
    from: "de",
    all: "Tous",
    into_a: "dans un",
    data_selected_filters: "les données des filtres sélectionnés",
    please_select_entity:
        "Veuillez sélectionner au moins une entité pour exporter les données",
    all_entities: "toutes les entités",
    and: "et",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ExportModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
