import {
    GetCurrentUserDocument,
    GetCurrentUserNoTenantDocument,
    GetCurrentUserNoTenantQuery,
    GetCurrentUserOrgsWithCustomizationDocument,
    GetCurrentUserOrgsWithCustomizationQuery,
    GetCurrentUserQuery,
    GetUsersDocument,
    GetUsersQuery,
    GetUsersWithReportingStatsDocument,
    GetUsersWithReportingStatsQuery,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { UserRepository } from "../UserRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLUserRepository implements UserRepository {
    get = async (): Promise<GetUsersQuery> => {
        return graphqlClientKoyeb.request(
            GetUsersDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };
    getCurrentUser = async (): Promise<GetCurrentUserQuery> =>
        graphqlClientKoyeb.request(
            GetCurrentUserDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    getCurrentUserNoTenant = async (): Promise<GetCurrentUserNoTenantQuery> => {
        console.log("dsds");
        const headers = await AccessHeadersHolder.getHeaders();
        return graphqlClientKoyeb.request(
            GetCurrentUserNoTenantDocument,
            undefined,
            headers,
        );
    };

    getWithReportingStats =
        async (): Promise<GetUsersWithReportingStatsQuery> => {
            return graphqlClientKoyeb.request(
                GetUsersWithReportingStatsDocument,
                undefined,
                await AccessHeadersHolder.getHeaders(),
            );
        };
    getCurrentUserOrgs =
        async (): Promise<GetCurrentUserOrgsWithCustomizationQuery> => {
            return graphqlClientKoyeb.request(
                GetCurrentUserOrgsWithCustomizationDocument,
                undefined,
                await AccessHeadersHolder.getHeaders(),
            );
        };
}
