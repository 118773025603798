import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { DesignPencil } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    CreateNewVersionFromScratchDocument,
    GetDocumentByUrlQuery,
} from "../../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import OptionLayout from "./OptionLayout";
import "./i18n";

const OptionStartFromScratch = ({
    getDocumentQuery,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
}) => {
    const { t } = useTranslation("CreateDocumentOption");

    const navigate = useNavigate();

    const createNewVersionMutation = useGraphQLMutation(
        CreateNewVersionFromScratchDocument,
    );

    const onCreate = () => {
        createNewVersionMutation.mutate(
            { id: getDocumentQuery.data.documentByUrl?.id! },
            { onSuccess: () => navigate("edit") },
        );
    };
    const createFromScratchButton = (
        <ButtonRegular
            loading={createNewVersionMutation.isPending}
            onClick={onCreate}
            className="min-w-[110px]"
        >
            {t("start_from_scratch_button")}
        </ButtonRegular>
    );

    return (
        <OptionLayout
            Icon={DesignPencil}
            button={createFromScratchButton}
            title={t("start_from_scratch_title")}
            subtitle={t("start_from_scratch_desc")}
        />
    );
};

export default OptionStartFromScratch;
