// React Hook
import { useEntities } from "@app/store/entityStore";
import {
    humanReadablePeriodToPeriod,
    Period,
    periodToHumanReadablePeriod,
} from "@app/utils/periodToUrl";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
    ArrayParam,
    objectToSearchString,
    StringParam,
    useQueryParam,
} from "use-query-params";
import { activePeriodsAtom, activeReportingEntityIdAtom } from "./context";
import { useDatapointGroups } from "./data";

export function useSetReportingFilters() {
    const [urlPeriods, setUrlPeriods] = useQueryParam("periods", ArrayParam);
    const [urlSite, setUrlSite] = useQueryParam("site", StringParam);

    const [periodsDidLoad, setPeriodsDidLoad] = useState<boolean>(false);

    const groups = useDatapointGroups();
    const { entities } = useEntities();

    const [activePeriods, setActivePeriods] = useAtom(activePeriodsAtom);

    const [activeReportingEntityId, setActiveReportingEntityId] = useAtom(
        activeReportingEntityIdAtom,
    );

    /* Initialize the entity
        - If site url is not set, set the default entity as active and put it in the url
        - If site url is set, set the entity from the url as active
    */
    useEffect(() => {
        if (!activeReportingEntityId && entities) {
            const entity = entities.find((entity) => entity.name === urlSite);
            if (entity) {
                setActiveReportingEntityId(entity?.id);
            } else {
                const defaultEntity = entities.find(
                    (entity) => entity.isDefault,
                );
                if (defaultEntity) {
                    setActiveReportingEntityId(defaultEntity?.id);
                } else if (entities.length > 0) {
                    setActiveReportingEntityId(entities[0].id);
                }
            }
        }
    }, [entities]);

    /*  Inititalize the periods. 
        - Wait for active entity to be set
        - If periods url is not set, use localStorage to set the periods, ie do not do anything but set the url
        - If periods url is set, set the periods from the url as active
    */
    useEffect(() => {
        if (!periodsDidLoad && activeReportingEntityId && groups) {
            let periods: Period[] = [];
            if (urlPeriods) {
                periods = humanReadablePeriodToPeriod(urlPeriods);
            } else if (!periods.length && activePeriods) {
                periods = activePeriods;
            } else if (!periods.length) {
                periods = groups
                    .filter(
                        (group) => group.entity?.id === activeReportingEntityId,
                    )
                    .map((group) => group.period);
            }

            setActivePeriods(periods);
            setPeriodsDidLoad(true);
        }
    }, [groups, activeReportingEntityId]);

    // Make sure site url match activeReportingEntityId
    useEffect(() => {
        const entity = entities?.find(
            (entity) => entity.id === activeReportingEntityId,
        );
        if (entity) setUrlSite(entity.name);
    }, [entities, activeReportingEntityId]);

    // Make sure period url match activeDatapointGroupIds
    useEffect(() => {
        if (!periodsDidLoad) return;
        const urlPeriods = activePeriods.map(periodToHumanReadablePeriod);

        setUrlPeriods(urlPeriods);
    }, [activePeriods]);

    return {
        setActivePeriods,
        setActiveReportingEntityId,
    };
}

export const useActiveDatatpointGroupIds = () => {
    const groups = useDatapointGroups();
    const activePeriods = useAtomValue(activePeriodsAtom);
    const activeEntityId = useAtomValue(activeReportingEntityIdAtom);

    const ids = useMemo(
        () =>
            (groups
                ?.filter(
                    (g) =>
                        g.entity?.id === activeEntityId &&
                        activePeriods.some(
                            (p) =>
                                p.year === g.period.year &&
                                p.quarter === g.period.quarter &&
                                p.month === g.period.month,
                        ),
                )
                .map((g) => g.id) || []) as string[],
        [groups, activePeriods, activeEntityId],
    );

    return ids;
};

export const useFilteredUrl = () => {
    const { org_uname } = useParams();
    const activeEntityId = useAtomValue(activeReportingEntityIdAtom);
    const activePeriods = useAtomValue(activePeriodsAtom);
    const { entities } = useEntities();
    const entityUrlParam = objectToSearchString({
        site: entities?.find((entity) => entity.id === activeEntityId)?.name,
    });
    const periodsUrlParam = objectToSearchString({
        periods: activePeriods.map(periodToHumanReadablePeriod),
    });
    return (
        window.location.origin +
        `/o/${org_uname}/admin/reporting/overview?${entityUrlParam}&${periodsUrlParam}`
    );
};
