import i18next from "@app/i18n";

const enResources = {
    tooltip: "Prefill matched data from Beavr reporting",
    tooltip_no_data: "The associated reporting datapoint is empty",
    tooltip_no_indicator:
        "This datapoint has no associated reporting datapoint",

    modal_title: "Prefill datapoint",
    modal_subtitle: "Select an entity and period to prefill datapoint",

    modal_entity_label: "Entity:",
    modal_entity_trigger_placeholder: "Select an entity",

    modal_period_label: "Period:",
    modal_period_trigger_placeholder: "Select a period",
    modal_period_select_year: "Year",
    modal_period_select_month: "Month",

    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    tooltip: "Préremplir les données correspondantes du reporting Beavr",
    tooltip_no_data: "Le datapoint de reporting associé est vide",
    tooltip_no_indicator:
        "Ce datapoint n'a pas de datapoint de reporting associé",

    modal_title: "Préremplir le datapoint",
    modal_subtitle:
        "Sélectionnez une entité et une période pour préremplir le datapoint",

    modal_entity_label: "Entité:",
    modal_entity_trigger_placeholder: "Sélectionner une entité",

    modal_period_label: "Période:",
    modal_period_trigger_placeholder: "Sélectionner une période",
    modal_period_select_year: "Année",
    modal_period_select_month: "Mois",

    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "CsrdDatapointPrefill";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
