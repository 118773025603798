import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";

import { Cancel, NavArrowDown } from "iconoir-react";
import { ButtonType } from "../../../Button/ButtonShared";
import ButtonSmall from "../../../Button/ButtonSmall";
import { HeaderFilter } from "@design-system/Table";
import { ButtonHTMLAttributes, forwardRef, Ref } from "react";

const FilterTrigger = forwardRef(
    (
        {
            children,
            unsetCallback,
            isHeader,
            ...props
        }: {} & ButtonHTMLAttributes<HTMLButtonElement> & {
                active?: boolean;
                unsetCallback?: () => void;
                isHeader?: boolean;
            },
        ref: Ref<HTMLButtonElement>,
    ) => {
        return (
            <DropdownPrimitive.Trigger asChild>
                {isHeader ? (
                    <HeaderFilter active={props.active} ref={ref}>
                        {children}
                    </HeaderFilter>
                ) : (
                    <ButtonSmall
                        ref={ref}
                        variant={ButtonType.OUTLINED}
                        IconRight={props.active ? Cancel : NavArrowDown}
                        onRightClick={props.active ? unsetCallback : undefined}
                        {...props}
                    >
                        {children}
                    </ButtonSmall>
                )}
            </DropdownPrimitive.Trigger>
        );
    },
);

export default FilterTrigger;
