import { Button } from "@design-system/Inputs/Button";
import { Text } from "@design-system/Typography/Text";
import { GetDocumentByUrlQuery } from "@generated/client/graphql";
import { DesignPencil } from "iconoir-react";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
    previewTemplateModalOpenAtom,
    previewTemplateModalTemplatesAtom,
} from "../ctx";
import OptionLayout from "./OptionLayout";
import "./i18n";

const OptionStartFromTemplate = ({
    document,
}: {
    document: GetDocumentByUrlQuery["documentByUrl"];
}) => {
    const { t } = useTranslation("CreateDocumentOption");
    const setTemplates = useSetAtom(previewTemplateModalTemplatesAtom);
    const setOpen = useSetAtom(previewTemplateModalOpenAtom);
    const handleStartFromTemplateButtonClick = () => {
        setOpen(true);
        setTemplates(document?.templates ?? undefined);
    };
    const createFromTemplateButton = (
        <Button onClick={handleStartFromTemplateButtonClick}>
            <Text variant="body-sm-bold" color="white">
                {t("start_from_template_button")}
            </Text>
        </Button>
    );

    return (
        <OptionLayout
            Icon={DesignPencil}
            button={createFromTemplateButton}
            title={t("start_from_template_title")}
            subtitle={t("start_from_template_desc")}
        />
    );
};

export default OptionStartFromTemplate;
