import { createTagAtom } from "@app/store/tagStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { TagType } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { useEffect, useRef, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";

const NewTagDropdownItem: FC<{
    type: TagType;
    onSaveNewTagSettled: (name: string) => void;
}> = ({ type, onSaveNewTagSettled }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const [isEditing, setIsEditing] = useState(false);
    const [newTagName, setNewTagName] = useState<string>("");
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [{ mutate }] = useAtom(createTagAtom);
    const handleNewTagNameChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setNewTagName(e.target.value?.trimStart());
        textareaRef.current?.focus();
    };
    const handleSaveNewTagName = () => {
        if (!newTagName) return;
        mutate(
            { type, name: newTagName },
            {
                onSettled() {
                    onSaveNewTagSettled(newTagName);
                    setNewTagName("");
                    setIsEditing(false);
                },
            },
        );
    };

    useEffect(() => {
        if (isEditing) {
            textareaRef.current?.focus();
        }
    }, [isEditing]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            textareaRef.current?.blur();
            handleSaveNewTagName();
        }
        if (e.key === "Escape") {
            e.preventDefault();
            setIsEditing(false);
        }
    };

    return (
        <>
            {isEditing ? (
                <FlexRow
                    h="9"
                    px="0"
                    py="1.5"
                    alignItems="center"
                    justifyContent="center"
                    className="relative rounded-lg focus:outline-none"
                >
                    <textarea
                        value={newTagName}
                        onChange={handleNewTagNameChange}
                        onKeyDown={handleKeyDown}
                        ref={textareaRef}
                        className="h-full w-full resize-none rounded-lg border-secondary focus:border-secondary focus:ring-0"
                    ></textarea>
                    <Button
                        variant="flatPrimary"
                        size="xs"
                        className="absolute right-2"
                        disabled={!newTagName}
                        onClick={handleSaveNewTagName}
                    >
                        <Icon name="arrowRight" size="xs" />
                    </Button>
                </FlexRow>
            ) : (
                <FlexRow
                    className="hover:bg-green-50 rounded-lg cursor-pointer flex-grow px-2 py-1.5 focus:outline-none space-x-2.5 flex items-center"
                    onClick={() => {
                        setIsEditing(true);
                    }}
                >
                    <Icon name="plus" size="xs" className="text-green" />
                    <Text variant="body-sm"> {t(keys.new_tag)}</Text>
                </FlexRow>
            )}
        </>
    );
};

export default NewTagDropdownItem;
