import InputSimple from "@app/components/InputSimple";
import { adminPaths } from "@app/routing/paths";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useModalActions } from "../useModalActions";

export const CreateCustom: FC<{
    fileKey: string;
    onClose: () => void;
}> = ({ fileKey, onClose }) => {
    const {
        associateFileToDocuments,
        createCustomDocument,
        isCreateCustomLoading,
    } = useModalActions();
    const [name, setName] = useState("");
    const { t } = useTranslation("DocumentsUploadModal");

    const handleCreate = async () => {
        if (!name) return;
        const res = await createCustomDocument(name);

        if (!res) return;
        /**
         * so far we directly add the document to the file
         * but in the future we could consider sumply adding
         * it to the multiselect list and let the user decide
         */
        associateFileToDocuments(fileKey, [
            {
                id: res.createDocument2.id,
                hasAlreadyEntry: false,
                name,
                theme: res.createDocument2.theme
                    ? {
                          color: res.createDocument2.theme.color,
                          name: res.createDocument2.theme.name,
                      }
                    : undefined,
            },
        ]);

        window.open(
            new URL(
                window.location.pathname +
                    "/" +
                    adminPaths.custom_documents +
                    "/" +
                    res.createDocument2.uniqueUrl,
                window.location.origin,
            ).href,
            "_blank",
        );

        onClose();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    return (
        <FlexCol w="full" gap="4" p="2">
            <FlexRow py="0.5" px="1" justifyContent="between">
                <Text color="secondary" variant="body-sm">
                    {t("multiselect_create_custom")}
                </Text>
                <a
                    className="cursor-pointer inline-flex items-center gap-1"
                    style={{ textDecoration: "none" }}
                    onClick={onClose}
                >
                    <Text color="brand" variant="body-sm-bold">
                        {t("multiselect_close_custom")}
                    </Text>
                    <Icon name="close" size="xs" />
                </a>
            </FlexRow>
            <InputSimple
                value={name}
                onChange={handleChange}
                title={t("multiselect_create_custom_input_placeholder")}
            />
            <Button
                disabled={!name.length}
                loading={isCreateCustomLoading}
                onClick={handleCreate}
            >
                {t("multiselect_create_custom_CTA")}
            </Button>
        </FlexCol>
    );
};
