import i18n from '@app/i18n';

const enResources = {
    label: "owners",
    warning: "⚠️ Assigning an owner will remove all existing owners",
    trigger_placeholder: "Select owner",
    unassign: "Unassign",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    label: "propriétaires",
    warning: "⚠️ Assigner un propriétaire écrasera tous les propriétaires existants",
    trigger_placeholder: "Sélectionner un propriétaire",
    unassign: "Désassigner",
} as const;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18n.addResources("en", "UserAssign", enResources);
i18n.addResources("fr", "UserAssign", frResources);