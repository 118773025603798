import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { cn, useIsOverflown, type ClassValue } from "@design-system/Utilities";
import { Slot } from "@radix-ui/react-slot";
import {
    forwardRef,
    useImperativeHandle,
    useRef,
    type FC,
    type PropsWithChildren,
} from "react";

export type EllipsisProps = PropsWithChildren<{
    /**
     * The text element to render as.
     */
    asChild?: boolean;
    className?: ClassValue;
    /**
     * Show or not a tooltip when the text is truncated.
     * The tooltip will show the full text.
     * @default false
     */
    hasTooltip?: boolean;
}>;

export const Ellipsis = forwardRef<HTMLParagraphElement, EllipsisProps>(
    ({ asChild, children, className, hasTooltip = false }, ref) => {
        const Comp = asChild ? Slot : "p";
        const ellipsisRef = useRef<HTMLParagraphElement>(null);
        const isOverflown = useIsOverflown(ellipsisRef);

        const innerText = ellipsisRef.current?.textContent;
        useImperativeHandle(ref, () => ellipsisRef.current!);

        return (
            <Tooltip>
                <Wrapper
                    className={className}
                    show={hasTooltip && isOverflown}
                    txt={innerText}
                >
                    <Comp
                        className={cn("truncate max-w-full", className)}
                        ref={ellipsisRef}
                    >
                        {children}
                    </Comp>
                </Wrapper>
            </Tooltip>
        );
    },
);

const Wrapper: FC<
    PropsWithChildren<{
        className?: ClassValue;
        txt?: string | null;
        show: boolean;
    }>
> = ({ children, className, txt, show }) => {
    return show ? (
        <>
            <Tooltip.Trigger
                asChild
                className={cn("overflow-hidden w-full", className)}
            >
                {children}
            </Tooltip.Trigger>
            <Tooltip.Content className="max-w-[600px]">{txt}</Tooltip.Content>
        </>
    ) : (
        children
    );
};
