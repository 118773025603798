import {
    CreateEmptyS3FileDocument,
    CreateFileInput,
    CreateManyEmptyS3FilesDocument,
    CreateOneFileDocument,
    CreateS3FilesAsContributorDocument,
    DeleteS3FileDocument,
    DeleteS3FilesDocument,
    GetEvidenceFilesDocument,
    GetSuggestedFilesDocument,
    GetSuggestedFilesInput,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { S3FileRepository } from "../S3FileRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLS3FileRepository implements S3FileRepository {
    async uploadToBucket(file: File, { url }: { url: string }) {
        await fetch(url, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Length": file.size.toString(),
                "Access-Control-Allow-Origin": "no-cors",
            },
        });
    }
    async createOneS3File(filename: string) {
        return graphqlClientKoyeb.request(
            CreateEmptyS3FileDocument,
            { filename },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createManyS3file(filenames: string[]) {
        return graphqlClientKoyeb.request(
            CreateManyEmptyS3FilesDocument,
            { filenames },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createManyS3fileAsContributor(input: {
        filenames: string[];
        userId?: string;
        questionnaireId?: string;
    }) {
        return graphqlClientKoyeb.request(
            CreateS3FilesAsContributorDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createOneFile(input: CreateFileInput) {
        return graphqlClientKoyeb.request(
            CreateOneFileDocument,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async deleteOne(id: string) {
        return graphqlClientKoyeb.request(
            DeleteS3FileDocument,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async deleteMany(ids: string[]) {
        return graphqlClientKoyeb.request(
            DeleteS3FilesDocument,
            { ids },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSuggestedFiles(input: GetSuggestedFilesInput) {
        return graphqlClientKoyeb.request(
            GetSuggestedFilesDocument,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getEvidenceFiles() {
        return graphqlClientKoyeb.request(
            GetEvidenceFilesDocument,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }
}
