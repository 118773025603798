import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { reportingKeys, useCreateDatapointGroup } from "../../data";
import { createContext, useState } from "react";
import "../../i18n";
import { Icon } from "@design-system/Icon";
import { useAtom, useAtomValue } from "jotai";
import {
    activeReportingEntityIdAtom,
    openPeriodManagementModalAtom,
} from "../../context";
import { useQueryClient } from "@tanstack/react-query";
import { useSetToast } from "@app/components/Toast";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import { useTranslation } from "react-i18next";
import { PeriodBox } from "./periodBox";
import { Sidebar } from "./sidebar";

type YearContextType = {
    selectedYear: number | null;
    setSelectedYear: (year: number) => void;
    newPeriods: {
        year: number;
        month?: string;
        quarter?: string;
    }[];
    setNewPeriods: (
        periods: {
            year: number;
            month?: string;
            quarter?: string;
        }[],
    ) => void;
};

export const YearContext = createContext<YearContextType | null>(null);

export const PeriodManagementModal = () => {
    const [open, setOpen] = useAtom(openPeriodManagementModalAtom);
    const [selectedYear, setSelectedYear] = useState<number>(2024);
    const [newPeriods, setNewPeriods] = useState<
        { year: number; month?: string; quarter?: string }[]
    >([]);
    const client = useQueryClient();
    const { setToastSuccess, setToastError } = useSetToast();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const { mutateAsync, isPending } = useCreateDatapointGroup();

    const createDatapointGroup = async (period: {
        year: number;
        month?: string;
        quarter?: string;
    }) => {
        await mutateAsync(
            {
                entityId: activeReportingEntityId,
                period,
            },
            {
                onSuccess: () => {
                    client.invalidateQueries({
                        queryKey: reportingKeys.periods(),
                    });
                    setToastSuccess(t(keys.add_period_success));
                },
                onError: () => {
                    setToastError(t(keys.add_period_error));
                },
            },
        );
    };

    const handleSaveClick = async () => {
        if (!activeReportingEntityId) return;
        for (const period of newPeriods) {
            await createDatapointGroup(period);
        }
        setNewPeriods([]);
        setOpen(false);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <YearContext.Provider
            value={{ selectedYear, setSelectedYear, newPeriods, setNewPeriods }}
        >
            <Modal open={open} onOpenChange={setOpen}>
                <Modal.Overlay scrollable>
                    <Modal.Content className="w-[620px] h-[620px]">
                        <Modal.Header className="border-b border-neutral-150">
                            <Modal.Title>{t(keys.modal_title)}</Modal.Title>
                            <Modal.Subtitle>
                                {t(keys.modal_subtitle)}
                            </Modal.Subtitle>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <FlexRow>
                                <FlexCol
                                    justifyContent="between"
                                    className="border-r border-neutral-150"
                                >
                                    <Sidebar />
                                    <Box px="4" pb="4" pt="1">
                                        <Modal.ConfirmCTA
                                            preventClose
                                            className="w-full"
                                            loading={isPending}
                                            onClick={handleSaveClick}
                                        >
                                            <FlexRow gap="2">
                                                {t(keys.save_button)}
                                                <Icon name="check" />
                                            </FlexRow>
                                        </Modal.ConfirmCTA>
                                    </Box>
                                </FlexCol>
                                <FlexCol
                                    gap="3"
                                    w="full"
                                    px="3"
                                    py="4"
                                    className="overflow-auto max-h-[524px]"
                                >
                                    <Text variant="header3" color="primary">
                                        {selectedYear}
                                    </Text>
                                    <PeriodBox type="year" />
                                    <PeriodBox type="quarter" />
                                    <PeriodBox type="month" />
                                </FlexCol>
                            </FlexRow>
                        </Modal.Body>
                    </Modal.Content>
                </Modal.Overlay>
            </Modal>
        </YearContext.Provider>
    );
};
