import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { documentURLAtom } from "@app/store/documentStore";
import { useParams } from "react-router-dom";
import { currOrgUnameAtom } from "@app/store/orgStore";

export function useSyncDocumentURL() {
    const { document_id } = useParams();
    const setDocumentURL = useSetAtom(documentURLAtom);

    useEffect(() => {
        setDocumentURL(document_id ?? null);
    }, [document_id]);
}

export function useSyncCurrOrgUname() {
    const { org_uname } = useParams<{ org_uname: string }>();
    const setCurrOrgUname = useSetAtom(currOrgUnameAtom);

    useEffect(() => {
        setCurrOrgUname(org_uname ?? null);
    }, [org_uname]);
}
