import i18next from "../../../i18n";

i18next.addResourceBundle("en", "MultiSelect", {
    search: "Search",
    tags: "Tags",
    new_tag: "New tag",
    create_tag: "Create tag",
    create_new_tag: "Create new tag",
});

i18next.addResourceBundle("fr", "MultiSelect", {
    search: "Rechercher",
    tags: "Tags",
    new_tag: "Nouveau tag",
    create_tag: "Créer un tag",
    create_new_tag: "Créer un nouveau tag",
});
