import { useS3FilesManagement } from "@app/hooks/createFileHook";
import { useSearchParams } from "react-router-dom";
import { modals } from "../../utils";

export function useDocumentsUploadModal() {
    /** Handle open state of modal */
    const [searchParams, setSearchParams] = useSearchParams();
    const isOpen = searchParams.get("modal") === modals.uploadDocuments;

    const { isLoading } = useS3FilesManagement();

    /** Store updated files state */
    const closeModal = () => {
        setSearchParams({});
    };

    const handleOpenChange = (open: boolean) => {
        if (open) return;
        closeModal();
    };

    return {
        closeModal,
        handleOpenChange,
        isOpen,
        isUploading: isLoading,
    };
}
