import { useTranslation } from "react-i18next";
import { Entity } from "../../../../generated/client/graphql";
import Container from "../../../components/Container";
import BodyHeader from "../../../shared/components/BodyHeader";

import { useSetAtom } from "jotai";
import { Building2 } from "lucide-react";
import EmptyTable from "../../../components/Table/EmptyTable";
import LoadingTable from "../../../components/Table/LoadingTable";
import { useCapturePageView } from "../../../lib/tracking/utils";
import NewSiteModal, { newSitesModalOpenAtom } from "./components/NewSiteModal";
import SitesTable from "./components/SitesTable";

import { useEntities } from "@app/store/entityStore";
import "./i18n";

export default function Sites() {
    useCapturePageView("settings");

    const { t } = useTranslation("Sites");
    const { entities, isLoading } = useEntities();
    const setCreateModalOpen = useSetAtom(newSitesModalOpenAtom);
    return (
        <>
            <Container>
                <BodyHeader
                    title={t("title")}
                    subTitle={t("subtitle")}
                    action={<NewSiteModal />}
                />
                <div className="p-4">
                    {!entities && isLoading ? (
                        <LoadingTable />
                    ) : entities?.length === 0 ? (
                        <EmptyTable
                            Icon={Building2}
                            smallText={t("empty_table_small_text")}
                            buttonText={t("empty_table_button_text")}
                            onClick={() => setCreateModalOpen(true)}
                        />
                    ) : entities ? (
                        <SitesTable sites={entities as Entity[]} />
                    ) : (
                        <div>Error, refresh ...</div>
                    )}
                </div>
            </Container>
        </>
    );
}
