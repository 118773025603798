import { Modal } from "@design-system/Modal";
import {
    activeReportingEntityIdAtom,
    exportModalIsOpenAtom,
} from "../../context";
import { useAtom, useAtomValue } from "jotai";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { SegmentButton } from "@design-system/Inputs/SegmentButton";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { ChipSelect } from "@design-system/Inputs/ChipSelect/Index";
import { useState, FC, useEffect } from "react";
import { ExtensionTag } from "@app/shared/components/ExtensionTag";
import { ClassValue, cn } from "@design-system/Utilities";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import { useTranslation } from "react-i18next";
import { useEntities } from "@app/store/entityStore";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useExportReporting } from "../../data";
import { ExportFormat } from "@generated/client/graphql";

type ExportModalProps = {
    className?: ClassValue;
};

export const exportData = {
    All: "All",
    Filtered: "Filtered",
} as const;

export type ExportData = keyof typeof exportData;

export const ALL_ENTITIES_TOKEN = "All";

export const ExportModal: FC<ExportModalProps> = ({ className }) => {
    const [open, setOpen] = useAtom(exportModalIsOpenAtom);
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const [entityIds, setEntityIds] = useState<string[]>([]);
    const [filterValue, setFilterValue] = useState<ExportData>(
        exportData.Filtered,
    );
    const [exportFormat, setExportFormat] = useState<ExportFormat>(
        ExportFormat.Xls,
    );
    const { entities } = useEntities();
    const { mutate: exportAndDownload, isPending: isExporting } =
        useExportReporting();

    const handleEntityValuesChange = (newValues: string[]) => {
        if (
            !entityIds.includes(ALL_ENTITIES_TOKEN) &&
            newValues.includes(ALL_ENTITIES_TOKEN) &&
            entities
        ) {
            setEntityIds([
                ALL_ENTITIES_TOKEN,
                ...entities.map((entity) => entity.id),
            ]);
        } else if (
            entityIds.includes(ALL_ENTITIES_TOKEN) &&
            !newValues.includes(ALL_ENTITIES_TOKEN)
        ) {
            setEntityIds([]);
        } else if (
            entityIds.includes(ALL_ENTITIES_TOKEN) &&
            newValues.includes(ALL_ENTITIES_TOKEN)
        ) {
            setEntityIds(
                newValues.filter((value) => value !== ALL_ENTITIES_TOKEN),
            );
        } else if (newValues.length === entities?.length) {
            setEntityIds([
                ALL_ENTITIES_TOKEN,
                ...entities.map((entity) => entity.id),
            ]);
        } else {
            setEntityIds(newValues);
        }
    };
    const handleExportFormatChange = (value: ExportFormat) => {
        if (!!value) {
            setExportFormat(value);
        }
    };

    const handleSelectedFilterValueChange = (value: ExportData) => {
        setFilterValue(value);
    };

    const formatEntityList = (entities: string[]) => {
        if (entities.length === 1) return entities[0];
        if (entities.length === 2) return entities.join(` ${t(keys.and)} `);
        return `${entities.slice(0, -1).join(", ")} ${t(keys.and)} ${entities[entities.length - 1]}`;
    };

    const { t } = useTranslation(RESOURCE_NAME);

    const exportDescription = `
${t(keys.will_export)} **${
        filterValue === exportData.All
            ? t(keys.all_data).toLowerCase()
            : t(keys.data_selected_filters)
    }** ${t(keys.from)} **${
        entityIds.includes(ALL_ENTITIES_TOKEN)
            ? t(keys.all_entities)
            : formatEntityList(
                  entities
                      ?.filter((entity) => entityIds.includes(entity.id))
                      ?.map((entity) => entity.name) || [],
              )
    }** ${t(keys.into_a)} **${
        exportFormat === ExportFormat.Xls ? t(keys.xls_file) : t(keys.pdf_file)
    }.**
`;
    const onConfirm = () => {
        exportAndDownload({ exportFormat, entityIds, filterValue });
    };

    useEffect(() => {
        if (!activeReportingEntityId) return;
        setEntityIds([activeReportingEntityId]);
    }, [activeReportingEntityId, setEntityIds]);

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay>
                <Modal.Content className={cn("w-[444px]", className)}>
                    <Modal.Header>
                        <Modal.Title>{t(keys.export)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="4">
                            <Text variant="body" color="secondary">
                                {t(keys.select_filetype)}
                            </Text>
                            <SegmentButton
                                value={exportFormat}
                                onValueChange={handleExportFormatChange}
                            >
                                <SegmentButton.Item value={ExportFormat.Xls}>
                                    <FlexRow gap="1" className="items-center">
                                        <ExtensionTag name="XLS" />
                                        <Text
                                            variant="body-bold"
                                            color="secondary"
                                        >
                                            {t(keys.xls_file)
                                                .charAt(0)
                                                .toUpperCase() +
                                                t(keys.xls_file).slice(1)}
                                        </Text>
                                    </FlexRow>
                                </SegmentButton.Item>
                                <SegmentButton.Item value={ExportFormat.Pdf}>
                                    <FlexRow gap="1" className="items-center">
                                        <ExtensionTag name="PDF" />
                                        <Text
                                            variant="body-bold"
                                            color="secondary"
                                        >
                                            {t(keys.pdf_file)
                                                .charAt(0)
                                                .toUpperCase() +
                                                t(keys.pdf_file).slice(1)}
                                        </Text>
                                    </FlexRow>
                                </SegmentButton.Item>
                            </SegmentButton>
                            <Modal.Separator />
                            <FlexCol gap="3" className="w-fit">
                                <Text variant="body" color="secondary">
                                    {t(keys.selected_data)}
                                </Text>
                                <SingleSelect
                                    value={filterValue}
                                    onValueChange={
                                        handleSelectedFilterValueChange
                                    }
                                >
                                    <SingleSelect.Trigger
                                        size="md"
                                        variant="tonal"
                                    />
                                    <SingleSelect.Content>
                                        <SingleSelect.Option
                                            value={exportData.All}
                                            label={t(keys.all_data)}
                                        />
                                        <SingleSelect.Option
                                            value={exportData.Filtered}
                                            label={t(keys.current_display)}
                                        />
                                    </SingleSelect.Content>
                                </SingleSelect>
                            </FlexCol>
                            <Modal.Separator />
                            <FlexCol gap="3">
                                <Text variant="body" color="secondary">
                                    {exportFormat === ExportFormat.Xls
                                        ? t(keys.from_entities)
                                        : t(keys.from_entity)}
                                </Text>
                                <ChipSelect
                                    values={entityIds}
                                    onValuesChange={handleEntityValuesChange}
                                    className="flex-wrap"
                                    mode={
                                        exportFormat === ExportFormat.Xls
                                            ? "multi"
                                            : "single"
                                    }
                                >
                                    {exportFormat === ExportFormat.Xls && (
                                        <ChipSelect.Chip
                                            value={ALL_ENTITIES_TOKEN}
                                            key={ALL_ENTITIES_TOKEN}
                                        >
                                            {t(keys.all)}
                                        </ChipSelect.Chip>
                                    )}
                                    {entities &&
                                        entities.map((entity, idx) => (
                                            <ChipSelect.Chip
                                                value={entity.id}
                                                key={idx}
                                            >
                                                {entity.name}
                                            </ChipSelect.Chip>
                                        ))}
                                </ChipSelect>
                            </FlexCol>
                            <Modal.Separator />
                            {entityIds.length ? (
                                <ReactMarkdown
                                    className="markdown text-base font-regular text-primary"
                                    children={exportDescription}
                                    remarkPlugins={[remarkGfm]}
                                />
                            ) : (
                                <Text variant="body" color="primary">
                                    {t(keys.please_select_entity)}
                                </Text>
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            disabled={!entityIds.length && true}
                            loading={isExporting}
                            onClick={onConfirm}
                            preventClose
                        >
                            {t(keys.export)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
