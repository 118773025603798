import Avatar from "@app/components/User/Avatar";
import { getFilteredSortedDatapointGroupsIds } from "@app/usecases/ReportingUseCases";

import { useSetToast } from "@app/components/Toast";
import {
    activeReportingEntityIdAtom,
    openReminderModalAtom,
    usersToSendReminderAtom,
} from "@app/pages/Reporting/context";
import {
    useActiveDatatpointGroupIds,
    useFilteredUrl,
} from "@app/pages/Reporting/hooks";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DatapointGroup, User } from "../../../../../generated/client/graphql";
import CheckboxCell from "../../../../components/Table/CheckboxCell";
import Header from "../../../../components/Table/Header";
import CheckboxHeader from "../../../../components/Table/Header/Checkbox";
import "../../i18n";
import { useDatapointReportingGroups } from "@app/pages/Reporting/data";
const selectCol: ColumnDef<User> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} />,
    meta: {
        headerClassName: "w-[50px] max-w-[50px]",
    },
};

const contributorCol: ColumnDef<User> = {
    id: "contributor",
    meta: {
        headerClassName: "w-[200px]",
    },
    header: ({ column }) => {
        const { t } = useTranslation("ReportingContributorsPage");
        return <Header title={t("contributor_column_title")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <FlexRow p="3" gap="3" alignItems="center">
                <Avatar
                    avatarUrl={row.original?.avatar?.signedUrl}
                    initials={row.original?.initials}
                    size="medium"
                />
                <Text
                    variant="body-bold"
                    className="capitalize"
                >{`${row.original?.firstName} ${row.original?.lastName}`}</Text>
            </FlexRow>
        );
    },
};

const dataCol = (
    datapointGroupId: string,
    datapointGroups: any[],
): ColumnDef<User> => {
    return {
        id: `data_${datapointGroupId}`,
        accessorKey: `data_${datapointGroupId}`,
        meta: {
            headerClassName: "w-[150px]",
        },
        header: ({ column }) => {
            const { t } = useTranslation("ReportingContributorsPage");
            const period = datapointGroups.find(
                (elt) => elt.id === datapointGroupId,
            )?.period;
            return (
                <Header
                    title={`${period?.quarter || ""} ${
                        period?.month
                            ? t("month", {
                                  context: period?.month,
                              })
                            : ""
                    } ${period?.year}`}
                    column={column}
                    disableSort={true}
                />
            );
        },
        cell: ({ row }) => {
            const contributorStat = row.original.reportingStats?.find(
                (elt) => elt?.datapointGroupId === datapointGroupId,
            );
            const { t } = useTranslation("ReportingContributorsPage");
            return (
                <FlexRow gap="2">
                    {contributorStat?.assignedDatapoints &&
                    contributorStat?.assignedDatapoints !== 0 ? (
                        <Text variant="body-sm">{`${contributorStat?.answeredDatapoints} / ${contributorStat?.assignedDatapoints} `}</Text>
                    ) : null}
                    <ProgressBar
                        completed={contributorStat?.answeredDatapoints || 0}
                        total={contributorStat?.assignedDatapoints || 0}
                        noDataPlaceholder={t("progress_bar_no_data")}
                    ></ProgressBar>
                </FlexRow>
            );
        },
    } as ColumnDef<User>;
};

const actionCol: ColumnDef<User> = {
    id: "action",
    accessorKey: "action",
    meta: {
        headerClassName: "w-[120px]",
    },
    header: () => {
        return <></>;
    },
    cell: ({ row }) => {
        const { setToastSuccess } = useSetToast();
        const { t } = useTranslation("ReportingContributorsPage");

        const setOpenReminderModal = useSetAtom(openReminderModalAtom);
        const setUsersToSendReminder = useSetAtom(usersToSendReminderAtom);
        const filteredUrl = useFilteredUrl();
        const handleLinkClick = (e: any) => {
            e.stopPropagation();
            navigator.clipboard.writeText(filteredUrl);
            setToastSuccess(t("success_copy"));
        };
        const handleReminderClick = (e: any) => {
            e.stopPropagation();
            setOpenReminderModal(true);
            setUsersToSendReminder([row.original]);
        };

        return (
            <FlexRow p="3" gap="3" alignItems="center">
                <Button variant="tonal" size="sm" onClick={handleLinkClick}>
                    <Icon name="link" size="sm"></Icon>
                </Button>

                <Button variant="tonal" size="sm" onClick={handleReminderClick}>
                    <Icon name="bell" size="sm"></Icon>
                </Button>
            </FlexRow>
        );
    },
};

export const useColumns = (): ColumnDef<User>[] => {
    const activeDatapointGroupsIds = useActiveDatatpointGroupIds();
    const { data: datapointGroupsData } = useDatapointReportingGroups();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    return useMemo(() => {
        return [
            selectCol,
            contributorCol,
            ...getFilteredSortedDatapointGroupsIds(
                activeDatapointGroupsIds,
                activeReportingEntityId,
                (datapointGroupsData?.datapointGroups as DatapointGroup[]) ||
                    [],
            ).map((id) => {
                return dataCol(id, datapointGroupsData?.datapointGroups || []);
            }),
            actionCol,
        ];
    }, [activeReportingEntityId, activeDatapointGroupsIds]);
};
