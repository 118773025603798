import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
    MutationFunction,
    QueryClient,
    QueryClientProvider,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import request from "graphql-request";
import { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AccessHeadersHolder } from "./repositories/AccessHeadersHolder";
import { graphqlClientKoyeb, restClient } from "./repositories/clients";
import { JotaiProvider } from "./store/StoreConfig";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus:
                import.meta.env.VITE_REACT_APP_AUTH_MODE === "development"
                    ? false
                    : true,
        },
    },
});

export function useRest(path: string) {
    const fetchData = async () =>
        restClient.get(path).then((response) => response.data);

    const { data, isLoading } = useQuery({
        queryKey: ["rest", ...path.split("/")],
        queryFn: fetchData,
    });
    return { data, isLoading };
}

export function useGraphQL<TResult, TVariables, TError>(
    document: TypedDocumentNode<TResult, TVariables>,
    variables?: TVariables extends Record<string, never> ? {} : TVariables,
    options?: Omit<
        UseQueryOptions<TResult, TError, TResult, any>,
        "queryKey" | "queryFn" | "initialData"
    >,
): UseQueryResult<TResult, TError> {
    return useQuery({
        queryKey: [(document.definitions[0] as any).name.value, variables],
        queryFn: async ({ queryKey }) => {
            return graphqlClientKoyeb.request(
                document,
                queryKey[1] ? queryKey[1] : undefined,
                await AccessHeadersHolder.getHeaders(),
            );
        },
        ...(options !== undefined ? options : null),
    });
}

useRest.getKey = (path: string) => ["rest", ...path.split("/")];

useGraphQL.getKey = <TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
) => [(document.definitions[0] as any).name.value, variables];

useGraphQL.getRootKey = <TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
) => [(document.definitions[0] as any).name.value];

export function useGraphQLMutation<TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    options?: Omit<
        UseMutationOptions<TResult, unknown, TVariables, unknown>,
        "mutationKey" | "mutationFn"
    >,
): UseMutationResult<TResult, unknown, TVariables, unknown> {
    const mutationFunction: MutationFunction<TResult, TVariables> = async (
        variables: TVariables,
    ) => {
        const headers = await AccessHeadersHolder.getHeaders();

        return request(
            import.meta.env.VITE_REACT_APP_GRAPHQL_URL_KOYEB,
            document,
            variables ? variables : {},
            options?.meta?.headers
                ? { ...headers, ...options.meta.headers }
                : headers,
        );
    };
    return useMutation<TResult, unknown, TVariables, unknown>({
        mutationFn: mutationFunction,
        ...options,
    });
}

export const QueryClientWithHeadersWrapper = ({
    children,
    noDevTools,
}: {
    children: ReactNode;
    noDevTools?: boolean;
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <JotaiProvider>
                <QueryClientWithHeaders>{children}</QueryClientWithHeaders>
            </JotaiProvider>
            {!noDevTools && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
};

const QueryClientWithHeaders = ({ children }: { children: ReactNode }) => {
    const { org_uname } = useParams();

    useEffect(() => {
        AccessHeadersHolder.setOrgUname(org_uname);
    }, [org_uname]);

    return <>{children}</>;
};

export default QueryClientWithHeaders;
