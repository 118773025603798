import {
    createContext,
    forwardRef,
    useContext,
    type ButtonHTMLAttributes,
    type FC,
    type ForwardRefExoticComponent,
    type PropsWithChildren,
    type Ref,
} from "react";

import { Icon, IconName } from "@design-system/Icon";

import { cn, type ClassValue } from "../../Utilities";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";

type NavigationContext = {
    selectedId?: string;
    onSelectedIdChange: (id: string) => void;
};

export const NavigationContext = createContext<NavigationContext | null>(null);

const Header: FC<PropsWithChildren> = ({ children }) => {
    return (
        <header
            className={cn(
                "flex items-center h-[64px] py-3 px-4 border-b border-tertiary ",
            )}
        >
            {children}
        </header>
    );
};

const Content: FC<PropsWithChildren<{ isLoading?: boolean }>> = ({
    children,
    isLoading,
}) => {
    return isLoading ? (
        <LoadingNavBar />
    ) : (
        <div
            className={cn(
                "flex flex-col flex-1 overflow-y-auto  scrollbar-hide p-4",
            )}
        >
            {children}
        </div>
    );
};

type GroupTitleProps = {
    title: string;
    className?: ClassValue;
    isOpen?: boolean;
    setIsOpen?: () => void;
};

const Group: FC<PropsWithChildren<GroupTitleProps>> = ({
    title,
    className,
    isOpen = true,
    setIsOpen,
    children,
}) => {
    return (
        <FlexCol className={cn(className)}>
            <FlexRow
                br="lg"
                className="h-[32px] cursor-pointer text-tertiary group hover:bg-secondary hover:text-neutral-500"
                px="1"
                py="1.5"
                alignItems="center"
                gap="0.5"
                w="full"
                onClick={setIsOpen}
            >
                <Text variant="caption-bold">{title}</Text>
                <Icon
                    name={isOpen ? "caretDown" : "caretRight"}
                    size="sm"
                    className="p-0.5 text-neutral-300 group-hover:text-neutral-500"
                />
            </FlexRow>
            {isOpen && children}
        </FlexCol>
    );
};

export type NavigationButtonComponentType = ForwardRefExoticComponent<
    PropsWithChildren<
        NavigationButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
    >
>;

const NavigationButton: NavigationButtonComponentType = forwardRef(
    (
        { children, classNames, id, iconName, text, ...buttonProps },
        ref: Ref<HTMLButtonElement>,
    ) => {
        const ctx = useContext(NavigationContext);
        // create a context to know if button is focused/hovered or active

        if (!ctx) throw new Error("Context must not null");
        const { selectedId, onSelectedIdChange } = ctx;
        const isSelected = id === selectedId;

        const handleClick = () => {
            onSelectedIdChange(id);
        };
        return (
            <button
                ref={ref}
                className={cn(
                    "py-1.5 px-1 flex items-center space-x-3 cursor-pointer whitespace-nowrap font-bold",
                    isSelected
                        ? "text-white bg-green rounded-lg"
                        : "text-secondary hover:bg-secondary rounded-lg",
                    classNames,
                )}
                onClick={handleClick}
                {...buttonProps}
            >
                <span className="p-0.5">
                    <Icon name={iconName} size="sm" />
                </span>
                <span>{text}</span>
            </button>
        );
    },
);

export const LoadingNavBar: FC<{ className?: ClassValue }> = ({
    className,
}) => {
    return (
        <FlexCol
            className={cn("h-full w-[232px]", className)}
            py="4"
            px="4"
            gap="6"
        >
            <FlexCol gap="5">
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" className="w-full" />
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" mb="2.5" className="w-[120px]" />
            </FlexCol>
            <FlexCol gap="5">
                <Skeleton h="2" br="lg" className="w-full" />
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" mb="2.5" className="w-[120px]" />
            </FlexCol>
            <FlexCol gap="5">
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" className="w-full" />
                <Skeleton h="2" br="lg" mb="2.5" className="w-[120px]" />
            </FlexCol>
            <FlexCol gap="5">
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" className="w-full" />
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" mb="2.5" className="w-full" />
            </FlexCol>
            <FlexCol gap="5">
                <Skeleton h="2" br="lg" className="w-[120px]" />
                <Skeleton h="2" br="lg" className="w-full" />
                <Skeleton h="2" br="lg" className="w-[120px]" />
            </FlexCol>
        </FlexCol>
    );
};

export type NavigationComponentType = FC<
    PropsWithChildren<{
        selectedId?: string;
        onSelectedIdChange: (id: string) => void;
        className?: ClassValue;
    }>
> & {
    Header: typeof Header;
    Content: typeof Content;
    Group: typeof Group;
    Button: typeof NavigationButton;
};

type NavigationButtonProps = {
    classNames?: string;
    id: string;
    text: string;
    iconName: IconName;
};

export const Navigation: NavigationComponentType = ({
    children,
    className,
    selectedId,
    onSelectedIdChange,
}) => {
    return (
        <NavigationContext.Provider value={{ selectedId, onSelectedIdChange }}>
            <nav
                className={cn(
                    "min-w-0 w-[232px] h-screen shrink-0 flex flex-col bg-primary border-r border-tertiary",
                    className,
                )}
            >
                {children}
            </nav>
        </NavigationContext.Provider>
    );
};

Navigation.Header = Header;
Navigation.Content = Content;
Navigation.Group = Group;
Navigation.Button = NavigationButton;
