import i18next from "@app/i18n";

const enResources = {
    stats_completed: "{{count}} datapoints completed",
    stats_total: "{{count}} total",
    stats_notStarted: "{{ count }} not  started",
    stats_inProgress: "{{ count }} in progress",
    stats_validated: "{{ count }} validated",
    stats_notMaterial: "{{ count }} not material",
    no_data: "No data",
    no_datapoint: "No datapoint for this search",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    stats_completed: "{{count}} datapoints completés",
    stats_total: "{{count}} total",
    stats_notStarted: "{{ count }} non commencés",
    stats_inProgress: "{{ count }} en cours",
    stats_validated: "{{ count }} validés",
    stats_notMaterial: "{{ count }} non matériel",
    no_data: "Pas de données",
    no_datapoint: "Aucun datapoint pour cette recherche",
} as const;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "CsrdPillar";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
