import { queryClient } from "@app/QueryClientWithHeaders";
import { type MutationKey } from "@tanstack/query-core";

/**
 * Performs an optimistic update for a specific query in TanStack Query that will be affected by the mutation.
 * 
 * This function is designed to optimistically update the cache for the query that corresponds to the given mutation variables. 
 * It cancels any outgoing queries related to the targeted query key, takes a snapshot of the current cache state, applies an update
 * to the cached query data, and sets the updated query data in the cache. It can be used within the `onMutate` option of `useMutation`
 * to manage optimistic updates.
 * 
 * @template Variables - The type of the mutation variables.
 * @template Query - The type of the query data being modified.
 * 
 * @param {Variables} variables - The variables passed to the mutation, which determine how the query data will be modified.
 * @param {(variables: Variables) => MutationKey} queryKeyFn - A function that generates the query key for the query to be modified, based on the mutation variables.
 * @param {(variables: Variables, previousQuery: Query) => Query} updateFn - A function that takes the mutation variables and the current query data, and returns the updated query data for the query being modified.
 * 
 * @returns {Promise<{ previousQuery: Query }>} A promise that resolves to an object containing the previous query data for rollback purposes in case of an error.
 * 
 * @throws {Error} If there is no previous query data in the cache for the given query key.
 * 
 * @example
 * const updateFn = (variables, previousQuery) => {
 *    return {
 *      ...previousQuery,
 *      name: variables.newName,
 *    };
 * };
 * 
 * const queryKeyFn = (variables) => ["user", variables.userId];
 * 
 * useMutation({
 *    mutationKey: ...,
 *    mutationFn: ...,
 *    onMutate: (variables) => tanstackUnitaryOptimisticUpdate(variables, queryKeyFn, updateFn),
 *    onError: (error, variables, context) => {
 *    onSettled() {
 *        queryClient.invalidateQueries({
 *            queryKey: ...,
 *        });
 *    },
 * });
 */
async function tanstackUnitaryOptimisticUpdate<
    Variables extends unknown,
    Query extends unknown,
>(
    variables: Variables,
    queryKeyFn: (variables: Variables) => MutationKey,
    updateFn: (variables: Variables, previousQuery: Query) => Query,
): Promise<{ previousQuery: Query }> {
    const queryKey = [...queryKeyFn(variables)];
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
        queryKey,
    });

    // Snapshot the previous value
    const previousQuery: Query | undefined = queryClient.getQueryData(queryKey);

    if (!previousQuery) throw new Error("No previous query found");
    // Get updated query
    const updatedQuery = updateFn(variables, previousQuery);

    // Update the cache with the new query
    queryClient.setQueryData(queryKey, updatedQuery);

    return { previousQuery };
}

/**
 * Creates `onMutate` and `onError` callbacks for a unitary optimistic update in TanStack Query.
 * 
 * This function returns an object containing `onMutate` and `onError` callbacks, which are designed to handle optimistic updates for a specific query that will be modified by the mutation.
 * 
 * - `onMutate`: Called before the mutation is fired, it performs the optimistic update by modifying the query data in the cache based on the provided variables.
 * - `onError`: Called if the mutation fails, it handles the error by rolling back the optimistic update, restoring the previous query data in the cache.
 * 
 * These callbacks are useful when setting up a mutation using `useMutation` to automatically manage optimistic updates and error handling.
 * 
 * @template Variables - The type of the mutation variables.
 * @template Query - The type of the query data being modified.
 * 
 * @param {(variables: Variables) => MutationKey} queryKeyFn - A function that generates the query key for the query to be modified, based on the mutation variables.
 * @param {(variables: Variables, previousQuery: Query) => Query} updateFn - A function that takes the mutation variables and the current query data, and returns the updated query data for the query being modified.
 * 
 * @returns {{
*   onMutate: (variables: Variables) => Promise<{ previousQuery: Query }>,
*   onError: (error: unknown, variables: Variables, context?: { previousQuery?: Query }) => unknown
* }} An object containing the `onMutate` and `onError` callbacks:
* 
* - `onMutate(variables: Variables)`: Performs the optimistic update by calling `tanstackUnitaryOptimisticUpdate` and modifying the cache with the updated query data.
* - `onError(error: unknown, variables: Variables, context?: { previousQuery?: Query })`: Restores the previous query data from the snapshot in case the mutation fails.
* 
* @throws {Error} If there is no previous query data in the cache during the rollback operation.
* 
* @example
* const updateFn = (variables, previousQuery) => {
*   return {
*     ...previousQuery,
*     name: variables.newName, // Optimistically update the user's name
*   };
* };
* 
* const queryKeyFn = (variables) => ["user", variables.userId]; // Query key for the user query being modified
* 
* useMutation({
*    mutationKey: ['updateUser'], // Mutation key for identifying this mutation
*    mutationFn: updateUser, // Function that performs the actual mutation on the server
*    ...tanstackUnitaryOptimisticUpdateOptionsMaker(queryKeyFn, updateFn),
*    onSettled: () => {
*        queryClient.invalidateQueries({
*            queryKey: ["user"], // Invalidate to ensure fresh data after the mutation completes
*        });
*    },
* });
*/

export function tanstackUnitaryOptimisticUpdateOptionsMaker<
    Variables extends unknown,
    Query extends unknown,
>(
    queryKeyFn: (variables: Variables) => MutationKey,
    updateFn: (variables: Variables, previousQuery: Query) => Query,
): {
    onMutate: (variables: Variables) => Promise<{ previousQuery: Query }>;
    onError: (
        error: unknown,
        variables: Variables,
        context?: { previousQuery?: Query },
    ) => unknown;
} {
    return {
        onMutate: async (variables) => {
            return tanstackUnitaryOptimisticUpdate(
                variables,
                queryKeyFn,
                updateFn,
            );
        },
        onError: (err, variables, context) => {
            console.error(err);
            // Rollback the optimistic update
            const queryKey = queryKeyFn(variables);
            if (!context?.previousQuery)
                throw new Error("No previous query found");
            queryClient.setQueryData(queryKey, context.previousQuery);
        },
    };
}
