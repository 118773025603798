import {
    LaunchTutorialButton,
    WrapperWithLaunchTutorialButton,
} from "@app/shared/components/Chameleon/LaunchTutorialButton";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { HelpButton } from "../Chameleon/HelpButton";

interface BodyHeaderProps {
    action?: React.ReactNode;
    dataChameleon?: {
        key: string;
        type: "tutorial" | "help";
    };
    icon?: React.ForwardRefExoticComponent<any>;
    subTitle: React.ReactNode;
    title: string;
}

export default function BodyHeader(props: BodyHeaderProps) {
    return (
        <div className="border-b border-neutral-150 py-4 px-7 flex gap-3">
            <div className="flex-grow flex gap-4">
                <div>
                    <FlexRow gap="2">
                        <Text variant="header1">{props.title}</Text>
                        <button></button>
                    </FlexRow>
                    <FlexRow gap="2" className="text-secondary">
                        <Text color="secondary" variant="body">
                            {props.subTitle}
                            {props.dataChameleon?.type === "help" && (
                                <HelpButton
                                    className="align-middle ml-2"
                                    dataChameleonKey={props.dataChameleon.key}
                                />
                            )}
                        </Text>
                    </FlexRow>
                </div>
            </div>
            {props.dataChameleon?.type === "tutorial" && props.action ? (
                <WrapperWithLaunchTutorialButton
                    chameleonData={props.dataChameleon.key}
                >
                    <div className="flex items-center space-x-2">
                        {props.action}
                    </div>
                </WrapperWithLaunchTutorialButton>
            ) : props.dataChameleon?.type === "tutorial" && !props.action ? (
                <LaunchTutorialButton chameleonData={props.dataChameleon.key} />
            ) : (
                <div className="flex items-center space-x-2">
                    {props.action}
                </div>
            )}
        </div>
    );
}
