import {
    DatapointUpsertInput,
    ExportReportingDocument,
    ExportReportingFilesDocument,
    ExportReportingMutation,
    ReportingExportFilesInput,
    ReportingExportInput,
    UpsertDatapointV2Document,
    UpsertDatapointV2Mutation,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { DatapointRepository } from "../DatapointRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLDatapointRepository implements DatapointRepository {
    upsert = async (input: {
        set: DatapointUpsertInput;
    }): Promise<UpsertDatapointV2Mutation> => {
        return graphqlClientKoyeb.request(
            UpsertDatapointV2Document,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    export = async (
        input: ReportingExportInput,
    ): Promise<ExportReportingMutation> => {
        return graphqlClientKoyeb.request(
            ExportReportingDocument,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    exportFiles = async (input: ReportingExportFilesInput) => {
        return graphqlClientKoyeb.request(
            ExportReportingFilesDocument,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
