import { UnitRepository } from "@app/repositories/UnitRepository";
import { GetUnitsDocument, GetUnitsQuery } from "@generated/client/graphql";
import { AccessHeadersHolder } from "../../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../../clients";

export class GraphQLUnitRepository implements UnitRepository {
    getAll = async (): Promise<GetUnitsQuery> => {
        return graphqlClientKoyeb.request(
            GetUnitsDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
