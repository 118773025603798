import { GraphQLCsrdRepository } from "@app/repositories/GraphQLRepositories/csrd";
import { type Pagination } from "@app/shared/types/API.types";
import {
    type CsrdDatapointsFilter,
    type CsrdPillar,
} from "@generated/client/graphql";
import { skipToken, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { frameworkKeys } from "../../data";
import {
    enrichDatapoints,
    getCsrdAssignmentStats,
    getEsrsDisplayStats,
    getPillarByEsrsid,
    getPillarsValidationStats,
    makeCmsDisclosureRequirements,
    makeCompletionStatOption,
    makePillars,
} from "./services";
const csrdRepository = new GraphQLCsrdRepository();

export const csrdKeys = {
    all: [...frameworkKeys.all, "csrd"] as const,
    pillars: () => [csrdKeys.all, "pillars"] as const,
    stats: () => [...csrdKeys.all, "stats"] as const,
    statsDetails: () => [...csrdKeys.stats(), "details"] as const,
    datapoint: () => [...csrdKeys.all, "datapoint"] as const,
    datapointDetails: (id: string) => [...csrdKeys.datapoint(), id] as const,
    datapoints: () => [...csrdKeys.all, "datapoints"] as const,
    datapointsDetails: (filter: CsrdDatapointsFilter, pagination: Pagination) =>
        [...csrdKeys.datapoints(), filter, pagination] as const,
};

export function usePillars() {
    const { data, ...query } = useQuery({
        queryKey: csrdKeys.pillars(),
        queryFn: csrdRepository.getPillars,
    });

    return { ...query, pillars: makePillars(data?.pillars) };
}

export function useGetPillarByEsrsId(filter: { esrsId?: string | null }) {
    const { pillars, ...query } = usePillars();
    const pillar = useMemo(
        () => getPillarByEsrsid(pillars?.list, filter.esrsId ?? ""),
        [pillars, filter.esrsId],
    );

    return { ...query, pillar };
}

export const useCsrdStats = () => {
    const { data, ...query } = useQuery({
        queryKey: csrdKeys.stats(),
        queryFn: csrdRepository.getGlobalStats,
    });

    const stats = data?.csrdStats;
    const completionStats = stats ? makeCompletionStatOption(stats) : undefined;
    const assignmentStats = getCsrdAssignmentStats(stats);
    return { ...query, stats, completionStats, assignmentStats };
};

export const useCsrdOverview = () => {
    const query = useQuery({
        queryKey: csrdKeys.statsDetails(),
        queryFn: csrdRepository.getSectionsAndStats,
    });
    const pillars = query?.data?.pillars;
    const pillarsValidationStats = getPillarsValidationStats(
        pillars as CsrdPillar[],
    );
    const esrsDisplayStats = getEsrsDisplayStats(pillars as CsrdPillar[]);

    return { ...query, pillars, pillarsValidationStats, esrsDisplayStats };
};

export function useCsrdDatapoint(id?: string) {
    const { data, ...query } = useQuery({
        queryKey: csrdKeys.datapointDetails(id ?? ""),
        queryFn: id ? () => csrdRepository.getDatapoint({ id }) : skipToken,
    });

    return { ...query, datapoint: data?.csrdDatapoint };
}

export const useCsrdDatapoints = (
    filter: CsrdDatapointsFilter = {},
    pagination: Pagination,
) => {
    const { data, ...query } = useQuery({
        queryKey: csrdKeys.datapointsDetails(filter, pagination),
        queryFn: () => csrdRepository.getDatapoints({ filter, ...pagination }),
    });

    return {
        ...query,
        datapoints: data?.csrdDatapoints?.data,
        enrichedDatapoints: enrichDatapoints(data?.csrdDatapoints?.data),
        count: data?.csrdDatapoints?.count,
    };
};

export function useCmsCsrdEsrssList() {
    const { pillars, ...query } = usePillars();

    const esrssList = pillars?.list?.map((pillar) => pillar.esrss).flat();
    return { ...query, esrssList };
}

export function useCmsCsrdDisclosureRequirementsList() {
    const { esrssList, ...query } = useCmsCsrdEsrssList();

    const disclosureRequirements = makeCmsDisclosureRequirements(esrssList);

    return {
        ...query,
        disclosureRequirements,
    };
}
