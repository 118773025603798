import i18next from "../../../i18n";

i18next.addResourceBundle("en", "LoadIndicators", {
    title: "Load indicators",
    subtitle: "Load indicators from a CSV file",
    existing_tags: "Existing tags",
    new_tags: "New tags: ",
    add_new_tags: "Add new tags",
});

i18next.addResourceBundle("fr", "LoadIndicators", {
    title: "Charger des indicateurs",
    subtitle: "Charger des indicateurs depuis un fichier CSV",
    existing_tags: "Tags existants",
    new_tags: "Nouveaux tags: ",
    add_new_tags: "Ajouter les nouveaux tags",
});
