import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentActivationStatus,
    DocumentType,
} from "../../../../../generated/client/graphql";
import SearchFilter from "../../../../components/Filters/Search";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { useUpdateDocuments } from "../../hooks";
import { useDocumentPageState } from "../../hooks/pageState";
import { useUnrelevantDocumentsTable } from "../DocumentsTable";
import "./i18n";
import { Table } from "@design-system/Table";
import { Button } from "@design-system/Inputs/Button";
export default function UnrelevantDocumentsModal({
    documents,
    type,
    open,
    setOpen,
}: {
    documents: Document[];
    type: DocumentType;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("UnrelevantDocumentsModal");

    const { mutate, isPending } = useUpdateDocuments();
    const { columns, data, tableOptions } = useUnrelevantDocumentsTable(
        documents,
        type,
    );
    const rowSelection = tableOptions.state.rowSelection;
    const selectedDocuments = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return data[Number(idx)];
        });

    const onSetRelevant = () => {
        mutate(
            selectedDocuments.map((d) => d.id),
            { activationStatus: DocumentActivationStatus.Relevant },
        );
        tableOptions.onRowSelectionChange({});
    };
    const { state, setState } = useDocumentPageState();

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                className="w-[820px] h-[500px]"
                title={t("title")}
                actions={
                    <Button
                        onClick={onSetRelevant}
                        loading={isPending}
                        disabled={selectedDocuments.length === 0}
                    >
                        {t("set_relevant", {
                            count: selectedDocuments.length,
                        })}
                    </Button>
                }
            >
                <div className="w-[820px]">
                    {documents ? (
                        <div className="flex flex-col gap-4 h-[500px]">
                            <SearchFilter
                                searchString={state.unrelevantSearchFilter}
                                setSearchString={(unrelevantSearchFilter) =>
                                    setState({ unrelevantSearchFilter })
                                }
                                className="w-full box-border"
                            />
                            <div className="h-full overflow-auto">
                                <Table
                                    columns={columns}
                                    data={data}
                                    options={tableOptions}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>Error, refresh ...</div>
                    )}
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
