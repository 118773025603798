import React, { FunctionComponent } from "react";
import { ButtonProps } from "./ButtonShared";

const ButtonOutline = React.forwardRef(
    (
        {
            Base,
            className,
            ...props
        }: {
            Base: FunctionComponent<
                ButtonProps & {
                    buttonClassName?: string;
                    textClassName?: string;
                    iconClassName?: string;
                } & React.ButtonHTMLAttributes<HTMLButtonElement>
            >;
        } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement> &
            React.RefAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <Base
                ref={forwardedRef}
                className={`bg-white hover:bg-secondary active:bg-tertiary  ${
                    props.active
                        ? " border-brand border-2 bg-tertiary"
                        : "border border-secondary"
                } ${className}`}
                textClassName="text-primary"
                iconClassName="text-primary"
                {...props}
            />
        );
    }
);

export default ButtonOutline;
