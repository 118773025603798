import { sendForReviewAtom, versionAtom } from "@app/store/versionStore";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useAtom, useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ValidationModal, validationModalAtom } from "../../ctx";
import { RESOURCE_NAME, keys } from "./i18n";
import { useUnsendReviewRequest } from "../../data";

export const SendForReviewModal: FC = () => {
    const [openModal, setOpenModal] = useAtom(validationModalAtom);
    const isOpen = openModal === ValidationModal.sendForReview;
    const closeModal = () => setOpenModal(null);

    const {
        mutate: sendForReviewMutation,
        isPending: isRequestForApprovalPending,
    } = useAtomValue(sendForReviewAtom);

    const sendForReview = () => sendForReviewMutation();

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <Modal open={isOpen} onOpenChange={closeModal}>
            <Modal.Overlay centerContent>
                <Modal.Content className={"w-[444px] text-primary"}>
                    <Modal.Header>
                        <Modal.Title>{t(keys.are_you_sure)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Text>{t(keys.after_sending)}</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isRequestForApprovalPending}
                            onClick={sendForReview}
                        >
                            {t(keys.confirm)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

export const UnsendReviewRequestModal = () => {
    const { data: { version } = { version: undefined } } =
        useAtomValue(versionAtom);

    const [openModal, setOpenModal] = useAtom(validationModalAtom);
    const isOpen = openModal === ValidationModal.unsendReviewRequest;
    const closeModal = () => setOpenModal(null);

    const { mutateAsync, isPending } = useUnsendReviewRequest();
    const unsendReviewRequest = async () => {
        if (!version?.id) throw new Error("Version ID is required");
        await mutateAsync(version.id);
        closeModal();
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <Modal open={isOpen} onOpenChange={closeModal}>
            <Modal.Overlay centerContent>
                <Modal.Content className="w-[444px] text-primary">
                    <Modal.Header>
                        <Modal.Title>{t(keys.unsend_title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Text>{t(keys.unsend_body)}</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isPending}
                            onClick={unsendReviewRequest}
                            variant="flatDanger"
                        >
                            {t(keys.confirm)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
