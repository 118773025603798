import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { type FC } from "react";
import { CreateCustom } from "./CreateCustomTab";
import { FilterTab } from "./FilterTab";
import { MatchedTab } from "./MatchedTab";
import {
    tab,
    useDocumentsMultidropdownSelect,
    type DocumentsMultidropdownSelectProps,
} from "./useDocumentsDropdownMultiselect";

export const DocumentsDropdownMultiselect: FC<
    DocumentsMultidropdownSelectProps
> = ({ fileKey }) => {
    const {
        closeCustomDoc,
        currentTab,
        docIds,
        dropdownTriggerTxt,
        handleNameFilterChange,
        handleSubthemesFilterChange,
        handleOpenChange,
        handleValuesChange,
        nameFilter,
        openCustomDocTab,
        openFilterTab,
        subthemesFilter,
        suggestions,
    } = useDocumentsMultidropdownSelect({ fileKey });
    return (
        <MultiSelect
            modal={false}
            onOpenChange={handleOpenChange}
            onValuesChange={handleValuesChange}
            replaceTrigger
            values={docIds}
        >
            <MultiSelect.Trigger>
                <Button size="sm" variant="outlined" className="w-fit">
                    {dropdownTriggerTxt}
                    <Button.Icon name="angleDown" />
                </Button>
            </MultiSelect.Trigger>
            <MultiSelect.Content className="w-[436px]" hasPortal={false}>
                {currentTab === tab.filter ? (
                    <FilterTab
                        onNameFilterChange={handleNameFilterChange}
                        onSubthemesFilterChange={handleSubthemesFilterChange}
                        nameFilter={nameFilter}
                        subthemesFilter={subthemesFilter}
                        openCustomDocTab={openCustomDocTab}
                        suggestions={suggestions}
                    />
                ) : currentTab === tab.createCustom ? (
                    <CreateCustom fileKey={fileKey} onClose={closeCustomDoc} />
                ) : currentTab === tab.matched ? (
                    <MatchedTab
                        onSearchForMoreClick={openFilterTab}
                        suggestions={suggestions}
                    />
                ) : null}
            </MultiSelect.Content>
        </MultiSelect>
    );
};
