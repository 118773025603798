import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import BodyHeader from "../../../shared/components/BodyHeader";

import * as RadioGroup from "@radix-ui/react-radio-group";
import { useAtomValue } from "jotai";
import Papa from "papaparse";
import { ChangeEvent, useEffect, useState } from "react";
import Checkbox from "../../../components/Checkbox";
import { IndicatorImport, columnMapAtom } from "./components/columns";
import { useRichedIndicatorImports } from "./components/hooks";
import IndicatorImportTable from "./components/table";
import LoadTags from "./components/tags";
import "./i18n";

const useParsedData = (rawCsvData?: any[]) => {
    const mapping = useAtomValue(columnMapAtom);

    const [newIndicators, setNewIndicators] = useState<IndicatorImport[]>();

    useEffect(() => {
        const inds = rawCsvData?.map((row) => {
            var indicatorInput: any = {
                csv: {},
            };

            for (let [key, value] of mapping) {
                indicatorInput.csv[key] = row[value];
            }
            return indicatorInput as IndicatorImport;
        });

        setNewIndicators(inds);
    }, [mapping, rawCsvData]);

    return { newIndicators };
};

export enum ImportType {
    Reporting = "reporting",
    Supplier = "supplier",
}
export default function Load() {
    const { t } = useTranslation("LoadIndicators");

    const [importType, setImportType] = useState<ImportType>();

    const [rawCsvData, setRawCsvData] = useState<any[]>();
    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.target.files &&
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results: any) {
                    setRawCsvData(results.data);
                },
            });
    };

    const { newIndicators } = useParsedData(rawCsvData);
    const { richIndicators } = useRichedIndicatorImports(
        newIndicators,
        importType,
    );
    return (
        <>
            <Container>
                <BodyHeader title={t("title")} subTitle={t("subtitle")} />
                <div className="p-4 space-y-4">
                    <input
                        type="file"
                        onChange={onFileChange}
                        multiple={false}
                    />
                    <LoadTags newIndicators={richIndicators} />
                    <RadioGroup.Root
                        value={importType}
                        onValueChange={(s) => setImportType(s as ImportType)}
                        className="flex space-x-4"
                    >
                        <RadioGroup.Item
                            value={ImportType.Reporting}
                            className="flex items-center space-x-2"
                        >
                            <Checkbox
                                checked={importType === ImportType.Reporting}
                            />
                            <div>Reporting</div>
                        </RadioGroup.Item>
                        <RadioGroup.Item
                            value={ImportType.Supplier}
                            className="flex items-center space-x-2"
                        >
                            <Checkbox
                                checked={importType === ImportType.Supplier}
                            />
                            <div>Supplier</div>
                        </RadioGroup.Item>
                    </RadioGroup.Root>

                    {richIndicators && (
                        <IndicatorImportTable
                            newIndicators={richIndicators}
                            importType={importType}
                        />
                    )}
                </div>
            </Container>
        </>
    );
}
