import { Icon } from "@design-system/Icon";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@design-system/Table/HTMLTable";
import { Text } from "@design-system/Typography/Text";
import { Button } from "@design-system/Inputs/Button";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";
import { RESOURCE_NAME } from "./i18n";
import { type ClassValue, cn } from "@design-system/Utilities";

export const DataValueTable: FC<{
    keyValuePairs: { key: string; value: string }[];
    setKeyValuePairs: Dispatch<
        SetStateAction<{ key: string; value: string }[] | undefined>
    >;
    className?: ClassValue;
    handleRowChange?: (
        e: ChangeEvent<HTMLInputElement>,
        idx: number,
        field: "key" | "value",
    ) => void;
    handleRowDelete?: (idx: number) => void;
    handleRowAdd?: () => void;
}> = ({
    keyValuePairs,
    setKeyValuePairs,
    className,
    handleRowChange,
    handleRowDelete,
    handleRowAdd,
}) => {
    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        idx: number,
        field: "key" | "value",
    ) => {
        if (handleRowChange) {
            handleRowChange(e, idx, field);
        } else {
            setKeyValuePairs((k) => {
                const newPairs = [...(k || [])];
                newPairs[idx][field] = e.target.value;
                return newPairs;
            });
        }
    };

    const handleAddValueClick = () => {
        if (handleRowAdd) {
            handleRowAdd();
        } else {
            setKeyValuePairs((k) => [...(k || []), { key: "", value: "" }]);
        }
    };

    const handleDeleteClick = (idx: number) => {
        return () => {
            if (handleRowDelete) {
                handleRowDelete(idx);
            } else {
                setKeyValuePairs((k) => {
                    const newPairs = [...(k || [])];
                    newPairs.splice(idx, 1);
                    return newPairs;
                });
            }
        };
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            className={cn(
                "w-full p-2 gap-2 border rounded-xl bg-white shadow-md",
                className,
            )}
        >
            <Table className="w-full">
                <TableHeader>
                    <TableRow>
                        <TableHead className="border-r h-4 p-2 min-w-[174px] text-primary font-bold">
                            {t("category")}
                        </TableHead>
                        <TableHead className="border-r h-4 p-2 min-w-[174.5px] text-primary font-bold">
                            {t("value")}
                        </TableHead>
                        <TableHead className="h-4 p-0 min-w-[34.5px]" />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {keyValuePairs.map((keyValuePair, idx) => (
                        <TableRow key={idx}>
                            <TableCell className="border-r p-2">
                                <input
                                    className="bg-transparent w-full p-0 font-default text-base font-regular border-0 focus:ring-0"
                                    placeholder={t("add_category_placeholder")}
                                    value={keyValuePair.key}
                                    onChange={(e) =>
                                        handleInputChange(e, idx, "key")
                                    }
                                />
                            </TableCell>
                            <TableCell className="border-r p-2">
                                <input
                                    className="bg-transparent w-full p-0 font-default text-base font-regular border-0 focus:ring-0"
                                    placeholder={t("add_value_placeholder")}
                                    value={keyValuePair.value}
                                    onChange={(e) =>
                                        handleInputChange(e, idx, "value")
                                    }
                                />
                            </TableCell>
                            <TableCell className="p-0 w-4">
                                <Button
                                    variant="plain"
                                    size="md"
                                    className="hover:text-danger"
                                    onClick={handleDeleteClick(idx)}
                                >
                                    <Icon name="trash" size="sm" />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button
                variant="outlined"
                className="w-full py-1 px-1.5"
                onClick={handleAddValueClick}
            >
                <Text variant="body-sm"> {t("add_value")} </Text>
                <Icon name="plus" size="xs" />
            </Button>
        </FlexCol>
    );
};
