import { GraphQLEntityRepository } from "@app/repositories/GraphQLRepositories/EntityRepository";
import { useQuery } from "@tanstack/react-query";

export const entityKeys = {
    all: ["entities"] as const,
};

const entityRepository = new GraphQLEntityRepository();

export const useEntities = () => {
    const query = useQuery({
        queryKey: entityKeys.all,
        queryFn: entityRepository.getMany,
    });

    return {
        entities: query.data?.entities,
        ...query,
    };
};
