import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFragment } from "../../../../generated/client/fragment-masking";
import {
    CustomizationFieldsFragmentDoc,
    GetSupplierQuestionnaireDataRequestsForContributionDocument,
    GetSupplierQuestionnnaireForContributorPageDocument,
    SupplierQuestionnaire,
} from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import Chip from "../../../components/Chip";
import { hexToColor } from "../../../utils/color";
import Background from "../components/Background";
import Footer from "../components/Footer";
import Progress from "../components/Progress";
import LastSavedBanner from "../components/SaveBanner";
import TitleBar from "../components/TitleBar";
import { activeGroupIdxAtom, useNavigate } from "../shared/navigate";
import { groupDataRequestsKeepOrder } from "../utils/group";
import CloseQuestionnaireButton from "./CloseQuestionnaireButton";
import DataEntry from "./DataEntry";
import "./i18n";

const SupplierContribution = () => {
    const { t } = useTranslation("SupplierContribution");

    const { questionnaire_id } = useParams<{
        questionnaire_id: string;
    }>();

    const { data, isLoading } = useGraphQL(
        GetSupplierQuestionnaireDataRequestsForContributionDocument,
        {
            questionnaireId: questionnaire_id!,
        },

        {
            enabled: questionnaire_id ? true : false,
        },
    );

    const questionnaireQuery = useGraphQL(
        GetSupplierQuestionnnaireForContributorPageDocument,
        {
            id: questionnaire_id!,
        },
        {
            enabled: questionnaire_id ? true : false,
        },
    );
    const supplierQuestionnaire = questionnaireQuery.data
        ?.supplierQuestionnaire as SupplierQuestionnaire | undefined;
    const customization = useFragment(
        CustomizationFieldsFragmentDoc,
        questionnaireQuery.data?.supplierQuestionnaire.customization,
    );

    const { groups } = useMemo(
        () => groupDataRequestsKeepOrder(data?.supplierQuestionnaireRequests),
        [data?.supplierQuestionnaireRequests],
    );

    const { scrollToGroup } = useNavigate(groups?.length || 0);
    const activeGroupIdx = useAtomValue(activeGroupIdxAtom);

    useEffect(() => {
        console.log("questionnaireid, ", questionnaire_id);
    }, [questionnaire_id]);
    return (
        <div className="flex flex-col items-center">
            <Background />
            <div className="z-10 top-0 sticky flex flex-col items-center bg-white">
                <LastSavedBanner color={hexToColor(customization?.hexColor)} />
                <TitleBar
                    submitButton={
                        <CloseQuestionnaireButton
                            supplierQuestionnaire={supplierQuestionnaire}
                            color={hexToColor(customization?.hexColor)}
                        />
                    }
                    logoSrc={customization?.logo?.signedUrl || undefined}
                >
                    {groups?.map((group, idx) => (
                        <Chip
                            key={idx}
                            name={
                                group.subtheme?.name || group.theme?.name || ""
                            }
                            value={group.dataRequests.length}
                            selected={idx === activeGroupIdx}
                            onClick={() => scrollToGroup(idx)}
                            bgColor={hexToColor(customization?.hexColor)}
                        />
                    ))}
                </TitleBar>
                <Progress
                    value={(activeGroupIdx || 0) + 1}
                    total={groups?.length || 1}
                    color={hexToColor(customization?.hexColor)}
                />
            </div>
            <div className="py-9 flex flex-col items-center space-y-6">
                {supplierQuestionnaire?.status === "CLOSED" ? (
                    <div className="text-sm font-regular">
                        {t("closed_questionnaire_message")}
                    </div>
                ) : (
                    <DataEntry
                        groups={groups}
                        color={hexToColor(customization?.hexColor)}
                        loading={isLoading}
                        supplierQuestionnaire={supplierQuestionnaire}
                    />
                )}
                <Footer logoSrc={customization?.logo?.signedUrl || undefined} />
            </div>
        </div>
    );
};

export default SupplierContribution;
