import {
    CreateIndicatorInput,
    CreateIndicatorsDocument,
    CreateIndicatorsMutation,
    GetIndicators2Document,
    GetIndicators2Query,
    IndicatorPatch,
    ReportingRemindersInput,
    SendReportingRemindersDocument,
    SendReportingRemindersMutation,
    UpdateIndicatorDocument,
    UpdateIndicatorInput,
    UpdateIndicatorMutation,
    UpdateIndicatorsDocument,
    UpdateIndicatorsMutation,
} from "../../../../generated/client/graphql";
import { AccessHeadersHolder } from "../../AccessHeadersHolder";
import { IndicatorRepository } from "../../IndicatorRepository";
import { graphqlClientKoyeb } from "../../clients";
export class GraphQLIndicatorRepository implements IndicatorRepository {
    get = async (entityId: string): Promise<GetIndicators2Query> => {
        return graphqlClientKoyeb.request(
            GetIndicators2Document,
            { entityId },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    update = async (input: {
        id: string;
        patch: IndicatorPatch;
    }): Promise<UpdateIndicatorMutation> => {
        return graphqlClientKoyeb.request(
            UpdateIndicatorDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
    updateMany = async (
        inputs: UpdateIndicatorInput[],
    ): Promise<UpdateIndicatorsMutation> => {
        return graphqlClientKoyeb.request(
            UpdateIndicatorsDocument,
            { inputs },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    sendReminders = async (
        input: ReportingRemindersInput,
    ): Promise<SendReportingRemindersMutation> => {
        return graphqlClientKoyeb.request(
            SendReportingRemindersDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createManyCustomIndicators = async (
        inputs: CreateIndicatorInput[],
    ): Promise<CreateIndicatorsMutation> => {
        return graphqlClientKoyeb.request(
            CreateIndicatorsDocument,
            { inputs },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
