import { useGraphQLMutation } from "@app/QueryClientWithHeaders";
import ChooseLocale from "@app/components/inputs_v2/ChooseLocale";
import { useCreateEditorContext } from "@app/screens/Document/Edit/hooks/useEditor";
import {
    previewTemplateModalOpenAtom,
    previewTemplateModalTemplatesAtom,
} from "@app/screens/Document/components/CreateVersionBlock/ctx";
import { currentUserAtom } from "@app/store/userStore";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { CreateFromTemplateDocument } from "@generated/client/graphql";
import { EditorContent } from "@tiptap/react";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./i18n";

const PreviewTemplateModal = ({
    showCTA,
    docId,
}: {
    showCTA?: boolean;
    docId?: string;
}) => {
    const { t } = useTranslation("PreviewTemplateModal");
    const [open, setOpen] = useAtom(previewTemplateModalOpenAtom);
    const templates = useAtomValue(previewTemplateModalTemplatesAtom);
    const currentUser = useAtomValue(currentUserAtom);
    const navigate = useNavigate();
    const [locale, setLocale] = useState<string>(
        currentUser.data?.currentUser.locale,
    );
    const { data } = useAtomValue(currentUserAtom);

    const { editor } = useCreateEditorContext({
        tipTapDocId: templates?.find((t) => t.locale === locale)?.tiptapDocId,
        tipTapJwt: templates?.find((t) => t.locale === locale)?.tiptapDocJwt,
        user: data?.currentUser,
        editable: false,
    });

    const createNewVersionMutation = useGraphQLMutation(
        CreateFromTemplateDocument,
    );

    const handleCTAClick = () => {
        if (!locale || !docId) return;

        createNewVersionMutation.mutate(
            {
                id: docId,
                locale: locale,
            },
            { onSuccess: () => navigate("edit") },
        );
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay className="py-5" scrollable>
                <Modal.Content className="w-[860px]">
                    <Modal.Header className="pb-2">
                        <FlexRow
                            gap="3"
                            alignItems="center"
                            justifyContent="between"
                        >
                            <Modal.Title>{t("template")}</Modal.Title>
                            <ChooseLocale
                                locale={locale}
                                setLocale={setLocale}
                                availableLocales={templates?.map(
                                    (t) => t.locale,
                                )}
                            />
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body className="max-h-[650px]">
                        <FlexCol gap="4" h="full" className="overflow-scroll">
                            <EditorContent editor={editor} />
                        </FlexCol>
                    </Modal.Body>
                    {showCTA && (
                        <Modal.Footer>
                            <Modal.CancelCTA>{t("cancel")}</Modal.CancelCTA>
                            <Modal.ConfirmCTA onClick={handleCTAClick}>
                                {t("create_version")}
                            </Modal.ConfirmCTA>
                        </Modal.Footer>
                    )}
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

export default PreviewTemplateModal;
