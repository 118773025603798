import { currentUserAtom, currentUserIsBeavrAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { type PlanhatPageview, type PlanhatPageviewsDetails } from "./types";
import { useDebouncedStableCallback } from "@app/shared/utils/debounce";

declare global {
    interface Window {
        plantrack: {
            identify(
                userId: string | null,
                opts: { email: string; companyExternalId: string },
            ): void;
            track(event: string, properties: any): void;
        };
    }
}

window.plantrack = window.plantrack ?? {};

export function useInitPlanhat() {
    const { org_uname } = useParams<{ org_uname: string }>();
    const { data } = useAtomValue(currentUserAtom);
    const { isBeavrUser, isPending: isBeavrUserPending } = useAtomValue(
        currentUserIsBeavrAtom,
    );

    const preventInit =
        !org_uname ||
        !data?.currentUser.email ||
        isBeavrUserPending ||
        isBeavrUser;

    useEffect(() => {
        if (preventInit) return;

        window.plantrack.identify(null, {
            email: data.currentUser.email,
            companyExternalId: org_uname,
        });
    }, [org_uname, data?.currentUser.email, preventInit]);
}

export function usePageviewTrack<T extends PlanhatPageview>(
    ...[event, properties]: PlanhatPageviewsDetails[T] extends undefined
        ? [T]
        : [T, PlanhatPageviewsDetails[T]]
) {
    const debouncedTrack = useDebouncedStableCallback(
        window.plantrack.track,
        1000,
    );
    useEffect(() => {
        debouncedTrack(event, properties);
    }, [debouncedTrack, event, properties]);
}
