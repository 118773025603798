import { Modal } from "@design-system/Modal";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { bulkUploadInputsAtom } from "./DocumentsUploadModalContext";
import { useModalActions } from "./useModalActions";

export const DocumentsUploadModalCTA = () => {
    const { t } = useTranslation("DocumentsUploadModal");
    const { handleCreateVersions, isCreateVersionLoading } = useModalActions();
    const bulkUploadIntputs = useAtomValue(bulkUploadInputsAtom);
    return (
        <>
            <Modal.CancelCTA loading={isCreateVersionLoading}>
                {t("cancel")}
            </Modal.CancelCTA>
            <Modal.ConfirmCTA
                loading={isCreateVersionLoading}
                onClick={handleCreateVersions}
                preventClose
            >
                {t("confirm", {
                    count: bulkUploadIntputs.reduce(
                        (acc, file) =>
                            acc + (file.associatedDocuments?.length ?? 0),
                        0,
                    ),
                })}
            </Modal.ConfirmCTA>
        </>
    );
};
