import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "SitesTable", {
    site: "Nom",
    shortName: "Nom court",
    type: "Type",
    edit_site: "Modifier",
    delete_site: "Supprimer",
    delete_tooltip: "This entity is associated with data and cannot be deleted",
    delete_error: "An error occurred while deleting the site",
});

i18next.addResourceBundle("fr", "SitesTable", {
    site: "Nom",
    shortName: "Nom court",
    type: "Type",
    edit_site: "Modifier",
    delete_site: "Supprimer",
    delete_tooltip:
        "Cette entité est associé à des données et ne peut pas être supprimé",
    delete_error: "Une erreur est survenue lors de la suppression du site",
});
