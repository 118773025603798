import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    GetAvailableThemesDocument,
    SubTheme,
    UpdateSubThemeInput,
    UpdateSubthemesDocument,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import Accordion from "../../../../components/Accordion";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import Loading from "../../../../components/Loading";
import { setSaveBarStateAtom } from "../../../../components/SaveBar";
import { orderSort } from "../../../../lib/utils";
import Subthemes from "../Subthemes";
import "./i18n";

export interface AvailableSubtheme {
    id: string;
    active: boolean;
    name?: string | undefined | null;
}

export const updateSubthemeInputsAtom = atom<UpdateSubThemeInput[]>([]);

export const updateSubthemeInputsSetterAtom = atom(
    null,
    (get, set, newVal: UpdateSubThemeInput) => {
        const current = get(updateSubthemeInputsAtom);
        const index = current.findIndex((e) => e.id === newVal.id);
        if (index === -1) {
            set(updateSubthemeInputsAtom, [...current, newVal]);
        } else {
            const newCurrent = [...current];
            newCurrent[index] = newVal;
            set(updateSubthemeInputsAtom, newCurrent);
        }
    },
);

export default function ThemesList() {
    const { t } = useTranslation("ThemeList");
    const { isLoading, error, data, refetch } = useGraphQL(
        GetAvailableThemesDocument,
    );

    const { mutate, isPending: updateLoading } = useGraphQLMutation(
        UpdateSubthemesDocument,
    );

    const [updateSubthemeInputs, setUpdateSubthemeInputs] = useAtom(
        updateSubthemeInputsAtom,
    );

    const setSaveBarState = useSetAtom(setSaveBarStateAtom);

    const onSave = () => {
        mutate(
            { inputs: updateSubthemeInputs },
            {
                async onSuccess() {
                    await refetch();
                    setUpdateSubthemeInputs([]);
                },
            },
        );
    };

    useEffect(() => {
        setSaveBarState({
            open: updateSubthemeInputs.length > 0,
            loading: updateLoading,
            content: (
                <div className=" text-white regular-highlight">
                    {t("message")}
                </div>
            ),
            mainButton: (
                <ButtonRegular
                    text={t("buttonSaveTitle")}
                    onClick={() => onSave()}
                    loading={updateLoading}
                />
            ),
            onReset: () => setUpdateSubthemeInputs([]),
        });
    }, [updateSubthemeInputs, updateLoading, onSave, setUpdateSubthemeInputs]);

    if (error) return <div>Error: {`${error}`}</div>;
    return (
        <>
            <div className="rounded-t-lg border border-neutral-150 divide-y">
                {isLoading && <Loading size="medium" />}
                {data &&
                    orderSort(data.themes).map((theme) => {
                        return (
                            <Accordion
                                key={theme.id}
                                title={theme.name || ""}
                                color={theme.color || "green"}
                                tags={<></>}
                            >
                                <div className="w-full">
                                    <Subthemes
                                        subthemes={
                                            orderSort(
                                                theme.subthemes,
                                            ) as SubTheme[]
                                        }
                                        themeId={theme.id}
                                    />
                                </div>
                            </Accordion>
                        );
                    })}
            </div>
        </>
    );
}
