import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";
import { VerifiedUser } from "iconoir-react";
import { useEffect, useState } from "react";
import {
    GetUserDetailsDocument,
    GiveAdminAccessDocument,
    UpdateUserDocument,
    User,
    UserRole,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import InputSimple from "../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../components/Toast";
import InputSelectAvatar from "../../../../components/inputs/InputSelect/Avatar";
import "./i18n";

export const toUpdateUserIdAtom = atom<string | undefined>(undefined);

export default function UpdateUserModal({ user }: { user: User | undefined }) {
    const { t } = useTranslation("UpdateUserModal");

    const [toUpdateUserId, setToUpdateUserId] = useAtom(toUpdateUserIdAtom);

    const [firstname, setFirstname] = useState<string | undefined>(
        user?.firstName,
    );
    const [lastname, setLastname] = useState<string | undefined>(
        user?.lastName,
    );
    const [department, setDepartment] = useState<string | undefined>(
        user?.department || undefined,
    );
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(
        user?.avatar?.url,
    );
    const client = useQueryClient();
    const { mutate, isPending } = useGraphQLMutation(UpdateUserDocument);
    const { mutate: giveAdminAccess, isPending: adminIsLoading } =
        useGraphQLMutation(GiveAdminAccessDocument);

    const { setToastSuccess, setToastError } = useSetToast();

    const canSave = true;
    const handleSave = () => {
        canSave &&
            toUpdateUserId &&
            mutate(
                {
                    id: toUpdateUserId,
                    firstname: firstname,
                    lastname: lastname,
                    department: department,
                    avatarUrl: avatarUrl,
                },
                {
                    onSuccess() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetUserDetailsDocument),
                        });
                        setToUpdateUserId(undefined);
                        setToastSuccess(t("user_update_success"));
                    },
                    onError() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetUserDetailsDocument),
                        });
                        setToUpdateUserId(undefined);
                        setToastError(t("user_update_error"));
                    },
                },
            );
    };
    const handleGiveAdminAccess = () => {
        toUpdateUserId &&
            giveAdminAccess(
                {
                    id: toUpdateUserId,
                },
                {
                    onSuccess() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetUserDetailsDocument),
                        });
                        setToastSuccess(t("give_admin_success"));
                    },
                    onError() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetUserDetailsDocument),
                        });
                        setToUpdateUserId(undefined);
                        setToastError(t("give_admin_error"));
                    },
                },
            );
    };

    useEffect(() => {
        setFirstname(user?.firstName);
        setLastname(user?.lastName);
        setDepartment(user?.department || "");
        setAvatarUrl(user?.avatar?.url);
    }, [user]);
    return (
        <CenterModal
            open={toUpdateUserId !== undefined}
            onOpenChange={(open) => {
                !open && setToUpdateUserId(undefined);
            }}
            modal={false}
        >
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="w-full space-y-3">
                    <InputSimple
                        title={t("firstname")}
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                    <InputSimple
                        title={t("lastname")}
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                    <InputSimple
                        disabled
                        title={t("email")}
                        value={user?.email}
                    />
                    <InputSimple
                        title={t("department")}
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                    />
                    <InputSelectAvatar
                        value={avatarUrl}
                        onValueChange={(url) => setAvatarUrl(url)}
                    />
                    {user?.permission?.role === UserRole.Contributor && (
                        <ButtonLarge
                            className="w-full"
                            text={t("promote")}
                            IconRight={VerifiedUser}
                            variant={ButtonType.TONAL}
                            loading={adminIsLoading}
                            onClick={handleGiveAdminAccess}
                        />
                    )}
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
