import { cn } from "@design-system/Utilities";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { FieldWrapper, type SharedProps } from "../Textfield/FieldWrapper";

export interface TextareaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        SharedProps {}
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, hint, success, error, placeholder, ...props }, ref) => {
        const inputRef = useRef<HTMLTextAreaElement>(null);
        useImperativeHandle(ref, () => inputRef.current as HTMLTextAreaElement);

        return (
            <FieldWrapper
                inputRef={inputRef}
                placeholder={placeholder}
                hint={hint}
                success={success}
                error={error}
                disabled={props.disabled}
                htmlFor={props.id}
                initialPlaceholderUp={!!props.value || !!props.defaultValue}
            >
                <textarea
                    ref={inputRef}
                    className={cn(
                        "bg-transparent w-full p-0 font-default text-base font-regular leading-[20px] border-0 focus:ring-0 placeholder:text-neutral-500 resize-none disabled:text-neutral-500",
                        className,
                    )}
                    {...props}
                />
            </FieldWrapper>
        );
    },
);
