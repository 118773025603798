import { SetStateAction } from "jotai";
import { AnnotationDelta } from "openai/resources/beta/threads/messages";

export type Message = { role: "user" | "assistant"; content: string };

export const appendToLastMessage = (
    text: string,
    setMessages: (value: SetStateAction<Message[]>) => void,
) => {
    setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        const updatedLastMessage = {
            ...lastMessage,
            content: lastMessage.content + text,
        };
        return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
};

export const annotateLastMessage = (
    annotations: AnnotationDelta[],
    setMessages: (value: SetStateAction<Message[]>) => void,
) => {
    setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        const updatedLastMessage = {
            ...lastMessage,
        };
        annotations.forEach((annotation: AnnotationDelta) => {
            updatedLastMessage.content = updatedLastMessage.content.replace(
                annotation.text as string,
                "",
            );
        });
        return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
};

export const appendMessage = (
    message: Message,
    setMessages: (value: SetStateAction<Message[]>) => void,
) => {
    setMessages((m) => [...m, message]);
};
