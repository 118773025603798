import { barHeightClassName } from "@app/screens/Document/Edit/utils";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { type FC } from "react";
import { useUpdateDocumentBar } from "./useUpdateDocumentBar";

export const UpdateDocumentBar: FC<{
    buttonLabel: string;
    description: string;
}> = ({ buttonLabel, description }) => {
    const { inputRef, isUploading, handleChange, handleClick } =
        useUpdateDocumentBar();

    return (
        <FlexRow
            alignItems="center"
            justifyContent="between"
            px="5"
            className={cn("border-b border-b-tertiary", barHeightClassName)}
        >
            <Text variant="body-sm-bold">{description}</Text>
            <label htmlFor="__Beavr_update_document_file_input">
                <Button
                    loading={isUploading}
                    onClick={handleClick}
                    size="sm"
                    variant="tonal"
                >
                    {buttonLabel}
                    <Button.Icon name="upload" />
                </Button>
            </label>
            <input
                hidden
                id="__Beavr_update_document_file_input"
                onChange={handleChange}
                ref={inputRef}
                type="file"
            />
        </FlexRow>
    );
};
