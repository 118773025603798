import { useTranslation } from "react-i18next";

import { Edit } from "iconoir-react";
import { useEffect, useState } from "react";
import {
    Document,
    UpdateDocumentV2Document,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import BaseItem from "../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import InputSimple from "../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../components/Toast";
import SelectInputOwner from "../../../../components/inputs/InputSelect/Owner";
import "./i18n";

export default function UpdateDocumentModal({
    document,
    refetch,
}: {
    document: Document;
    refetch: () => void;
}) {
    const { t } = useTranslation("UpdateDocumentModal");

    const [open, setOpen] = useState<boolean>(false);

    const [name, setName] = useState<string | undefined>(document?.name);
    const [description, setDescription] = useState<string | undefined>(
        document?.description || undefined,
    );
    const [ownerId, setOwnerId] = useState<string | undefined>(
        document?.owner?.id,
    );

    const { mutate, isPending } = useGraphQLMutation(UpdateDocumentV2Document);
    const { setToastSuccess, setToastError } = useSetToast();

    const canSave =
        name !== document.name ||
        description !== document.description ||
        ownerId !== document.owner?.id;
    const handleSave = () => {
        canSave &&
            mutate(
                {
                    documentId: document.id,
                    patch: {
                        assignedToId: ownerId,
                        description: description,
                        name: name,
                    },
                },
                {
                    onSuccess() {
                        refetch();
                        setOpen(false);
                        setToastSuccess(t("document_update_success"));
                    },
                    onError() {
                        refetch();
                        setToastError(t("document_update_error"));
                    },
                },
            );
    };

    useEffect(() => {
        setName(document.name);
        setDescription(document.description || undefined);
    }, [document]);

    return (
        <>
            <BaseItem
                IconLeft={() => <Edit className="text-green w-4 h-4" />}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                {t("update")}
            </BaseItem>
            <CenterModal open={open} onOpenChange={setOpen}>
                <CenterModalContent
                    title={t("title")}
                    subtitle={t("subtitle")}
                    actions={
                        <>
                            <ButtonLarge
                                text={t("save")}
                                variant={ButtonType.ELEVATED}
                                disabled={!canSave}
                                onClick={() => {
                                    handleSave();
                                }}
                                loading={isPending}
                            />
                        </>
                    }
                >
                    <div className="w-full space-y-3">
                        <InputSimple
                            title={t("document_name")}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <InputSimple
                            title={t("document_description")}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <SelectInputOwner
                            value={ownerId}
                            onValueChange={(id) => setOwnerId(id)}
                        />
                    </div>
                </CenterModalContent>
            </CenterModal>
        </>
    );
}
