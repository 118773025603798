import { usePageviewTrack } from "@app/shared/planhat";
import { currentUserRoleAtom } from "@app/store/userStore";
import { UserRole } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { type FC } from "react";
import { Navigate } from "react-router-dom";
import { ConfigurationSection } from "./components/ConfigurationSection";
import { DocumentUploadModal } from "./components/DocumentsUploadModal";
import { PlanSection } from "./components/PlanSection/PlanSection";

const Dashboard: FC = () => {
    const { role } = useAtomValue(currentUserRoleAtom);

    usePageviewTrack("pageview_dashboard");

    if (role === UserRole.Contributor) {
        return <Navigate to="reporting/overview" />;
    } else {
        return (
            <main className="flex flex-col gap-4">
                <ConfigurationSection />
                <PlanSection />
                <DocumentUploadModal />
            </main>
        );
    }
};

export default Dashboard;
