export const spacing = {
    0: "0px",
    px: "1px",
    0.5: "2px",
    1: "4px",
    1.5: "6px",
    2: "8px",
    2.5: "10px",
    3: "12px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "32px",
    8: "40px",
    9: "48px",
    10: "56px",
    11: "64px",
    12: "64px",
    14: "64px",
    16: "64px",
    18: "72px",
    20: "80px",
    28: "112px",
    30: "120px",
    40: "160px",
} as const;

export const padding = {
    p: {
        "0": "p-0",
        "0.5": "p-0.5",
        "1": "p-1",
        "1.5": "p-1.5",
        "2": "p-2",
        "2.5": "p-2.5",
        "3": "p-3",
        "4": "p-4",
        "5": "p-5",
        "6": "p-6",
        "7": "p-7",
        "8": "p-8",
        "9": "p-9",
        "10": "p-10",
        "11": "p-11",
        "12": "p-12",
        "14": "p-14",
        "16": "p-16",
        "18": "p-18",
        "20": "p-20",
        "28": "p-28",
        "30": "p-30",
        "40": "p-40",
    },
    pt: {
        "0": "pt-0",
        "0.5": "pt-0.5",
        "1": "pt-1",
        "1.5": "pt-1.5",
        "2": "pt-2",
        "2.5": "pt-2.5",
        "3": "pt-3",
        "4": "pt-4",
        "5": "pt-5",
        "6": "pt-6",
        "7": "pt-7",
        "8": "pt-8",
        "9": "pt-9",
        "10": "pt-10",
        "11": "pt-11",
        "12": "pt-12",
        "14": "pt-14",
        "16": "pt-16",
        "18": "pt-18",
        "20": "pt-20",
        "28": "pt-28",
        "30": "pt-30",
        "40": "pt-40",
    },
    pb: {
        "0": "pb-0",
        "0.5": "pb-0.5",
        "1": "pb-1",
        "1.5": "pb-1.5",
        "2": "pb-2",
        "2.5": "pb-2.5",
        "3": "pb-3",
        "4": "pb-4",
        "5": "pb-5",
        "6": "pb-6",
        "7": "pb-7",
        "8": "pb-8",
        "9": "pb-9",
        "10": "pb-10",
        "11": "pb-11",
        "12": "pb-12",
        "14": "pb-14",
        "16": "pb-16",
        "18": "pb-18",
        "20": "pb-20",
        "28": "pb-28",
        "30": "pb-30",
        "40": "pb-40",
    },
    pl: {
        "0": "pl-0",
        "0.5": "pl-0.5",
        "1": "pl-1",
        "1.5": "pl-1.5",
        "2": "pl-2",
        "2.5": "pl-2.5",
        "3": "pl-3",
        "4": "pl-4",
        "5": "pl-5",
        "6": "pl-6",
        "7": "pl-7",
        "8": "pl-8",
        "9": "pl-9",
        "10": "pl-10",
        "11": "pl-11",
        "12": "pl-12",
        "14": "pl-14",
        "16": "pl-16",
        "18": "pl-18",
        "20": "pl-20",
        "28": "pl-28",
        "30": "pl-30",
        "40": "pl-40",
    },
    pr: {
        "0": "pr-0",
        "0.5": "pr-0.5",
        "1": "pr-1",
        "1.5": "pr-1.5",
        "2": "pr-2",
        "2.5": "pr-2.5",
        "3": "pr-3",
        "4": "pr-4",
        "5": "pr-5",
        "6": "pr-6",
        "7": "pr-7",
        "8": "pr-8",
        "9": "pr-9",
        "10": "pr-10",
        "11": "pr-11",
        "12": "pr-12",
        "14": "pr-14",
        "16": "pr-16",
        "18": "pr-18",
        "20": "pr-20",
        "28": "pr-28",
        "30": "pr-30",
        "40": "pr-40",
    },
    px: {
        "0": "px-0",
        "0.5": "px-0.5",
        "1": "px-1",
        "1.5": "px-1.5",
        "2": "px-2",
        "2.5": "px-2.5",
        "3": "px-3",
        "4": "px-4",
        "5": "px-5",
        "6": "px-6",
        "7": "px-7",
        "8": "px-8",
        "9": "px-9",
        "10": "px-10",
        "11": "px-11",
        "12": "px-12",
        "14": "px-14",
        "16": "px-16",
        "18": "px-18",
        "20": "px-20",
        "28": "px-28",
        "30": "px-30",
        "40": "px-40",
    },
    py: {
        "0": "py-0",
        "0.5": "py-0.5",
        "1": "py-1",
        "1.5": "py-1.5",
        "2": "py-2",
        "2.5": "py-2.5",
        "3": "py-3",
        "4": "py-4",
        "5": "py-5",
        "6": "py-6",
        "7": "py-7",
        "8": "py-8",
        "9": "py-9",
        "10": "py-10",
        "11": "py-11",
        "12": "py-12",
        "14": "py-14",
        "16": "py-16",
        "18": "py-18",
        "20": "py-20",
        "28": "py-28",
        "30": "py-30",
        "40": "py-40",
    },
} as const;

export const margin = {
    m: {
        auto: "m-auto",
        "0": "m-0",
        "0.5": "m-0.5",
        "1": "m-1",
        "1.5": "m-1.5",
        "2": "m-2",
        "2.5": "m-2.5",
        "3": "m-3",
        "4": "m-4",
        "5": "m-5",
        "6": "m-6",
        "7": "m-7",
        "8": "m-8",
        "9": "m-9",
        "10": "m-10",
        "11": "m-11",
        "12": "m-12",
        "14": "m-14",
        "16": "m-16",
        "18": "m-18",
        "20": "m-20",
        "28": "m-28",
        "30": "m-30",
        "40": "m-40",
    },
    mt: {
        auto: "mt-auto",
        "0": "mt-0",
        "0.5": "mt-0.5",
        "1": "mt-1",
        "1.5": "mt-1.5",
        "2": "mt-2",
        "2.5": "mt-2.5",
        "3": "mt-3",
        "4": "mt-4",
        "5": "mt-5",
        "6": "mt-6",
        "7": "mt-7",
        "8": "mt-8",
        "9": "mt-9",
        "10": "mt-10",
        "11": "mt-11",
        "12": "mt-12",
        "14": "mt-14",
        "16": "mt-16",
        "18": "mt-18",
        "20": "mt-20",
        "28": "mt-28",
        "30": "mt-30",
        "40": "mt-40",
    },
    mb: {
        auto: "mb-auto",
        "0": "mb-0",
        "0.5": "mb-0.5",
        "1": "mb-1",
        "1.5": "mb-1.5",
        "2": "mb-2",
        "2.5": "mb-2.5",
        "3": "mb-3",
        "4": "mb-4",
        "5": "mb-5",
        "6": "mb-6",
        "7": "mb-7",
        "8": "mb-8",
        "9": "mb-9",
        "10": "mb-10",
        "11": "mb-11",
        "12": "mb-12",
        "14": "mb-14",
        "16": "mb-16",
        "18": "mb-18",
        "20": "mb-20",
        "28": "mb-28",
        "30": "mb-30",
        "40": "mb-40",
    },
    ml: {
        auto: "ml-auto",
        "0": "ml-0",
        "0.5": "ml-0.5",
        "1": "ml-1",
        "1.5": "ml-1.5",
        "2": "ml-2",
        "2.5": "ml-2.5",
        "3": "ml-3",
        "4": "ml-4",
        "5": "ml-5",
        "6": "ml-6",
        "7": "ml-7",
        "8": "ml-8",
        "9": "ml-9",
        "10": "ml-10",
        "11": "ml-11",
        "12": "ml-12",
        "14": "ml-14",
        "16": "ml-16",
        "18": "ml-18",
        "20": "ml-20",
        "28": "ml-28",
        "30": "ml-30",
        "40": "ml-40",
    },
    mr: {
        auto: "mr-auto",
        "0": "mr-0",
        "0.5": "mr-0.5",
        "1": "mr-1",
        "1.5": "mr-1.5",
        "2": "mr-2",
        "2.5": "mr-2.5",
        "3": "mr-3",
        "4": "mr-4",
        "5": "mr-5",
        "6": "mr-6",
        "7": "mr-7",
        "8": "mr-8",
        "9": "mr-9",
        "10": "mr-10",
        "11": "mr-11",
        "12": "mr-12",
        "14": "mr-14",
        "16": "mr-16",
        "18": "mr-18",
        "20": "mr-20",
        "28": "mr-28",
        "30": "mr-30",
        "40": "mr-40",
    },
    mx: {
        auto: "mx-auto",
        "0": "mx-0",
        "0.5": "mx-0.5",
        "1": "mx-1",
        "1.5": "mx-1.5",
        "2": "mx-2",
        "2.5": "mx-2.5",
        "3": "mx-3",
        "4": "mx-4",
        "5": "mx-5",
        "6": "mx-6",
        "7": "mx-7",
        "8": "mx-8",
        "9": "mx-9",
        "10": "mx-10",
        "11": "mx-11",
        "12": "mx-12",
        "14": "mx-14",
        "16": "mx-16",
        "18": "mx-18",
        "20": "mx-20",
        "28": "mx-28",
        "30": "mx-30",
        "40": "mx-40",
    },
    my: {
        auto: "my-auto",
        "0": "my-0",
        "0.5": "my-0.5",
        "1": "my-1",
        "1.5": "my-1.5",
        "2": "my-2",
        "2.5": "my-2.5",
        "3": "my-3",
        "4": "my-4",
        "5": "my-5",
        "6": "my-6",
        "7": "my-7",
        "8": "my-8",
        "9": "my-9",
        "10": "my-10",
        "11": "my-11",
        "12": "my-12",
        "14": "my-14",
        "16": "my-16",
        "18": "my-18",
        "20": "my-20",
        "28": "my-28",
        "30": "my-30",
        "40": "my-40",
    },
} as const;

export const borderRadius = {
    none: "rounded-none",
    sm: "rounded-sm",
    md: "rounded-md",
    DEFAULT: "rounded",
    lg: "rounded-lg",
    xl: "rounded-xl",
    xxl: "rounded-2xl",
    full: "rounded-full",
} as const;

export const height = {
    "0": "h-0",
    px: "h-px",
    "0.5": "h-0.5",
    "1": "h-1",
    "1.5": "h-1.5",
    "2": "h-2",
    "2.5": "h-2.5",
    "3": "h-3",
    "3.5": "h-3.5",
    "4": "h-4",
    "5": "h-5",
    "6": "h-6",
    "7": "h-7",
    "8": "h-8",
    "9": "h-9",
    "10": "h-10",
    "11": "h-11",
    "12": "h-12",
    "14": "h-14",
    "16": "h-16",
    "20": "h-20",
    "24": "h-24",
    "28": "h-28",
    "32": "h-32",
    "36": "h-36",
    "40": "h-40",
    auto: "h-auto",
    "1/2": "h-1/2",
    "1/3": "h-1/3",
    "2/3": "h-2/3",
    "1/4": "h-1/4",
    "2/4": "h-2/4",
    "3/4": "h-3/4",
    "1/5": "h-1/5",
    "2/5": "h-2/5",
    "3/5": "h-3/5",
    "4/5": "h-4/5",
    "1/6": "h-1/6",
    "2/6": "h-2/6",
    "3/6": "h-3/6",
    "4/6": "h-4/6",
    "5/6": "h-5/6",
    full: "h-full",
    screen: "h-screen",
    svh: "h-svh",
    lvh: "h-lvh",
    dvh: "h-dvh",
    min: "h-min",
    max: "h-max",
    fit: "h-fit",
} as const;

export const minHeight = {
    "0": "min-h-0",
    "1": "min-h-1",
    "2": "min-h-2",
    "3": "min-h-3",
    "4": "min-h-4",
    "5": "min-h-5",
    "6": "min-h-6",
    "7": "min-h-7",
    "8": "min-h-8",
    "9": "min-h-9",
    "10": "min-h-10",
    "11": "min-h-11",
    "12": "min-h-12",
    "14": "min-h-14",
    "16": "min-h-16",
    "20": "min-h-20",
    "24": "min-h-24",
    "28": "min-h-28",
    "32": "min-h-32",
    "36": "min-h-36",
    "40": "min-h-40",
    px: "min-h-px",
    "0.5": "min-h-0.5",
    "1.5": "min-h-1.5",
    "2.5": "min-h-2.5",
    "3.5": "min-h-3.5",
    full: "min-h-full",
    screen: "min-h-screen",
    svh: "min-h-svh",
    lvh: "min-h-lvh",
    dvh: "min-h-dvh",
    min: "min-h-min",
    max: "min-h-max",
    fit: "min-h-fit",
} as const;

export const maxHeight = {
    "0": "max-h-0",
    px: "max-h-px",
    "0.5": "max-h-0.5",
    "1": "max-h-1",
    "1.5": "max-h-1.5",
    "2": "max-h-2",
    "2.5": "max-h-2.5",
    "3": "max-h-3",
    "3.5": "max-h-3.5",
    "4": "max-h-4",
    "5": "max-h-5",
    "6": "max-h-6",
    "7": "max-h-7",
    "8": "max-h-8",
    "9": "max-h-9",
    "10": "max-h-10",
    "11": "max-h-11",
    "12": "max-h-12",
    "14": "max-h-14",
    "16": "max-h-16",
    "20": "max-h-20",
    "24": "max-h-24",
    "28": "max-h-28",
    "32": "max-h-32",
    "36": "max-h-36",
    "40": "max-h-40",
    "44": "max-h-44",
    "48": "max-h-48",
    "52": "max-h-52",
    "56": "max-h-56",
    "60": "max-h-60",
    "64": "max-h-64",
    "72": "max-h-72",
    "80": "max-h-80",
    "96": "max-h-96",
    none: "max-h-none",
    full: "max-h-full",
    screen: "max-h-screen",
    svh: "max-h-svh",
    lvh: "max-h-lvh",
    dvh: "max-h-dvh",
    min: "max-h-min",
    max: "max-h-max",
    fit: "max-h-fit",
} as const;

export const width = {
    "0": "w-0",
    px: "w-px",
    "0.5": "w-0.5",
    "1": "w-1",
    "1.5": "w-1.5",
    "2": "w-2",
    "2.5": "w-2.5",
    "3": "w-3",
    "3.5": "w-3.5",
    "4": "w-4",
    "5": "w-5",
    "6": "w-6",
    "7": "w-7",
    "8": "w-8",
    "9": "w-9",
    "10": "w-10",
    "11": "w-11",
    "12": "w-12",
    "14": "w-14",
    "16": "w-16",
    "20": "w-20",
    "24": "w-24",
    "28": "w-28",
    "32": "w-32",
    "36": "w-36",
    "40": "w-40",
    auto: "w-auto",
    "1/2": "w-1/2",
    "1/3": "w-1/3",
    "2/3": "w-2/3",
    "1/4": "w-1/4",
    "2/4": "w-2/4",
    "3/4": "w-3/4",
    "1/5": "w-1/5",
    "2/5": "w-2/5",
    "3/5": "w-3/5",
    "4/5": "w-4/5",
    "1/6": "w-1/6",
    "2/6": "w-2/6",
    "3/6": "w-3/6",
    "4/6": "w-4/6",
    "5/6": "w-5/6",
    full: "w-full",
    screen: "w-screen",
    svw: "w-svw",
    lvw: "w-lvw",
    dvw: "w-dvw",
    min: "w-min",
    max: "w-max",
    fit: "w-fit",
} as const;

export const maxWidth = {
    "0": "max-w-0",
    px: "max-w-px",
    "0.5": "max-w-0.5",
    "1": "max-w-1",
    "1.5": "max-w-1.5",
    "2": "max-w-2",
    "2.5": "max-w-2.5",
    "3": "max-w-3",
    "3.5": "max-w-3.5",
    "4": "max-w-4",
    "5": "max-w-5",
    "6": "max-w-6",
    "7": "max-w-7",
    "8": "max-w-8",
    "9": "max-w-9",
    "10": "max-w-10",
    "11": "max-w-11",
    "12": "max-w-12",
    "14": "max-w-14",
    "16": "max-w-16",
    "20": "max-w-20",
    "24": "max-w-24",
    "28": "max-w-28",
    "32": "max-w-32",
    "36": "max-w-36",
    "40": "max-w-40",
    none: "max-w-none",
    xs: "max-w-xs",
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg",
    xl: "max-w-xl",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "4xl": "max-w-4xl",
    "5xl": "max-w-5xl",
    "6xl": "max-w-6xl",
    "7xl": "max-w-7xl",
    full: "max-w-full",
    min: "max-w-min",
    max: "max-w-max",
    fit: "max-w-fit",
    prose: "max-w-prose",
    "screen-sm": "max-w-screen-sm",
    "screen-md": "max-w-screen-md",
    "screen-lg": "max-w-screen-lg",
    "screen-xl": "max-w-screen-xl",
    "screen-2xl": "max-w-screen-2xl",
} as const;

export const minWidth = {
    "0": "min-w-0",
    px: "min-w-px",
    "0.5": "min-w-0.5",
    "1": "min-w-1",
    "1.5": "min-w-1.5",
    "2": "min-w-2",
    "2.5": "min-w-2.5",
    "3": "min-w-3",
    "3.5": "min-w-3.5",
    "4": "min-w-4",
    "5": "min-w-5",
    "6": "min-w-6",
    "7": "min-w-7",
    "8": "min-w-8",
    "9": "min-w-9",
    "10": "min-w-10",
    "11": "min-w-11",
    "12": "min-w-12",
    "14": "min-w-14",
    "16": "min-w-16",
    "20": "min-w-20",
    "24": "min-w-24",
    "28": "min-w-28",
    "32": "min-w-32",
    "36": "min-w-36",
    "40": "min-w-40",
    full: "min-w-full",
    min: "min-w-min",
    max: "min-w-max",
    fit: "min-w-fit",
} as const;

export const size = {
    "0": "size-0",
    px: "size-px",
    "0.5": "size-0.5",
    "1": "size-1",
    "1.5": "size-1.5",
    "2": "size-2",
    "2.5": "size-2.5",
    "3": "size-3",
    "3.5": "size-3.5",
    "4": "size-4",
    "5": "size-5",
    "6": "size-6",
    "7": "size-7",
    "8": "size-8",
    "9": "size-9",
    "10": "size-10",
    "11": "size-11",
    "12": "size-12",
    "14": "size-14",
    "16": "size-16",
    "20": "size-20",
    "24": "size-24",
    "28": "size-28",
    "32": "size-32",
    "36": "size-36",
    "40": "size-40",
    auto: "size-auto",
    "1/2": "size-1/2",
    "1/3": "size-1/3",
    "2/3": "size-2/3",
    "1/4": "size-1/4",
    "2/4": "size-2/4",
    "3/4": "size-3/4",
    "1/5": "size-1/5",
    "2/5": "size-2/5",
    "3/5": "size-3/5",
    "4/5": "size-4/5",
    "1/6": "size-1/6",
    "2/6": "size-2/6",
    "3/6": "size-3/6",
    "4/6": "size-4/6",
    "5/6": "size-5/6",
    full: "size-full",
    min: "size-min",
    max: "size-max",
    fit: "size-fit",
} as const;

export const elevation = {
    popup: "shadow-popup",
    container: "shadow-container",
    dropdown: "shadow-dropdown",
    button_elevated: "shadow-button_elevated",
    // elevation
    "b-100": "shadow-b-100",
    "b-200": "shadow-b-200",
    "b-300": "shadow-b-300",
    "b-400": "shadow-b-400",
    "t-100": "shadow-t-100",
    "t-200": "shadow-t-200",
    "t-300": "shadow-t-300",
    "t-400": "shadow-t-400",
} as const;

export const layout = {
    display: {
        block: "block",
        "inline-block": "inline-block",
        inline: "inline",
        flex: "flex",
        "inline-flex": "inline-flex",
        table: "table",
        "inline-table": "inline-table",
        "table-caption": "table-caption",
        "table-cell": "table-cell",
        "table-column": "table-column",
        "table-column-group": "table-column-group",
        "table-footer-group": "table-footer-group",
        "table-header-group": "table-header-group",
        "table-row-group": "table-row-group",
        "table-row": "table-row",
        "flow-root": "flow-root",
        grid: "grid",
        "inline-grid": "inline-grid",
        contents: "contents",
        "list-item": "list-item",
        hidden: "hidden",
    },
    direction: {
        row: "flex-row",
        "row-reverse": "flex-row-reverse",
        col: "flex-col",
        "col-reverse": "flex-col-reverse",
    },
    wrap: {
        wrap: "flex-wrap",
        "wrap-reverse": "flex-wrap-reverse",
        nowrap: "flex-nowrap",
    },
    gap: {
        "0": "gap-0",
        px: "gap-px",
        "0.5": "gap-0.5",
        "1": "gap-1",
        "1.5": "gap-1.5",
        "2": "gap-2",
        "2.5": "gap-2.5",
        "3": "gap-3",
        "3.5": "gap-3.5",
        "4": "gap-4",
        "5": "gap-5",
        "6": "gap-6",
        "7": "gap-7",
        "8": "gap-8",
        "9": "gap-9",
        "10": "gap-10",
        "11": "gap-11",
        "12": "gap-12",
        "14": "gap-14",
        "16": "gap-16",
        "20": "gap-20",
        "24": "gap-24",
        "28": "gap-28",
        "32": "gap-32",
        "36": "gap-36",
        "40": "gap-40",
    },
    justifyContent: {
        start: "justify-start",
        end: "justify-end",
        center: "justify-center",
        between: "justify-between",
        around: "justify-around",
        evenly: "justify-evenly",
        stretch: "justify-stretch",
        normal: "justify-normal",
    },
    alignItems: {
        start: "items-start",
        end: "items-end",
        center: "items-center",
        baseline: "items-baseline",
        stretch: "items-stretch",
    },
    alignSelf: {
        auto: "self-auto",
        start: "self-start",
        end: "self-end",
        center: "self-center",
        stretch: "self-stretch",
        baseline: "self-baseline",
    },
} as const;

export const textColor = {
    primary: "text-primary",
    secondary: "text-secondary",
    tertiary: "text-tertiary",
    disabled: "text-disabled",
    brand: "text-brand",
    brand_hover: "text-brand_hover",
    danger: "text-danger",
    dark: "text-dark",
    inherit: "text-inherit",
    white: "text-white",
    warning: "text-warning-500",
} as const;

export const textVariant = {
    hero1: "hero-1",
    hero2: "hero-2",
    hero3: "hero-3",
    hero4: "hero-4",
    hero5: "hero-5",
    hero6: "hero-6",
    header1: "text-2xl font-bold",
    header2: "text-xl font-bold",
    header3: "text-lg font-bold",
    "body-lg": "text-lg",
    "body-lg-bold": "text-lg font-bold",
    body: "text-base",
    "body-bold": "text-base font-bold",
    "body-sm": "text-sm",
    "body-sm-bold": "text-sm font-bold",
    "body-xs": "text-xs",
    "body-xs-bold": "text-xs font-bold",
    caption: "text-xs uppercase",
    "caption-bold": "text-[11px] font-bold uppercase",
} as const;

export const textLayout = {
    align: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
        justify: "text-justify",
        start: "text-start",
        end: "text-end",
    },
    verticalAlign: {
        top: "align-top",
        middle: "align-middle",
        bottom: "align-bottom",
        baseline: "align-baseline",
        "text-top": "align-text-top",
        "text-bottom": "align-text-bottom",
        sub: "align-sub",
        super: "align-super",
    },
    whitespace: {
        normal: "whitespace-normal",
        nowrap: "whitespace-nowrap",
        pre: "whitespace-pre",
        "pre-line": "whitespace-pre-line",
        "pre-wrap": "whitespace-pre-wrap",
    },
} as const;

export function mapPropsToClasses(props: Record<string, any>) {
    return Object.entries(props)
        .map(([prop, value]) => {
            if (isPadding(prop)) {
                return padding[prop][
                    value as keyof (typeof padding)[keyof typeof padding]
                ];
            }
            if (isMargin(prop)) {
                return margin[prop][
                    value as keyof (typeof margin)[keyof typeof margin]
                ];
            }
            if (isHeight(prop)) {
                return height[value as keyof typeof height];
            }

            if (isMinHeight(prop)) {
                return minHeight[value as keyof typeof minHeight];
            }

            if (isMaxHeight(prop)) {
                return maxHeight[value as keyof typeof maxHeight];
            }

            if (isWidth(prop)) {
                return width[value as keyof typeof width];
            }

            if (isMaxWidth(prop)) {
                return maxWidth[value as keyof typeof maxWidth];
            }

            if (isMinWidth(prop)) {
                return minWidth[value as keyof typeof minWidth];
            }

            if (prop === "size") {
                return size[value as keyof typeof size];
            }

            if (prop === "br") {
                return borderRadius[value as keyof typeof borderRadius];
            }

            if (prop === "elevation") {
                return elevation[value as keyof typeof elevation];
            }

            if (isLayout(prop)) {
                return layout[prop][
                    value as keyof (typeof layout)[keyof typeof layout]
                ];
            }

            if (prop === "textColor") {
                return textColor[value as keyof typeof textColor];
            }

            if (prop === "textVariant") {
                return textVariant[value as keyof typeof textVariant];
            }

            if (isTextLayout(prop)) {
                return textLayout[prop][
                    value as keyof (typeof textLayout)[keyof typeof textLayout]
                ];
            }

            return undefined;
        })
        .filter((className) => className !== undefined);
}

function isHeight(key: unknown): key is keyof typeof height {
    return key === "h";
}

function isMinHeight(key: unknown): key is keyof typeof minHeight {
    return key === "minH";
}

function isMaxHeight(key: unknown): key is keyof typeof maxHeight {
    return key === "maxH";
}

function isPadding(key: unknown): key is keyof typeof padding {
    return (
        key === "p" ||
        key === "pt" ||
        key === "pb" ||
        key === "pl" ||
        key === "pr" ||
        key === "px" ||
        key === "py"
    );
}

function isMargin(key: unknown): key is keyof typeof margin {
    return (
        key === "m" ||
        key === "mt" ||
        key === "mb" ||
        key === "ml" ||
        key === "mr" ||
        key === "mx" ||
        key === "my"
    );
}

function isWidth(key: unknown): key is keyof typeof width {
    return key === "w";
}

function isMaxWidth(key: unknown): key is keyof typeof maxWidth {
    return key === "maxW";
}

function isMinWidth(key: unknown): key is keyof typeof minWidth {
    return key === "minW";
}

function isLayout(key: unknown): key is keyof typeof layout {
    return (
        key === "display" ||
        key === "direction" ||
        key === "wrap" ||
        key === "gap" ||
        key === "justifyContent" ||
        key === "alignItems" ||
        key === "alignSelf"
    );
}

function isTextLayout(key: unknown): key is keyof typeof textLayout {
    return key === "align" || key === "verticalAlign" || key === "whitespace";
}
