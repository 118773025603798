import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { OrganizationRepository } from "@app/repositories/OrganizationRepository";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import {
    GetBeavrAdminDocument,
    GetUserOrganisationsDocument,
    GetUserOrganisationsQueryVariables,
    UpdateOrgDocument,
    UpdateOrgMutationVariables,
} from "@generated/client/graphql";

export class GraphQLOrganizationRepository implements OrganizationRepository {
    get = async (input: GetUserOrganisationsQueryVariables) =>
        graphqlClientKoyeb.request(
            GetUserOrganisationsDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    getBeavrInternalUsers = async () =>
        graphqlClientKoyeb.request(
            GetBeavrAdminDocument,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );

    updateOrganization = async (input: UpdateOrgMutationVariables) => {
        return graphqlClientKoyeb.request(
            UpdateOrgDocument,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
