import Checkbox from "@app/components/Checkbox";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { File } from "@generated/client/graphql";
import * as Popover from "@radix-ui/react-popover";
import { useContext, useRef } from "react";
import { InputContext } from ".";

export type CommentBoxProps = {
    comment: string | undefined;
    proofs: File[] | undefined;
    onCommentChange: (comment: string | undefined) => void;
    onCommentBlur?: (comment: string | undefined) => void;
    setOpen?: (open: boolean) => void;
    checked?: boolean;
    onChecked?: (checked: boolean) => void;
    checkTextPlaceholder: string;
};

const CommentBox = ({
    comment,
    proofs,
    onCommentChange,
    onCommentBlur,
    setOpen,
    checked,
    onChecked,
    checkTextPlaceholder,
}: CommentBoxProps) => {
    const ctx = useContext(InputContext);
    if (!ctx) throw new Error("Context must not null");

    // this is a dummy disable state that is set to true whenenver onChange is called.
    // We can have someting smarter that would compare local state to the intiial value
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // if comment is an empty string, set to undefined
        onCommentChange(e.target.value?.trimStart() || undefined);
    };
    const handleBlur = () => {
        onCommentBlur?.(comment);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.metaKey && e.key === "Enter") {
            textareaRef.current?.blur();
            handleSave();
        }
    };
    const handleSave = () => {
        setOpen?.(false);
    };

    return (
        <FlexCol
            justifyContent="center"
            elevation="popup"
            p="2"
            gap="1"
            className="border rounded-lg bg-primary w-[385px]"
        >
            <div className="relative">
                <textarea
                    ref={textareaRef}
                    value={comment}
                    onChange={handleCommentChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    className="w-full block h-full resize-none border-1 rounded-lg border-secondary focus:border-secondary bg-secondary focus:ring-0 "
                />
                <Button
                    onClick={handleSave}
                    size="xs"
                    className="rounded-[20px] absolute right-1.5 bottom-1.5 h-[24px] w-[24px]"
                >
                    <Icon name="arrowUp" className="w-3 text-white"></Icon>
                </Button>
            </div>
            {onChecked && (
                <FlexRow h="6" alignItems="center" justifyContent="between">
                    {!!proofs?.length ? (
                        <Popover.Root>
                            <Popover.Trigger>
                                <FlexRow
                                    alignItems="center"
                                    gap="1"
                                    p="1"
                                    className="rounded-sm cursor-pointer hover:bg-tertiary"
                                >
                                    <Icon name="document" size="sm"></Icon>
                                    <Text variant="body-sm-bold" className="">
                                        proofs
                                    </Text>
                                </FlexRow>
                            </Popover.Trigger>
                            <Popover.Content align="start">
                                <FlexCol
                                    gap="2"
                                    className="bg-white rounded-xxl "
                                    p="3"
                                    elevation="popup"
                                >
                                    {proofs.map((proof, index) => (
                                        <a
                                            key={proof?.id}
                                            href={proof?.signedUrl || undefined}
                                            target="_blank"
                                            className="font-bold text-base"
                                        >
                                            {`Proof ${index + 1}`}
                                        </a>
                                    ))}
                                </FlexCol>
                            </Popover.Content>
                        </Popover.Root>
                    ) : (
                        <div />
                    )}
                    <FlexRow
                        h="6"
                        alignItems="center"
                        justifyContent="end"
                        gap="1.5"
                    >
                        <Checkbox
                            checked={checked || false}
                            onCheckedChange={onChecked}
                        ></Checkbox>
                        <Text variant="body-sm">{checkTextPlaceholder}</Text>
                    </FlexRow>
                </FlexRow>
            )}
        </FlexCol>
    );
};

export default CommentBox;
