import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "DocumentEditorSideBar", {
    info_button_title: "Infos",
    content_button_title: "Content",
    show_more: "Show more",
    show_less: "Show less",
    policy_main_desc:
        "A policy is a document that formalizes your commitments and objectives with regard to one or more key sustainability issues. The policies you write form the basis of your sustainability management system.",
    policy_extended_intro: "To be considered compliant, a policy must :",
    policy_extended_content:
        "<li>Cover one or more key sustainability issues relevant to your organization.</li><li>Specify the scope of application (entities, employees).</li><li>Specify the quantitative targets you are setting for each key issue and the associated time horizon.</li><li>Specify governance, division of responsibilities (who is accountable at the highest level?), and the policy implementation process.</li><li>Indicate mechanisms for reviewing and updating the policy.</li>",
    conclusion:
        "The templates prepared by the Beavr team systematically follow this structure.",
    procedure_main_desc:
        "A procedure is a document that formalizes one or more internal processes and gives precise instructions on how to complete certain tasks. The procedures you formalize provide a framework for the measures and initiatives deployed internally within the organization.",
    procedure_extended_intro: "To be considered compliant, a procedure must :",
    procedure_extended_content:
        "<li>Be relevant, i.e. cover one or more key sustainability issues applicable to your organization.</li> <li>Specify the context and process(es) covered, as well as the scope of application.</li> <li>Specify the mechanisms in place within the organization or the key steps to be followed.</li> <li>Specify governance: responsible party, validator, etc.</li> <li>Specify mechanisms for reviewing and updating the procedure.</li>",
    children_documents: "Child documents",
    parent_documents: "Parent documents",
    go_to_doc: "Go to document",
    insert_doc: "Insert in current document",
    no_data_yet: "No data yet",
    document_not_started: "Document not started",
    document_is_not_text: "Document is not a text file",
    version_history_title: "version history",
    current_version: "Current version",
    restore_version: "Restore this version",
    download_version: "Download this version",
    mark_as_resolved: "Mark as resolved",
    unresolve: "Unresolve",
    edit_comment: "Edit comment",
    delete_comment: "Delete comment",
    show_resolved: "Show resolved ({{ count }})",
    add_comment: "Add comment",
    no_resolved_comments: "No resolved comments yet",
    no_active_comments: "No active comments yet",
    custom_doc_has_no_description: "This custom document has no description",
    view_template: "View template",
});

i18next.addResourceBundle("fr", "DocumentEditorSideBar", {
    info_button_title: "Infos",
    content_button_title: "Contenu",
    show_more: "Voir plus",
    show_less: "Voir moins",
    policy_main_desc:
        "Une politique est un document qui vient formaliser vos engagements et vos objectifs sur un ou plusieurs enjeux de durabilité. Les politiques que vous rédigez constituent le socle de votre système de management de la durabilité.",
    policy_extended_intro:
        "Afin d'être jugée conforme, une politique doit impérativement :",
    policy_extended_content:
        "<li>Couvrir les enjeux clés de durabilité pertinents pour votre organisation.</li><li>Préciser le champ d’application (entités, employés).</li><li>Spécifier les objectifs quantitatifs que vous vous fixez pour chaque enjeu clé et l’horizon de temps associé.</li><li>Préciser la gouvernance, la répartition des responsabilités (qui est responsable au plus haut niveau ?), et le processus de mise en œuvre de la politique.</li><li>Indiquer les mécanismes d'examen et de mise à jour de la politique</li>",
    conclusion:
        "Les modèles préparés par l’équipe Beavr respectent systématiquement cette structure.",
    procedure_main_desc:
        "Une procédure est un document qui vient formaliser un (ou plusieurs) processus internes et donner des instructions précises sur la façon de compléter certaines tâches. Les procédures que vous formalisez viennent encadrer les mesures et initiatives déployées en interne dans l’organisation.",
    procedure_extended_intro:
        "Afin d’être jugée conforme, une procédure doit impérativement :",
    procedure_extended_content:
        "<li>Etre pertinente, c’est à dire couvrir un ou plusieurs enjeux clés de durabilité applicables à votre organisation.</li><li>Préciser le contexte et le ou les processus couverts, ainsi que le périmètre d’application.</li><li>Spécifier les mécanismes mis en place dans l’organisation ou les étapes clés à suivre.</li><li>Préciser la gouvernance : responsable, validateur, etc.</li><li>Indiquer les mécanismes d'examen et de mise à jour de la procédure</li>",
    children_documents: "Documents enfants",
    parent_documents: "Documents parents",
    go_to_doc: "Aller au document",
    insert_doc: "Insérer dans le document courrant",
    no_data_yet: "Pas encore de données",
    document_not_started: "Document non débuté",
    document_is_not_text: "Le document n'est pas un fichier texte",
    version_history_title: "historique des versions",
    current_version: "Version actuelle",
    restore_version: "Restaurer cette version",
    download_version: "Télécharger cette version",
    mark_as_resolved: "Résoudre",
    unresolve: "Rouvrir",
    edit_comment: "Modifier le commentaire",
    delete_comment: "Supprimer le commentaire",
    show_resolved: "Afficher les commentaires résolus ({{ count }})",
    add_comment: "Ajouter un commentaire",
    no_resolved_comments: "Pas encore de commentaires résolus",
    no_active_comments: "Pas encore de commentaires actifs",
    custom_doc_has_no_description:
        "Ce document spécifique n'a pas de description",
    view_template: "Aperçu du modèle",
});
