import { DocumentsSuggestions } from "@app/usecases/DocumentUseCases/types";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "./Item";

type MatchedTabProps = {
    onSearchForMoreClick: () => void;
    suggestions: DocumentsSuggestions;
};

export const MatchedTab: FC<MatchedTabProps> = ({
    onSearchForMoreClick,
    suggestions,
}) => {
    const { t } = useTranslation("DocumentsUploadModal");
    return (
        <>
            <FlexRow py="0.5" px="1" justifyContent="between">
                <Text color="secondary" variant="body-sm">
                    {t("multiselect_matched_documents")}
                </Text>
                <a
                    className="cursor-pointer inline-flex items-center gap-1"
                    style={{ textDecoration: "none" }}
                    onClick={onSearchForMoreClick}
                >
                    <Text color="brand" variant="body-sm-bold">
                        {t("multiselect_search_for_more")}
                    </Text>
                    <Icon name="add" size="xs" />
                </a>
            </FlexRow>
            {suggestions.items.map(({ id, name, theme }) => {
                return <Item id={id} key={id} name={name} theme={theme} />;
            })}
        </>
    );
};
