import { Box } from "@design-system/Layout/Box";
import { ClassValue, cn } from "@design-system/Utilities";
import { PropsWithChildren, type FC } from "react";

export type ChipProps = PropsWithChildren<{
    active?: boolean;
    className?: ClassValue;
}>;

export const Chip: FC<ChipProps> = ({ active, children, className }) => {
    return (
        <Box
            px="4"
            py="2"
            alignItems="start"
            br="full"
            className={cn(
                "border cursor-pointer text-primary font-bold bg-secondary",
                active && "bg-beavrGreen text-white",
                className,
            )}
            elevation="b-100"
        >
            {children}
        </Box>
    );
};
