import { Chip as BaseChip } from "@design-system/DataDisplay/Chip";
import { FlexRow } from "@design-system/Layout/Flex";
import { ClassValue, cn } from "@design-system/Utilities";
import {
    ButtonHTMLAttributes,
    ForwardRefExoticComponent,
    PropsWithChildren,
    Ref,
    createContext,
    forwardRef,
    useContext,
    useEffect,
    type FC,
} from "react";

type ChipSelectContext = {
    values: string[];
    onValuesChange: (values: string[]) => void;
    mode: "single" | "multi";
};

export const ChipSelectContext = createContext<ChipSelectContext | null>(null);

type ChipComponentProps = { value: string };

export type ChipComponentType = ForwardRefExoticComponent<
    PropsWithChildren<
        ChipComponentProps & ButtonHTMLAttributes<HTMLButtonElement>
    >
>;

const Chip: ChipComponentType = forwardRef(
    ({ children, value }, ref: Ref<HTMLButtonElement>) => {
        const ctx = useContext(ChipSelectContext);
        if (!ctx) throw new Error("Context must not null");
        const { values, onValuesChange, mode } = ctx;
        const selected = values.includes(value);
        const toggleActive = () => {
            if (mode === "single") {
                onValuesChange(selected ? [] : [value]);
                return;
            }
            if (selected) {
                onValuesChange(values.filter((elt) => elt !== value));
            } else {
                onValuesChange([...values, value]);
            }
        };

        return (
            <button ref={ref} onClick={toggleActive} className="[all:unset]">
                <BaseChip active={selected}>{children}</BaseChip>
            </button>
        );
    },
);

export type ChipSelectComponentType = FC<
    PropsWithChildren<{
        values: string[];
        onValuesChange: (values: string[]) => void;
        className?: ClassValue;
        mode?: "single" | "multi";
    }>
> & {
    Chip: typeof Chip;
};

export const ChipSelect: ChipSelectComponentType = ({
    children,
    values,
    onValuesChange,
    className,
    mode = "multi",
}) => {
    useEffect(() => {
        if (mode === "single" && values.length > 1) {
            onValuesChange([values[values.length - 1]]);
        }
    }, [mode]);

    return (
        <ChipSelectContext.Provider value={{ values, onValuesChange, mode }}>
            <FlexRow alignItems="center" gap="2" className={cn(className)}>
                {children}
            </FlexRow>
        </ChipSelectContext.Provider>
    );
};

ChipSelect.Chip = Chip;
