import { ReactComponent as PDFDocumentIllustration } from "@app/assets/illustrations/document-pdf.svg";
import { ReactComponent as PPTDocumentIllustration } from "@app/assets/illustrations/document-ppt.svg";
import { ReactComponent as TxtDocumentIllustration } from "@app/assets/illustrations/document-txt.svg";
import { ReactComponent as UnknownDocumentIllustration } from "@app/assets/illustrations/document-unknown-format.svg";
import { ReactComponent as XLSDocumentIllustration } from "@app/assets/illustrations/document-xls.svg";
import { Box } from "@design-system/Layout/Box";

export const FileIcon = ({ mimeType }: { mimeType: string }) => {
    const isPdf = mimeType === "application/pdf";
    const isPpt = [
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-powerpoint.template.macroenabled.12",
        "",
    ].includes(mimeType);
    const isXls = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(mimeType);
    const isTxt = [
        "text/plain",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(mimeType);
    const isUnknown = !isPdf && !isPpt && !isXls && !isTxt;
    return (
        <Box>
            {isPdf && <PDFDocumentIllustration />}
            {isPpt && <PPTDocumentIllustration />}
            {isXls && <XLSDocumentIllustration />}
            {isTxt && <TxtDocumentIllustration />}
            {isUnknown && <UnknownDocumentIllustration />}
        </Box>
    );
};
