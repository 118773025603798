import { NotifyNewCommentOnDocumentDocument } from "../../../generated/client/graphql";
import { useGraphQLMutation } from "../../QueryClientWithHeaders";

export const useNotifications = () => {
    const { mutate: notifyNewCommentOnDoc } = useGraphQLMutation(
        NotifyNewCommentOnDocumentDocument,
    );

    return { notifyNewCommentOnDoc };
};
