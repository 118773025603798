import { useHover } from "@uidotdev/usehooks";
import { MouseEvent } from "react";
interface CheckboxProps {
    checked: boolean | "partial";
    disabled?: boolean;
    onCheckedChange?: (checked: boolean) => void;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    color?: string;
    allowPartialClick?: boolean;
}

export default function Checkbox({
    checked,
    disabled,
    onCheckedChange,
    onClick,
    color,
    allowPartialClick,
}: CheckboxProps) {
    const c = color || "var(--color-green-DEFAULT)";

    const [ref, hovering] = useHover<HTMLButtonElement>();

    const checkColor = hovering ? c : "var(--color-neutral-500)";

    if (checked === true) {
        return (
            <button
                onClick={(e) => {
                    onCheckedChange?.(!checked);
                    onClick?.(e);
                }}
                style={{
                    color: disabled ? "var(--color-neutral-300)" : c,
                }}
                disabled={disabled}
            >
                <svg
                    className="text-inherit"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.66668 0C1.19391 0 0 1.19391 0 2.66668L0 13.3334C0 14.8061 1.19391 16.0001 2.66668 16.0001H13.3334C14.8061 16.0001 16.0001 14.8061 16.0001 13.3334V2.66668C16.0001 1.19391 14.8061 0 13.3334 0L2.66668 0ZM6.74512 8.94154L4.23531 6.43172L2.66668 8.00036L6.74512 12.0788L13.3334 5.49053L11.7648 3.92189L6.74512 8.94154Z"
                        fill="currentColor"
                    />
                </svg>
            </button>
        );
    } else if (checked === "partial") {
        return (
            <button
                disabled={disabled}
                style={{
                    color: disabled ? "var(--color-neutral-300)" : c,
                }}
                onClick={allowPartialClick ? onClick : undefined}
            >
                <svg
                    className="text-inherit"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3333 1.33333H2.66667C1.93029 1.33333 1.33333 1.93029 1.33333 2.66667V13.3333C1.33333 14.0697 1.93029 14.6667 2.66667 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V2.66667C14.6667 1.93029 14.0697 1.33333 13.3333 1.33333ZM2.66667 0C1.19391 0 0 1.19391 0 2.66667L0 13.3333C0 14.8061 1.19391 16 2.66667 16H13.3333C14.8061 16 16 14.8061 16 13.3333V2.66667C16 1.19391 14.8061 0 13.3333 0L2.66667 0Z"
                        fill="currentColor"
                    />
                    <rect
                        x="3"
                        y="3"
                        width="10"
                        height="10"
                        rx="1"
                        color="currentColor"
                        fill="currentColor"
                    />
                </svg>
            </button>
        );
    } else {
        return (
            <button
                ref={ref}
                onClick={(e) => {
                    onCheckedChange?.(!checked);
                    onClick?.(e);
                }}
                style={{
                    color: disabled ? "var(--color-neutral-300)" : checkColor,
                }}
                disabled={disabled}
            >
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    className="text-inherit"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3333 1.33333H2.66667C1.93029 1.33333 1.33333 1.93029 1.33333 2.66667V13.3333C1.33333 14.0697 1.93029 14.6667 2.66667 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V2.66667C14.6667 1.93029 14.0697 1.33333 13.3333 1.33333ZM2.66667 0C1.19391 0 0 1.19391 0 2.66667L0 13.3333C0 14.8061 1.19391 16 2.66667 16H13.3333C14.8061 16 16 14.8061 16 13.3333V2.66667C16 1.19391 14.8061 0 13.3333 0L2.66667 0Z"
                        fill="currentColor"
                    />
                </svg>
            </button>
        );
    }
}
